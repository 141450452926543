import { createApi } from '@reduxjs/toolkit/query/react';
import { cloneDeep, isError } from 'lodash';

import { EMPTY_LIST_RULES } from '~/constants/commissions';
import { baseQueryHandler } from '~/shared/utils';
import { generateRulesData } from '~/shared/utils/commissions';
import {
  ICommissionTreeResponse,
  IRulesResponse,
  TCommissionsQueryParams,
  TTypeRules,
} from '~/types/commissions';
import { RowType } from '~/types/common';

import {
  EMPTY_COMMISSIONS_INSTRUMENTS,
  EMPTY_COMMISSIONS_TREE,
} from './constants';
import {
  FETCH_COMMISSIONS_RULES_ENDPOINT,
  FETCH_COMMISSION_GROUPS_ENDPOINT,
  getFetchCommissionsInstrumentsEndpoint,
  getFetchCommissionsTreeEndpoint,
  getPostCommissionsEndpoint,
} from './endpoints';
import { convertCommissionsStructureFromFlatToTree } from './helpers';
import {
  ICommissionGroupsResponse,
  IConvertCommissionsStructureFromFlatToTreeReturn,
  IGetInstrumentsParams,
  IGetInstrumentsReturn,
  IResponseDictRules,
  ISearchCommissionsInstrumentsParams,
  ISearchInstrumentsReturn,
} from './types';

export const commissionsApi = createApi({
  reducerPath: 'commissionsApi',
  baseQuery: baseQueryHandler,
  tagTypes: [
    'CommissionGroups',
    'CommissionsInstruments',
    'CommissionsRules',
    'CommissionsSearchInstruments',
    'CommissionsTree',
  ],
  endpoints: (builder) => ({
    getCommissionsRules: builder.query<IResponseDictRules, void>({
      query: () => ({
        url: FETCH_COMMISSIONS_RULES_ENDPOINT,
      }),
      transformResponse: (data) => {
        const listRules = cloneDeep(EMPTY_LIST_RULES);
        const typeRules: TTypeRules = {};

        data.forEach((item: IRulesResponse) => {
          const { type, name, rule } = item;
          listRules[type].push(name);
          typeRules[name] = rule;
        });

        return { listRules, typeRules };
      },
      providesTags: ['CommissionsRules'],
    }),
    getCommissionsTree: builder.query<
      IConvertCommissionsStructureFromFlatToTreeReturn,
      TCommissionsQueryParams | null
    >({
      queryFn: async (params, _, __, fetchWithBaseQuery) => {
        if (!params) {
          return EMPTY_COMMISSIONS_TREE;
        }

        const { layer, entity } = params;

        const { data, error } = await fetchWithBaseQuery({
          url: getFetchCommissionsTreeEndpoint(layer, entity),
        });

        if (error || !data) {
          return {
            error: error || 'Error while fetching tree',
          };
        }

        return {
          data: convertCommissionsStructureFromFlatToTree(data.data),
        };
      },
      providesTags: ['CommissionsTree'],
    }),
    getCommissionsInstruments: builder.query<
      IGetInstrumentsReturn,
      IGetInstrumentsParams
    >({
      // We need custom error type here
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryFn: async (params, { dispatch }, __, fetchWithBaseQuery) => {
        const { path, limit, skip, queryParams } = params;

        if (!queryParams) {
          return EMPTY_COMMISSIONS_INSTRUMENTS;
        }

        const { layer, entity } = queryParams;
        const newParams = { path, limit, skip };

        const { data, error } = await fetchWithBaseQuery({
          url: getFetchCommissionsInstrumentsEndpoint(layer, entity),
          params: newParams,
        });

        if (error || !data) {
          return {
            error: {
              message: error,
            },
          };
        }

        if (data.pagination.total > skip + limit) {
          dispatch(
            commissionsApi.endpoints.getCommissionsInstruments.initiate({
              path,
              limit,
              skip: skip + limit,
              queryParams,
            }),
          );
        }

        return {
          data: {
            ...data,
            path,
          },
        };
      },
      providesTags: ['CommissionsInstruments'],
    }),
    searchCommissionsInstruments: builder.query<
      ISearchInstrumentsReturn,
      ISearchCommissionsInstrumentsParams
    >({
      // We need custom error type here
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryFn: async (params, _, __, fetchWithBaseQuery) => {
        const { search, skip, queryParams, instruments = [] } = params;

        if (!queryParams) {
          return { data: { instruments: [], isFinish: true } };
        }

        const { layer, entity } = queryParams;
        const newParams = { search, skip, limit: Infinity };

        const { data, error } = await fetchWithBaseQuery({
          url: getFetchCommissionsInstrumentsEndpoint(layer, entity),
          params: newParams,
        });

        if (error || !data) {
          return {
            error: {
              message: error,
            },
          };
        }

        const { data: dataInstruments } = data;

        const newInstruments = [
          ...instruments,
          ...dataInstruments.map(
            ({
              commission: { rules, ...commission },
              ...instrument
            }: ICommissionTreeResponse) => ({
              ...instrument,
              ...commission,
              ...generateRulesData(rules),
              rowType: RowType.Instrument,
            }),
          ),
        ];

        return {
          data: {
            instruments: newInstruments,
            isFinish: true,
          },
        };
      },
      providesTags: ['CommissionsSearchInstruments'],
    }),
    saveCommissions: builder.mutation({
      query: ({ changedCommissions, layer, entity }) => ({
        url: getPostCommissionsEndpoint(layer, entity),
        method: 'POST',
        data: changedCommissions,
      }),
      transformErrorResponse: (e) => {
        if (isError(e)) {
          return e.message;
        }
        return e;
      },
    }),
    getCommissionGroupsForOptions: builder.query<
      ICommissionGroupsResponse[],
      void
    >({
      query: () => ({
        url: FETCH_COMMISSION_GROUPS_ENDPOINT,
      }),
      providesTags: ['CommissionGroups'],
    }),
  }),
});

export const {
  useGetCommissionGroupsForOptionsQuery,
  useLazyGetCommissionsInstrumentsQuery,
  useLazyGetCommissionsRulesQuery,
  useLazyGetCommissionsTreeQuery,
  useLazySearchCommissionsInstrumentsQuery,
  useSaveCommissionsMutation,
} = commissionsApi;
