import { styled, TDefaultTheme } from 'react-ui-kit-exante';

export const StyledPopoverContainer = styled('div')(
  ({ theme }: { theme: TDefaultTheme }) => ({
    minWidth: '165px',
    padding: '12px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    backgroundColor: theme?.color.line.primary,
  }),
);

export const StyledOptions = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
}));

export const StyledOptionLabel = styled('span')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

export const StyledOptionIconImg = styled('img')(
  ({ theme }: { theme: TDefaultTheme }) => ({
    width: '16px',
    height: '16px',
    borderRadius: '50%',
    backgroundColor: theme?.color.line.primary,
    marginRight: '10px',
  }),
);

export const StyledOptionIconDiv = styled('div')(
  ({ theme }: { theme: TDefaultTheme }) => ({
    width: '16px',
    height: '16px',
    borderRadius: '50%',
    backgroundColor: theme?.color.line.primary,
    marginRight: '10px',
  }),
);

export const StyledOptionName = styled('span')(() => ({
  fontSize: '16px',
}));
