import { ICommissionRule } from '~/types/commisionRules';

export const columns = [
  {
    Header: 'Name',
    accessor: 'name',
    disableSortBy: true,
    id: 'name',
    type: 'text',
  },
  {
    Header: 'Rule',
    accessor: 'rule',
    disableSortBy: true,
    id: 'rule',
    type: 'text',
  },
  {
    Header: 'Type',
    accessor: 'type',
    disableSortBy: true,
    id: 'type',
    type: 'text',
  },
];

export const RULE_OPTIONS = [
  { label: 'Fixed', value: 'fixed' },
  { label: 'Percent', value: 'percent' },
];

export const TYPE_OPTIONS = [
  { label: 'Internal', value: 'internal' },
  { label: 'Exchange', value: 'exchange' },
  { label: 'Rebate', value: 'rebate' },
];

export const DEFAULT_VALUES: ICommissionRule = {
  name: '',
  type: '',
  rule: '',
};
