import { IconButton, IconButtonProps } from 'react-ui-kit-exante';

type TAddButtonProps = Omit<IconButtonProps, 'iconName' | 'iconColor'>;

export const AddButton = (props: TAddButtonProps) => {
  return (
    <IconButton
      {...props}
      iconName="AddIcon"
      iconColor="action"
      label="Add"
      type="button"
      iconSize={24}
    />
  );
};
