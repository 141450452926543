import { FilterValue } from 'react-ui-kit-exante';

interface NotificationsColumnsProps {
  onFilter(col: string, value: FilterValue): void;
  onRemove(col: string): void;
  channelOptions: { label: string; value: string }[];
}

export const getColumns = ({
  onRemove,
  onFilter,
  channelOptions,
}: NotificationsColumnsProps) => {
  const columns = [
    {
      Header: 'Username',
      accessor: 'username',
      disableSortBy: true,
      onRemove,
      onFilter,
    },
    {
      Header: 'Channel',
      accessor: 'channel',
      disableSortBy: true,
      type: 'multiSelect',
      filterOptions: channelOptions,
      onRemove,
      onFilter,
    },
    {
      Header: 'Type',
      accessor: 'type',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Name',
      accessor: 'name',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Subject',
      accessor: 'subject',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Content',
      accessor: 'content',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Date',
      accessor: 'date',
      disableSortBy: true,
      formatting: 'dateTime',
      type: 'dateTimeRange',
      onRemove,
      onFilter,
    },
  ];

  return columns;
};

export const channelOptions = [
  { value: 'EMAIL', label: 'EMAIL' },
  { value: 'SMS', label: 'SMS' },
  { value: 'PUSH', label: 'PUSH' },
  { value: 'INTERNAL_CA', label: 'INTERNAL_CA' },
];
