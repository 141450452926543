import { ChangeEvent, useCallback, useRef, useState } from 'react';
import { Notification, IColumn } from 'react-ui-kit-exante';

import { DEFAULT_TABLE_COLUMN_WIDTH } from '~/constants';

interface IColumnConfig {
  width: Record<string, number>;
  header: Record<string, string>;
}

function parseCSVbyRows(raw: string): string[] {
  // split by rows and delete empty rows
  const array = raw.split(/\r?\n+/).filter(Boolean);
  // skip repeated rows
  const uniq = new Set(array);

  return Array.from(uniq);
}

const separateTextByColumns = (str: string, keys: string[]) => {
  const preparedString: Record<string, string> = {};
  let current = '';
  let escapeMode = false;
  let keyIndex = 0;
  // eslint-disable-next-line no-restricted-syntax
  for (let i = 0; i < str.length; i += 1) {
    const char = str[i];

    if (char === `"`) {
      escapeMode = !escapeMode;
    } else if (char === ',' && escapeMode) {
      current += char;
    } else if (char === ',' && !escapeMode) {
      const key = keys[keyIndex];
      preparedString[key] = current;
      keyIndex += 1;
      current = '';
    } else {
      current += char;
    }
  }

  preparedString[keys[keyIndex]] = current;
  return preparedString;
};

export function useAttachCSV<
  TColumn extends IColumn<TCSV>,
  TCSV extends Partial<Record<keyof TCSV, unknown>>,
>(COLUMNS_CONFIG: IColumnConfig) {
  const [columns, setColumns] = useState<TColumn[]>([]);
  const [tableData, setTableData] = useState<TCSV[]>([]);
  const fileReaderInstance = useRef(new FileReader());

  const loadHandle = useCallback(() => {
    const { result } = fileReaderInstance.current;

    if (typeof result !== 'string' || result.trim() === '') {
      Notification.error({
        title: 'Incorrect file',
      });
      return;
    }

    const parsedCSVbyRows = parseCSVbyRows(result);
    const newColumns = parsedCSVbyRows[0].split(',');

    if (parsedCSVbyRows.length < 2) {
      Notification.error({
        title: 'Too few lines in the file',
      });
      return;
    }

    const newTableData = parsedCSVbyRows.reduce<TCSV[]>(
      (acc, row, rowIndex) => {
        // skip first row
        if (!rowIndex) {
          return acc;
        }
        const preparedRow = separateTextByColumns(row, newColumns) as TCSV;
        return [...acc, preparedRow];
      },
      [],
    );

    const tableColumns = newColumns.flatMap((key) => {
      if (!key) {
        return [];
      }
      return {
        accessor: key,
        Header: COLUMNS_CONFIG.header[key] || key,
        width: COLUMNS_CONFIG.width[key] || DEFAULT_TABLE_COLUMN_WIDTH,
      };
    }) as TColumn[];

    setTableData(newTableData);
    setColumns(tableColumns);

    if (tableColumns.length < newColumns.length) {
      Notification.warning({
        title:
          'Some columns were omitted because not all headings were provided',
      });
    }
  }, [COLUMNS_CONFIG]);

  const handleInputFile = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      fileReaderInstance.current = new FileReader();
      fileReaderInstance.current.onload = loadHandle;
      if (event.target.files?.[0]) {
        fileReaderInstance.current.readAsText(event.target.files?.[0]);
      }

      // reset the value in order to be able to download the same file
      // eslint-disable-next-line no-param-reassign
      event.target.value = '';
    },
    [loadHandle],
  );

  return { columns, tableData, handleInputFile };
}
