import { REGEX_SPLIT_COMMA_AND_PIPE } from '~/constants';
import { apiRequest, paramsTransformer, formatDate } from '~/shared/utils';
import { IGlobalSummary } from '~/types/accounts';

import {
  EXPORT_GLOBAL_SUMMARY_PARAMS_MAPPER,
  GLOBAL_SUMMARY_PARAMS_MAPPER,
} from './constants';
import {
  createGlobalSummaryAPI,
  getHistoricalGlobalSummaryEndpoint,
} from './endpoints';
import {
  IHistoricalGlobalSummaryParameters,
  IGlobalSummaryParameters,
  IHistoricalGlobalSummaryPayload,
} from './types';

export class GlobalSummaryRepository {
  public static fetchGlobalSummary(params: IGlobalSummaryParameters) {
    const {
      date,
      currency,
      paginationParams,
      sortingParams,
      reconciliationMode,
      filtersParams,
      isAggregatedView,
      isByPositionSide,
      isCurrencyRisk,
    } = params;

    const preparedDate = date ? formatDate(date) : null;

    const { skip, limit } = paginationParams;

    const preparedParams = paramsTransformer({
      params: {
        ...filtersParams,
        reconciliationMode,
        sorting: sortingParams,
      },
      mapper: Array.isArray(sortingParams)
        ? GLOBAL_SUMMARY_PARAMS_MAPPER
        : EXPORT_GLOBAL_SUMMARY_PARAMS_MAPPER,
    });

    const prepareSymbolId = filtersParams?.symbolId
      ?.split(REGEX_SPLIT_COMMA_AND_PIPE)
      .map((item) => item.trim());

    return apiRequest<IGlobalSummary>({
      url: createGlobalSummaryAPI({
        date: preparedDate,
        currency,
        isAggregatedView,
        isByPositionSide,
        isCurrencyRisk,
      }),
      params: { ...preparedParams, symbolId: prepareSymbolId, skip, limit },
    });
  }

  public static fetchHistoricalGlobalSummary(
    params: IHistoricalGlobalSummaryParameters,
    payload: IHistoricalGlobalSummaryPayload,
  ) {
    const { date, currency, filters } = params;

    return apiRequest<IGlobalSummary>({
      url: getHistoricalGlobalSummaryEndpoint(date, currency),
      method: 'POST',
      params: filters,
      data: payload,
    });
  }
}
