import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { NameCell } from '~/shared/components';
import {
  selectDictListRules,
  selectDictTypesRules,
  selectFiltersLayer,
} from '~/store/commissions';

import { PERCENT_MARKER } from '../../constants';

import {
  CheckboxCell,
  CurrencyCell,
  InheritedCell,
  OverrideCell,
  TextCell,
} from './components';

export function useColumns() {
  const filtersLayer = useSelector(selectFiltersLayer);
  const listRules = useSelector(selectDictListRules);
  const typesRules = useSelector(selectDictTypesRules);

  const getProps = useCallback(() => ({ layer: filtersLayer }), [filtersLayer]);

  const rulesIsEmpty = useMemo(
    () => Object.values(listRules).every((item) => !item.length),
    [listRules],
  );

  if (rulesIsEmpty) {
    return [];
  }

  const columns = [
    {
      Cell: NameCell,
      Header: 'Name',
      accessor: 'displayName',
      disableSortBy: true,
      id: 'name',
      width: 2000,
    },
    {
      Cell: OverrideCell,
      Header: 'Override',
      accessor: 'override',
      disableSortBy: true,
      maxWidth: 120,
      getProps,
    },
    {
      Cell: InheritedCell,
      Header: 'Inherited',
      accessor: 'inherited',
      disableSortBy: true,
      maxWidth: 120,
      getProps,
    },
    {
      Cell: CurrencyCell,
      Header: 'Currency',
      accessor: 'currency',
      disableSortBy: true,
      maxWidth: 140,
      getProps,
    },
    {
      Header: 'Min fee',
      columns: [
        {
          Cell: TextCell,
          Header: 'Value',
          accessor: 'minimumFee',
          disableSortBy: true,
          maxWidth: 100,
          getProps,
        },
        {
          Cell: TextCell,
          Header: 'Odd',
          accessor: 'minFeeOdd',
          disableSortBy: true,
          maxWidth: 100,
          getProps,
        },
        {
          Cell: TextCell,
          Header: 'Rebate rate',
          accessor: 'minFeeRebateRate',
          disableSortBy: true,
          minWidth: 100,
          getProps,
        },
        {
          Cell: CheckboxCell,
          Header: 'Exchange',
          accessor: 'minFeeExchange',
          disableSortBy: true,
          minWidth: 100,
          getProps,
        },
      ],
    },
    {
      Header: 'Exchange',
      columns: listRules.exchange.map((rule) => ({
        Cell: TextCell,
        Header:
          typesRules[rule] === 'percent' ? `${rule}${PERCENT_MARKER}` : rule,
        accessor: `exchange.${rule}`,
        disableSortBy: true,
        maxWidth: 100,
        getProps,
      })),
    },
    {
      Header: 'Internal',
      columns: listRules.internal.map((rule) => ({
        Cell: TextCell,
        Header:
          typesRules[rule] === 'percent' ? `${rule}${PERCENT_MARKER}` : rule,
        accessor: `internal.${rule}`,
        disableSortBy: true,
        maxWidth: 100,
        getProps,
      })),
    },
    {
      Header: 'Rebate',
      columns: listRules.rebate.map((rule) => ({
        Cell: TextCell,
        Header:
          typesRules[rule] === 'percent' ? `${rule}${PERCENT_MARKER}` : rule,
        accessor: `rebate.${rule}`,
        disableSortBy: true,
        maxWidth: 100,
        getProps,
      })),
    },
  ];

  return columns;
}
