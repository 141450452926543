import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { fetchCurrencies } from '~/store/currencies';
import { fetchTypes } from '~/store/types';

export function useStoreRequests() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCurrencies());
    dispatch(fetchTypes());
  }, [dispatch]);
}
