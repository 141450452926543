import cn from 'classnames';
import { UseFormGetValues } from 'react-hook-form';
import { Input } from 'react-ui-kit-exante';

import {
  FormCheckboxContainer,
  FormInputContainer,
  FormSelectContainer,
} from '~/containers/form';
import { IOption } from '~/types/form';
import { TPossibleAccessRights } from '~/types/users';

import { TFormData } from '../types';

import {
  StyledPanel,
  StyledColumn,
  StyledCheckboxes,
} from './GeneralForm.styled';
import { formatDateTime } from './helpers';

interface IGeneralFormProps {
  clientName?: string;
  disabledCounterpartyAccountId: boolean;
  disabledPurpose: boolean;
  getValues: UseFormGetValues<TFormData>;
  options: Record<string, IOption[]>;
  rejectedRequestFor: Record<string, boolean>;
  userPermissions: Record<string, TPossibleAccessRights>;
}

export const GeneralForm = ({
  clientName = '',
  disabledCounterpartyAccountId,
  disabledPurpose,
  getValues,
  options,
  rejectedRequestFor,
  userPermissions,
}: IGeneralFormProps) => {
  const disabledCashConversionSettings =
    rejectedRequestFor.cashConversionSettings ||
    !userPermissions['Account info: cash conversion settings'].write;

  const disabledRegularPaymentsSettings =
    rejectedRequestFor.regularPaymentsSettings ||
    !userPermissions['Account info: interests/overnights settings'].write;

  const disabledRoutingSettings =
    rejectedRequestFor.routingSettings ||
    !userPermissions['Account info: routing settings'].write;

  const disabledInternalSettings =
    rejectedRequestFor.internalSettings ||
    !userPermissions['Account info: internal settings'].write;

  const displayMirroringLevel =
    typeof getValues('routing.mirroringLevel') !== 'undefined';

  return (
    <StyledPanel
      className={cn({
        Disabled: !userPermissions['Account info'].write,
      })}
    >
      <StyledColumn>
        <FormInputContainer readonly label="ID" name="account.id" />
        <FormInputContainer
          label="Client ID"
          name="account.clientId"
          readonly
        />
        <FormInputContainer
          label="Archived Date"
          name="account.archivedDate"
          readonly={rejectedRequestFor.account}
        />
        <Input label="Client Name" value={clientName} disabled fullWidth />
        <FormInputContainer
          formatValue={formatDateTime}
          label="Created"
          name="account.created"
          readonly
        />
        <FormSelectContainer
          readOnly
          label="Type"
          name="account.type"
          options={options.accountTypes}
        />
        <FormSelectContainer
          disabled={disabledPurpose || rejectedRequestFor.account}
          label="Purpose"
          name="account.accountPurpose"
          options={options.accountPurpose}
        />
        <FormSelectContainer
          disabled={rejectedRequestFor.account}
          label="Segregation group"
          name="account.ownershipType"
          options={options.ownershipType}
        />
        {userPermissions['Account info: cash conversion settings'].read ? (
          <FormSelectContainer
            disabled={disabledCashConversionSettings}
            label="Autoconversion"
            name="cashConversion.convertCashInto"
            options={options.currenciesAutoConversion}
          />
        ) : null}
        <FormInputContainer
          label="Subowner Description"
          name="account.subOwnerDescription"
          readonly={rejectedRequestFor.account}
        />
        {userPermissions['Account info: routing settings'].read &&
        displayMirroringLevel ? (
          <FormInputContainer
            label="Mirroring level"
            name="routing.mirroringLevel"
            readonly={disabledRoutingSettings}
          />
        ) : null}
        <FormInputContainer
          label="Comment"
          name="account.comment"
          readonly={rejectedRequestFor.account}
        />
        <FormInputContainer
          label="Internal Comment"
          name="account.internalComment"
          readonly={rejectedRequestFor.account}
        />
        <FormSelectContainer
          disabled={disabledCounterpartyAccountId || rejectedRequestFor.account}
          label="Counterparty account name"
          name="account.counterpartyAccountId"
          options={options.accountProviders}
        />
        <FormSelectContainer
          disabled
          label="Branding"
          name="account.branding"
          options={options.brandingList}
        />
        <FormInputContainer
          label="Owner Name override"
          name="extReports.clientName"
        />
        <FormInputContainer
          label="Owner Code override "
          name="extReports.clientCode"
        />
        <FormInputContainer
          label="Account Owner override"
          name="extReports.accountOwner"
        />
      </StyledColumn>

      <StyledCheckboxes>
        {userPermissions['Account info: internal settings'].read ? (
          <FormCheckboxContainer
            disabled={disabledInternalSettings}
            label="Exclude from global metrics"
            name="internal.excludeFromGlobalMetrics"
          />
        ) : null}

        {userPermissions['Account info: interests/overnights settings'].read ? (
          <>
            <FormCheckboxContainer
              disabled={disabledRegularPaymentsSettings}
              label="Use positive PnL"
              name="regularPayments.interestUsePositivePnl"
            />
            <FormCheckboxContainer
              disabled={disabledRegularPaymentsSettings}
              label="Use negative PnL"
              name="regularPayments.interestUseNegativePnl"
            />
            <FormCheckboxContainer
              disabled={disabledRegularPaymentsSettings}
              label="Excess margin fee"
              name="regularPayments.useExcessMarginFee"
            />
          </>
        ) : null}

        <FormCheckboxContainer
          disabled={rejectedRequestFor.commissionSettings}
          label="Calculate commission rebate"
          name="commission.calcCommissionRebate"
        />

        {userPermissions['Account info: interests/overnights settings'].read ? (
          <>
            <FormCheckboxContainer
              disabled={disabledRegularPaymentsSettings}
              label="Calculate positive interest"
              name="regularPayments.calcInterestsPositiveFlag"
            />
            <FormCheckboxContainer
              disabled={disabledRegularPaymentsSettings}
              label="Calculate negative interest"
              name="regularPayments.calcInterestsNegativeFlag"
            />
            <FormCheckboxContainer
              disabled={disabledRegularPaymentsSettings}
              label="Calculate overnight"
              name="regularPayments.calcOvernightsFlag"
            />
            <FormCheckboxContainer
              disabled={disabledRegularPaymentsSettings}
              label="Calculate interest rebate"
              name="regularPayments.calcInterestRebate"
            />{' '}
          </>
        ) : null}

        <FormCheckboxContainer
          disabled={rejectedRequestFor.account}
          label="Archived"
          name="account.archived"
        />
        <FormCheckboxContainer
          disabled={rejectedRequestFor.commissionSettings}
          label="Take minimum fee"
          name="commission.takeMinimumFee"
        />
        {userPermissions['Account info: interests/overnights settings'].read ? (
          <FormCheckboxContainer
            disabled={disabledRegularPaymentsSettings}
            label="Freemoney premium"
            name="regularPayments.useFreemoneyPremium"
          />
        ) : null}
        {userPermissions['Account info: cash conversion settings'].read ? (
          <FormCheckboxContainer
            disabled={disabledCashConversionSettings}
            label="Disable force conversion"
            name="cashConversion.disableForcedConversion"
          />
        ) : null}
      </StyledCheckboxes>
    </StyledPanel>
  );
};
