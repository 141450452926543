import { NavLink } from 'react-router-dom';
import { styled, TDefaultTheme } from 'react-ui-kit-exante';

export const StyledLink = styled(NavLink)(
  ({ theme }: { theme: TDefaultTheme }) => ({
    color: theme?.color.typo?.action,
    cursor: 'pointer',
    textDecoration: 'underline',
    '&:hover': {
      color: theme?.color.typo?.promo,
    },
  }),
);

export const StyledBtn = styled('div')(
  ({ theme }: { theme: TDefaultTheme }) => ({
    color: theme?.color.typo?.action,
    cursor: 'pointer',
    textDecoration: 'underline',
    '&:hover': {
      color: theme?.color.typo?.promo,
    },
  }),
);
