import { getBaseBackOfficeUrl } from '~/constants';
import { DOWNLOAD_RISK_ARRAYS } from '~/resources/symbols/endpoints';

export const createLinkToRiskArrayDownloading = (searchQuery = '') => {
  const baseURL = `https://${getBaseBackOfficeUrl()}`;
  const url = new URL(DOWNLOAD_RISK_ARRAYS, baseURL);

  if (searchQuery) {
    url.searchParams.set('search', searchQuery);
  }

  return url.toString();
};
