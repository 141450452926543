import { useContext } from 'react';
import { Panel } from 'react-ui-kit-exante';

import { DEFAULT_POOLING_INTERVAL_DATA } from '~/constants';
import { useCurrency } from '~/hooks';
import { clientPageContext } from '~/pages/ClientPage/context/ClientPageContext';
import { StaticCenteredLoader } from '~/shared/components';
import { formatDate } from '~/shared/utils';
import { useGetClientAccountsSummaryQuery } from '~/store/client';
import { IAccountSummary } from '~/types/accounts';
import { IUseQueryReturn } from '~/types/rtkQuery';

import { DetailedAccountInfoContainer } from '../../DetailedAccountInfoContainer';

interface IDetailedClientSummaryProps {
  accountIDs: string[];
}

export const DetailedClientSummary = ({
  accountIDs,
}: IDetailedClientSummaryProps) => {
  const currency = useCurrency();
  const {
    clientAccountsSummary: {
      filters: { date, setDate, resetDate },
    },
  } = useContext(clientPageContext);

  const { data, isLoading } = useGetClientAccountsSummaryQuery<
    IUseQueryReturn<IAccountSummary>
  >(
    {
      date: formatDate(date || new Date()),
      currency,
      accounts: accountIDs,
    },
    {
      pollingInterval: DEFAULT_POOLING_INTERVAL_DATA,
    },
  );

  if (isLoading) {
    return <StaticCenteredLoader withBackground height="125px" />;
  }

  if (!data) {
    return <Panel>No data</Panel>;
  }

  return (
    <DetailedAccountInfoContainer
      accountSummary={data}
      date={date}
      setDate={setDate}
      resetDate={resetDate}
      currency={currency}
    />
  );
};
