import { debounce, get, has, isEmpty } from 'lodash';
import { useCallback, useEffect, useRef, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import {
  Table,
  AutocompleteAsync,
  Select,
  Notification,
  ISelectOption,
  TCellData,
} from 'react-ui-kit-exante';

import {
  useLazyGetCommissionsRulesQuery,
  useLazySearchCommissionsInstrumentsQuery,
  useLazyGetCommissionsInstrumentsQuery,
  useLazyGetCommissionSettingsQuery,
} from '~/api';
import {
  useAddNewCommissionGroupMutation,
  useGetCommissionGroupsQuery,
  useRemoveCommissionGroupMutation,
} from '~/api/commissionGroups/commissionGroups.api';
import {
  DEFAULT_DEBOUNCE_TIMEOUT,
  DEFAULT_SORT_TABLE_BY,
  EMPTY_ARRAY,
  MAX_REQUEST_LIMIT,
  MIN_SEARCH_LENGTH,
} from '~/constants';
import {
  useTableVirtualized,
  usePrevious,
  useAccountsAutocomplete,
} from '~/hooks';
import { SettingsIcon } from '~/images/icons';
import { CommissionRules } from '~/pages/CommissionRulesPage';
import { prepareValue } from '~/pages/RiskArrays/helpers';
import { LayersLayout } from '~/shared/components/LayersLayout';
import { Switch } from '~/shared/components/Switch';
import { getTableId, transformDataToSelectOptions } from '~/shared/utils';
import {
  selectCommissionsTree,
  selectExpandedRows,
  filtersSetGroup,
  filtersSetLayer,
  resetTable,
  selectDownloadedPaths,
  selectFiltersAccount,
  selectFiltersGroup,
  selectFiltersLayer,
  filtersSetAccount,
  filtersSetRelatedGroup,
  selectFiltersQueryParams,
  selectChangedCommissions,
  selectFiltersRelatedGroup,
  updateNodeValue,
  updateInstrumentValue,
} from '~/store/commissions';
import { getQueryParams } from '~/store/commissions/helpers';
import { FilterLayers, tabs, ICommissionTree } from '~/types/commissions';

import CommissionsStyles from './Commissions.module.css';
import { SelectStyles } from './constants';
import { canExpandDefinition } from './helpers';
import { getAndInsertInstrumentsToNode } from './helpers/getAndInsertInstrumentsToNode';
import {
  useColumns,
  useCommissionTree,
  useHandleCellClick,
  useSaveData,
} from './hooks';

/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */

export const Commissions = () => {
  const dispatch = useDispatch();
  const tableRef = useRef<HTMLDivElement | null>(null);
  const [getCommissionsRules] = useLazyGetCommissionsRulesQuery();
  const [searchInstruments, searchState] =
    useLazySearchCommissionsInstrumentsQuery();

  const tree = useSelector(selectCommissionsTree);
  const prevTree = usePrevious(tree);
  const expandedRows = useSelector(selectExpandedRows);

  const { fetchCommissionsTree, isLoadingTree, getCommissionsTree } =
    useCommissionTree();

  const { handleCellClick } = useHandleCellClick();

  const columns = useColumns();

  const { virtualized, updateTableSizes } = useTableVirtualized(
    tableRef.current,
  );

  const [getInstruments] = useLazyGetCommissionsInstrumentsQuery();

  const filtersLayer = useSelector(selectFiltersLayer);
  const filtersGroup = useSelector(selectFiltersGroup);
  const filtersAccount = useSelector(selectFiltersAccount);
  const downloadedPaths = useSelector(selectDownloadedPaths);
  const location = useLocation();
  const [addNewCommissionGroup] = useAddNewCommissionGroupMutation();
  const [removeCommissionGroup] = useRemoveCommissionGroupMutation();
  const filtersQueryParams = useSelector(selectFiltersQueryParams);
  const { nodes: changedNodes, instruments: changedInstruments } = useSelector(
    selectChangedCommissions,
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const [saveData] = useSaveData();
  const [allExpanded, setAllExpanded] = useState(false);
  const getAccountsAutocompleteFn = useAccountsAutocomplete();
  const [fetchCommissionSettings] = useLazyGetCommissionSettingsQuery();

  const relatedGroup = useSelector(selectFiltersRelatedGroup);

  const hasChangedGroup =
    filtersLayer === FilterLayers.Accounts && filtersGroup !== relatedGroup;
  const hasChangedCommissions =
    !isEmpty(changedNodes) || !isEmpty(changedInstruments);

  const { data: groups } = useGetCommissionGroupsQuery({
    ignoreForbiddenError: true,
  });

  const commissionGroups = useMemo(() => {
    if (!groups) {
      return EMPTY_ARRAY;
    }

    return transformDataToSelectOptions(groups, {
      valueKey: 'id',
      labelKey: 'name',
      sort: true,
    }) as ISelectOption[];
  }, [groups]);

  const commissionsGroupsForAccount = useMemo(() => {
    if (!groups) {
      return EMPTY_ARRAY;
    }

    return [
      { label: 'Default', value: 'default' },
      ...transformDataToSelectOptions(groups, {
        valueKey: 'id',
        labelKey: 'name',
        sort: true,
      }),
    ] as ISelectOption[];
  }, [groups]);

  const predefinedAccount = location?.state?.accountId;

  const getAccountsCommissionsSet = useCallback(
    async (account: string) => {
      const response = await fetchCommissionSettings({ accountId: account });

      if (response.data) {
        dispatch(filtersSetRelatedGroup(response.data.commissionGroupId));
      }
    },
    [dispatch, fetchCommissionSettings],
  );

  const onChangeLayerHandler = useCallback(
    (tagName: string, _tabIndex: number | null, isMount = false) => {
      dispatch(resetTable({}));
      dispatch(filtersSetLayer(tagName));

      setSearchParams((params) => {
        if (!isMount) {
          params.delete('account');
        }

        params.set('layer', tagName);

        return params;
      });
    },
    [dispatch, setSearchParams],
  );

  const onChangeGroupHandler = useCallback(
    async (value: number | null) => {
      dispatch(
        filtersSetGroup({
          value,
          shouldResetDownloadedPaths: filtersLayer === FilterLayers.Groups,
        }),
      );

      if (filtersLayer === FilterLayers.Groups) {
        dispatch(
          resetTable({
            shouldResetExpandedRows: true,
            shouldResetDownloadedPaths: true,
          }),
        );
        fetchCommissionsTree({ params: { entity: value } });
      } else {
        dispatch(
          resetTable({
            shouldResetExpandedRows: false,
            shouldResetDownloadedPaths: false,
          }),
        );
        const groupQueryParams = getQueryParams({
          layer: FilterLayers.Groups,
          group: value,
          account: null,
          relatedGroup: null,
        });

        for (const path of downloadedPaths) {
          await getAndInsertInstrumentsToNode({
            dispatch,
            getInstruments,
            path,
            queryParams: groupQueryParams,
            skip: 0,
          });
        }
      }
    },
    [
      dispatch,
      downloadedPaths,
      fetchCommissionsTree,
      filtersLayer,
      getInstruments,
    ],
  );

  const onChangeAccountHandler = useCallback(
    (_: unknown, data: { value: string }) => {
      if (!data) {
        return;
      }

      const { value } = data;
      dispatch(filtersSetAccount(value));
      setSearchParams((params) => {
        if (['null', 'undefined'].includes(value)) {
          return params;
        }
        if (value) {
          params.set('account', value);
        } else {
          params.delete('account');
        }

        return params;
      });
      getCommissionsTree({
        entity: value,
        layer: FilterLayers.Accounts,
      });
    },
    [dispatch, getCommissionsTree, setSearchParams],
  );

  const setDefaultGroup = useCallback(
    (force?: boolean) => {
      if (
        force ||
        (!filtersGroup && commissionGroups && commissionGroups.length > 0)
      ) {
        const [defaultGroup] = commissionGroups;
        if (typeof defaultGroup.value === 'number') {
          onChangeGroupHandler(defaultGroup.value);
        }
      }
    },
    [filtersGroup, commissionGroups, onChangeGroupHandler],
  );

  const handleSearchInstruments = useCallback(
    async (value: string) => {
      const search = value.trim();

      if (search === '') {
        setAllExpanded(false);
      }

      if (search.length < MIN_SEARCH_LENGTH) {
        dispatch(resetTable({}));

        return;
      }

      setAllExpanded(false);

      await searchInstruments({
        search: value,
        limit: MAX_REQUEST_LIMIT,
        skip: 0,
        queryParams: filtersQueryParams,
      });

      // Expanding for all rows doesn't work without changing state for some reasons
      setAllExpanded(true);
    },
    [searchInstruments, filtersQueryParams, dispatch],
  );

  const debounceHandleChangeInput = useMemo(
    () => debounce(handleSearchInstruments, DEFAULT_DEBOUNCE_TIMEOUT),
    [handleSearchInstruments],
  );

  const handleChange = useCallback(
    (value: string) => {
      debounceHandleChangeInput(value);
    },
    [debounceHandleChangeInput],
  );

  const setDefaultAccount = useCallback(async () => {
    const autocompleteFn = getAccountsAutocompleteFn();
    const data = await autocompleteFn('');

    if (data.options) {
      const [defaultAccount] = data.options;
      if (defaultAccount) {
        onChangeAccountHandler(null, { value: String(defaultAccount.value) });

        setSearchParams((params) => {
          // params.set('account', defaultAccount.value);

          return params;
        });
      }
    }
  }, [getAccountsAutocompleteFn, onChangeAccountHandler, setSearchParams]);

  const handleDeleteGroup = useCallback(async () => {
    const res = await removeCommissionGroup({
      data: {
        groupId: `${filtersGroup}`,
      },
    });

    if (!has(res, 'error')) {
      Notification.success({
        title: 'Group was successfully deleted',
      });
      setDefaultGroup(true);
    }
  }, [setDefaultGroup, filtersGroup, removeCommissionGroup]);

  const handleAddGroup = useCallback(
    async (groupName: string) => {
      const res = await addNewCommissionGroup({
        data: {
          name: groupName,
        },
      });

      if (!has(res, 'error')) {
        Notification.success({
          title: 'Group was successfully added',
        });
      }
    },
    [addNewCommissionGroup],
  );

  useEffect(() => {
    if (!filtersGroup && filtersLayer === FilterLayers.Groups) {
      setDefaultGroup();
    }
  }, [setDefaultGroup, filtersLayer]);

  useEffect(() => {
    if (filtersLayer === FilterLayers.Accounts && filtersAccount) {
      getAccountsCommissionsSet(filtersAccount);
    }
  }, [filtersAccount, filtersLayer, getAccountsCommissionsSet]);

  useEffect(() => {
    if (predefinedAccount) {
      onChangeLayerHandler(String(FilterLayers.Accounts), null, true);
    }
  }, []);

  useEffect(() => {
    if (!filtersAccount) {
      const initialAccount = searchParams.get('account');
      onChangeAccountHandler(null, { value: String(initialAccount) });
    }
  }, [filtersAccount, onChangeAccountHandler, searchParams]);

  useEffect(() => {
    const initialAccount = searchParams.get('account');

    if (initialAccount) {
      onChangeAccountHandler(null, { value: initialAccount });
    } else if (
      !initialAccount &&
      [FilterLayers.Accounts].includes(filtersLayer)
    ) {
      setDefaultAccount();
    }
  }, [filtersLayer]);

  useEffect(() => {
    const initialLayer = searchParams.get('layer');

    if (initialLayer) {
      onChangeLayerHandler(initialLayer, null, true);
    }
  }, []);

  const handleCellUpdate = useCallback(
    (values: TCellData<ICommissionTree>[]) => {
      values.forEach((value) => {
        const {
          id,
          row: {
            original: { path, rowType },
          },
          value: newValue,
        } = value;

        const actionFn =
          rowType === 'node' ? updateNodeValue : updateInstrumentValue;

        if (newValue) {
          dispatch(
            actionFn({
              path,
              value: prepareValue(newValue),
              column: id,
            }),
          );
        }
      });
    },
    [dispatch],
  );

  useEffect(() => {
    fetchCommissionsTree();
  }, []);

  useEffect(() => {
    getCommissionsRules();
  }, [getCommissionsRules]);

  useEffect(() => {
    if (
      prevTree.length !== tree.length ||
      (tree.length !== 0 && virtualized.height === 0)
    ) {
      updateTableSizes();
    }
  }, [prevTree, tree, updateTableSizes, virtualized.height]);

  const initialLayer = searchParams.get('layer');

  const preparedTabs = tabs.map((tab) => {
    return {
      tabName: tab,
      render: tab === 'Rules' ? <SettingsIcon /> : tab,
    };
  });

  return (
    <LayersLayout
      title="Commissions"
      tabs={preparedTabs}
      initialTab={initialLayer || FilterLayers.Default}
      pageControls={
        filtersLayer === FilterLayers.Default ? null : (
          <Switch
            condition={[
              filtersLayer === FilterLayers.Accounts,
              filtersLayer === FilterLayers.Groups,
            ]}
          >
            {[
              <>
                <AutocompleteAsync
                  fetchData={getAccountsAutocompleteFn()}
                  onChange={onChangeAccountHandler}
                  options={EMPTY_ARRAY}
                  placeholder="Accounts"
                  value={filtersAccount}
                  sx={SelectStyles}
                />
                <Select
                  label="Groups"
                  options={commissionsGroupsForAccount || EMPTY_ARRAY}
                  onChange={({ target: { value } }) => {
                    onChangeGroupHandler(
                      value === 'default' ? null : Number(value),
                    );
                  }}
                  value={filtersGroup === null ? 'default' : filtersGroup}
                  sx={SelectStyles}
                />
              </>,

              <Select
                key="groups"
                label="Groups"
                options={commissionGroups || EMPTY_ARRAY}
                onChange={({ target: { value } }) =>
                  onChangeGroupHandler(
                    value === 'default' ? null : Number(value),
                  )
                }
                value={filtersGroup ? `${filtersGroup}` : 'default'}
                sx={SelectStyles}
              />,
            ]}
          </Switch>
        )
      }
      searchParams={{
        hidden: filtersLayer === FilterLayers.Rules,
        onSearch: handleChange,
        onReset: () => {
          dispatch(resetTable({}));
          setAllExpanded(false);
        },
      }}
      table={
        filtersLayer === FilterLayers.Rules ? (
          <CommissionRules />
        ) : (
          <div ref={tableRef}>
            <Table
              className={CommissionsStyles.Table}
              columns={columns}
              data={tree}
              defaultSortBy={DEFAULT_SORT_TABLE_BY}
              expanded={{
                canExpandDefinition,
                customExpandControl: true,
                listOfInitialExpandedRowKeys: expandedRows || allExpanded,
              }}
              handleCellClick={handleCellClick}
              isFlexLayout
              saveViewParamsAfterLeave
              tableId={getTableId('Commissions')}
              virtualized={virtualized}
              isLoading={isLoadingTree || searchState.isFetching}
              copyPasteMode
              onTableCellUpdate={handleCellUpdate}
            />
          </div>
        )
      }
      onChangeLayer={onChangeLayerHandler}
      onDeleteGroup={handleDeleteGroup}
      onSave={saveData}
      onAddGroup={handleAddGroup}
      onRefresh={() => fetchCommissionsTree({ forceRefetch: true })}
      loading={isLoadingTree || searchState.isFetching}
      isSaveDisabled={!hasChangedGroup && !hasChangedCommissions}
      hideTopPanel={filtersLayer === FilterLayers.Rules}
      lastTabStyles={{
        marginLeft: 'auto',
      }}
    />
  );
};
