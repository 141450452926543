import { EMPTY_FUNCTION } from '~/constants';

import { CloseButton } from '../CloseButton';
import { DeleteButton } from '../DeleteButton';
import { RefreshButton } from '../RefreshButton';
import { SaveButton } from '../SaveButton';

import styles from './PanelHeaderControls.module.css';

export interface IPanelHeaderControlsProps {
  onClose?: () => void;
  onDelete?: () => void;
  onSave?: (() => void) | null;
  hideSaveButton?: boolean;
  disabled?: boolean;
  onRefresh?: () => void;
  isRefreshLoading?: boolean;
}

export const PanelHeaderControls = ({
  onClose,
  onDelete,
  onSave,
  hideSaveButton = false,
  disabled = false,
  onRefresh,
  isRefreshLoading,
}: IPanelHeaderControlsProps) => {
  return (
    <div className={styles.PanelHeaderControls}>
      {!hideSaveButton && (
        <SaveButton
          type="submit"
          disabled={disabled}
          onClick={onSave || EMPTY_FUNCTION}
        />
      )}

      {onDelete ? (
        <div onClick={onDelete} aria-hidden="true">
          <DeleteButton loading={false} />
        </div>
      ) : null}

      {onRefresh ? (
        <div className={styles.RefreshButton}>
          <RefreshButton
            disabled={isRefreshLoading}
            onRefresh={onRefresh}
            type="button"
          />
        </div>
      ) : null}

      {onClose ? <CloseButton onClick={onClose} /> : null}
    </div>
  );
};
