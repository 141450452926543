import cn from 'classnames';
import { useCallback, useMemo, useRef } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { Panel, IconButton, Modal, useModal } from 'react-ui-kit-exante';

import { EMPTY_ARRAY } from '~/constants';
import { usePickUserPermissions, useUsersAutocomplete } from '~/hooks';
import { NoDataWrapper } from '~/shared/components';
import { IOption } from '~/types/form';

import { FormMultiSelectContainer, FormSelectAsyncContainer } from '../../form';
import { IDataAvailabilityState } from '../types';

import ClientUsersStyles from './ClientUsers.module.css';

interface IClientsProps {
  dataAvailabilityState: IDataAvailabilityState;
  options: Record<string, Array<IOption>>;
}

export const ClientUsers = ({
  dataAvailabilityState,
  options: { clientUserPermissionsOptions },
}: IClientsProps) => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'clientUsers',
  });
  const clientId = useWatch({ control, name: 'client.id' });
  const getUsersAutocompleteFn = useUsersAutocomplete();

  const confirmModal = useModal();
  const confirmIndex = useRef<number | null>(null);

  const userPermissions = usePickUserPermissions(['User client']);

  const searchUsers = useMemo(
    () => getUsersAutocompleteFn(),
    [getUsersAutocompleteFn],
  );

  const handleAddRow = useCallback(
    () =>
      append({
        clientId,
        userId: { value: null, label: '' },
        rights: EMPTY_ARRAY,
      }),
    [append, clientId],
  );
  const handleDeleteClick = (index: number) => () => {
    confirmIndex.current = index;
    confirmModal.onOpen();
  };

  const handleRemoveRow = () => {
    if (confirmIndex.current === null) {
      return;
    }

    remove(confirmIndex.current);
    confirmModal.onClose();
  };

  if (!userPermissions['User client'].read) {
    return null;
  }

  return (
    <Panel
      className={cn(ClientUsersStyles.Container, {
        Disabled: !userPermissions['User client'].write,
      })}
      title="Users"
    >
      <NoDataWrapper hasData={dataAvailabilityState.hasClientUsersData}>
        <FormSelectAsyncContainer
          label="Owner"
          name="client.owner"
          fetchData={searchUsers}
        />

        {fields.map((field, index) => (
          <div key={field.id} className={ClientUsersStyles.Item}>
            <FormSelectAsyncContainer
              label="User ID"
              name={`clientUsers.${index}.userId`}
              fetchData={searchUsers}
            />
            <FormMultiSelectContainer
              label="Rights"
              name={`clientUsers.${index}.rights`}
              options={clientUserPermissionsOptions}
            />

            <div className={ClientUsersStyles.DeleteWrapper}>
              <IconButton
                iconName="DeleteIcon"
                iconColor="radical"
                iconSize={24}
                onClick={handleDeleteClick(index)}
              />
            </div>
          </div>
        ))}

        <div className={ClientUsersStyles.AddWrapper}>
          <IconButton
            iconName="AddIcon"
            iconColor="action"
            onClick={handleAddRow}
          />
        </div>

        <Modal
          isOpened={confirmModal.isOpened}
          onClose={confirmModal.onClose}
          title="Are you sure?"
          confirmButton={{
            handleConfirm: handleRemoveRow,
          }}
        >
          <div>Do you want to delete this row?</div>
        </Modal>
      </NoDataWrapper>
    </Panel>
  );
};
