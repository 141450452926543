import { FilterValue, IColumn } from 'react-ui-kit-exante';

import { AutoCompleteSearch } from 'types/common';
import { transformVariantsToSelectOptions } from '~/shared/utils';
import { ITrade } from '~/types/trades';
import { ITypesEntities } from '~/types/types';

export const DISPLAYED_COLUMN_KEYS: (keyof ITrade | 'actions')[] = [
  'userId',
  'tradeTime',
  'symbolId',
  'symbolType',
  'accountId',
  'side',
  'price',
  'quantity',
  'commission',
  'currency',
  'executionCounterparty',
  'valueDate',
  'clientComment',
  'clientType',
  'tradeType',
  'isManual',
  'actions',
];

interface IGetColumns {
  onFilter(col: string, value: FilterValue): void;
  onRemove(col: string): void;
  types: ITypesEntities & {
    tradeType: string[];
    legalEntity: string[];
    settlementCounterpartyTypes: string[];
    executionCounterpartyTypes: string[];
  };
  fetchAccounts: AutoCompleteSearch;
  fetchSymbols: AutoCompleteSearch;
  fetchUsers: AutoCompleteSearch;
}

export function getColumns({
  onFilter,
  onRemove,
  types: {
    tradeType,
    legalEntity,
    symbolTypes,
    settlementCounterpartyTypes,
    executionCounterpartyTypes,
  },
}: IGetColumns): IColumn<ITrade>[] {
  return [
    {
      Header: 'User',
      accessor: 'userId',
      onFilter,
      onRemove,
    },
    {
      Header: 'Gw time',
      accessor: 'gwTime',
      disableFilters: true,
      formatting: 'dateTimeUTC',
    },
    {
      Header: 'Trade time',
      accessor: 'tradeTime',
      disableFilters: true,
      formatting: 'dateTimeUTC',
    },
    {
      Header: 'Counterparty time',
      accessor: 'counterpartyTime',
      disableFilters: true,
      formatting: 'dateTimeUTC',
    },
    {
      Header: 'Counterparty account',
      accessor: 'counterpartyAccountId',
      disableFilters: true,
    },
    {
      Header: 'Counterparty client',
      accessor: 'counterpartyClientId',
      disableFilters: true,
    },
    {
      Header: 'Symbol',
      accessor: 'symbolId',
      onFilter,
      onRemove,
    },
    {
      Header: 'Symbol type',
      accessor: 'symbolType',
      type: 'select',
      filterOptions: transformVariantsToSelectOptions(symbolTypes.values),
      onFilter,
      onRemove,
    },
    {
      Header: 'Account',
      accessor: 'accountId',
      onFilter,
      onRemove,
    },
    {
      Header: 'Side',
      accessor: 'side',
      disableFilters: true,
    },
    {
      Header: 'Price',
      accessor: 'price',
      disableFilters: true,
      formatting: 'number',
    },
    {
      Header: 'Quantity',
      accessor: 'quantity',
      disableFilters: true,
      formatting: 'number',
    },
    {
      Header: 'Commission',
      accessor: 'commission',
      disableFilters: true,
      formatting: 'number',
    },
    {
      Header: 'Commission currency',
      accessor: 'commissionCurrency',
      disableFilters: true,
    },
    {
      Header: 'Forced close out',
      accessor: 'isForcedCloseOut',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Order id',
      accessor: 'orderId',
      onFilter,
      onRemove,
    },
    {
      Header: 'Order position',
      accessor: 'orderPos',
      disableFilters: true,
    },
    {
      Header: 'Exchange order id',
      accessor: 'exchangeOrderId',
      onFilter,
      onRemove,
    },
    {
      Header: 'Currency',
      accessor: 'currency',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Exec broker',
      accessor: 'execBroker',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Contract multiplier',
      accessor: 'contractMultiplier',
      disableSortBy: true,
      disableFilters: true,
      formatting: 'number',
    },
    {
      Header: 'Settlement counterparty',
      accessor: 'settlementCounterparty',
      onFilter,
      onRemove,
      type: 'textInput',
      // filterOptions: transformVariantsToSelectOptions(
      //   settlementCounterpartyTypes,
      // ),
    },
    {
      Header: 'Execution counterparty',
      accessor: 'executionCounterparty',
      onFilter,
      onRemove,
      type: 'textInput',
      // filterOptions: transformVariantsToSelectOptions(
      //   executionCounterpartyTypes,
      // ),
    },
    {
      Header: 'Value date',
      accessor: 'valueDate',
      disableFilters: true,
      formatting: 'dateUTC',
    },
    {
      Header: 'Client comment',
      accessor: 'clientComment',
      disableFilters: true,
      editParams: {
        editable: true,
      },
    },
    {
      Header: 'Internal comment',
      accessor: 'internalComment',
      disableFilters: true,
      editParams: {
        editable: true,
      },
    },
    {
      Header: 'Client type',
      accessor: 'clientType',
      disableFilters: true,
    },
    {
      Header: 'Trade type',
      accessor: 'tradeType',
      type: 'select',
      filterOptions: transformVariantsToSelectOptions(tradeType),
      onFilter,
      onRemove,
    },
    {
      Header: 'PNL',
      accessor: 'pnl',
      disableFilters: true,
      formatting: 'number',
    },
    {
      Header: 'Manual',
      accessor: 'isManual',
      disableFilters: true,
    },
    {
      Header: 'Who',
      accessor: 'who',
      disableFilters: true,
    },
    {
      Header: 'Closed quantity',
      accessor: 'closedQuantity',
      disableFilters: true,
      formatting: 'number',
    },
    {
      Header: 'Traded volume',
      accessor: 'tradedVolume',
      disableFilters: true,
      formatting: 'number',
    },
    {
      Header: 'Legal entity',
      accessor: 'legalEntity',
      disableSortBy: true,
      type: 'multiSelect',
      filterOptions: transformVariantsToSelectOptions(legalEntity),
      onFilter,
      onRemove,
    },
    {
      Header: 'Source',
      accessor: 'source',
      onFilter,
      onRemove,
    },
  ];
}
