import { useCallback, useMemo, useState } from 'react';
import {
  ButtonDatePicker,
  ExportTableParams,
  useTableData,
} from 'react-ui-kit-exante';

import {
  useGetAccountTypesQuery,
  useGetAssetTypesQuery,
} from '~/api/types/types.api';
import { useAccountsAutocomplete, useCurrency } from '~/hooks';
import { globalSummaryService, symbolsService } from '~/resources';
import { IGlobalSummaryFilters } from '~/resources/globalSummary/types';
import {
  calculateCountOfPages,
  transformDataToSelectOptions,
  transformVariantsToSelectOptions,
} from '~/shared/utils';
import { isDatePresent, today } from '~/shared/utils/dates';
import { IGlobalSummary, IPosition } from '~/types/accounts';
import { IPaginationParameters } from '~/types/api';

import GlobalSummaryStyles from '../GlobalSummary.module.css';
import {
  DEFAULT_SORTING,
  DEFAULT_SORTING_AGGREGATED_DATA,
  getAdditionalFilters,
} from '../columns';

interface IUseGlobalSummary {
  preCall: () => void;
  tableId: string;
  isAggregatedView?: boolean;
  isByPositionSide?: boolean;
  isCurrencyRisk?: boolean;
}

export function useGlobalSummary({
  preCall,
  tableId,
  isAggregatedView = false,
  isByPositionSide = false,
  isCurrencyRisk = false,
}: IUseGlobalSummary) {
  const currency = useCurrency();
  const [date, setDate] = useState<Date | null>(null);
  const [reconciliationMode, setReconciliationMode] = useState(false);
  const { data: accountTypes } = useGetAccountTypesQuery();
  const { data: assetTypes } = useGetAssetTypesQuery();
  const getAccountsAutocompleteFn = useAccountsAutocomplete();

  const getSummary = useCallback(
    (params: {
      filtersParams: IGlobalSummaryFilters;
      paginationParams: IPaginationParameters;
      sortingParams: unknown;
    }) => {
      preCall();

      return globalSummaryService.getGlobalSummary({
        date,
        currency,
        reconciliationMode,
        isAggregatedView,
        isByPositionSide,
        isCurrencyRisk,
        filtersParams: params.filtersParams,
        paginationParams: params.paginationParams,
        sortingParams: params.sortingParams as Record<string, unknown>,
      });
    },
    [
      currency,
      date,
      isAggregatedView,
      isByPositionSide,
      isCurrencyRisk,
      preCall,
      reconciliationMode,
    ],
  );

  const tableArgs = useMemo(
    () => ({
      data: {
        onFetch: getSummary,
      },
      sorting: {
        getDefaultSorting: () =>
          isAggregatedView ? DEFAULT_SORTING_AGGREGATED_DATA : DEFAULT_SORTING,
      },
      tableId,
      saveViewParamsAfterLeave: true,
    }),
    [isAggregatedView, getSummary, tableId],
  );

  const {
    data,
    limit,
    skip,
    setLimit,
    setPage,
    page,
    isLoading,
    setFilter,
    removeFilter,
    setSorting,
    filters: filtersTable,
    resetFilters,
    fetchData,
  } = useTableData<IGlobalSummary | null>(tableArgs);

  const additionalFilters = useMemo(
    () =>
      getAdditionalFilters({
        onFilter: setFilter,
        onRemove: removeFilter,
      }),
    [removeFilter, setFilter],
  );

  const filteringProps = useMemo(
    () => ({
      additionalFilters: isCurrencyRisk ? [] : additionalFilters,
      removeAllFilters: resetFilters,
      filters: filtersTable,
      manualFilters: true,
    }),
    [isCurrencyRisk, additionalFilters, resetFilters, filtersTable],
  );

  const total = data?.pagination?.total || 0;
  const pageCount = useMemo(
    () => calculateCountOfPages(total, limit),
    [limit, total],
  );
  const handleDateChange = useCallback((newDate: Date | null) => {
    if (newDate === null || isDatePresent(newDate)) {
      setDate(newDate);
    }
  }, []);

  const exportTableParams = useMemo(
    () => ({
      type: 'server',
      onFetch: async (params) =>
        globalSummaryService.exportGlobalSummary({
          date,
          currency,
          reconciliationMode,
          filtersParams: params,
          sortingParams: params.sorting,
          isAggregatedView,
          isByPositionSide,
          isCurrencyRisk,
          paginationParams: { ...params, skip: 0 },
        }),
    }),
    [
      currency,
      date,
      isAggregatedView,
      isByPositionSide,
      isCurrencyRisk,
      reconciliationMode,
    ],
  ) as ExportTableParams<IPosition> | undefined;

  const serverPaginationProps = useMemo(
    () => ({
      pageSize: limit,
      skip,
      setPage,
      setPageSize: setLimit,
      pageIndex: page,
      total,
      pageCount,
    }),
    [skip, limit, page, pageCount, setLimit, setPage, total],
  );

  const fetchSymbols = useMemo(
    () => symbolsService.autoCompleteSearchSymbols.bind(null, 0),
    [],
  );

  const additionalHistoricalAction = useMemo(
    () => [
      {
        key: 'historicalMode',
        component: (
          <ButtonDatePicker
            className={GlobalSummaryStyles.StyledDatePicker}
            maxDate={today}
            showTodayButton
            onChange={handleDateChange}
            selected={date || null}
            todayButtonText="Real time"
            onToday={() => {
              setDate(today);
            }}
          />
        ),
      },
    ],
    [handleDateChange, date],
  );

  const preparedAccountTypes = transformVariantsToSelectOptions(
    accountTypes?.values,
  );
  const preparedAssetTypes = transformDataToSelectOptions(
    assetTypes?.values || [],
    {
      valueKey: 'type',
      labelKey: 'type',
      sort: false,
    },
  );

  return {
    date,
    data,
    currency,
    isLoading,
    setFilter,
    setDate: handleDateChange,
    removeFilter,
    setReconciliationMode,
    setSorting,
    reconciliationMode,
    exportTableParams,
    filteringProps,
    fetchSymbols,
    fetchAccounts: getAccountsAutocompleteFn(),
    accountTypes: preparedAccountTypes,
    assetTypes: preparedAssetTypes,
    ...serverPaginationProps,
    refetch: fetchData,
    additionalHistoricalAction,
  };
}
