import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  IconButton,
  Modal,
  Notification,
  Table,
  useModal,
} from 'react-ui-kit-exante';

import {
  useDeleteCommissionRuleMutation,
  useGetCommissionRulesQuery,
} from '~/api';
import { CONFIRM_DELETE_MESSAGE, DEFAULT_SORT_TABLE_BY } from '~/constants';
import { DeleteIcon, EditIcon } from '~/images/icons';
import { getTableId } from '~/shared/utils';
import { ICommissionRule } from '~/types/commisionRules';

import { Form } from './components';
import { columns, DEFAULT_VALUES } from './constants';
import { useValidationSchema } from './hooks';

export const CommissionRules = () => {
  const [displayModal, setDisplayModal] = useState(false);
  const [editedName, setEditedName] = useState('');
  const nameForDelete = useRef('');

  const confirmModal = useModal();

  const { data: tableData = [] } = useGetCommissionRulesQuery();
  const [deleteCommissionRule] = useDeleteCommissionRuleMutation();

  const validationSchema = useValidationSchema(tableData, editedName);

  const formInstance = useForm({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(validationSchema),
  });
  const { setValue, reset: resetForm } = formInstance;

  const openModal = useCallback(() => {
    setDisplayModal(true);
  }, []);

  const closeModal = useCallback(() => {
    setDisplayModal(false);
    resetForm();
  }, [resetForm]);

  const additionalActions = useMemo(
    () => [
      {
        component: (
          <IconButton
            iconName="AddIcon"
            iconColor="action"
            label="Add commision rule"
            iconSize={24}
            onClick={openModal}
          />
        ),
      },
    ],
    [openModal],
  );

  const deleteClickHandler = useCallback(
    ({ name }: { name: string }) => {
      nameForDelete.current = name;
      confirmModal.onOpen();
    },
    [confirmModal],
  );

  const removeRow = useCallback(async () => {
    const result = await deleteCommissionRule(nameForDelete.current);

    if ('data' in result) {
      Notification.success({
        title: 'Rule successfully deleted',
      });
    } else {
      Notification.error({
        title: 'Rule failed to delete',
      });
    }

    confirmModal.onClose();

    nameForDelete.current = '';
    setEditedName('');
  }, [deleteCommissionRule, confirmModal]);

  const editClickHandler = useCallback(
    (data: ICommissionRule) => {
      Object.entries(data).forEach((item) => {
        const [key, value] = item;

        setValue(key as keyof ICommissionRule, value, { shouldDirty: false });
      });

      setEditedName(data.name);
      setDisplayModal(true);
    },
    [setValue],
  );

  const rowActions = useMemo(
    () => ({
      hideEdit: true,
      show: true,
      additionalActions: [
        {
          label: <EditIcon />,
          onClick: editClickHandler,
          title: 'Edit',
          width: 20,
        },
        {
          label: <DeleteIcon />,
          onClick: deleteClickHandler,
          title: 'Remove',
          width: 20,
        },
      ],
    }),
    [editClickHandler, deleteClickHandler],
  );

  const confirmButton = useMemo(
    () => ({
      handleConfirm: removeRow,
    }),
    [removeRow],
  );

  if (!tableData) {
    return null;
  }

  return (
    <>
      <Table
        additionalActions={additionalActions}
        columns={columns}
        data={tableData}
        defaultSortBy={DEFAULT_SORT_TABLE_BY}
        isFlexLayout
        rowActions={rowActions}
        showTableInfo
        tableId={getTableId('CommissionRules')}
        title="Commission Rules"
      />

      <Form
        closeModal={closeModal}
        displayModal={displayModal}
        editedName={editedName}
        formInstance={formInstance}
      />

      <Modal
        confirmButton={confirmButton}
        isOpened={confirmModal.isOpened}
        keepMounted={false}
        onClose={confirmModal.onClose}
        title="Are you sure?"
      >
        <div>{CONFIRM_DELETE_MESSAGE}</div>
      </Modal>
    </>
  );
};
