import { useContext, useEffect } from 'react';
import { Panel, NoData } from 'react-ui-kit-exante';

import { useGetAccountSummaryQuery, useGetCoreAccountQuery } from '~/api';
import { useCurrency, usePrevious, useLogHandleTime } from '~/hooks';
import { IAccountTabComponentProps } from '~/pages/AccountPage/TabManagement/types';
import { accountPageContext } from '~/pages/AccountPage/context';
import { useRefreshActiveTab } from '~/pages/AccountPage/hooks';
import { StaticCenteredLoader } from '~/shared/components';
import { formatDate } from '~/shared/utils';

import { EnchantedPositionsContainer } from '../PositionsContainer';

export const AccountSummaryContainer = ({
  accountId,
}: IAccountTabComponentProps) => {
  const currency = useCurrency();
  const {
    accountSummary: {
      filters: { date },
    },
  } = useContext(accountPageContext);
  const { setStartHandleTime, logHandleTime } = useLogHandleTime(
    'account-summary-entry-screen',
  );

  setStartHandleTime();

  const { data: account } = useGetCoreAccountQuery(
    { accountId },
    { skip: !accountId },
  );

  const { setRefreshFn } = useRefreshActiveTab();

  const {
    data: accountSummary,
    isLoading,
    refetch,
    isFetching,
  } = useGetAccountSummaryQuery({
    id: accountId,
    currency,
    date: date ? formatDate(date) : null,
  });

  const prevPositions = usePrevious(accountSummary?.positions);

  useEffect(() => {
    if (!prevPositions && accountSummary?.positions) {
      logHandleTime();
    }
  }, [accountSummary, logHandleTime, prevPositions]);

  useEffect(() => {
    setRefreshFn('summary', {
      refetch,
      isLoading: isFetching,
    });
  }, [setRefreshFn, refetch, isFetching]);

  if (isLoading) {
    return <StaticCenteredLoader withBackground height="340px" />;
  }

  if (account?.archived) {
    return <Panel>Account is archived!</Panel>;
  }

  if (!accountSummary?.positions.length) {
    return <NoData />;
  }

  return (
    <EnchantedPositionsContainer
      accountId={account?.id}
      currency={currency}
      invalidateData={refetch}
      positions={accountSummary.positions}
    />
  );
};
