import { useEffect, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { Select } from 'react-ui-kit-exante';

import { IOption } from '~/types/form';

interface IFormSelectContainerProps {
  className?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  label?: string;
  menuProps?: Record<string, unknown>;
  multiple?: boolean;
  name: string;
  onChange?: (value: string) => void;
  options: Array<IOption>;
  readOnly?: boolean; // disable mutate form state onChange
  getValue?: (value: string) => void;
}

export const FormSelectContainer = ({
  className,
  disabled = false,
  fullWidth = true,
  label = '',
  menuProps = {},
  multiple = false,
  name,
  onChange: onChangeProp,
  options,
  readOnly = false,
  getValue,
}: IFormSelectContainerProps) => {
  const { control, register } = useFormContext();
  const {
    field: { onChange, onBlur, value },
    fieldState: { error, isDirty },
  } = useController({
    control,
    name,
  });
  const [selectValue, setSelectValue] = useState(() => {
    if (!options.length) {
      return '';
    }

    if (value === null) {
      return 'null';
    }

    return value;
  });

  register(name, {
    disabled,
  });

  // fix for WBU-257, section "Add Manual transaction"
  useEffect(() => {
    if (!disabled) {
      onChange(value);

      if (onChangeProp) {
        onChangeProp(value);
      }
      if (getValue) {
        getValue(value);
      }
    }
  }, [onChangeProp, onChange, value, disabled]);

  useEffect(() => {
    if (!options.length) {
      setSelectValue('');
    } else if (value === null) {
      setSelectValue('null');
    } else {
      setSelectValue(value);
    }
  }, [value, options]);

  return (
    <Select
      className={className}
      multiple={multiple}
      disabled={disabled || readOnly}
      error={!!error}
      fullWidth={fullWidth}
      label={label}
      message={error?.message}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      options={options}
      // TODO Select from MUI doesn't support null as value https://github.com/mui/material-ui/issues/23558
      value={selectValue}
      menuProps={menuProps}
      warning={isDirty}
    />
  );
};
