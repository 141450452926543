import { useCallback } from 'react';

import { useLazySearchByAccountsQuery } from '~/api';

const defaultAutocompleteState = {
  total: 0,
  fetchData: null,
  options: [],
  canceled: true,
};

export const useAccountsAutocomplete = (archived = false) => {
  const [searchByAccounts] = useLazySearchByAccountsQuery();

  const getAccountsAutocompleteFn = useCallback(
    (skip = 0) => {
      return async (search: string) => {
        const response = await searchByAccounts({
          archived,
          search,
          skip,
        });

        if ('error' in response || !response.data) {
          return defaultAutocompleteState;
        }

        return {
          ...response.data,
          fetchData: getAccountsAutocompleteFn(response?.data?.nextSkip || 0),
        };
      };
    },
    [archived, searchByAccounts],
  );

  return getAccountsAutocompleteFn;
};
