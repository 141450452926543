import { useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton } from 'react-ui-kit-exante';

import { useGetCoreUserQuery, useGetCurrentUserAccessRightsQuery } from '~/api';
import { usePickUserPermissions } from '~/hooks';
import {
  TRANSACTION_ADD_PATH,
  TRANSACTION_IMPORT_PATH,
  TRANSFER_ADD_PATH,
} from '~/routes';

export function useAdditionalActions(withAddButton: boolean) {
  const navigate = useNavigate();
  const { data: { userId } = {} } = useGetCurrentUserAccessRightsQuery();
  const { data: currentUser } = useGetCoreUserQuery({ id: Number(userId) });

  const goToAddTransactionPage = useCallback(() => {
    navigate(TRANSACTION_ADD_PATH, {
      state: { previousPath: window.location.href },
    });
  }, [navigate]);

  const goToAddTransferPage = useCallback(() => {
    navigate(TRANSFER_ADD_PATH, {
      state: { previousPath: window.location.href },
    });
  }, [navigate]);

  const goToBulkImport = useCallback(() => {
    navigate(TRANSACTION_IMPORT_PATH, {
      state: { previousPath: window.location.href },
    });
  }, [navigate]);

  const userPermissions = usePickUserPermissions(['Add/withdraw asset']);

  return useMemo(() => {
    const currentUserHasAllBrandingPermissions =
      currentUser?.info.brandingPermission === 'ALL';

    const actions = [];

    if (
      userPermissions['Add/withdraw asset'].write &&
      currentUserHasAllBrandingPermissions
    ) {
      actions.push({
        component: (
          <IconButton
            iconName="AddIcon"
            iconColor="action"
            label="Add transfer"
            iconSize={24}
            onClick={goToAddTransferPage}
          />
        ),
      });
    }

    if (
      withAddButton &&
      userPermissions['Add/withdraw asset'].write &&
      currentUserHasAllBrandingPermissions
    ) {
      actions.push({
        component: (
          <IconButton
            iconName="DownloadIcon"
            iconColor="action"
            label="Bulk import"
            iconSize={24}
            onClick={goToBulkImport}
          />
        ),
      });
    }

    if (
      userPermissions['Add/withdraw asset'].write &&
      currentUserHasAllBrandingPermissions
    ) {
      actions.push({
        component: (
          <IconButton
            iconName="AddIcon"
            iconColor="action"
            label="Add transaction"
            iconSize={24}
            onClick={goToAddTransactionPage}
          />
        ),
      });
    }

    return actions;
  }, [
    currentUser?.info.brandingPermission,
    goToAddTransactionPage,
    goToAddTransferPage,
    goToBulkImport,
    userPermissions,
    withAddButton,
  ]);
}
