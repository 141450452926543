import { get, keys, set } from 'lodash';
import React, {
  createContext,
  useCallback,
  useContext,
  useState,
  useMemo,
  ReactNode,
} from 'react';
import { IRowExpand } from 'react-ui-kit-exante';

import { EMPTY_ARRAY, EMPTY_FUNCTION } from '~/constants';

import { toExpandedTreePath } from '../helpers';
import { useRiskArrayTree } from '../hooks';
import { IAddRequestToUpdate, IRemoveRequestToUpdate } from '../hooks/types';
import { ITreeNode } from '../types';

interface IGetValue {
  (props: { name: string; path: string }): unknown;
}

interface ISetValue {
  (props: { name: string; path: string; value: unknown }): void;
}

interface IGetSubRows {
  (props: { path: string }): unknown;
}

const RiskArrayTreeContext = createContext<{
  getValue: IGetValue;
  setValue: ISetValue;
  getOriginalCache: () => ITreeNode[] | null;
  getSubRows: IGetSubRows;
  getTree: () => ITreeNode[];
  getIconsMap: () => Record<string, string>;
  isLoading: boolean;
  search: string;
  expandRow: (row: IRowExpand<ITreeNode>) => void;
  setSearch: (search: string) => void;
  addRequestToUpdate: IAddRequestToUpdate;
  removeRequestToUpdate: IRemoveRequestToUpdate;
  setShowExpired: () => void;
  showExpired: boolean;
  saveTree: () => Promise<void>;
  refetch: (
    refetchFullTree: boolean,
    requestParams: { showExpired: boolean },
  ) => void;
  getExpandedRows: () => Record<string, boolean>;
  isDirtyField: (name: string, path: string) => boolean;
  getIsDirty: () => boolean;
  loadNext: () => void;
  pagination: {
    total: number;
    skip: number;
    limit: number;
    setLimit: (val: number) => void;
    showPagination: boolean;
  };
}>({
  getValue: EMPTY_FUNCTION,
  setValue: EMPTY_FUNCTION,
  getOriginalCache: () => null,
  getSubRows: EMPTY_FUNCTION,
  getTree: () => EMPTY_ARRAY,
  getIconsMap: () => ({}),
  isLoading: false,
  search: '',
  expandRow: EMPTY_FUNCTION,
  setSearch: EMPTY_FUNCTION,
  addRequestToUpdate: EMPTY_FUNCTION,
  removeRequestToUpdate: EMPTY_FUNCTION,
  setShowExpired: EMPTY_FUNCTION,
  showExpired: false,
  saveTree: async () => {},
  refetch: EMPTY_FUNCTION,
  getExpandedRows: () => ({}),
  isDirtyField: () => false,
  getIsDirty: () => false,
  loadNext: EMPTY_FUNCTION,
  pagination: {
    total: 0,
    skip: 0,
    limit: 0,
    setLimit: EMPTY_FUNCTION,
    showPagination: false,
  },
});

const RiskArrayFormContext = createContext<{
  dirtyFields: Record<string, boolean>;
  setDirtyFields: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
  hasDirtyFields: () => boolean;
}>({
  dirtyFields: {},
  setDirtyFields: EMPTY_FUNCTION,
  hasDirtyFields: () => false,
});

interface IContextProviderProps {
  children: ReactNode;
}

export const RiskArrayFormContextProvider = ({
  children,
}: IContextProviderProps) => {
  const [dirtyFields, setDirtyFields] = useState<Record<string, boolean>>({});

  const hasDirtyFields = useCallback(() => {
    return keys(dirtyFields).length > 0;
  }, [dirtyFields]);

  const state = useMemo(
    () => ({
      dirtyFields,
      setDirtyFields,
      hasDirtyFields,
    }),
    [dirtyFields, hasDirtyFields],
  );

  return (
    <RiskArrayFormContext.Provider value={state}>
      {children}
    </RiskArrayFormContext.Provider>
  );
};

export const RiskArrayTreeContextProvider = ({
  children,
}: IContextProviderProps) => {
  const state = useRiskArrayTree();
  const { getTree, setTree, getOriginalCache, saveTree } = state;

  const getValue: IGetValue = useCallback(
    ({ name, path }) => {
      const tree = getTree();
      const obj = get(tree, toExpandedTreePath(path));
      return obj ? obj[name] : null;
    },
    [getTree],
  );

  const setValue: ISetValue = useCallback(
    ({ name, path, value }) => {
      const fullPath = toExpandedTreePath(path);
      setTree((prev) => {
        const newState = [...prev];
        set(newState, `${fullPath}.${name}`, value);

        return [...newState];
      });
    },
    [setTree],
  );

  const getSubRows: IGetSubRows = useCallback(
    ({ path }) => {
      const tree = getTree();
      const obj = get(tree, `${path.replaceAll('.', '.subRows.')}.subRows`);
      return obj;
    },
    [getTree],
  );

  const value = useMemo(() => {
    return {
      ...state,
      getValue,
      setValue,
      getOriginalCache,
      getSubRows,
      saveTree,
    };
  }, [state, getValue, setValue, getOriginalCache, getSubRows, saveTree]);

  return (
    <RiskArrayTreeContext.Provider value={value}>
      {children}
    </RiskArrayTreeContext.Provider>
  );
};

export const useRiskArrayContext = () => {
  const context = useContext(RiskArrayTreeContext);

  if (context === null) {
    throw new Error(
      'Context must be used within a RiskArrayTreeContextProvider',
    );
  }

  return context;
};

export const useRiskArrayFormContext = () => {
  const context = useContext(RiskArrayFormContext);

  if (context === null) {
    throw new Error(
      'Context must be used within a RiskArrayFormContextProvider',
    );
  }

  return context;
};
