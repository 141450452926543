import { PROXY_CORE_PATH } from '~/constants';
import { FilterLayers } from '~/types/commissions';

const COMMISSIONS_PATH = '/api/symbols/commissions';

export const FETCH_COMMISSIONS_RULES_ENDPOINT = `${COMMISSIONS_PATH}/rules`;

export const FETCH_COMMISSION_GROUPS_ENDPOINT = `${PROXY_CORE_PATH}v2.0/commissions/groups`;

export const getFetchCommissionsTreeEndpoint = (
  layer: FilterLayers,
  entity?: number | string,
) => {
  if (layer === FilterLayers.Groups && entity) {
    return `${COMMISSIONS_PATH}/group/tree?groupId=${entity}`;
  }

  if (layer === FilterLayers.Accounts && entity) {
    return `${COMMISSIONS_PATH}/account/tree?accountId=${entity}`;
  }

  return `${COMMISSIONS_PATH}/default/tree`;
};

export const getFetchCommissionsInstrumentsEndpoint = (
  layer: FilterLayers,
  entity?: number | string,
) => {
  if (layer === FilterLayers.Groups && entity) {
    return `${COMMISSIONS_PATH}/group?groupId=${entity}`;
  }

  if (layer === FilterLayers.Accounts && entity) {
    return `${COMMISSIONS_PATH}/account?accountId=${entity}`;
  }

  return `${COMMISSIONS_PATH}/default`;
};

export const getPostCommissionsEndpoint = (
  layer: FilterLayers,
  entity?: number | string,
) => {
  if (layer === FilterLayers.Groups && entity) {
    return `${COMMISSIONS_PATH}/group?groupId=${entity}`;
  }

  if (layer === FilterLayers.Accounts && entity) {
    return `${PROXY_CORE_PATH}v2.1/accounts/${entity}/commissions`;
  }

  return `${COMMISSIONS_PATH}/default`;
};
