import { yupResolver } from '@hookform/resolvers/yup';
import { get, isUndefined, omitBy } from 'lodash';
import { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import {
  PanelGroup,
  Panel,
  Notification,
  Modal,
  useModal,
} from 'react-ui-kit-exante';

import {
  useAddInterCommodityMutation,
  useDeleteInterCommodityMutation,
  useLazyGetInterCommodityQuery,
  useGetUnderlyingQuery,
  useLazyGetInterCommodityByIdQuery,
  useUpdateInterCommodityMutation,
} from '~/api';
import {
  FormInputContainer,
  FormCheckboxContainer,
  FormMultiSelectContainer,
} from '~/containers/form';
import { INTER_COMMODITY_PATH } from '~/routes';
import { PanelHeaderControls } from '~/shared/components';
import { IInterCommodityRequestBody } from '~/types/interCommodity';

import InterCommodityFormPageStyles from './InterCommodityFormPage.module.css';
import { DEFAULT_VALUES, VALIDATION_SCHEMA } from './constants';

export const InterCommodityFormPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const modal = useModal();

  const [getInterCommodityQuery] = useLazyGetInterCommodityQuery();
  const [getInterCommodityById] = useLazyGetInterCommodityByIdQuery();
  const [addInterCommodity] = useAddInterCommodityMutation();
  const [updateInterCommodity] = useUpdateInterCommodityMutation();
  const [deleteInterCommodity] = useDeleteInterCommodityMutation();

  const formInstance = useForm({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(VALIDATION_SCHEMA),
  });

  const {
    formState: { isDirty, isSubmitting },
    getValues,
    reset,
  } = formInstance;

  const fetchInterCommodityQuery = async () => {
    const { data } = await getInterCommodityQuery({});
    const defaultPriority =
      data &&
      Math.max(...data.map((item: { priority: number }) => item.priority)) + 1;

    reset({ ...DEFAULT_VALUES, priority: defaultPriority });
  };

  useEffect(() => {
    if (!id) {
      fetchInterCommodityQuery();
    }
  }, []);

  const { underlyingOptions } = useGetUnderlyingQuery(undefined, {
    selectFromResult: ({ data }) => ({
      underlyingOptions: data
        ? data.map(({ underlying }) => ({
            label: underlying,
            value: underlying,
          }))
        : [],
    }),
  });

  const onSubmitHandler = async (data: IInterCommodityRequestBody) => {
    let result;

    if (id) {
      result = await updateInterCommodity({ id, data });
    } else {
      result = await addInterCommodity(data);
    }

    if (!('error' in result)) {
      Notification.success({
        title: `Inter-commodity successfully ${id ? 'updated' : 'added'}`,
      });

      if (id) {
        reset(omitBy(getValues(), isUndefined));
      } else {
        navigate(INTER_COMMODITY_PATH, {
          state: {
            previousPath: window.location.href,
          },
        });
      }
    }
  };
  const submitHandle = formInstance.handleSubmit(onSubmitHandler);

  const disabled = isSubmitting || !isDirty;

  const onCloseHandler = useCallback(() => {
    navigate(INTER_COMMODITY_PATH, {
      state: {
        previousPath: window.location.href,
      },
    });
  }, [navigate]);

  const onDeleteHandler = useCallback(async () => {
    const result = await deleteInterCommodity(id);

    if (!('error' in result)) {
      Notification.success({
        title: `Inter-commodity successfully deleted`,
      });

      navigate(INTER_COMMODITY_PATH, {
        state: {
          previousPath: window.location.href,
        },
      });
    }
  }, [navigate, deleteInterCommodity, id]);

  const fetchInterCommodityById = useCallback(async () => {
    if (id) {
      const { data } = await getInterCommodityById(id);
      reset(data);
    }
  }, [reset, getInterCommodityById, id]);

  useEffect(() => {
    fetchInterCommodityById();
  }, [fetchInterCommodityById]);

  return (
    <FormProvider {...formInstance}>
      <form onSubmit={submitHandle}>
        <Panel
          action={
            <PanelHeaderControls
              disabled={disabled}
              onClose={onCloseHandler}
              onDelete={id ? modal.onOpen : undefined}
              onRefresh={id ? fetchInterCommodityById : undefined}
            />
          }
          disableBodyPaddings
          title={`${id ? 'Edit' : 'Add'} inter-commodity`}
        />

        <PanelGroup className={InterCommodityFormPageStyles.PanelGroup}>
          <Panel className={InterCommodityFormPageStyles.Panel}>
            <FormMultiSelectContainer
              disableCloseOnSelect={false}
              isMultiple={false}
              label="Underlying₁"
              name="underlying1"
              options={underlyingOptions}
            />

            <FormInputContainer label="Δ₁" name="deltaRatio1" type="number" />

            <FormMultiSelectContainer
              disableCloseOnSelect={false}
              isMultiple={false}
              label="Underlying₂"
              name="underlying2"
              options={underlyingOptions}
            />

            <FormInputContainer label="Δ₂" name="deltaRatio2" type="number" />

            <FormInputContainer
              label="Spread credit"
              name="spreadCredit"
              type="number"
            />

            <FormCheckboxContainer label="Same Leg Side" name="sameLegSide" />

            <FormCheckboxContainer label="Tier FF" name="tierFrontFront" />
            <FormCheckboxContainer label="Tier FB" name="tierFrontBack" />
            <FormCheckboxContainer label="Tier BB" name="tierBackBack" />
          </Panel>
        </PanelGroup>
      </form>

      <Modal
        isOpened={modal.isOpened}
        onClose={modal.onClose}
        title="Are you sure?"
        confirmButton={{
          handleConfirm: onDeleteHandler,
        }}
      >
        <div>Do you want to delete this inter-commodity?</div>
      </Modal>
    </FormProvider>
  );
};
