import { Loader } from 'react-ui-kit-exante';

import { RemoveIcon } from '~/images/icons';

import Styles from './DeleteButton.module.css';

interface IDeleteButtonProps {
  loading: boolean;
}

export const DeleteButton = ({ loading }: IDeleteButtonProps) => {
  return (
    <span className={Styles.DeleteButton}>
      {loading ? <Loader size="m" /> : <RemoveIcon />}
    </span>
  );
};
