import { useMemo } from 'react';
import * as Yup from 'yup';

import { ICommissionRule } from '~/types/commisionRules';

function getExistNames(tableData: ICommissionRule[], editedName: string) {
  return tableData.reduce<string[]>((acc, { name }) => {
    if (name !== editedName) {
      acc.push(name);
    }

    return acc;
  }, []);
}

export function useValidationSchema(
  tableData: ICommissionRule[],
  editedName: string,
) {
  return useMemo(
    () =>
      Yup.object({
        name: Yup.string()
          .required('Name is required')
          .notOneOf(
            getExistNames(tableData, editedName),
            'This name already exists',
          ),
        rule: Yup.string().required('Rule is required'),
        type: Yup.string().required('Type is required'),
      }),
    [editedName, tableData],
  );
}
