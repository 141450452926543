import { createSlice } from '@reduxjs/toolkit';

import { EStatus } from '~/types/api';
import { IStateField } from '~/types/state';
import { ITypesEntities } from '~/types/types';

import { DEFAULT_ENTITY } from './constants';
import { fetchTypes } from './types.actions';

const initialState: IStateField<ITypesEntities> = {
  status: EStatus.Idle,
  error: null,
  data: {
    sex: DEFAULT_ENTITY,
    userType: DEFAULT_ENTITY,
    corporateCategory: DEFAULT_ENTITY,
    symbolTypes: DEFAULT_ENTITY,
  },
};

export const typesSlice = createSlice({
  name: 'types',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTypes.pending, (state) => {
      state.status = EStatus.Loading;
      state.error = null;
    });

    builder.addCase(fetchTypes.fulfilled, (state, action) => {
      state.data = action.payload;
      state.status = EStatus.Succeeded;
    });

    builder.addCase(fetchTypes.rejected, (state, action) => {
      state.error = action.payload || null;
      state.status = EStatus.Failed;
    });
  },
});
