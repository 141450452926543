import { pick } from 'lodash';

import { formatDate, isAxiosError } from '~/shared/utils';
import { isValidDate } from '~/shared/utils/dates';
import { deepOmitFalsyValues } from '~/shared/utils/object';
import { TParams } from '~/types/api';
import { ITransaction, ITransactionPostRequest } from '~/types/transactions';

import {
  arraySupportedFields,
  expectedFields,
} from './transactions.api.constants';

function getInitialStateForManualTransaction(
  accountId: string,
  extraData: {
    '4EyesCheck': boolean;
    marketPrice?: string;
  },
):
  | Pick<
      ITransactionPostRequest,
      'accountId' | 'commission' | 'extraData' | 'source'
    >
  | Pick<ITransactionPostRequest, 'commission' | 'source'> {
  if (extraData && extraData['4EyesCheck']) {
    return {
      accountId,
      commission: {},
      extraData,
      source: 'wbo',
    };
  }

  return { accountId, commission: {}, source: 'wbo', extraData };
}

export function getDataForPostManualTransaction(
  transaction: ITransactionPostRequest,
): Partial<ITransactionPostRequest> {
  const { accountId, transactionType, extraData, ...restTransactionFormData } =
    transaction;

  const initialStateForManualTransaction = getInitialStateForManualTransaction(
    String(accountId),
    extraData,
  );

  const data = Object.entries(restTransactionFormData).reduce(
    (acc, [key, value]) => {
      if (value === 'takeCommission' || key === 'useRevenue') {
        return acc;
      }

      if (key === 'assetId') {
        return { ...acc, asset: value };
      }

      if (key === 'useAutoconversion' || key === 'useAutoCashConversion') {
        return {
          ...acc,
          useAutoCashConversion:
            typeof value === 'string' ? transformStringToBoolean(value) : value,
        };
      }

      if (key === 'commissionValue') {
        return { ...acc, commission: { ...acc.commission, value } };
      }

      if (key === 'commissionCurrency') {
        return { ...acc, commission: { ...acc.commission, currency: value } };
      }

      if (key === 'commissionOperationType') {
        return { ...acc, commission: { ...acc.commission, type: value } };
      }

      if (key === 'amount') {
        return {
          ...acc,
          amount: transactionType === 'withdraw' ? `-${value}` : value,
        };
      }

      if (key === 'valueDate' && value) {
        return {
          ...acc,
          valueDate: isValidDate(new Date(value))
            ? formatDate(new Date(String(value)))
            : value,
        };
      }

      return {
        ...acc,
        [key]: value,
      };
    },
    initialStateForManualTransaction,
  );

  return deepOmitFalsyValues(data, { exclude: ['useAutoCashConversion'] });
}

export const prepareFiltersToRequestTransactions = (filters: TParams) => {
  const keys = Object.keys(filters);
  const result = keys.reduce<Record<string, unknown>>((acc, key) => {
    const isArraySupportedField = arraySupportedFields.includes(key);
    const value = filters[key];

    if (isArraySupportedField && Array.isArray(value)) {
      acc[key] = value.join('|');
    } else {
      acc[key] = value;
    }

    return { ...acc, hideLockTransactions: false };
  }, {});

  return result;
};

type TTransactionErrorMessage = string | Record<string, unknown>;
export type TTransactionErrorDescription = Record<
  number,
  Record<string, string>
>;

export type TTransactionError = {
  statusCode?: number;
  message?: TTransactionErrorMessage;
  description?: TTransactionErrorDescription;
  url?: string;
};

export function transformError(error: unknown): TTransactionError | null {
  if (isAxiosError(error)) {
    const response = error.response;

    if (response) {
      const { data, request } = response;
      const { message, statusCode } = data;

      return {
        statusCode,
        message,
        description: message.description,
        url: request.responseURL,
      };
    }
  }

  if (error instanceof Error) {
    return { message: `${error.name}: ${error.message}` };
  }

  return null;
}

export function transformStringToBoolean(value: string): boolean | null {
  if (value.toLowerCase() === 'true') {
    return true;
  }

  if (value.toLowerCase() === 'false') {
    return false;
  }

  return null;
}

export function transactionMapper(transaction: ITransaction): ITransaction {
  return pick(transaction, expectedFields);
}

export function transactionsMappers(
  transactions: ITransaction[],
): ITransaction[] {
  return transactions.map((transaction) => transactionMapper(transaction));
}
