import { mapValues, pickBy } from 'lodash';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { PATH_DELIMITER } from '~/constants/common';
import { selectDictTypesRules } from '~/store/commissions';
import { FilterLayers, ICommissionTree } from '~/types/commissions';
import { RowType } from '~/types/common';

export function usePrepareDataForSend() {
  const typesRules = useSelector(selectDictTypesRules);

  return useCallback(
    (data: ICommissionTree, layer: FilterLayers) => {
      const {
        currency,
        inherited,
        minFeeExchange = false,
        minFeeOdd,
        minFeeRebateRate,
        minimumFee,
        override,
        path,
        rowType,
        typeExchangeTicker,
      } = data;

      const rulesExchange = pickBy(data.exchange, (value) => value !== null);
      const rulesInternal = pickBy(data.internal, (value) => value !== null);
      const rulesRebate = pickBy(data.rebate, (value) => value !== null);

      const splitedPath = (
        rowType === RowType.Node ? path : typeExchangeTicker
      ).split(PATH_DELIMITER);

      const [type, exchange] = splitedPath;
      let ticker;

      if (rowType === RowType.Instrument) {
        ticker = splitedPath.pop();
      }

      return {
        active: layer === FilterLayers.Default ? !inherited : override,
        currency: currency === 'null' ? null : currency,
        exchange,
        inherited,
        minFeeExchange,
        minFeeOdd,
        minFeeRebateRate,
        minimumFee,
        type,
        rules: {
          ...mapValues(rulesInternal, (value, key) => ({
            rate: value,
            rule: typesRules[key],
            type: 'internal',
          })),
          ...mapValues(rulesRebate, (value, key) => ({
            rate: value,
            rule: typesRules[key],
            type: 'rebate',
          })),
          ...(layer === FilterLayers.Default &&
            mapValues(rulesExchange, (value, key) => ({
              rate: value,
              rule: typesRules[key],
              type: 'exchange',
            }))),
        },
        ...(ticker && { ticker }),
      };
    },
    [typesRules],
  );
}
