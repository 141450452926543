import { PROXY_CORE_PATH } from '~/constants';
import { FilterLayers } from '~/types/overnights';

const OVERNIGHTS_PATH = '/api/symbols/overnights';

export const getOvernightsTreeEndpoint = (
  layer: FilterLayers,
  entity?: number | string,
) => {
  if (layer === FilterLayers.Groups && entity) {
    return `${OVERNIGHTS_PATH}/group/tree?groupId=${entity}`;
  }

  if (layer === FilterLayers.Accounts && entity) {
    return `${OVERNIGHTS_PATH}/account/tree?accountId=${entity}`;
  }

  return `${OVERNIGHTS_PATH}/default/tree`;
};

export const getFetchOvernightsInstruments = (
  layer: FilterLayers,
  entity?: number | string,
) => {
  if (layer === FilterLayers.Groups && entity) {
    return `${OVERNIGHTS_PATH}/group?groupId=${entity}`;
  }

  if (layer === FilterLayers.Accounts && entity) {
    return `${OVERNIGHTS_PATH}/account?accountId=${entity}`;
  }

  return `${OVERNIGHTS_PATH}/default`;
};

export const getPostOvernightsEndpoint = (
  layer: FilterLayers,
  entity?: number | string,
) => {
  if (layer === FilterLayers.Groups && entity) {
    return `${OVERNIGHTS_PATH}/group?groupId=${entity}`;
  }

  if (layer === FilterLayers.Accounts && entity) {
    return `${PROXY_CORE_PATH}v2.1/accounts/${entity}/rates/overnights`;
  }

  return `${OVERNIGHTS_PATH}/default`;
};

export const OVERNIGHTS_SETS = `${PROXY_CORE_PATH}v2.0/overnights/sets`;
