import { pick, set } from 'lodash';
import { useCallback } from 'react';
import { UseFormGetValues, UseFormReset } from 'react-hook-form';

import { usePostTradesMutation } from '~/api';
import { useBack } from '~/hooks';
import { TRADES_PATH } from '~/routes';
import { flattenSelectedValues, sendNotification } from '~/shared/utils';
import { ITradePostRequest } from '~/types/trades';

import { useAccountTabs } from '../../AccountPage/TabManagement/hooks';
import {
  DEFAULT_VALUES,
  FIELDS_FOR_RESET_AFTER_SUCCESS_SUBMIT,
} from '../constants';
import { TradeFormValues } from '../types';

function toPostTradeParams(
  params: TradeFormValues['switched'],
  nonSwitchedUse4EyesCheck: unknown,
): ITradePostRequest {
  const res = {} as ITradePostRequest;
  Object.entries(params).forEach(([k, v]) => {
    if (k === 'quantity' || k === 'price') {
      set(res, k, Number(v));
    }

    set(res, k, v);
  });

  res.use4EyesCheck = Boolean(nonSwitchedUse4EyesCheck);

  return res;
}

interface IUseSubmitProps {
  accountId: string;
  getValues: UseFormGetValues<TradeFormValues>;
  isSwitchTrade: boolean;
  reset: UseFormReset<TradeFormValues>;
}

export function useSubmit({
  accountId,
  getValues,
  isSwitchTrade,
  reset,
}: IUseSubmitProps) {
  const { activityTabIndex } = useAccountTabs();

  const [updateTrade] = usePostTradesMutation();

  const successCallback = useCallback(() => {
    const values = getValues();

    sendNotification(
      `Trade has been submitted${
        values.nonSwitched.use4EyesCheck ? ' for approval' : ''
      }`,
      'success',
    );

    reset({
      ...values,
      ...pick(DEFAULT_VALUES, FIELDS_FOR_RESET_AFTER_SUCCESS_SUBMIT),
    });
  }, [getValues, reset]);

  const redirectToTrade = useBack({
    defaultPath: TRADES_PATH,
  });

  const redirectToTradePage = () => {
    redirectToTrade();

    localStorage.setItem(
      'currentTab',
      accountId ? String(activityTabIndex) : '0',
    );
  };

  const onSubmitHandler = useCallback(
    async ({ nonSwitched, switched }: TradeFormValues) => {
      const requestParams = {
        ...nonSwitched,
        accountId:
          typeof nonSwitched.accountId === 'string'
            ? nonSwitched.accountId
            : nonSwitched.accountId.value,
        userId:
          typeof nonSwitched.user === 'string'
            ? nonSwitched.user
            : nonSwitched.user.value,
      };

      const resultNonSwitched = await updateTrade({
        params: flattenSelectedValues(requestParams),
      });

      if ('data' in resultNonSwitched && resultNonSwitched.data) {
        if (isSwitchTrade) {
          const switchTradeRequestParams = {
            ...switched,
            accountId:
              typeof switched.accountId === 'string'
                ? switched.accountId
                : switched.accountId.value,
            userId:
              typeof switched.user === 'string'
                ? switched.user
                : switched.user.value,
          };
          const resultSwitched = await updateTrade({
            params: toPostTradeParams(
              switchTradeRequestParams,
              nonSwitched.use4EyesCheck,
            ),
          });

          if ('data' in resultSwitched && resultSwitched.data) {
            successCallback();
            redirectToTradePage();
          }
        } else {
          successCallback();
          redirectToTradePage();
        }
      }
    },
    [isSwitchTrade, redirectToTradePage, successCallback, updateTrade],
  );

  return { onSubmitHandler, redirectToTradePage };
}
