import { isEqual, isFunction } from 'lodash';
import { FC, useCallback, useEffect, useMemo } from 'react';
import {
  Bookmarks,
  ExportTableParams,
  Table,
  useTableData,
} from 'react-ui-kit-exante';

import { EMPTY_ARRAY, NO_DATA_HEIGHT } from '~/constants';
import { usePrevious, useLogHandleTime } from '~/hooks';
import { getDefaultBookmarkResponse } from '~/hooks/useBookmark/helpers';
import { IBookmarkResponseProps } from '~/hooks/useBookmark/types';
import { auditLogService } from '~/resources';
import { IAuditLog } from '~/resources/auditLog';
import { WithBookmarks } from '~/shared/components/WithBookmarks';
import {
  calculateCountOfPages,
  getIsFieldExistsAndEmpty,
} from '~/shared/utils';
import { IRefreshActiveTabQuery } from '~/types/refetch';

import { TGlobalFilters } from '../TransactionsContainer/types';

import { DISPLAYED_COLUMN_KEYS, getColumns } from './columns';
import { TABLE_ID } from './constants';
import { prepareParamsToService, getDefaultFilters } from './helpers';
import { DEFAULT_SORT_BY } from './sorting';
import { IFetchData, IFetchParams } from './types';

interface IAuditLogContainerProps {
  entity?: string;
  globalFilters?: TGlobalFilters;
  tableId?: string;
  updateRefetch?: (state: IRefreshActiveTabQuery) => void;
}

export const AuditLog: FC<IAuditLogContainerProps & IBookmarkResponseProps> = ({
  entity = 'audit-log-list',
  globalFilters,
  updateRefetch,
  tableId = TABLE_ID,
  selectedBookmark,
  handleSaveBookmark,
  handleSaveAsNewBookmark,
  handleShareBookmark,
  handleDeleteBookmark,
}) => {
  const { setStartHandleTime, logHandleTime } = useLogHandleTime(entity);

  const fetchAndPrepareData: IFetchData = useCallback(
    async ({ params }) => {
      const isEmptyAccountsList =
        globalFilters && getIsFieldExistsAndEmpty(globalFilters, 'accountId');

      if (isEmptyAccountsList) {
        const emptyResponse = { data: [], pagination: { total: 0 } };
        return emptyResponse;
      }

      setStartHandleTime();

      const preparedParams = prepareParamsToService({
        ...globalFilters,
        ...params,
      });

      return auditLogService.resolveAuditLogs(preparedParams);
    },
    [globalFilters, setStartHandleTime],
  );

  const tableDataArgs = useMemo(
    () => ({
      data: { onFetch: fetchAndPrepareData },
      filters: { getDefaultFilters },
      saveViewParamsAfterLeave: true,
      tableId,
      sorting: { getDefaultSorting: () => DEFAULT_SORT_BY },
    }),
    [fetchAndPrepareData, tableId],
  );

  const {
    data,
    limit,
    setLimit,
    setPage,
    page,
    isLoading,
    setFilter,
    removeFilter,
    resetFilters,
    setSorting,
    filters,
    fetchData: refetch,
  } = useTableData(tableDataArgs);

  useEffect(() => {
    if (isFunction(updateRefetch)) {
      updateRefetch({
        refetch,
        isLoading,
      });
    }
  }, [refetch, isLoading, updateRefetch]);

  const prevData = usePrevious(data);

  const total = data?.pagination.total || 0;
  const pageCount = useMemo(
    () => calculateCountOfPages(total, limit),
    [limit, total],
  );

  const columns = getColumns({
    onFilter: setFilter,
    onRemove: removeFilter,
  });

  const filterProps = {
    removeAllFilters: resetFilters,
    filters,
    manualFilters: true,
  };

  const serverPaginationProps = useMemo(
    () => ({
      pageSize: limit,
      setPage,
      setPageSize: setLimit,
      pageIndex: page,
      total,
      pageCount,
    }),
    [limit, page, pageCount, setLimit, setPage, total],
  );

  const exportTableParams = useMemo(
    () => ({
      type: 'server',
      onFetch: async (params: IFetchParams) => {
        const preparedParams = prepareParamsToService({
          ...params,
          ...globalFilters,
        });

        return auditLogService.resolveAuditLogsForExport({
          ...preparedParams,
          offset: 0,
        });
      },
    }),
    [globalFilters],
  ) as ExportTableParams<IAuditLog> | undefined;

  useEffect(() => {
    if (data && !isEqual(prevData, data)) {
      logHandleTime();
    }
  }, [data, logHandleTime, prevData]);

  const bookmarkComponent = useMemo(() => {
    if (globalFilters) {
      return null;
    }

    return (
      <Bookmarks
        initialValues={selectedBookmark}
        onSave={(name) => handleSaveBookmark(name, filters)}
        onSaveAsNew={(name) => handleSaveAsNewBookmark(name, filters)}
        onShare={handleShareBookmark}
        onDelete={handleDeleteBookmark}
      />
    );
  }, [
    filters,
    globalFilters,
    handleSaveBookmark,
    handleSaveAsNewBookmark,
    handleShareBookmark,
    handleDeleteBookmark,
    selectedBookmark,
  ]);

  const displayedColumnKeys = useMemo(
    () =>
      selectedBookmark.columns.length
        ? selectedBookmark.columns
        : DISPLAYED_COLUMN_KEYS,
    [selectedBookmark.columns],
  );

  return (
    <Table<IAuditLog>
      columns={columns}
      data={data?.data || EMPTY_ARRAY}
      defaultSortBy={DEFAULT_SORT_BY}
      displayedColumnKeys={displayedColumnKeys}
      exportTableParams={exportTableParams}
      filteringProps={filterProps}
      filtersRightPanelComponent={bookmarkComponent}
      hasFilters
      hasPagination
      isFlexLayout
      isLoading={isLoading}
      isPinnedHeader
      manualSortBy
      noDataHeight={NO_DATA_HEIGHT}
      onSort={setSorting}
      saveColumnOrder
      saveViewParamsAfterLeave
      serverPaginationProps={serverPaginationProps}
      showScrollbar
      showTableInfo
      tableId={tableId}
      title="Audit Logs"
    />
  );
};

export const AuditLogContainer: FC<IAuditLogContainerProps> = ({
  entity = 'audit-log-list',
  globalFilters,
  updateRefetch,
  tableId = TABLE_ID,
}) => {
  if (globalFilters) {
    const {
      selectedBookmark,
      handleSaveBookmark,
      handleSaveAsNewBookmark,
      handleShareBookmark,
      handleDeleteBookmark,
    } = getDefaultBookmarkResponse('Audit logs');

    return (
      <AuditLog
        entity={entity}
        globalFilters={globalFilters}
        tableId={tableId}
        updateRefetch={updateRefetch}
        selectedBookmark={selectedBookmark}
        handleSaveBookmark={handleSaveBookmark}
        handleSaveAsNewBookmark={handleSaveAsNewBookmark}
        handleShareBookmark={handleShareBookmark}
        handleDeleteBookmark={handleDeleteBookmark}
      />
    );
  }

  return (
    <WithBookmarks
      component={AuditLog}
      entity={entity}
      globalFilters={globalFilters}
      pageName="Audit logs"
      tableId={tableId}
      updateRefetch={updateRefetch}
    />
  );
};
