const apiEndpoints = {
  account: '/account_types',
  accountOwnership: '/account_ownership',
  accountPurposes: '/account_purposes',
  accountStatus: '/account_status',
  asset: '/asset',
  categories: '/category',
  client: '/client',
  counter_parties: '/counterparty-accounts',
  exante: '/exante',
  feePolicy: '/fee_policy',
  incorporation: '/incorporation',
  interCashCardStatus: '/intercash_card_status',
  legalEntity: '/legal_entity',
  logic: '/logic_type',
  operation: '/operation?withSystemOnly=true',
  superUser: '/superuser',
  trade: '/trade',
  userClientPermissions: '/user_client_permissions',
  executionCounterparty: '/additional_execution_counterparty',
  settlementCounterparty: '/additional_settlement_counterparty',
};

export const getTypeEndpoint = (key: keyof typeof apiEndpoints) => {
  if (key === 'counter_parties') {
    return `/api${apiEndpoints.counter_parties}`;
  }
  return `/api/types${apiEndpoints[key]}`;
};
