import { createAsyncThunk } from '@reduxjs/toolkit';

import { symbolsService } from '~/resources';
import { ITypesEntities } from '~/types/types';

import {
  DEFAULT_CORPORATE_CATEGORY,
  DEFAULT_SEX_ENTITY,
  DEFAULT_USER_TYPE,
} from './constants';

export const fetchTypes = createAsyncThunk<
  ITypesEntities,
  undefined,
  { rejectValue: string }
>('types/fetch', async (_, { rejectWithValue }) => {
  try {
    const symbolTypesPromise = symbolsService.resolveSymbolTypes();

    // TODO Move to the RTK API types
    const symbolTypes = await symbolTypesPromise;

    return {
      sex: DEFAULT_SEX_ENTITY,
      userType: DEFAULT_USER_TYPE,
      corporateCategory: DEFAULT_CORPORATE_CATEGORY,
      symbolTypes,
    };
  } catch (error) {
    return rejectWithValue((error as Error).message);
  }
});
