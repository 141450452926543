import { createSlice } from '@reduxjs/toolkit';
import { clone, cloneDeep, get, groupBy, isEqual, set, unset } from 'lodash';

import { commissionsApi } from '~/api';
import { REGEX_LAST_SEGMENT_IN_PATH } from '~/constants';
import { deepSet, getAllPathsSet } from '~/shared/utils';
import { generateRulesData } from '~/shared/utils/commissions';
import { RowType } from '~/types/common';
import type { TApplicationState } from '~/types/store';

import { DEFAULT_CHANGED_COMMISSIONS, INITIAL_STATE } from './constants';
import { getQueryParams } from './helpers';

export const commissionsSlice = createSlice({
  name: 'commissions',
  initialState: INITIAL_STATE,
  reducers: {
    expandedRowsSet: (state, { payload }) => {
      const [expanded, id, value] = payload;

      state.table.expandedRows = {
        ...expanded,
        [id]: value,
      };
    },
    expandedRowsUpdate: (state, { payload }) => {
      const [id, value] = payload;

      const expandedRows = clone(state.table.expandedRows);
      const newExpandedRows =
        typeof expandedRows === 'boolean'
          ? expandedRows
          : {
              ...expandedRows,
              [id]: value,
            };

      state.table.expandedRows = newExpandedRows;
    },
    filtersSetAccount: (state, { payload }) => {
      const select = {
        ...state.filters.select,
        account: payload,
      };
      const queryParams = getQueryParams({
        ...select,
        relatedGroup: null,
      });

      state.filters.relatedGroup = null;
      state.filters.select = select;
      state.filters.queryParams = queryParams;

      state.table.downloadedPaths = [];
    },
    filtersSetGroup: (state, { payload }) => {
      const { value, shouldResetDownloadedPaths } = payload;

      const select = {
        ...state.filters.select,
        group: value,
      };
      const queryParams = getQueryParams({
        ...select,
        relatedGroup: state.filters.relatedGroup,
      });

      state.filters.select = select;
      state.filters.queryParams = queryParams;

      if (shouldResetDownloadedPaths) {
        state.table.downloadedPaths = [];
      }
    },
    filtersSetRelatedGroup: (state, { payload }) => {
      const fixedPayload = payload === 'null' ? null : payload;

      const select = {
        ...state.filters.select,
        group: fixedPayload,
      };
      const queryParams = getQueryParams({
        ...select,
        relatedGroup: fixedPayload,
      });

      state.filters.relatedGroup = fixedPayload;
      state.filters.select = select;
      state.filters.queryParams = queryParams;

      state.table.downloadedPaths = [];
    },
    filtersSetLayer: (state, { payload }) => {
      const select = {
        layer: payload,
        account: null,
        group: null,
      };
      const queryParams = getQueryParams({ ...select, relatedGroup: null });

      state.filters.select = select;
      state.filters.queryParams = queryParams;
      state.filters.relatedGroup = null;
    },
    resetTable: (state, { payload }) => {
      const {
        shouldResetExpandedRows = true,
        shouldResetDownloadedPaths = true,
      } = payload;

      if (shouldResetExpandedRows) {
        state.table.expandedRows = {};
      }

      if (shouldResetDownloadedPaths) {
        state.table.downloadedPaths = [];
      }

      state.table.tree = cloneDeep(state.table.defaultTree);
      state.table.positionByIdInTree = cloneDeep(
        state.table.defaultPositionByIdInTree,
      );

      state.search.isActive = false;
      state.search.tree = [];
      state.initialCommission = {};
    },
    updateInstrumentValue: (state, { payload }) => {
      const { path, value, column } = payload;

      const row = get(
        state[state.search.isActive ? 'search' : 'table'].tree,
        state.table.positionByIdInTree[path],
      );

      if (!state.initialCommission[row.path]) {
        state.initialCommission[row.path] = cloneDeep(row);
        deepSet(row, column, value);
        state.changedCommission.instruments[path] = row;

        return;
      }

      deepSet(row, column, value);

      if (
        isEqual(cloneDeep(state.initialCommission[row.path]), cloneDeep(row))
      ) {
        delete state.changedCommission.instruments[path];
      } else {
        state.changedCommission.instruments[path] = row;
      }
    },
    updateNodeValue: (state, { payload }) => {
      const { path, value, column } = payload;

      const row = get(
        state[state.search.isActive ? 'search' : 'table'].tree,
        state.table.positionByIdInTree[path],
      );

      if (!state.initialCommission[row.path]) {
        state.initialCommission[row.path] = cloneDeep(row);
        deepSet(row, column, value);
        state.changedCommission.nodes[path] = row;

        return;
      }

      deepSet(row, column, value);

      if (
        isEqual(cloneDeep(state.initialCommission[row.path]), cloneDeep(row))
      ) {
        delete state.changedCommission.nodes[path];
      } else {
        state.changedCommission.nodes[path] = row;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      commissionsApi.endpoints.getCommissionsRules.matchFulfilled,
      (state, { payload }) => {
        const { listRules, typeRules } = payload;

        state.dict.listRules = listRules;
        state.dict.typeRules = typeRules;
      },
    );
    builder.addMatcher(
      commissionsApi.endpoints.getCommissionsTree.matchFulfilled,
      (state, { payload }) => {
        state.table.defaultTree = cloneDeep(payload.tree);
        state.table.tree = cloneDeep(payload.tree);
        state.table.positionByIdInTree = cloneDeep(payload.positionByIdInTree);
        state.table.defaultPositionByIdInTree = cloneDeep(
          payload.positionByIdInTree,
        );
        state.table.expandedRows = {};

        state.initialCommission = {};
      },
    );
    builder.addMatcher(
      commissionsApi.endpoints.getCommissionsInstruments.matchFulfilled,
      (state, { payload }) => {
        const { path, data: instruments } = payload;

        const oldInstruments = get(
          state.table.tree,
          `${state.table.positionByIdInTree[path]}.subRows`,
          [],
        );

        const newInstruments = [
          ...oldInstruments,
          ...instruments.map(
            ({ commission: { rules, ...commission }, ...instrument }) => ({
              ...instrument,
              ...commission,
              ...generateRulesData(rules),
              rowType: RowType.Instrument,
            }),
          ),
        ];

        deepSet(
          state.table.tree,
          `${state.table.positionByIdInTree[path]}.subRows`,
          newInstruments,
        );

        state.table.positionByIdInTree = {
          ...state.table.positionByIdInTree,
          ...newInstruments.reduce<Record<string, string>>(
            (acc, item, index) => {
              acc[
                item.path
              ] = `${state.table.positionByIdInTree[path]}.subRows.${index}`;
              return acc;
            },
            {},
          ),
        };

        if (!state.table.downloadedPaths.includes(path)) {
          state.table.downloadedPaths.push(path);
        }
      },
    );
    builder.addMatcher(
      commissionsApi.endpoints.searchCommissionsInstruments.matchFulfilled,
      (state, { payload }) => {
        state.search.isActive = true;

        if (payload.isFinish) {
          const groupedInstrumentsByPath = groupBy(
            payload.instruments,
            (item) => item.path.replace(REGEX_LAST_SEGMENT_IN_PATH, ''),
          );

          const pathsWithInstruments: string[] = [];

          const tree = cloneDeep(state.table.defaultTree);

          const positionByIdInTreeForCalculate = clone(
            state.table.defaultPositionByIdInTree,
          );

          const positionByIdInTreeForState = clone(
            state.table.defaultPositionByIdInTree,
          );

          Object.entries(groupedInstrumentsByPath).forEach(
            ([path, instruments]) => {
              const position = positionByIdInTreeForCalculate[path];

              set(tree, `${position}.subRows`, instruments);
              pathsWithInstruments.push(path);
              delete positionByIdInTreeForCalculate[path];

              instruments.forEach((instrument, index) => {
                positionByIdInTreeForState[
                  instrument.path
                ] = `${position}.subRows.${index}`;
              });
            },
          );

          const allPathsSet = getAllPathsSet(
            pathsWithInstruments,
            REGEX_LAST_SEGMENT_IN_PATH,
          );

          Object.entries(positionByIdInTreeForCalculate).forEach(
            ([path, position]) => {
              if (!allPathsSet.has(path)) {
                unset(tree, position);
              }
            },
          );

          state.table.expandedRows = true;
          state.search.tree = tree;

          state.changedCommission = DEFAULT_CHANGED_COMMISSIONS;
          state.table.downloadedPaths = [];
          state.table.positionByIdInTree = positionByIdInTreeForState;

          state.initialCommission = {};
        }
      },
    );
    builder.addMatcher(
      commissionsApi.endpoints.saveCommissions.matchFulfilled,
      (state) => {
        state.changedCommission = DEFAULT_CHANGED_COMMISSIONS;

        state.initialCommission = {};
      },
    );
  },
});

export const selectCommissionsTree = (state: TApplicationState) => {
  if (state.commissions.search.isActive) {
    return state.commissions.search.tree;
  }

  return state.commissions.table.tree;
};

export const selectExpandedRows = (state: TApplicationState) =>
  state.commissions.table.expandedRows;

export const selectFiltersLayer = (state: TApplicationState) =>
  state.commissions.filters.select.layer;

export const selectFiltersAccount = (state: TApplicationState) =>
  state.commissions.filters.select.account;

export const selectFiltersGroup = (state: TApplicationState) =>
  state.commissions.filters.select.group;

export const selectFiltersRelatedGroup = (state: TApplicationState) =>
  state.commissions.filters.relatedGroup;

export const selectFiltersQueryParams = (state: TApplicationState) =>
  state.commissions.filters.queryParams;

export const selectDownloadedPaths = (state: TApplicationState) =>
  state.commissions.table.downloadedPaths;

export const selectSearchIsActive = (state: TApplicationState) =>
  state.commissions.search.isActive;

export const selectDictListRules = (state: TApplicationState) =>
  state.commissions.dict.listRules;

export const selectDictTypesRules = (state: TApplicationState) =>
  state.commissions.dict.typeRules;

export const selectChangedCommissions = (state: TApplicationState) =>
  state.commissions.changedCommission;

export const {
  expandedRowsSet,
  expandedRowsUpdate,
  filtersSetAccount,
  filtersSetGroup,
  filtersSetLayer,
  filtersSetRelatedGroup,
  resetTable,
  updateInstrumentValue,
  updateNodeValue,
} = commissionsSlice.actions;
