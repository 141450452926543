import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Loader } from 'react-ui-kit-exante';

import {
  useGetCoreUserQuery,
  useGetCurrentUserAccessRightsQuery,
  useImportTransactionMutation,
} from '~/api';
import type { TTransactionError } from '~/api/transactions/transactions.api.helpers';
import { TransactionImport } from '~/containers/TransactionImportContainer/TransactionImport';
import { useAttachCSV, useBack } from '~/hooks';
import { TRANSACTIONS_PATH } from '~/routes';
import { UploadMark } from '~/shared/components/UploadMark';
import {
  getInputFileText,
  getRowPropsHelperTransactions,
} from '~/shared/utils/bulkImport';
import {
  TTransactionCSV,
  TTransactionImportColumn,
} from '~/types/transactions';

import { BulkTransactionCheckbox } from './BulkTransactionCheckbox';
import {
  BULK_MANUAL_TRANSACTION,
  COLUMNS_CONFIG,
  defaultValues,
} from './constants';
import { getValidationSchema } from './validationSchema';

export const TransactionImportContainer = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [errorIndexes, setErrorIndexes] = useState<Record<number, boolean>>({});
  const [uploadedSuccessfully, setUploadedSuccessfully] = useState(false);
  const { tableData, columns, handleInputFile } = useAttachCSV<
    TTransactionImportColumn,
    TTransactionCSV
  >(COLUMNS_CONFIG);
  const displayTable = Boolean(columns.length);
  const inputFileText = useMemo(
    () => getInputFileText(displayTable, uploadedSuccessfully, hasErrors),
    [displayTable, hasErrors, uploadedSuccessfully],
  );
  const [importTransaction] = useImportTransactionMutation();
  const { data: { userId } = {} } = useGetCurrentUserAccessRightsQuery();
  const {
    data: currentUser,
    isLoading: isLoadingCurrentUser,
    isFetching: isFetchingCurrentUser,
  } = useGetCoreUserQuery({ id: Number(userId) });
  const navigate = useNavigate();

  useEffect(() => {
    const currentUserHasAllBrandingPermissions =
      currentUser?.info.brandingPermission === 'ALL';

    if (!currentUserHasAllBrandingPermissions) {
      navigate('/', {
        state: { previousPath: window.location.href },
      });
    }
  }, [currentUser?.info.brandingPermission, navigate]);

  const validationSchema = useMemo(() => getValidationSchema(), []);
  const formInstance = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const { reset, getValues } = formInstance;

  const handleUpload = useCallback(async () => {
    const { bulkManualTransaction } = getValues();

    setIsLoading(true);

    const preparedParams = {
      transactions: tableData,
      bulkManualTransaction,
    };

    const response = await importTransaction(preparedParams);

    if ('data' in response && 'description' in response.data) {
      const { description } = response.data as TTransactionError;

      setErrorIndexes(
        Object.keys(description || {}).reduce(
          (acc, key) => ({
            ...acc,
            [Number(key) - 1]: true,
          }),
          {},
        ),
      );

      setIsLoading(false);
      setHasErrors(true);
      setUploadedSuccessfully(false);

      return;
    }

    reset();
    setIsLoading(false);

    setHasErrors(false);
    setUploadedSuccessfully(true);
  }, [getValues, importTransaction, reset, tableData]);

  const additionalActions = useMemo(
    () =>
      uploadedSuccessfully || hasErrors
        ? undefined
        : [
            {
              key: BULK_MANUAL_TRANSACTION,
              component: <BulkTransactionCheckbox />,
            },
            {
              key: 'upload',
              children: <UploadMark>Upload transactions</UploadMark>,
              onClick: handleUpload,
            },
          ],
    [handleUpload, hasErrors, uploadedSuccessfully],
  );

  const getRowProps = useCallback(
    ({ index }: { index: number }) =>
      getRowPropsHelperTransactions(
        errorIndexes,
        hasErrors,
        index,
        uploadedSuccessfully,
      ),
    [errorIndexes, hasErrors, uploadedSuccessfully],
  );

  const wrappedHandleInputFile = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setHasErrors(false);
    setErrorIndexes({});
    setUploadedSuccessfully(false);
    handleInputFile(event);
  };

  const handleCloseImport = useBack({ defaultPath: TRANSACTIONS_PATH });

  if (isLoadingCurrentUser || isFetchingCurrentUser) {
    return <Loader />;
  }

  return (
    <FormProvider {...formInstance}>
      <TransactionImport
        additionalActions={additionalActions}
        onInputFile={wrappedHandleInputFile}
        columns={columns}
        displayTable={displayTable}
        getRowProps={getRowProps}
        inputFileText={inputFileText}
        isLoading={isLoading}
        tableData={tableData}
        onClose={handleCloseImport}
      />
    </FormProvider>
  );
};
