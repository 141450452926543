import { useMemo } from 'react';

import { useAccountsAutocomplete, useUsersAutocomplete } from '~/hooks';
import { symbolsService } from '~/resources';

export function useFetchData() {
  const getAccountsAutocompleteFn = useAccountsAutocomplete();
  const getUsersAutocompleteFn = useUsersAutocomplete();

  return useMemo(
    () => ({
      accountId: getAccountsAutocompleteFn(),
      symbolId: symbolsService.autoCompleteSearchSymbols.bind(null, 0),
      user: getUsersAutocompleteFn(),
    }),
    [getAccountsAutocompleteFn, getUsersAutocompleteFn],
  );
}
