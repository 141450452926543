import { PROXY_CORE_PATH } from '~/constants';
import { TGroupId } from '~/types/interestRates/interestRates';

const BASE_URL = `${PROXY_CORE_PATH}v2.0/rates/interests`;

export const interestRatesEndpoints = {
  base: BASE_URL,
  benchmark: `${BASE_URL}/benchmark`,
  markup: `${BASE_URL}/markup`,
  groups: `${PROXY_CORE_PATH}v2.0/interests/sets`,
  getInterestRatesByAccountEndpoint: (accountId: string) =>
    `${PROXY_CORE_PATH}v2.0/accounts/${accountId}/rates/interests`,
  getInterestRatesByGroupEndpoint: (groupId: TGroupId) =>
    `${PROXY_CORE_PATH}v2.0/interests/sets/${groupId}/rates`,
};
