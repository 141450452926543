import { PanelGroup, Panel, Tooltip } from 'react-ui-kit-exante';

import {
  FormCheckboxContainer,
  FormInputContainer,
  FormMultiSelectContainer,
  FormSelectContainer,
} from '~/containers/form';
import { WarningLite } from '~/images/icons';
import { FixedLoader, PanelHeaderControls } from '~/shared/components';
import { IOption } from '~/types/form';

import { TOOLTIP_TEXT } from '../../constants';
import { DescriptionList } from '../DescriptionList';

import LegalEntityEntryScreenStyles from './LegalEntity.module.css';

interface ILegalEntityEntryScreenProps {
  disabledName: boolean;
  disabledSubmitButton: boolean;
  displayLoader: boolean;
  onCloseHandler(): void;
  onDeleteHandler?: () => void;
  onSubmitHandler(): Promise<void>;
  options: Record<string, Array<IOption>>;
  title: string;
  onRefresh?: () => void;
  isRefreshLoading?: boolean;
}

export const LegalEntity = ({
  disabledName,
  disabledSubmitButton,
  displayLoader,
  onCloseHandler,
  onDeleteHandler,
  onSubmitHandler,
  options,
  title,
  onRefresh,
  isRefreshLoading,
}: ILegalEntityEntryScreenProps) => {
  return (
    <form onSubmit={onSubmitHandler}>
      <Panel
        action={
          <PanelHeaderControls
            disabled={disabledSubmitButton}
            onClose={onCloseHandler}
            onDelete={onDeleteHandler}
            onRefresh={onRefresh}
            isRefreshLoading={isRefreshLoading}
          />
        }
        disableBodyPaddings
        title={title}
      />

      <div className={LegalEntityEntryScreenStyles.Wrapper}>
        <PanelGroup className={LegalEntityEntryScreenStyles.PanelGroup}>
          <Panel
            action={
              <Tooltip
                placement="left"
                title={<DescriptionList list={TOOLTIP_TEXT.GENERAL_INFO} />}
              >
                <span>
                  <WarningLite
                    className={LegalEntityEntryScreenStyles.InfoIcon}
                  />
                </span>
              </Tooltip>
            }
            className={LegalEntityEntryScreenStyles.Panel}
            title="General Info"
          >
            <FormInputContainer
              disabled={disabledName}
              label="Name"
              name="name"
            />

            <FormInputContainer label="Company" name="company" />

            <FormInputContainer label="LEI" name="lei" />

            <FormInputContainer label="Order" name="order" />

            <FormCheckboxContainer label="Is default?" name="isDefault" />

            <FormSelectContainer
              label="Default account type"
              name="defaultAccountType"
              options={options.accountTypes}
            />
          </Panel>

          <Panel
            action={
              <Tooltip
                placement="left"
                sx={{
                  maxWidth: 400,
                  width: 400,
                }}
                title={
                  <DescriptionList
                    list={TOOLTIP_TEXT.AUTOCONVERSION_SETTINGS}
                  />
                }
              >
                <span>
                  <WarningLite
                    className={LegalEntityEntryScreenStyles.InfoIcon}
                  />
                </span>
              </Tooltip>
            }
            className={LegalEntityEntryScreenStyles.Panel}
            title="Autoconversion Settings"
          >
            <FormCheckboxContainer
              label="Autoconversion by default"
              name="autoconversionByDefault"
            />

            <FormSelectContainer
              label="Convert cash into"
              name="convertCashInto"
              options={options.currencies}
            />

            <FormMultiSelectContainer
              label="Force autoconversion currencies"
              name="forceAutoconversionCurrencies"
              options={options.currencies}
            />

            <FormSelectContainer
              label="Force convert cash into"
              name="forceConvertCashInto"
              options={options.currencies}
            />
          </Panel>
        </PanelGroup>

        <PanelGroup className={LegalEntityEntryScreenStyles.PanelGroup}>
          <Panel
            action={
              <Tooltip
                placement="left"
                title={
                  <DescriptionList list={TOOLTIP_TEXT.EXECUTION_SETTINGS} />
                }
              >
                <span>
                  <WarningLite
                    className={LegalEntityEntryScreenStyles.InfoIcon}
                  />
                </span>
              </Tooltip>
            }
            className={LegalEntityEntryScreenStyles.Panel}
            title="Execution Settings"
          >
            <FormCheckboxContainer
              label="Apply execution scheme"
              name="applyExecutionScheme"
            />

            <FormMultiSelectContainer
              label="Available currencies"
              name="availableCurrencies"
              options={options.currencies}
            />
          </Panel>

          <Panel
            action={
              <Tooltip
                placement="left"
                title={
                  <>
                    Default value for all fields in&nbsp;this section filed
                    on&nbsp;this&nbsp;LE account creating. Unless otherwise
                    specified
                  </>
                }
              >
                <span>
                  <WarningLite
                    className={LegalEntityEntryScreenStyles.InfoIcon}
                  />
                </span>
              </Tooltip>
            }
            className={LegalEntityEntryScreenStyles.Panel}
            title="Calculation Settings"
          >
            <FormCheckboxContainer
              label="Calc interests negative flag"
              name="calcInterestsNegativeFlag"
            />

            <FormCheckboxContainer
              label="Calc interests positive flag"
              name="calcInterestsPositiveFlag"
            />

            <FormCheckboxContainer
              label="Calc overnights flag"
              name="calcOvernightsFlag"
            />

            <FormCheckboxContainer
              label="Use excess margin fee"
              name="useExcessMarginFee"
            />

            <FormCheckboxContainer
              label="Use freemoney premium"
              name="useFreemoneyPremium"
            />
          </Panel>

          <Panel
            action={
              <Tooltip
                placement="left"
                title={
                  <DescriptionList list={TOOLTIP_TEXT.REGULATORY_REPORTING} />
                }
              >
                <span>
                  <WarningLite
                    className={LegalEntityEntryScreenStyles.InfoIcon}
                  />
                </span>
              </Tooltip>
            }
            className={LegalEntityEntryScreenStyles.Panel}
            title="Regulatory Reporting"
          >
            <FormCheckboxContainer label="Under EMIR" name="underEMIR" />
          </Panel>
        </PanelGroup>

        {displayLoader ? <FixedLoader /> : null}
      </div>
    </form>
  );
};
