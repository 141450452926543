import { useController, useFormContext } from 'react-hook-form';
import { Checkbox } from 'react-ui-kit-exante';

import {
  BULK_MANUAL_TRANSACTION,
  BULK_MANUAL_TRANSACTION_LABEL,
} from './constants';

export const BulkTransactionCheckbox = () => {
  const { control } = useFormContext();
  const {
    field: { onBlur, onChange, value },
  } = useController({
    control,
    name: BULK_MANUAL_TRANSACTION,
  });

  return (
    <Checkbox
      checked={value ?? false}
      label={BULK_MANUAL_TRANSACTION_LABEL}
      name={BULK_MANUAL_TRANSACTION}
      onBlur={onBlur}
      onChange={onChange}
    />
  );
};
