import { useCallback } from 'react';

import { useLazySearchByUsersQuery } from '~/api';

const defaultAutocompleteState = {
  total: 0,
  fetchData: null,
  options: [],
  canceled: true,
};

export const useUsersAutocomplete = (archived = false) => {
  const [searchByUsers] = useLazySearchByUsersQuery();

  const getUsersAutocompleteFn = useCallback(
    (skip = 0) => {
      return async (search: string) => {
        const response = await searchByUsers({
          archived,
          search,
          skip,
        });

        if ('error' in response || !response.data) {
          return defaultAutocompleteState;
        }

        return {
          ...response.data,
          fetchData: getUsersAutocompleteFn(response?.data?.nextSkip || 0),
        };
      };
    },
    [archived, searchByUsers],
  );

  return getUsersAutocompleteFn;
};
