import { object, string, mixed, array, lazy } from 'yup';

import { DEFAULT_USER_TYPE } from '~/store/types/constants';

export const newUserSchema = (haveAllBranding: boolean) =>
  object({
    username: string().required('Username is a required field'),
    password: string()
      .required('Password is a required field')
      .min(10, 'Password must contain at least 10 characters')
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).+$/gm,
        'Password must contain at least one capital letter, one lowercase letter and one number',
      ),
    passwordConfirmation: string()
      .required('Re-password is a required field')
      .test(
        'passwords-match',
        'Passwords do not match',
        (value, { parent: { password } }) => {
          return value === password;
        },
      ),
    userType: mixed().oneOf(DEFAULT_USER_TYPE.values),
    branding: haveAllBranding
      ? string()
      : string().required('Branding is a required field'),
    brandingPermission: lazy((value) => {
      if (haveAllBranding) {
        return typeof value === 'string' ? string() : array();
      }
      return typeof value === 'string'
        ? string().required('Branding permission is a required field')
        : array().min(1, 'Branding permission is a required field');
    }),
  });
