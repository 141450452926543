import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  AutocompleteAsync,
  Select,
  useData,
  Skeleton,
  Notification,
} from 'react-ui-kit-exante';

import {
  useGetSymbolPermissionsGroupsQuery,
  useLazySearchByAccountsQuery,
  useLazySearchByUsersQuery,
} from '~/api';
import {
  useLazyGetAccountPermissionsByAccountIdQuery,
  useLazyGetAccountPermissionsByUserIdQuery,
} from '~/api/accountPermissions';
import { transformSymbolPermissionsGroupsToOptions } from '~/api/symbolPermissions/helpers';
import { EMPTY_ARRAY } from '~/constants';
import {
  useAccountsAutocomplete,
  usePrevious,
  useUsersAutocomplete,
} from '~/hooks';
import { FiltersWrapper } from '~/shared/components';
import { Switch } from '~/shared/components/Switch';
import { FilterLayers } from '~/types/symbolPermissions';

import { SymbolPermissionsContext } from '../../constants';
import { useAccountGroup, useUserGroup, useFilters } from '../../hooks';
import { TFilterChangeHandle } from '../../types';

import FiltersStyle from './Filters.module.css';

interface IFiltersProps {
  layer: FilterLayers;
  reloadDataOnFilterChange: TFilterChangeHandle;
  resetDataOnFilterChange: TFilterChangeHandle;
  setBlockTableMessage: Dispatch<SetStateAction<string>>;
  resetTable: () => void;
}

const selectorMinWidth = 280;

export const FiltersContainer = ({
  layer,
  reloadDataOnFilterChange,
  resetDataOnFilterChange,
  setBlockTableMessage,
  resetTable,
}: IFiltersProps) => {
  const [state] = useContext(SymbolPermissionsContext);
  const [getAccounts] = useLazySearchByAccountsQuery();
  const [getUsers] = useLazySearchByUsersQuery();
  const getAccountsAutocompleteFn = useAccountsAutocomplete();
  const getUsersAutocompleteFn = useUsersAutocomplete();
  const [getAccountPermissionsByUserId] =
    useLazyGetAccountPermissionsByUserIdQuery();
  const [getAccountPermissionsByAccountId] =
    useLazyGetAccountPermissionsByAccountIdQuery();

  const {
    filters: {
      select: { account, user, group },
    },
  } = state;

  const { fetchSearchAccounts, fetchSearchUsers } = useMemo(
    () => ({
      fetchSearchAccounts: getAccountsAutocompleteFn(),
      fetchSearchUsers: getUsersAutocompleteFn(),
    }),
    [getAccountsAutocompleteFn, getUsersAutocompleteFn],
  );

  const prevUser = usePrevious(user);
  const prevAccount = usePrevious(account);

  const {
    effectiveSubLayer,
    needReloadData,
    onChangeAccountAutocompleteHandler,
    onChangeAccountSelectHandler,
    onChangeUserAutocompleteHandler,
    onChangeUserSelectHandler,
    onChangeGroupHandler,
  } = useFilters({
    resetDataOnFilterChange,
    setBlockTableMessage,
  });

  const { onUserSelect, loadingUserSet } = useUserGroup(
    resetDataOnFilterChange,
  );
  const { onAccountSelect, loadingAccountSet } = useAccountGroup(
    resetDataOnFilterChange,
  );

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (account) {
      return;
    }

    const initialAccount = searchParams.get('account');

    if (initialAccount && layer === FilterLayers.Accounts) {
      requestAnimationFrame(() => {
        onChangeAccountSelectHandler(initialAccount);
        onAccountSelect(initialAccount);
        reloadDataOnFilterChange();
      });
    }
  }, [
    account,
    layer,
    onAccountSelect,
    onChangeAccountSelectHandler,
    reloadDataOnFilterChange,
    searchParams,
  ]);

  useEffect(() => {
    if (user) {
      return;
    }
    const initialUser = searchParams.get('user');

    if (initialUser && layer === FilterLayers.Users) {
      requestAnimationFrame(() => {
        onChangeUserSelectHandler(initialUser);
        onUserSelect(initialUser);
        reloadDataOnFilterChange();
      });
    }
  }, [
    layer,
    onAccountSelect,
    onChangeUserSelectHandler,
    searchParams,
    onUserSelect,
    reloadDataOnFilterChange,
    needReloadData,
    user,
  ]);

  const setDefaultUser = useCallback(async () => {
    const response = await getUsers({
      search: '',
      skip: 0,
      limit: 1,
      archived: false,
    });

    if ('error' in response) {
      return;
    }

    if (response.data) {
      const [defaultUser] = response.data.options;
      if (defaultUser) {
        onChangeUserSelectHandler(String(defaultUser.value));

        setSearchParams((params) => {
          params.set('user', defaultUser.value);
          params.delete('account');

          return params;
        });
      }
    }
  }, [getUsers, setSearchParams, onChangeUserSelectHandler]);

  const setDefaultAccount = useCallback(async () => {
    const response = await getAccounts({
      search: '',
      skip: 0,
      limit: 1,
      archived: false,
    });

    if ('error' in response) {
      return;
    }

    if (response.data) {
      const [defaultAccount] = response.data.options;
      if (defaultAccount) {
        onChangeAccountSelectHandler(String(defaultAccount.value));

        setSearchParams((params) => {
          params.set('account', defaultAccount.value);
          params.delete('user');

          return params;
        });
      }
    }
  }, [getAccounts, onChangeAccountSelectHandler, setSearchParams]);

  useEffect(() => {
    const initialUser = searchParams.get('user');

    if (!initialUser && [FilterLayers.Users].includes(layer)) {
      setDefaultUser();
    }
  }, [layer]);

  useEffect(() => {
    const initialAccount = searchParams.get('account');

    if (!initialAccount && [FilterLayers.Accounts].includes(layer)) {
      setDefaultAccount();
    }
  }, [layer]);

  const { data: permissionGroupOptions } = useGetSymbolPermissionsGroupsQuery(
    undefined,
    {
      selectFromResult: transformSymbolPermissionsGroupsToOptions,
    },
  );

  const preparedGroupOptions = useMemo(
    () => [
      { label: 'Default', value: 'default' },
      ...permissionGroupOptions.slice(1),
    ],
    [permissionGroupOptions],
  );

  const getAccountsByUsername = useCallback(async () => {
    if (!user) {
      return EMPTY_ARRAY;
    }

    const result = await getAccountPermissionsByUserId({
      userId: user,
    });

    if ('error' in result) {
      Notification.error({ title: String(result.error) });
      return EMPTY_ARRAY;
    }
    return (result.data || []).map(({ accountId }) => ({
      value: accountId,
      label: accountId,
    }));
  }, [getAccountPermissionsByUserId, user]);

  const { data: accountOptions, fetchData: fetchAccounts } = useData({
    onFetch: getAccountsByUsername,
  });

  const getUsersByAccountId = useCallback(async () => {
    if (!account) {
      return EMPTY_ARRAY;
    }

    const result = await getAccountPermissionsByAccountId({
      accountId: account,
    });

    if ('error' in result) {
      return EMPTY_ARRAY;
    }

    return (result.data || EMPTY_ARRAY).map(({ userId }) => ({
      value: userId,
      label: userId,
    }));
  }, [account, getAccountPermissionsByAccountId]);

  const { data: userOptions, fetchData: fetchUsers } = useData({
    onFetch: getUsersByAccountId,
  });

  const permissionGroupOptionsWithoutDefault = useMemo(
    () => permissionGroupOptions?.slice(1),
    [permissionGroupOptions],
  );

  const displayGroupSelect =
    layer === FilterLayers.Groups ||
    (layer === FilterLayers.Accounts && account) ||
    (layer === FilterLayers.Users && user);

  const displayAccountSelect =
    layer === FilterLayers.Effective &&
    effectiveSubLayer === FilterLayers.Users;
  const displayUserSelect =
    layer === FilterLayers.Effective &&
    effectiveSubLayer === FilterLayers.Accounts;

  const displayAccountAutocomplete =
    layer === FilterLayers.Accounts ||
    (layer === FilterLayers.Effective && !displayAccountSelect);

  const displayUserAutocomplete =
    layer === FilterLayers.Users ||
    (layer === FilterLayers.Effective && !displayUserSelect);

  const setDefaultGroup = useCallback(() => {
    if (
      !group &&
      permissionGroupOptionsWithoutDefault &&
      permissionGroupOptionsWithoutDefault.length > 0
    ) {
      const [defaultGroup] = permissionGroupOptionsWithoutDefault;

      if (layer === FilterLayers.Groups) {
        onChangeGroupHandler(Number(defaultGroup.value));
      }
    }
  }, [
    group,
    permissionGroupOptionsWithoutDefault,
    layer,
    onChangeGroupHandler,
  ]);

  useEffect(() => {
    if (user && prevUser !== user && effectiveSubLayer === FilterLayers.Users) {
      fetchAccounts();
    }
  }, [effectiveSubLayer, prevUser, user, fetchAccounts]);

  useEffect(() => {
    if (!group) {
      setDefaultGroup();
    }
  }, [group, setDefaultGroup]);

  useEffect(() => {
    if (
      account &&
      prevAccount !== account &&
      effectiveSubLayer === FilterLayers.Accounts
    ) {
      fetchUsers();
    }
  }, [effectiveSubLayer, prevAccount, account, fetchUsers]);

  useEffect(() => {
    if (
      (layer === FilterLayers.Users && !user) ||
      (layer === FilterLayers.Accounts && !account)
    ) {
      resetDataOnFilterChange();
    }
  }, [account, layer, resetDataOnFilterChange, user]);

  if (!permissionGroupOptions) {
    return null;
  }

  return (
    <div className={FiltersStyle.Wrapper}>
      <FiltersWrapper>
        {displayAccountAutocomplete && (
          <AutocompleteAsync
            fetchData={fetchSearchAccounts}
            onChange={(_, option) => {
              const { value = '' } = option || {};
              onChangeAccountAutocompleteHandler(value);
              onAccountSelect(value);

              if (needReloadData.current) {
                reloadDataOnFilterChange();
                needReloadData.current = false;
              }

              setSearchParams((params) => {
                if (value) {
                  params.set('account', value);
                } else {
                  params.delete('account');
                }

                return params;
              });
            }}
            options={EMPTY_ARRAY}
            placeholder="Account"
            value={account}
            sx={{
              minWidth: selectorMinWidth,
            }}
          />
        )}

        {displayAccountSelect && (
          <Select
            fullWidth
            onChange={({ target: { value = '' } }) => {
              onChangeAccountSelectHandler(value);
              onAccountSelect(value);

              if (needReloadData.current) {
                reloadDataOnFilterChange();
                needReloadData.current = false;
              }
            }}
            options={accountOptions || EMPTY_ARRAY}
            label="Account"
            value={account ? `${account}` : ''}
            disabled={accountOptions?.length === 0}
            sx={{
              minWidth: selectorMinWidth,
            }}
          />
        )}

        {displayUserAutocomplete && (
          <AutocompleteAsync
            fetchData={fetchSearchUsers}
            onChange={(_, option) => {
              const { value = '' } = option || {};

              onChangeUserAutocompleteHandler(value);
              onUserSelect(value);

              if (needReloadData.current) {
                reloadDataOnFilterChange();
                needReloadData.current = false;
              }

              setSearchParams((params) => {
                if (value) {
                  params.set('user', value);
                } else {
                  params.delete('user');
                }

                return params;
              });
            }}
            options={EMPTY_ARRAY}
            placeholder="User"
            value={user}
            sx={{
              minWidth: selectorMinWidth,
            }}
          />
        )}

        {displayUserSelect && (
          <Select
            fullWidth
            onChange={({ target: { value = '' } }) => {
              onChangeUserSelectHandler(value);
              onUserSelect(value);

              if (needReloadData.current) {
                reloadDataOnFilterChange();
                needReloadData.current = false;
              }
            }}
            options={userOptions || EMPTY_ARRAY}
            label="User"
            value={user ? `${user}` : ''}
            disabled={userOptions?.length === 0}
            sx={{
              minWidth: selectorMinWidth,
            }}
          />
        )}

        <Switch condition={Boolean(displayGroupSelect)}>
          <Switch
            condition={[
              loadingAccountSet || loadingUserSet,
              !loadingAccountSet && !loadingUserSet,
            ]}
          >
            <Skeleton
              width={280}
              height={47}
              classes={{ root: FiltersStyle.Skeleton }}
            />
            <Select
              fullWidth
              onChange={({ target: { value = '' } }) => {
                onChangeGroupHandler(
                  value === 'default' ? null : Number(value),
                );

                resetTable();
              }}
              options={
                layer === FilterLayers.Groups
                  ? permissionGroupOptionsWithoutDefault || EMPTY_ARRAY
                  : preparedGroupOptions
              }
              label="Group"
              value={group ? `${group}` : 'default'}
            />
          </Switch>
        </Switch>
      </FiltersWrapper>
    </div>
  );
};
