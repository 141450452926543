import { styled, TDefaultTheme } from 'react-ui-kit-exante';

export const StyledContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const StyledTitle = styled('h2')(
  ({ theme }: { theme: TDefaultTheme }) => ({
    fontWeight: 400,
    fontSize: '32px',
    lineHeight: '48px',
    color: theme?.color.typo.primary,
    overflow: 'hidden',
    maxWidth: '960px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',

    '@media (min-width: 1440px)': {
      maxWidth: '1136px',
    },
    '@media (min-width: 1920px)': {
      maxWidth: '1544px',
    },
  }),
);

export const StyledButtonsWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  minWidth: '210px',
}));

export const StyledCloseSaveWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  columnGap: '24px',
  marginLeft: '64px',
}));
