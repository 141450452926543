import { yupResolver } from '@hookform/resolvers/yup';
import { useContext, useEffect, useMemo, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { useGetCoreUserQuery, useGetCurrentUserAccessRightsQuery } from '~/api';
import {
  useGetClientTypesQuery,
  useGetIncorporationTypesQuery,
  useGetLegalEntityTypesQuery,
  useGetUserClientsPermissionsTypesQuery,
} from '~/api/types/types.api';
import { EMPTY_ARRAY, EMPTY_OPTION_NULLABLE } from '~/constants';
import { useBrandingList } from '~/hooks';
import { clientPageContext } from '~/pages/ClientPage/context/ClientPageContext';
import { FixedLoader, SaveButton, TabContentHeader } from '~/shared/components';
import {
  createFormSubmitHandler,
  transformVariantsToSelectOptions,
} from '~/shared/utils';
import { IClientUser } from '~/types/clients';
import { IOption, TFormHandlerNew } from '~/types/form';

import { ClientUsers } from '../ClientUsers';
import { Info } from '../Info';
import { InfoFormTemplate } from '../InfoFormTemplate';
import { IDataAvailabilityState, TFormData } from '../types';
import { getValidationSchema } from '../validationSchema';

import { createHandlersMap } from './helpers';

interface IClientInfoFormProps extends TFormData {
  dataAvailabilityState: IDataAvailabilityState;
  clientProviders: IOption[];
}

export const InfoFormContainer = ({
  dataAvailabilityState,
  clientProviders,
  ...formData
}: IClientInfoFormProps) => {
  const dispatch = useDispatch();
  const { setDirtyTabs } = useContext(clientPageContext);
  const { client, clientUsers, clientAccounts } = formData;
  const { data: clientTypes } = useGetClientTypesQuery();
  const clientTypesOptions = transformVariantsToSelectOptions(
    clientTypes?.values,
    { shouldSortAlphabetically: true },
  );

  const { data: incorporationTypes } = useGetIncorporationTypesQuery();
  const incorporationTypesOptions = transformVariantsToSelectOptions(
    incorporationTypes?.values,
    { capitalized: true, shouldSortAlphabetically: true },
  );

  const { data: legalEntityTypes } = useGetLegalEntityTypesQuery();
  const legalEntityOptions = transformVariantsToSelectOptions(
    legalEntityTypes?.values,
    { capitalized: true, shouldSortAlphabetically: true },
  );
  const { data: clientUserPermissionTypes } =
    useGetUserClientsPermissionsTypesQuery();
  const clientUserPermissionsOptions = transformVariantsToSelectOptions(
    clientUserPermissionTypes?.values,
  );

  const { data: { userId } = {} } = useGetCurrentUserAccessRightsQuery();
  const { data: currentUser } = useGetCoreUserQuery({ id: Number(userId) });
  const currentUserHasAllBrandingPermissions =
    currentUser?.info.brandingPermission === 'ALL';

  const { brandingList } = useBrandingList();

  const brandingListOptions = useMemo(
    () =>
      brandingList?.length
        ? [EMPTY_OPTION_NULLABLE, ...brandingList]
        : EMPTY_ARRAY,
    [brandingList],
  );

  const options = useMemo(
    () => ({
      clientTypes: clientTypesOptions,
      incorporationTypes: incorporationTypesOptions,
      legalEntities: legalEntityOptions,
      clientAccounts,
      clientProviders,
      clientUserPermissionsOptions,
      brandingListOptions,
    }),
    [
      clientTypesOptions,
      incorporationTypesOptions,
      legalEntityOptions,
      clientAccounts,
      clientProviders,
      clientUserPermissionsOptions,
      brandingListOptions,
    ],
  );

  const validationSchema = useMemo(
    () =>
      getValidationSchema({
        clientProviders,
        clientTypes: clientTypesOptions || [],
        incorporationTypes: incorporationTypesOptions || [],
      }),
    [clientProviders, clientTypesOptions, incorporationTypesOptions],
  );
  const formInstance = useForm({
    defaultValues: formData,
    resolver: yupResolver(validationSchema),
  });

  const {
    handleSubmit,
    reset,
    getValues,
    formState: { dirtyFields, isDirty, isSubmitting },
  } = formInstance;

  // formState should be in object for useEffect https://react-hook-form.com/docs/useform/formstate
  const wrapperFormState = { formState: formInstance.formState };

  const previousClientUsers = useRef<IClientUser[] | undefined>(clientUsers);

  const handlersMap = useMemo<TFormHandlerNew<TFormData> | null>(
    () =>
      createHandlersMap({
        dispatch,
        client,
        clientAccounts,
        previousClientUsers,
      }),
    [clientAccounts, client, dispatch],
  );

  const handleFormSubmit = useMemo(
    () =>
      createFormSubmitHandler<TFormData>({
        dirtyFields,
        handlersMap,
        getValues,
        reset,
      }),
    [dirtyFields, handlersMap, getValues, reset],
  );

  useEffect(() => {
    setDirtyTabs('info', wrapperFormState.formState.isDirty);
  }, [setDirtyTabs, wrapperFormState.formState.isDirty]);

  if (!client) {
    return <div>No client</div>;
  }

  return (
    <FormProvider {...formInstance}>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        {isSubmitting && <FixedLoader />}

        <InfoFormTemplate
          clientUsers={
            <ClientUsers
              dataAvailabilityState={dataAvailabilityState}
              options={options}
            />
          }
          dataAvailabilityState={dataAvailabilityState}
          info={
            <Info
              currentUserHasAllBrandingPermissions={
                currentUserHasAllBrandingPermissions
              }
              options={options}
            />
          }
          header={
            <TabContentHeader
              title="Client info"
              actions={
                <SaveButton type="submit" disabled={isSubmitting || !isDirty} />
              }
            />
          }
        />
      </form>
    </FormProvider>
  );
};
