import cn from 'classnames';
import { ChangeEvent, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { RadioGroup } from 'react-ui-kit-exante';

import { useGetCurrentUserAccessRightsQuery } from '~/api';
import { EMPTY_ARRAY } from '~/constants';
import { FormButtonSelectContainer } from '~/containers/form/FormButtonSelectContainer';
import { FormItem } from '~/shared/components';
import { IOption } from '~/types/form';

import AvailableCurrenciesFormStyles from './AvailableCurrenciesFormStyles.module.css';
import {
  AVAILABLE_CURRENCIES_FORM_NAME,
  CurrenciesRadio,
  radioOptions,
} from './constants';
import { getRadioValue } from './helpers';

type AvailableCurrenciesProps = {
  currenciesSelectOptions: IOption[];
};

export const AvailableCurrenciesForm = ({
  currenciesSelectOptions,
}: AvailableCurrenciesProps) => {
  const { control } = useFormContext();
  const {
    field: { onChange, value },
    fieldState: { isDirty },
  } = useController({
    control,
    name: AVAILABLE_CURRENCIES_FORM_NAME,
  });

  const { data: currentUserPermissions } = useGetCurrentUserAccessRightsQuery();
  const hasWriteSettingsAccess =
    !!currentUserPermissions?.write['Account info'];

  const [radioValue, setRadioValue] = useState<CurrenciesRadio | ''>(
    getRadioValue(value, currenciesSelectOptions),
  );

  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value as CurrenciesRadio;

    switch (newValue) {
      case CurrenciesRadio.All: {
        onChange(
          currenciesSelectOptions.map(
            ({ value: currencyValue }) => currencyValue,
          ),
        );
        break;
      }

      case CurrenciesRadio.None: {
        onChange(EMPTY_ARRAY);
        break;
      }

      case CurrenciesRadio.Inherited: {
        onChange(null);
        break;
      }

      default: {
        break;
      }
    }

    setRadioValue(newValue);
  };

  if (!currenciesSelectOptions.length) {
    return <div>No currencies for selection</div>;
  }

  return (
    <FormItem
      className={cn({
        Disabled: !hasWriteSettingsAccess,
      })}
    >
      <RadioGroup
        radioOptions={radioOptions}
        handleChange={handleRadioChange}
        value={radioValue}
        warning={isDirty}
      />
      {radioValue === CurrenciesRadio.Manual && (
        <FormButtonSelectContainer
          label="Select currencies"
          options={currenciesSelectOptions}
          name="account.availableCurrencies"
          withIcon
        />
      )}
      <div className={AvailableCurrenciesFormStyles.Total}>
        Total: {value?.length || 0}
      </div>
    </FormItem>
  );
};
