import { useMemo } from 'react';
import { UseFormGetValues } from 'react-hook-form';

import {
  useGetCoreAccountQuery,
  useGetCoreUserQuery,
  useGetCurrentUserAccessRightsQuery,
} from '~/api';
import { useClient, usePickUserPermissions } from '~/hooks';

import { GeneralForm } from '../GeneralForm';
import { TFormData } from '../types';

import { TYPES_THAT_CANNOT_CHANGE_PURPOSE } from './constants';
import { useOptions, useRejectedRequestFor } from './hooks';

interface IGeneralSettingsProps {
  accountId: string;
  getValues: UseFormGetValues<TFormData>;
}

export const GeneralSettings = ({
  accountId,
  getValues,
}: IGeneralSettingsProps) => {
  const userPermissions = usePickUserPermissions([
    'Account info',
    'Account info: cash conversion settings',
    'Account info: interests/overnights settings',
    'Account info: internal settings',
    'Account info: routing settings',
  ]);

  const { data: account } = useGetCoreAccountQuery(
    { accountId },
    { skip: !accountId },
  );

  const { data: { userId } = {} } = useGetCurrentUserAccessRightsQuery();
  const { data: currentUser } = useGetCoreUserQuery({ id: Number(userId) });
  const currentUserHasAllBrandingPermissions =
    currentUser?.info.brandingPermission === 'ALL';

  const disabledPurpose = useMemo(
    () =>
      TYPES_THAT_CANNOT_CHANGE_PURPOSE.includes(account?.type || '') ||
      !currentUserHasAllBrandingPermissions,
    [account, currentUserHasAllBrandingPermissions],
  );

  const { client } = useClient(account?.clientId);
  const counterpartyId = client?.counterpartyId;
  const clientName = client?.name;

  const rejectedRequestFor = useRejectedRequestFor(accountId);

  const options = useOptions(counterpartyId);

  return (
    <GeneralForm
      clientName={clientName}
      disabledCounterpartyAccountId={!counterpartyId}
      disabledPurpose={disabledPurpose}
      getValues={getValues}
      options={options}
      rejectedRequestFor={rejectedRequestFor}
      userPermissions={userPermissions}
    />
  );
};
