import { IAccountPermissionByUsername } from '~/types/accountPermissions';
import { IAtpPermissionsResponse } from '~/types/accountPermissions/atpPermissions';

export function getBackInjectedPermissions(
  permissions: IAccountPermissionByUsername[],
  createResponse: IAccountPermissionByUsername[] | null,
) {
  if (!createResponse) {
    return permissions;
  }

  return permissions.reduce<IAccountPermissionByUsername[]>((acc, curr) => {
    const repeatedItem = createResponse.find(
      (item) => item.accountId === curr.accountId,
    );

    if (repeatedItem) {
      return [...acc, repeatedItem];
    }

    return [...acc, curr];
  }, []);
}

export function atpPermissionsMapper({
  superuser,
  ...rest
}: IAtpPermissionsResponse) {
  return {
    defaultAccountAccess: superuser || null,
    atpPermissions: rest,
  };
}
