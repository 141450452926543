import { getBaseWorkflowUrl, PROXY_CORE_PATH } from '~/constants';

const baseWorkflowUrl = getBaseWorkflowUrl();

export const FETCH_TRANSACTIONS = '/api/transactions/';

export function getPostTransactionEndpoint(
  accountId: string,
  shouldSendThroughWorkflowApi?: boolean,
) {
  if (shouldSendThroughWorkflowApi) {
    return `https://${baseWorkflowUrl}/api/v2/run-workflow/manual_transaction`;
  }

  return `${PROXY_CORE_PATH}v2.1/accounts/${accountId}/transaction`;
}

export function getImportTransactionEndpoint(bulkManualTransaction: boolean) {
  if (bulkManualTransaction) {
    return `https://${baseWorkflowUrl}/api/v2/run-workflow/bulk_manual_transaction`;
  }

  return `${PROXY_CORE_PATH}v2.1/transactions`;
}

export function getUpdateTransactionEndpoint(uuid: string) {
  return `${PROXY_CORE_PATH}v2.1/transactions/${uuid}`;
}

export function getRollbackTransactionURL(id: number) {
  return `${PROXY_CORE_PATH}v2.1/transactions/rollback/${id}`;
}

export const TRANSACTIONS_ENDPOINT = `${PROXY_CORE_PATH}v2.1/transactions`;
