import { sendUiKitErrorNotification } from '~/shared/utils';
import { check403Error, AuditLogsParamsError } from '~/shared/utils/errors';
import { IDataWithPagination } from '~/types/api';

import { AuditLogRepository } from './auditLog.repository';
import {
  checkParams,
  prepareParamsToRequest,
  transformAuditLogErrorToValidError,
} from './helpers';
import { IAuditLog, TResolveAuditLogsParams } from './types';

export class AuditLogService {
  public async resolveAuditLogs(
    params: TResolveAuditLogsParams,
  ): Promise<IDataWithPagination<IAuditLog[]>> {
    try {
      const invalidParams = checkParams(params);

      if (invalidParams.length) {
        throw new AuditLogsParamsError(invalidParams);
      }

      const preparedParams = prepareParamsToRequest(params);
      const { data } = await AuditLogRepository.fetchAuditLogs(preparedParams);

      return {
        data: data.logs,
        pagination: {
          total: data.total,
        },
      };
    } catch (error) {
      const is403Error = check403Error(error);

      if (!is403Error) {
        sendUiKitErrorNotification(transformAuditLogErrorToValidError(error));
      }

      return { data: [], pagination: { total: 0 } };
    }
  }

  public async resolveAuditLogsForExport(
    params: TResolveAuditLogsParams,
  ): Promise<IAuditLog[]> {
    try {
      const invalidParams = checkParams(params);

      if (invalidParams.length) {
        throw new AuditLogsParamsError(invalidParams);
      }

      const preparedParams = prepareParamsToRequest(params);
      const { data } = await AuditLogRepository.fetchAuditLogs(preparedParams);

      return data.logs;
    } catch (error) {
      const is403Error = check403Error(error);

      if (!is403Error) {
        sendUiKitErrorNotification(transformAuditLogErrorToValidError(error));
      }

      return [];
    }
  }
}
