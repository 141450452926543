import cn from 'classnames';
import { useCallback, useRef } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { IconButton, Modal, useModal } from 'react-ui-kit-exante';

import { CONFIRM_DELETE_MESSAGE } from '~/constants';
import {
  FormCheckboxContainer,
  FormSelectAsyncContainer,
  FormSelectContainer,
} from '~/containers/form';
import { useAccountsAutocomplete, usePickUserPermissions } from '~/hooks';
import { IOption } from '~/types/form';

import PermissionStyles from './Permissions.module.css';
import { isDropDownDisabled } from './helpers';

const DEFAULT_PERMISSION = {
  accountId: null,
  overrideAccountStatus: false,
  status: 'blocked',
};

interface IPermissionsProps {
  accountStatusesOptions: IOption[];
  initialPermissions: string[];
}

export const Permissions = ({
  accountStatusesOptions,
  initialPermissions,
}: IPermissionsProps) => {
  const confirmModal = useModal();
  const confirmIndex = useRef<number | null>(null);
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'permissions',
  });
  // fields don't update after submit.
  const watchedPermissions = useWatch({
    control,
    name: 'permissions',
  });

  const userPermissions = usePickUserPermissions(['User account status']);

  const getAccountsAutocompleteFn = useAccountsAutocomplete();

  const handleAddRow = useCallback(() => append(DEFAULT_PERMISSION), [append]);
  const handleMinusClick = (index: number) => () => {
    confirmIndex.current = index;
    confirmModal.onOpen();
  };

  const handleRemoveRow = () => {
    if (confirmIndex.current === null) {
      return;
    }

    remove(confirmIndex.current);
    confirmModal.onClose();
  };

  return (
    <div
      className={cn(PermissionStyles.Container, {
        Disabled: !userPermissions['User account status'].write,
      })}
    >
      {fields.map((field, index) => {
        return (
          <div key={field.id} className={PermissionStyles.Item}>
            <FormSelectAsyncContainer
              label="Account id"
              name={`permissions.${index}.accountId`}
              fetchData={getAccountsAutocompleteFn()}
              disabled={isDropDownDisabled(
                initialPermissions,
                watchedPermissions,
                index,
              )}
            />
            <FormSelectContainer
              label="Account status"
              name={`permissions.${index}.status`}
              options={accountStatusesOptions}
            />
            <FormCheckboxContainer
              label="Override"
              name={`permissions.${index}.overrideAccountStatus`}
            />

            <div className={PermissionStyles.MinusWrapper}>
              <IconButton
                iconName="DeleteIcon"
                iconColor="radical"
                iconSize={24}
                onClick={handleMinusClick(index)}
              />
            </div>
          </div>
        );
      })}

      <div className={PermissionStyles.AddWrapper}>
        <IconButton
          iconColor="action"
          iconName="AddIcon"
          onClick={handleAddRow}
        />
      </div>

      <Modal
        keepMounted={false}
        isOpened={confirmModal.isOpened}
        onClose={confirmModal.onClose}
        title="Are you sure?"
        confirmButton={{
          handleConfirm: handleRemoveRow,
        }}
      >
        <div>{CONFIRM_DELETE_MESSAGE}</div>
      </Modal>
    </div>
  );
};
