import { DEFAULT_LOCALE } from '~/constants';

export * from './currencyFormatter';
export * from './flattenSelectedValues';
export * from './formatCurrency';
export * from './formatErrorForKitNotification';
export * from './formatNumber';
export * from './formatPercentage';
export * from './formatDate';
export { formatNumberByLocale } from './formatNumberByLocale';

export const defaultLocaleFormatter = new Intl.NumberFormat(DEFAULT_LOCALE);
