import { isString, omit } from 'lodash';
import { Sorting } from 'react-ui-kit-exante';

const ACCOUNT_IDS_SEPARATOR = '|';

/**
 * Define whether the value is accountId or clientId
 *
 * @param value string. Must be in the format XXX.XXX if it's accountId or XXX if it's clientId
 * @return boolean
 */

const isAccountId = (value: string) => {
  return /.?\..?/.test(value);
};

const prepareAccountIds = (accountIdSearchInput: string) => {
  return accountIdSearchInput
    .split(ACCOUNT_IDS_SEPARATOR)
    .map((id: string) => (isAccountId(id) ? id : `${id}*`))
    .join(ACCOUNT_IDS_SEPARATOR);
};

export const prepareParamsWithAccountId = (
  params: Record<string, string | string[] | number | Sorting | unknown>,
  newFieldName = 'accountId_regexp',
) => {
  const { accountId } = params || {};

  if (accountId && isString(accountId)) {
    const accountIdRegexp = prepareAccountIds(accountId);

    return {
      ...omit(params, 'accountId'),
      [newFieldName]: accountIdRegexp,
    };
  }
  return params;
};
