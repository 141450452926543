import { useCallback, useRef, useMemo } from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { IconButton, Modal, useModal } from 'react-ui-kit-exante';

import { CONFIRM_DELETE_MESSAGE } from '~/constants';
import { IFormArrayItemStructure } from '~/types/form';

import { FormInputContainer } from '../FormInputContainer';
import { FormMultiSelectContainer } from '../FormMultiSelectContainer';
import { FormSelectAsyncContainer } from '../FormSelectAsyncContainer';
import { FormSelectContainer } from '../FormSelectContainer';

import FormArrayContainerModuleStyles from './FormArrayContainer.module.css';
import { FormArrayDeleteButton } from './components';
import { getEmptyRow } from './helpers/getEmptyRow';

interface IFormArrayContainerProps {
  name: string;
  structure: Array<IFormArrayItemStructure>;
  title?: string;
}

export const FormArrayContainer = ({
  name,
  structure,
  title,
}: IFormArrayContainerProps) => {
  const confirmModal = useModal();
  const indexForDelete = useRef<number | null>(null);
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });
  const EMPTY_ROW = getEmptyRow(structure);

  const fieldsLength = useMemo(() => fields?.length, [fields]);
  const columnsLength = useMemo(() => structure.length, [structure]);

  const openModal = (index: number) => {
    confirmModal.onOpen();
    indexForDelete.current = index;
  };

  const closeModal = () => {
    confirmModal.onClose();
    indexForDelete.current = null;
  };

  const addKeyValue = useCallback(() => {
    append(EMPTY_ROW);
  }, [append, EMPTY_ROW]);

  const removeKeyValue = useCallback(() => {
    if (typeof indexForDelete.current === 'number') {
      remove(indexForDelete.current);
    }

    indexForDelete.current = null;

    confirmModal.onClose();
  }, [remove, confirmModal]);

  return (
    <div className={FormArrayContainerModuleStyles.FormArray}>
      <h3>
        {title}

        {!fieldsLength ? (
          <IconButton iconName="AddIcon" color="action" onClick={addKeyValue} />
        ) : null}
      </h3>

      {fieldsLength ? (
        <div
          className={FormArrayContainerModuleStyles.FormArrayRow}
          data-columns-length={columnsLength}
        >
          {structure.map((item) => {
            return <span key={item.label}>{item.label}</span>;
          })}
        </div>
      ) : null}

      {fields?.map((field, rowIndex) => {
        return (
          <div
            className={FormArrayContainerModuleStyles.FormArrayRow}
            key={field.id}
            data-columns-length={columnsLength}
          >
            {structure.map(
              ({ type, fetchData, label, options, ...props }, columnIndex) => {
                switch (type) {
                  case 'selectasync':
                    if (fetchData) {
                      return (
                        <FormSelectAsyncContainer
                          fetchData={fetchData}
                          key={`${field.id}.${label}`}
                          name={`${name}.${rowIndex}.${columnIndex}`}
                          {...props}
                        />
                      );
                    }
                    return null;
                  case 'select':
                    return (
                      <FormSelectContainer
                        key={`${field.id}.${label}`}
                        name={`${name}.${rowIndex}.${columnIndex}`}
                        options={options || []}
                      />
                    );
                  case 'multiselect':
                    return (
                      <FormMultiSelectContainer
                        key={`${field.id}.${label}`}
                        name={`${name}.${rowIndex}.${columnIndex}`}
                        options={options || []}
                      />
                    );
                  case 'tel':
                  case 'text':
                    return (
                      <FormInputContainer
                        key={`${field.id}.${label}`}
                        name={`${name}.${rowIndex}.${columnIndex}`}
                        type={type}
                      />
                    );
                  default:
                    return null;
                }
              },
            )}

            <FormArrayDeleteButton index={rowIndex} openModal={openModal} />
          </div>
        );
      })}

      {fieldsLength ? (
        <div
          className={FormArrayContainerModuleStyles.FormArrayRow}
          data-columns-length={columnsLength}
        >
          <IconButton iconName="AddIcon" color="action" onClick={addKeyValue} />
        </div>
      ) : null}

      <Modal
        isOpened={confirmModal.isOpened}
        onClose={closeModal}
        title="Are you sure?"
        confirmButton={{
          handleConfirm: removeKeyValue,
        }}
        keepMounted={false}
      >
        <div>{CONFIRM_DELETE_MESSAGE}</div>
      </Modal>
    </div>
  );
};
