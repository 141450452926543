import { useCallback, useMemo } from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { Modal, Notification } from 'react-ui-kit-exante';

import {
  useAddCommissionRuleMutation,
  useEditCommissionRuleMutation,
} from '~/api';
import {
  FormInputContainer,
  FormToggleButtonGroupContainer,
} from '~/containers/form';

import CommissionRulesStyles from '../CommissionRules.module.css';
import { RULE_OPTIONS, TYPE_OPTIONS } from '../constants';

interface IFormProps {
  closeModal: () => void;
  displayModal: boolean;
  editedName: string;
  formInstance: UseFormReturn<
    { name: string; type: string; rule: string },
    string
  >;
}

export const Form = ({
  closeModal,
  displayModal,
  editedName,
  formInstance,
}: IFormProps) => {
  const [addCommissionRule] = useAddCommissionRuleMutation();
  const [editCommissionRule] = useEditCommissionRuleMutation();

  const onSubmitHandler = useCallback(
    async (formData: Record<string, unknown>) => {
      let result;

      if (editedName) {
        result = await editCommissionRule({ name: editedName, data: formData });
      } else {
        result = await addCommissionRule(formData);
      }

      if ('data' in result) {
        Notification.success({
          title: 'Rule saving successfully',
        });
      }

      closeModal();
    },
    [editedName, closeModal, addCommissionRule, editCommissionRule],
  );

  const submitHandle = formInstance.handleSubmit(onSubmitHandler);

  const confirmButton = useMemo(
    () => ({
      confirmButtonName: 'Save',
      handleConfirm: submitHandle,
    }),
    [submitHandle],
  );

  return (
    <Modal
      confirmButton={confirmButton}
      isOpened={displayModal}
      onClose={closeModal}
      title="Add new commission rule"
    >
      <FormProvider {...formInstance}>
        <form onSubmit={submitHandle} className={CommissionRulesStyles.Form}>
          <FormInputContainer label="Name" name="name" />

          <FormToggleButtonGroupContainer
            alwaysSelected
            exclusive
            label="Type"
            name="type"
            options={TYPE_OPTIONS}
          />

          <FormToggleButtonGroupContainer
            alwaysSelected
            exclusive
            label="Rule"
            name="rule"
            options={RULE_OPTIONS}
          />
        </form>
      </FormProvider>
    </Modal>
  );
};
