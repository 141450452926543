import {
  FormHelperText as FormHelperTextMUI,
  ToggleButton as ToggleButtonMUI,
  ToggleButtonGroup as ToggleButtonGroupMUI,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { blockNonNativeProps } from '../../../helpers';
import { TDefaultTheme } from '../../../theme';

import { TSizes } from './ToggleButtonGroup.types';
import { DISABLED_OPACITY } from './constants';

interface ILabelStyledProps {
  disabled: boolean;
  error: boolean;
  warning?: boolean;
  size?: TSizes;
}

interface IToggleButtonGroupStyledProps {
  disabled: boolean;
  warning?: boolean;
}

interface IToggleButtonStyledProps {
  error: boolean;
  warning?: boolean;
  size?: TSizes;
  loading?: boolean;
}

const getColor = (theme: TDefaultTheme, error: boolean, warning: boolean) => {
  switch (true) {
    case error:
      return theme.color.input.error;

    case warning:
      return theme.color.input.warning;

    default:
      return theme.color.typo.action;
  }
};

const getBgColor = (theme: TDefaultTheme, error: boolean, warning: boolean) => {
  switch (true) {
    case error:
      return theme.color.bg.radical;

    case warning:
      return theme.color.input.transparentWarning;

    default:
      return theme.color.input.bg.default;
  }
};

export const LabelStyled = styled('label', {
  shouldForwardProp: blockNonNativeProps(['error', 'warning']),
})<ILabelStyledProps>(({ disabled, theme }) => {
  const opacity = disabled ? DISABLED_OPACITY : 1;

  return {
    fontFamily: theme.font.main,
    fontSize: '15px',
    fontWeight: 500,
    lineHeight: '24px',
    marginRight: 8,
    opacity,
  };
});

export const ToggleButtonGroupStyled = styled(
  ToggleButtonGroupMUI,
)<IToggleButtonGroupStyledProps>(({ disabled, fullWidth }) => {
  const opacity = disabled ? DISABLED_OPACITY : 1;
  const pointerEvents = disabled ? 'none' : 'auto';
  const width = fullWidth ? '100%' : 'auto';

  return {
    '&.MuiToggleButtonGroup-root': {
      width,
      opacity,
      pointerEvents,
    },
  };
});

export const ToggleButtonStyled = styled(ToggleButtonMUI, {
  shouldForwardProp: blockNonNativeProps(['error', 'warning', 'size']),
})<IToggleButtonStyledProps>(
  ({ error, theme, warning, size, disabled, color, loading }) => {
    const bgColor = getBgColor(theme, error, !!warning);
    const opacity = disabled ? DISABLED_OPACITY : 1;

    return {
      '&.MuiToggleButton-root': {
        border: `1px solid ${theme.color.line.primary}`,
        color: getColor(theme, error, !!warning),
        opacity,
        fontFamily: theme.font.main,
        fontSize: '15px',
        fontWeight: 500,
        textTransform: 'capitalize',
        padding: size === 'small' ? '7px 8px' : '12px 8px',
        lineHeight: size === 'small' ? '16px' : '22px',
        background: bgColor,

        [theme?.breakpoints?.up('md')]: {
          padding: size === 'small' ? '7px 8px' : '12px 16px',
        },

        '&.Mui-selected, &:hover': {
          background: theme.color.input.bg.hover,
          color: theme.color.typo.primary,
        },

        ...(color &&
          theme?.color.button[color] && {
            color: theme?.color.button[color].corner.default,
            '&:hover': {
              background: theme?.color.button[color].gr.hover,
              backgroundColor: theme?.color.button[color].corner.default,
              color: theme?.color.button[color].typo.hover,
            },
            '&:active, &:focus-visible': {
              background: theme?.color.button[color].gr.focus,
              color: theme?.color.button[color].typo.focus,
            },
            '&.Mui-disabled': {
              opacity: disabled && !loading ? 0.4 : 1,
            },
          }),
      },
    };
  },
);

export const ToggleButtonSkeletonStyled = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.color.line.primary}`,
  background: theme.color.input.bg.default,
  padding: '12px 8px',
  lineHeight: '22px',
  borderRadius: '4px',
  width: '100%',
}));

export const ToggleButtonWrapperStyled = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const FormHelperTextStyled = styled(FormHelperTextMUI, {
  shouldForwardProp: blockNonNativeProps('fullWidth'),
})(({ theme }) => ({
  '&.MuiFormHelperText-root': {
    margin: 0,
    fontFamily: theme.font.main,
    fontSize: '11px',
    lineHeight: '16px',
    fontWeight: '400',
    color: theme.color.typo.secondary,
  },
  '&.MuiFormHelperText-root.Mui-error': {
    color: theme.color.input.error,
    flex: '1 1 100%',
  },
}));
