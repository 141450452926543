import { isEqual } from 'lodash';

import { IOption } from '~/types/form';

export function buildDataWithExtractedAutocompleteValue<
  T extends Record<keyof T, unknown>,
>(arr: T[], fields: string[]) {
  return arr.map((item) => {
    const itemKeys = Object.keys(item).filter((key) => fields.includes(key));

    if (!itemKeys.length) {
      return item;
    }

    return itemKeys.reduce<T>((acc, curr) => {
      return {
        ...acc,
        [curr]: extractAutocompleteValue(item[curr as keyof T]),
      };
    }, item);
  });
}

// value can be any type doesn't matter
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function extractAutocompleteValue(item: any) {
  if (isAutocompleteValue(item)) {
    return item.value;
  }

  return item;
}

function isAutocompleteValue(item: unknown): item is IOption {
  const optionKeys: Array<keyof IOption> = ['label', 'value'];

  return item instanceof Object && isEqual(optionKeys, Object.keys(item));
}
