import { isEqual } from 'lodash';
import { useEffect, useMemo } from 'react';
import { Bookmarks, Table } from 'react-ui-kit-exante';

import { useGetLegalEntityTypesQuery } from '~/api/types/types.api';
import { EMPTY_ARRAY, NO_DATA_HEIGHT } from '~/constants';
import { useLogHandleTime, usePrevious } from '~/hooks';
import { IBookmarkResponseProps } from '~/hooks/useBookmark/types';
import {
  DEFAULT_SORTING_AGGREGATED_DATA,
  DISPLAYED_COLUMNS_KEYS,
  getColumns,
} from '~/pages/GlobalSummaryByAccountsPage/columns';
import {
  GLOBAL_SUMMARY_BY_POSITION_TABLE_ID,
  NOT_NEED_COLUMNS,
} from '~/pages/GlobalSummaryByAccountsPage/constants';
import { prepareTableTitle } from '~/pages/GlobalSummaryByAccountsPage/helpers';
import { useGlobalSummary } from '~/pages/GlobalSummaryByAccountsPage/hooks';
import { WithBookmarks } from '~/shared/components/WithBookmarks/WithBookmarks';
import { transformVariantsToSelectOptions } from '~/shared/utils';

type PageProps = {
  isAggregatedView?: true;
  isByPositionSide?: true;
};

type TAggregatedGlobalSummary = PageProps & IBookmarkResponseProps;

export const GlobalSummaryByPositionPageComponent = ({
  isAggregatedView,
  isByPositionSide,
  selectedBookmark,
  handleSaveBookmark,
  handleSaveAsNewBookmark,
  handleShareBookmark,
  handleDeleteBookmark,
}: TAggregatedGlobalSummary) => {
  const { data: legalEntityTypes } = useGetLegalEntityTypesQuery();
  const legalEntityOptions = transformVariantsToSelectOptions(
    legalEntityTypes?.values,
    { capitalized: true, shouldSortAlphabetically: true },
  );
  const { setStartHandleTime, logHandleTime } = useLogHandleTime(
    'global-summary-by-position',
  );

  const {
    date,
    isLoading,
    data,
    currency,
    exportTableParams,
    setSorting,
    setFilter,
    removeFilter,
    filteringProps,
    accountTypes,
    assetTypes,
    additionalHistoricalAction,
    ...serverPaginationProps
  } = useGlobalSummary({
    preCall: setStartHandleTime,
    isAggregatedView,
    isByPositionSide,
    tableId: GLOBAL_SUMMARY_BY_POSITION_TABLE_ID,
  });

  const prevData = usePrevious(data?.data);

  const columns = useMemo(
    () =>
      getColumns({
        onFilter: setFilter,
        onRemove: removeFilter,
        currency,
        legalEntityOptions,
        assetTypes,
      }).filter(({ accessor }) => !NOT_NEED_COLUMNS.has(accessor)),
    [setFilter, removeFilter, currency, legalEntityOptions, assetTypes],
  );

  useEffect(() => {
    if (data?.data && !isEqual(prevData, data?.data)) {
      logHandleTime();
    }
  }, [data, logHandleTime, prevData]);

  const title = prepareTableTitle(date, 'Global Summary by position side');

  const bookmarkComponent = useMemo(
    () => (
      <Bookmarks
        initialValues={selectedBookmark}
        onSave={(name) => handleSaveBookmark(name, filteringProps.filters)}
        onSaveAsNew={(name) =>
          handleSaveAsNewBookmark(name, filteringProps.filters)
        }
        onShare={handleShareBookmark}
        onDelete={handleDeleteBookmark}
      />
    ),
    [
      filteringProps.filters,
      handleSaveBookmark,
      handleSaveAsNewBookmark,
      handleShareBookmark,
      handleDeleteBookmark,
      selectedBookmark,
    ],
  );

  return (
    <Table
      title={title}
      columns={columns}
      data={data?.data || EMPTY_ARRAY}
      defaultSortBy={DEFAULT_SORTING_AGGREGATED_DATA}
      displayedColumnKeys={DISPLAYED_COLUMNS_KEYS}
      exportTableParams={exportTableParams}
      filteringProps={filteringProps}
      hasFilters
      hasPagination
      isFlexLayout
      isLoading={isLoading}
      isPinnedHeader
      manualSortBy
      noDataHeight={NO_DATA_HEIGHT}
      onSort={setSorting}
      saveColumnOrder
      saveViewParamsAfterLeave
      serverPaginationProps={serverPaginationProps}
      showTableInfo
      tableId={GLOBAL_SUMMARY_BY_POSITION_TABLE_ID}
      additionalActions={additionalHistoricalAction}
      filtersRightPanelComponent={bookmarkComponent}
    />
  );
};

export const GlobalSummaryByPositionPage = (props: PageProps) => {
  const tableId = GLOBAL_SUMMARY_BY_POSITION_TABLE_ID;

  return (
    <WithBookmarks
      component={GlobalSummaryByPositionPageComponent}
      pageName="GlobalSummaryByPosition"
      tableId={tableId}
      {...props}
    />
  );
};
