import { FilterValue, IColumn } from 'react-ui-kit-exante';

import { transformVariantsToSelectOptions } from '~/shared/utils';
import { TFlattenClient } from '~/types/clients';
import { IOption } from '~/types/form';
import { ITypesEntities } from '~/types/types';

export const DISPLAYED_COLUMN_KEYS: (keyof TFlattenClient)[] = [
  'id',
  'lastModificationDate',
  'owner',
  'name',
  'clientType',
  'legalEntity',
  'documentsPending',
  'withdrawalRestriction',
];

interface IGetColumns {
  onFilter(col: string, value: FilterValue): void;
  onRemove(col: string): void;
  types: ITypesEntities & {
    legalEntity: string[];
    clientType: string[];
    incorporationTypes: string[];
    exanteTypes: string[];
  };
  brandingList: IOption[];
}

export function getColumns({
  onFilter,
  onRemove,
  types: {
    clientType,
    legalEntity,
    corporateCategory,
    exanteTypes,
    incorporationTypes,
  },
  brandingList,
}: IGetColumns): IColumn<TFlattenClient>[] {
  return [
    {
      Header: 'Id',
      accessor: 'id',
      onFilter,
      onRemove,
    },
    {
      Header: 'Last modification date',
      accessor: 'lastModificationDate',
      type: 'dateRange',
      onFilter,
      onToday: onFilter,
      onYesterday: onFilter,
      onLastWeek: onFilter,
      onRemove,
      sortDescFirst: true,
      formatting: 'dateUTC',
    },
    { Header: 'Owner', accessor: 'owner', onFilter, onRemove },
    {
      Header: 'Name',
      accessor: 'name',
      onFilter,
      onRemove,
    },
    {
      Header: 'Client type',
      accessor: 'clientType',
      type: 'multiSelect',
      filterOptions: transformVariantsToSelectOptions(clientType),
      onFilter,
      onRemove,
    },
    {
      Header: 'Legal entity',
      accessor: 'legalEntity',
      type: 'multiSelect',
      filterOptions: transformVariantsToSelectOptions(legalEntity),
      onFilter,
      onRemove,
    },
    {
      Header: 'Documents pending',
      accessor: 'documentsPending',
      type: 'checkbox',
      onFilter,
      onRemove,
    },
    {
      Header: 'Withdrawal restriction',
      accessor: 'withdrawalRestriction',
      type: 'checkbox',
      onFilter,
      onRemove,
    },

    {
      Header: 'Archived',
      accessor: 'archived',
      type: 'checkbox',
      onFilter,
      onRemove,
    },
    {
      Header: 'Branding',
      accessor: 'branding',
      disableSortBy: true,
      type: 'select',
      filterOptions: brandingList,
      onFilter,
      onRemove,
    },
    {
      Header: 'Closed',
      accessor: 'closed',
      type: 'checkbox',
      onFilter,
      onRemove,
    },
    {
      Header: 'Corporate category',
      accessor: 'corporateCategory',
      type: 'multiSelect',
      filterOptions: transformVariantsToSelectOptions(corporateCategory.values),
      onFilter,
      onRemove,
    },
    {
      Header: 'Email',
      accessor: 'email',
      disableSortBy: true,
      onFilter,
      onRemove,
    },
    {
      Header: 'Exante type',
      accessor: 'exanteType',
      type: 'multiSelect',
      filterOptions: transformVariantsToSelectOptions(exanteTypes),
      onFilter,
      onRemove,
    },
    {
      Header: 'Incorporation type',
      accessor: 'incorporationType',
      type: 'multiSelect',
      filterOptions: transformVariantsToSelectOptions(incorporationTypes),
      onFilter,
      onRemove,
    },
    {
      Header: 'Internal info',
      accessor: 'internalInfo',
      disableSortBy: true,
      onFilter,
      onRemove,
    },
    {
      Header: 'Primary phone',
      accessor: 'primaryPhone',
      disableSortBy: true,
      onFilter,
      onRemove,
    },
    { Header: 'Role', accessor: 'role', onFilter, onRemove },
    {
      Header: 'Secondary phone',
      accessor: 'secondaryPhone',
      disableSortBy: true,
      onFilter,
      onRemove,
    },
    {
      Header: 'Net asset value, EUR',
      accessor: 'netAssetValue',
      type: 'numberRange',
      formatting: 'number',
      onFilter,
      onRemove,
      disableSortBy: true,
    },
  ];
}
