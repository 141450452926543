import { IFetchDataResult } from 'react-ui-kit-exante/build/Components/Inputs/AutocompleteAsync/types';

import { IOptionsArguments } from '../apiRequest';

export function calculateFetchData(
  skip: number,
  limit: number,
  total: number,
  callback: (
    symbolId: string,
    options: IOptionsArguments,
  ) => Promise<IFetchDataResult>,
) {
  return skip + limit < total ? callback : null;
}
