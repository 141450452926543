import { cloneDeep, get, has, includes, pick, set } from 'lodash';

import { isAxiosError, reduceByKeys, TReduceFn } from '~/shared/utils';
import { prepareDateRangeStringForAPI } from '~/shared/utils/dates';

import { availableRequestParams } from './constants';
import {
  TFetchAuditLogsParams,
  TResolveAuditLogsParams,
  TResolveAuditLogsQueryParams,
} from './types';

const QUERY_PARTS_SEPARATOR = ' and ';
const datePeriodKeys = ['time'] as const;

const getDatePeriodQuery = (datePeriod?: [string, string]): string => {
  if (!datePeriod) {
    return '';
  }

  const { from, to } = prepareDateRangeStringForAPI(['from', 'to'], datePeriod);
  return `"time">='${from}' and "time"<='${to}'`;
};

const getAnyValueQuery = (key: string, value: unknown): string => {
  return `"${key}"~"${value}"`;
};

const isDatePeriodValue = (
  key: string,
): key is (typeof datePeriodKeys)[number] => includes(datePeriodKeys, key);

const createQueryFromFilters = (
  filters: TResolveAuditLogsQueryParams,
): string => {
  const initialValue: string[] = [];
  const reduceFn: TReduceFn<
    TResolveAuditLogsQueryParams,
    typeof initialValue
  > = (acc, item) => {
    let queryPart;

    if (isDatePeriodValue(item)) {
      queryPart = getDatePeriodQuery(filters[item]);
    } else {
      queryPart = getAnyValueQuery(item, filters[item]);
    }

    acc.push(queryPart);
    return acc;
  };

  const arr = reduceByKeys(filters, reduceFn, initialValue);

  return arr.join(QUERY_PARTS_SEPARATOR);
};

export const prepareParamsToRequest = (
  params: TResolveAuditLogsParams,
): TFetchAuditLogsParams => {
  const { query, ...rest } = params;
  const requestParams = pick(rest, [...availableRequestParams]);

  if (query) {
    return {
      ...requestParams,
      query: createQueryFromFilters(query),
    };
  }

  return rest;
};

export const checkParams = (params: TResolveAuditLogsParams) => {
  const invalidParams = Object.keys(params).filter(
    (param) => !availableRequestParams.has(param),
  );

  return invalidParams;
};

const setIfNotExists = (
  target: Parameters<typeof set>[0],
  path: string,
  value: unknown,
) => {
  if (!has(target, path)) {
    set(target, path, value);
  }
};

export const transformAuditLogErrorToValidError = (error: unknown) => {
  if (isAxiosError(error)) {
    const messageObject = cloneDeep(get(error, 'response.data'));
    const status = get(error, 'response.status');

    setIfNotExists(error, 'response.data.message', messageObject);
    setIfNotExists(error, 'response.data.statusCode', status);
  }

  return error;
};
