import { yupResolver } from '@hookform/resolvers/yup';
import { capitalize } from 'lodash';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Loader, RadioGroup } from 'react-ui-kit-exante';

import {
  useGetAccountPurposeTypesQuery,
  useGetAccountTypesQuery,
  useGetCategoriesTypesQuery,
  useGetLegalEntityTypesQuery,
  useGetOperationTypesQuery,
} from '~/api/types/types.api';
import { EMPTY_ARRAY, EMPTY_OPTION_NULLABLE } from '~/constants';
import { useAccountsAutocomplete } from '~/hooks';
import {
  MirroringRuleOptions,
  radioOptions,
  transferMultiplierOptions,
} from '~/pages/MirroringRulePage/constants';
import { getValidationSchema } from '~/pages/MirroringRulePage/validationSchema';
import { mirroringRulesService } from '~/resources';
import { MIRRORING_RULES_PATH } from '~/routes';
import { SaveButton, CloseButton } from '~/shared/components';
import { EntryScreenActions } from '~/shared/components/EntryScreenActions';
import { transformVariantsToSelectOptions } from '~/shared/utils';

import {
  FormDateTimePickerContainer,
  FormInputContainer,
  FormMultiSelectAsyncContainer,
  FormMultiSelectContainer,
  FormSelectAsyncContainer,
  FormSelectContainer,
} from '../form';

import { NewMirroringRule } from './NewMirroringRule';
import { defaultValues } from './constants';

export const NewMirroringRuleContainer = () => {
  const [loading, setLoading] = useState(false);
  const [activeOption, setActiveOption] = useState<MirroringRuleOptions>(
    MirroringRuleOptions.Categories,
  );

  const { data: categoryTypes } = useGetCategoriesTypesQuery();
  const categoriesTypeOptions = transformVariantsToSelectOptions(
    categoryTypes?.values,
    { capitalized: true },
  );
  const { data: operationTypes } = useGetOperationTypesQuery();
  const operationTypeOptions = (operationTypes?.values || []).map(
    (operationType) => ({
      value: operationType,
      label: operationType.split('/').map(capitalize).join('/'),
    }),
  );
  const { data: accountPurposeTypes } = useGetAccountPurposeTypesQuery();
  const accountPurposesOptions = transformVariantsToSelectOptions(
    accountPurposeTypes?.values,
  );
  const { data: accountTypes } = useGetAccountTypesQuery();
  const accountTypesOptions = transformVariantsToSelectOptions(
    accountTypes?.values,
  );

  const { data: legalEntityTypes } = useGetLegalEntityTypesQuery();
  const legalEntitiesOptions = useMemo(
    () => [
      EMPTY_OPTION_NULLABLE,
      ...transformVariantsToSelectOptions(legalEntityTypes?.values, {
        capitalized: true,
        shouldSortAlphabetically: true,
      }),
    ],
    [legalEntityTypes?.values],
  );
  const navigate = useNavigate();
  const validationSchema = useMemo(() => getValidationSchema(), []);
  const formInstance = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit } = formInstance;
  const getAccountsAutocompleteFn = useAccountsAutocomplete();

  const onSubmit: SubmitHandler<typeof defaultValues> = useCallback(
    async (values) => {
      setLoading(true);
      const createdMirroringRule =
        await mirroringRulesService.createMirroringRule(values);
      setLoading(false);

      if (createdMirroringRule.data?.id) {
        const createdMirroringRuleID = createdMirroringRule.data?.id;
        navigate(`${MIRRORING_RULES_PATH}/${createdMirroringRuleID}`);
      }
    },
    [navigate],
  );

  const onClose = useCallback(() => {
    navigate(MIRRORING_RULES_PATH);
  }, [navigate]);

  const handleRadioGroupChange = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>): void => {
      setActiveOption(value as MirroringRuleOptions);
    },
    [],
  );

  return (
    <FormProvider {...formInstance}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <NewMirroringRule
          header={
            <EntryScreenActions
              closeButton={<CloseButton onClick={onClose} />}
              saveButton={loading ? <Loader /> : <SaveButton />}
              title="Create new mirroring rule"
            />
          }
          fields={[
            <FormInputContainer key="name" label="Name" name="name" />,
            <FormSelectAsyncContainer
              key="targetAccount"
              label="Target account"
              name="targetAccount"
              fetchData={getAccountsAutocompleteFn()}
            />,
            <FormMultiSelectContainer
              key="accountTypes"
              label="Account types"
              name="accountTypes"
              options={accountTypesOptions}
            />,
            <FormMultiSelectContainer
              key="accountPurposes"
              label="Account purposes"
              name="accountPurposes"
              options={accountPurposesOptions}
            />,
            <FormMultiSelectContainer
              key="sourceLegalEntities"
              label="Source legal entities"
              name="sourceLegalEntities"
              options={legalEntitiesOptions}
            />,
            <FormMultiSelectAsyncContainer
              key="sourceAccounts"
              label="Source accounts"
              name="sourceAccounts"
              fetchData={getAccountsAutocompleteFn()}
            />,
            <FormMultiSelectAsyncContainer
              key="blackListAccounts"
              label="Black list accounts"
              name="blackListAccounts"
              fetchData={getAccountsAutocompleteFn()}
            />,
            <FormMultiSelectContainer
              freeSolo
              key="excludedAccounts"
              label="Excluded Accounts"
              name="excludedAccounts"
              options={EMPTY_ARRAY}
              disabled
            />,
            <FormMultiSelectContainer
              freeSolo
              key="custodians"
              label="Custodians"
              name="custodians"
              options={EMPTY_ARRAY}
            />,
            <RadioGroup
              key="radioOptions"
              handleChange={handleRadioGroupChange}
              radioOptions={radioOptions}
              value={activeOption}
            />,
            <FormMultiSelectContainer
              key="categories"
              label="Categories"
              name="categories"
              options={categoriesTypeOptions}
              disabled={activeOption !== MirroringRuleOptions.Categories}
            />,
            <FormMultiSelectContainer
              key="transactions"
              label="Transactions"
              name="operationTypes"
              options={operationTypeOptions}
              disabled={activeOption !== MirroringRuleOptions.Transactions}
            />,
            <FormSelectContainer
              key="internalTransferFromLE"
              label="Internal transfer from LE"
              name="tags.transfer.from.legalEntity"
              options={legalEntitiesOptions}
            />,
            <FormInputContainer
              key="internalTransferFromEnvironment"
              label="Internal transfer from environment"
              name="tags.transfer.from.environment"
            />,
            <FormSelectContainer
              key="internalTransferToLE"
              label="Internal transfer to LE"
              name="tags.transfer.to.legalEntity"
              options={legalEntitiesOptions}
            />,
            <FormInputContainer
              key="internalTransferToEnvironment"
              label="Internal transfer to environment"
              name="tags.transfer.to.environment"
            />,
            <FormSelectAsyncContainer
              key="rebateTagAccount"
              label="Rebate tag account"
              name="tags.rebate.from.account"
              fetchData={getAccountsAutocompleteFn()}
            />,
            <FormSelectContainer
              key="rebateTagLE"
              label="Rebate tag LE"
              name="tags.rebate.from.legalEntity"
              options={legalEntitiesOptions}
            />,
            <FormSelectContainer
              key="transferMultiplier"
              label="Transfer multiplier"
              name="transferMultiplier"
              options={transferMultiplierOptions}
            />,
            <FormDateTimePickerContainer
              key="startDate"
              label="Start date"
              name="startDateTime"
            />,
            <FormDateTimePickerContainer
              key="endDate"
              label="End date"
              name="endDateTime"
            />,
          ]}
        />
      </form>
    </FormProvider>
  );
};
