import { ICellValue, IColumn } from 'react-ui-kit-exante';

import { TotalFooter } from '~/shared/components/TotalFooter';
import { formatCellToCurrency } from '~/shared/utils/formatters/formatCellToCurrency';
import { formatUnderScoreFields } from '~/shared/utils/formatters/formatUnderScoreFields';
import { calculateColumnValuesSum } from '~/shared/utils/table';

import { TMarginTableData } from '../types';

export const DISPLAYED_COLUMN_KEYS = [
  'type',
  'underlying',
  'asset',
  'margin',
  'currency',
  'convertedMargin',
];
export const getColumns = (currency: string): IColumn<TMarginTableData>[] => [
  {
    Header: 'Type',
    accessor: 'type',
    Cell: ({ row: { values } }: ICellValue<TMarginTableData>) =>
      formatUnderScoreFields(values.type),
    Footer: TotalFooter,
  },
  {
    Header: 'Underlying',
    accessor: 'underlying',
  },
  {
    Header: 'Asset',
    accessor: 'asset',
  },
  {
    Header: 'Margin',
    accessor: 'margin',
    Cell: formatCellToCurrency<TMarginTableData>('margin'),
    formatting: 'number',
  },
  {
    Header: 'CCY',
    accessor: 'currency',
  },
  {
    Header: `Margin, ${currency}`,
    accessor: 'convertedMargin',
    Cell: formatCellToCurrency<TMarginTableData>('convertedMargin'),
    Footer: calculateColumnValuesSum<TMarginTableData>('convertedMargin'),
    formatting: 'number',
  },
  {
    Header: 'Price',
    accessor: 'price',
    Cell: formatCellToCurrency('price'),
    formatting: 'number',
  },
  {
    Header: 'Position',
    accessor: 'position',
    Cell: formatCellToCurrency('position'),
    formatting: 'number',
  },
  {
    Header: 'Asset type',
    accessor: 'assetType',
  },
  {
    Header: 'Leverage rate',
    accessor: 'leverageRate',
    formatting: 'number',
  },
  {
    Header: 'Extreme margin',
    accessor: 'extremeMargin',
    formatting: 'number',
  },
  {
    Header: 'Effective qtty',
    accessor: 'effectiveQtty',
    Cell: formatCellToCurrency('effectiveQtty'),
    formatting: 'number',
  },
  {
    Header: 'Currency leverage rate short',
    accessor: 'currencyLeverageRateShort',
    minWidth: 268,
    formatting: 'number',
  },
  {
    Header: 'Currency leverage rate long',
    accessor: 'currencyLeverageRateLong',
    minWidth: 258,
    formatting: 'number',
  },
  {
    Header: 'Crossrate',
    accessor: 'crossrate',
    formatting: 'number',
  },
  {
    Header: `Extreme margin, ${currency}`,
    accessor: 'convertedExtremeMargin',
    minWidth: 196,
    formatting: 'number',
  },
];
