import { createApi } from '@reduxjs/toolkit/query/react';
import { Notification } from 'react-ui-kit-exante';

import {
  baseQueryHandler,
  check403Error,
  sendNotification,
} from '~/shared/utils';
import { TCurrency } from '~/types/currencies';
import {
  Group,
  IInterestRate,
  TGroupId,
} from '~/types/interestRates/interestRates';

import { interestRatesEndpoints } from './endpoints';
import {
  addUpdatedBenchmarkData,
  addUpdatedMarkupData,
  createInterestRatesMap,
  getUpdateBenchmarkPayload,
  getUpdateGroupRatePayload,
  getUpdateMarkupPayload,
  getValues,
  haveBeenUpdated,
} from './helpers';
import {
  IGroupPayload,
  IInterestRateByAccount,
  IInterestRateByGroup,
  TInterestRatesMap,
  TUpdatedFields,
} from './types';

export const interestRatesApi = createApi({
  reducerPath: 'interestRatesApi',
  baseQuery: baseQueryHandler,
  tagTypes: [
    'InterestRates',
    'InterestRatesByAccount',
    'InterestRatesByGroup',
    'Groups',
  ],
  endpoints: (builder) => ({
    getInterestRates: builder.query<TInterestRatesMap, void>({
      queryFn: async (_, __, ___, fetchWithBasicQuery) => {
        const [markupResponse, benchmarkResponse] = await Promise.all([
          fetchWithBasicQuery({
            url: interestRatesEndpoints.markup,
            ignoreForbiddenError: true,
          }),
          fetchWithBasicQuery({
            url: interestRatesEndpoints.benchmark,
            ignoreForbiddenError: true,
          }),
        ]);

        const markupResponseError = markupResponse.error;
        const benchmarkResponseError = benchmarkResponse.error;

        if (markupResponseError || benchmarkResponseError) {
          return {
            error:
              markupResponseError ||
              benchmarkResponseError ||
              'Unexpected error',
          };
        }

        return {
          data: createInterestRatesMap(
            markupResponse.data,
            benchmarkResponse.data,
          ),
        };
      },
      providesTags: ['InterestRates'],
    }),
    getInterestRatesByAccount: builder.query<
      IInterestRateByAccount[],
      {
        accountId: string;
      }
    >({
      query: ({ accountId }) => ({
        url: interestRatesEndpoints.getInterestRatesByAccountEndpoint(
          accountId,
        ),
        ignoreForbiddenError: true,
      }),
      providesTags: (_, __, arg) => [
        { type: 'InterestRatesByAccount' as const, id: arg.accountId },
        'InterestRatesByAccount',
      ],
    }),
    getInterestRatesByGroup: builder.query<IInterestRateByGroup[], TGroupId>({
      query: (groupId) => ({
        url: interestRatesEndpoints.getInterestRatesByGroupEndpoint(groupId),
        ignoreForbiddenError: true,
      }),
      providesTags: (_, __, arg) => [
        { type: 'InterestRatesByGroup' as const, id: arg },
        'InterestRatesByGroup',
      ],
    }),

    updateGroupRate: builder.mutation<
      void,
      { groupId: TGroupId; payload: TUpdatedFields<IInterestRate> }
    >({
      query: ({ groupId, payload }) => ({
        method: 'POST',
        url: interestRatesEndpoints.getInterestRatesByGroupEndpoint(groupId),
        data: [
          {
            setId: groupId,
            ...getUpdateGroupRatePayload(payload),
          },
        ],
        ignoreForbiddenError: true,
        onSuccess: () =>
          sendNotification('Rate was successfully updated', 'success'),
      }),
      transformResponse: (_, meta) => {
        return meta?.params?.data;
      },
      invalidatesTags: (_, __, arg) => [
        { type: 'InterestRatesByGroup' as const, id: arg.groupId },
        'InterestRatesByGroup',
      ],
    }),
    removeGroupRate: builder.mutation<
      void,
      { currency: TCurrency; groupId: TGroupId }
    >({
      query: ({ currency, groupId }) => ({
        method: 'POST',
        url: interestRatesEndpoints.getInterestRatesByGroupEndpoint(groupId),
        data: [
          {
            active: false,
            currency,
          },
        ],
        ignoreForbiddenError: true,
      }),
      invalidatesTags: (_, __, arg) => [
        { type: 'InterestRatesByGroup' as const, id: arg.groupId },
        'InterestRatesByGroup',
      ],
    }),
    getInterestsGroups: builder.query<Group[], void>({
      query: () => ({
        url: interestRatesEndpoints.groups,
        ignoreForbiddenError: true,
      }),
      providesTags: ['Groups'],
    }),
    createInterestsGroup: builder.mutation<
      { name: string; id: TGroupId },
      { payload: IGroupPayload }
    >({
      query: ({ payload }) => ({
        method: 'POST',
        url: interestRatesEndpoints.groups,
        data: payload,
        onSuccess: () =>
          sendNotification('Group was successfully created', 'success'),
        ignoreForbiddenError: true,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data: newGroup } = await queryFulfilled;

          dispatch(
            interestRatesApi.util.updateQueryData(
              'getInterestsGroups',
              undefined,
              (draft) => {
                draft.push(newGroup);
              },
            ),
          );

          dispatch(
            interestRatesApi.util.upsertQueryData(
              'getInterestRatesByGroup',
              newGroup.id,
              [],
            ),
          );
        } catch (e) {
          console.error(e);
        }
      },
      invalidatesTags: ['Groups'],
    }),
    updateInterestsGroup: builder.mutation<
      Group,
      { id: TGroupId; payload: IGroupPayload }
    >({
      query: ({ id, payload }) => ({
        method: 'POST',
        url: `${interestRatesEndpoints.groups}/${id}`,
        data: payload,
        ignoreForbiddenError: true,
        onSuccess: () =>
          sendNotification('Group was successfully updated', 'success'),
      }),
      invalidatesTags: ['Groups'],
    }),
    removeInterestsGroup: builder.mutation<unknown, { id: TGroupId }>({
      query: ({ id }) => ({
        method: 'DELETE',
        url: `${interestRatesEndpoints.groups}/${id}`,
        onSuccess: () =>
          sendNotification('Group was successfully deleted', 'success'),
        ignoreForbiddenError: true,
      }),
      invalidatesTags: ['Groups'],
    }),
    updateInterestRate: builder.mutation<
      IInterestRate | null,
      { payload: TUpdatedFields<IInterestRate> }
    >({
      queryFn: async ({ payload: data }, _, __, fetchWithBasicQuery) => {
        try {
          const {
            currency: { value: currency },
            days,
            negativeBenchmark,
            negativeMarkup,
            negativeRebate,
            positiveBenchmark,
            positiveMarkup,
            positiveRebate,
          } = data;

          const isBenchmarkUpdate = haveBeenUpdated([
            days,
            negativeBenchmark,
            positiveBenchmark,
          ]);

          const isMarkupUpdate = haveBeenUpdated([
            negativeMarkup,
            positiveMarkup,
            negativeRebate,
            positiveRebate,
          ]);

          let updatedBenchmarkData = null;
          let updatedMarkupData = null;

          if (isBenchmarkUpdate) {
            const { data: updatedBenchmarkResponse, error } =
              await fetchWithBasicQuery({
                method: 'POST',
                url: interestRatesEndpoints.benchmark,
                data: [getUpdateBenchmarkPayload(data)],
              });
            updatedBenchmarkData = updatedBenchmarkResponse;

            if (error) {
              return {
                error,
              };
            }
          }
          if (isMarkupUpdate) {
            const { data: updatedMarkupResponse, error } =
              await fetchWithBasicQuery({
                method: 'POST',
                url: interestRatesEndpoints.markup,
                data: [getUpdateMarkupPayload(data)],
              });

            updatedMarkupData = updatedMarkupResponse;

            if (error) {
              return {
                error,
              };
            }
          }

          if (!isBenchmarkUpdate && !isMarkupUpdate) {
            return {
              data: null,
            };
          }
          let updatedRow = getValues(data);

          updatedRow = addUpdatedBenchmarkData(
            currency,
            updatedRow,
            updatedBenchmarkData,
          );

          updatedRow = addUpdatedMarkupData(
            currency,
            updatedRow,
            updatedMarkupData,
          );

          return { data: updatedRow };
        } catch (error) {
          return {
            error: String(error),
          };
        }
      },

      invalidatesTags: ['InterestRates'],
    }),
    updateAccountRate: builder.mutation<
      unknown,
      { accountId: string; payload: TUpdatedFields<IInterestRate> }
    >({
      query: ({ accountId, payload }) => ({
        url: interestRatesEndpoints.getInterestRatesByAccountEndpoint(
          accountId,
        ),
        method: 'POST',
        data: [getUpdateGroupRatePayload(payload)],
        ignoreForbiddenError: true,
        onSuccess: () =>
          sendNotification('Rate was successfully updated', 'success'),
      }),
      invalidatesTags: (_, __, arg) => [
        { type: 'InterestRatesByAccount' as const, id: arg.accountId },
        'InterestRatesByAccount',
      ],
    }),
    removeAccountRate: builder.mutation<
      unknown,
      { accountId: string; currency: TCurrency }
    >({
      query: ({ accountId, currency }) => ({
        method: 'POST',
        url: interestRatesEndpoints.getInterestRatesByAccountEndpoint(
          accountId,
        ),
        data: [
          {
            active: false,
            currency,
          },
        ],
        ignoreForbiddenError: true,
      }),
      invalidatesTags: (_, __, arg) => [
        { type: 'InterestRatesByAccount' as const, id: arg.accountId },
        'InterestRatesByAccount',
      ],
    }),
  }),
});
