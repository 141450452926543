import { useMemo } from 'react';
import { IconButton } from 'react-ui-kit-exante';

import { useAccountStatuses, useUsersAutocomplete } from '~/hooks';

import {
  FormCheckboxContainer,
  FormSelectAsyncContainer,
  FormSelectContainer,
} from '../../../form';

import UsersPermissionItemStyles from './UsersPermissionItem.module.css';

interface IUsersPermissionItemProps {
  userId: string;
  index: number;
  isNew?: boolean;
  onRemove: (index: number, isNew?: boolean) => void;
}

export const UsersPermissionItem = ({
  userId,
  index,
  isNew,
  onRemove,
}: IUsersPermissionItemProps) => {
  const { accountStatusesOptions } = useAccountStatuses();
  const getUsersAutocompleteFn = useUsersAutocomplete();

  const handleRemove = () => {
    onRemove(index, isNew);
  };

  const fetchUsers = useMemo(
    () => getUsersAutocompleteFn(0),
    [getUsersAutocompleteFn],
  );

  return (
    <li key={userId} className={UsersPermissionItemStyles.Item}>
      <FormSelectAsyncContainer
        label="User name"
        name={`users.${index}.userId`}
        fetchData={fetchUsers}
        disabled={!isNew}
      />
      <FormSelectContainer
        name={`users[${index}].status`}
        label="Status"
        options={accountStatusesOptions}
      />
      <FormCheckboxContainer
        name={`users[${index}].overrideAccountStatus`}
        label="Override"
      />
      <div className={UsersPermissionItemStyles.RemoveButtonContainer}>
        <IconButton
          iconName="DeleteIcon"
          iconColor="radical"
          iconSize={24}
          onClick={handleRemove}
        />
      </div>
    </li>
  );
};
