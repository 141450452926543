import { useMemo } from 'react';

import { useGetTradeTypesQuery } from '~/api/types/types.api';

import {
  COUNTER_PARTY_OPTIONS,
  SETTLEMENT_COUNTER_PARTY_OPTIONS,
  SIDE_OPTIONS,
} from '../constants';

import { useExecutionSchemesOptions } from './useExecutionSchemesOptions';

export function useOptions() {
  const { executionSchemesOptions, isExecutionSchemesLoading } =
    useExecutionSchemesOptions();

  const { data: tradeTypes } = useGetTradeTypesQuery();

  const options = useMemo(
    () => ({
      counterparty: COUNTER_PARTY_OPTIONS,
      executionSchemeId: executionSchemesOptions || [],
      settlementCounterparty: SETTLEMENT_COUNTER_PARTY_OPTIONS,
      side: SIDE_OPTIONS,
      tradeType: tradeTypes
        ? tradeTypes?.map((type) => ({ label: type, value: type }))
        : [],
    }),
    [executionSchemesOptions, tradeTypes],
  );

  return { options, isExecutionSchemesLoading };
}
