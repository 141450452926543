import { isEqual, isFunction, pick } from 'lodash';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Bookmarks,
  IconButton,
  Table,
  useTableData,
  Notification,
  Tooltip,
  Modal,
  OnSaveEditableRow,
} from 'react-ui-kit-exante';
import { ActionButtonProps } from 'react-ui-kit-exante/build/Components/Table/ActionButton/ActionButton.types';

import {
  FetchTradesParams,
  useGetCoreUserQuery,
  useGetCurrentUserAccessRightsQuery,
  useLazyGetTradesForExportQuery,
  useLazyGetTradesQuery,
  useRollbackTradeMutation,
  useUpdateTradeMutation,
} from '~/api';
import { defaultTradesResponse } from '~/api/trades/trades.api.constants';
import {
  useGetAccountTypesQuery,
  useGetExecutionCounterPartyQuery,
  useGetLegalEntityTypesQuery,
  useGetSettlementCounterPartyQuery,
  useGetTradeTypesQuery,
} from '~/api/types/types.api';
import { EMPTY_ARRAY, NO_DATA_HEIGHT } from '~/constants';
import { prepareInitialSelectedColumns } from '~/containers/PositionsContainer/hooks/useSelectedColumns/helpers';
import {
  useAccountsAutocomplete,
  useLogHandleTime,
  usePrevious,
  useSyncTableViewParams,
  useUsersAutocomplete,
} from '~/hooks';
import { getDefaultBookmarkResponse } from '~/hooks/useBookmark/helpers';
import { IBookmarkResponseProps } from '~/hooks/useBookmark/types';
import { RollbackIcon } from '~/images/icons/Rollback';
import { symbolsService } from '~/resources';
import { TRADE_ADD_PATH, TRADE_IMPORT_PATH } from '~/routes';
import { WithBookmarks } from '~/shared/components/WithBookmarks';
import {
  calculateCountOfPages,
  clearTableLocalStorageForActionColumn,
  getIsFieldExistsAndEmpty,
  prepareParamsWithAccountId,
  sendNotification,
  transformVariantsToSelectOptions,
} from '~/shared/utils';
import { hasAllRequiredFields } from '~/shared/utils/hasAllRequiredFields';
import { selectTypes } from '~/store/types';
import { TParams } from '~/types/api';
import { IRefreshActiveTabQuery } from '~/types/refetch';
import { ITrade, ITradeState } from '~/types/trades';

import { DEFAULT_SORTING_TS } from '../TransactionsContainer/sorting';

import { TABLE_ID } from './constants';
import {
  DISPLAYED_COLUMN_KEYS,
  getAdditionalFilters,
  getColumns,
  getDefaultFilters,
} from './filters';

interface ITradesContainerProps {
  entity?: string;
  globalFilters?: Record<string, unknown>;
  tableId?: string;
  updateRefetch?: (state: IRefreshActiveTabQuery) => void;
  withAddButton?: boolean;
}

const isValidRequestParams = (
  value: Record<string, unknown>,
): value is FetchTradesParams => {
  return hasAllRequiredFields<FetchTradesParams>(value, ['fromTo']);
};

export const Trades: FC<ITradesContainerProps & IBookmarkResponseProps> = ({
  entity = 'trades-list',
  globalFilters,
  updateRefetch,
  tableId = TABLE_ID,
  withAddButton = true,
  selectedBookmark,
  handleSaveBookmark,
  handleSaveAsNewBookmark,
  handleShareBookmark,
  handleDeleteBookmark,
}) => {
  const [rollbackTrade] = useRollbackTradeMutation();
  const navigate = useNavigate();
  const { data: types } = useSelector(selectTypes);
  const { setStartHandleTime, logHandleTime } = useLogHandleTime(entity);
  const [fetchTrades] = useLazyGetTradesQuery();
  const [fetchTradesForExport] = useLazyGetTradesForExportQuery();
  const [updateTrade, { isLoading: isUpdateTradeLoading }] =
    useUpdateTradeMutation();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [rollbackData, setRollbackData] = useState<null | {
    orderId: string;
    orderPosition: number;
  }>(null);
  const getUsersAutocompleteFn = useUsersAutocomplete();
  const { data: tradeTypes } = useGetTradeTypesQuery();
  const { data: legalEntity } = useGetLegalEntityTypesQuery();
  const { data: { userId } = {} } = useGetCurrentUserAccessRightsQuery();
  const { data: currentUser } = useGetCoreUserQuery({ id: Number(userId) });
  const currentUserHasAllBrandingPermissions =
    currentUser?.info.brandingPermission === 'ALL';
  const { data: settlementCounterpartyTypes } =
    useGetSettlementCounterPartyQuery();
  const { data: executionCounterpartyTypes } =
    useGetExecutionCounterPartyQuery();

  const getTrades = useCallback(
    async ({ params }: { params: Record<string, unknown> }) => {
      const isEmptyAccountsList =
        globalFilters && getIsFieldExistsAndEmpty(globalFilters, 'accountId');

      if (isEmptyAccountsList) {
        return defaultTradesResponse;
      }

      const preparedParams = prepareParamsWithAccountId({
        ...globalFilters,
        ...params,
      });

      setStartHandleTime();

      if (isValidRequestParams(preparedParams)) {
        const { data } = await fetchTrades(preparedParams);

        return data || defaultTradesResponse;
      }

      return defaultTradesResponse;
    },

    [globalFilters, setStartHandleTime, fetchTrades],
  );
  const defaultFilters = useMemo(() => getDefaultFilters, []);
  const tableDataArgs = useMemo(
    () => ({
      data: { onFetch: getTrades },
      filters: { getDefaultFilters: defaultFilters, required: ['fromTo'] },
      tableId,
      saveViewParamsAfterLeave: true,
      sorting: { getDefaultSorting: () => DEFAULT_SORTING_TS },
    }),
    [defaultFilters, getTrades, tableId],
  );

  const {
    data,
    limit,
    setLimit,
    setPage,
    page,
    isLoading,
    setFilter,
    removeFilter,
    resetFilters,
    setSorting,
    filters,
    fetchData: refetch,
  } = useTableData<ITradeState>(tableDataArgs);
  const getAccountsAutocomplete = useAccountsAutocomplete();

  useEffect(() => {
    if (isFunction(updateRefetch)) {
      updateRefetch({
        refetch,
        isLoading,
      });
    }
  }, [updateRefetch, refetch, isLoading]);

  const total = data?.pagination.total || 0;
  const pageCount = useMemo(
    () => calculateCountOfPages(total, limit),
    [limit, total],
  );

  const previousDataTrades = usePrevious(data?.trades);

  const fetchSymbols = useMemo(
    () => symbolsService.autoCompleteSearchSymbols.bind(null, 0),
    [],
  );

  const fetchUsers = useMemo(
    () => getUsersAutocompleteFn(),
    [getUsersAutocompleteFn],
  );
  const columns = useMemo(
    () =>
      getColumns({
        onFilter: setFilter,
        onRemove: removeFilter,
        types: {
          ...types,
          tradeType: tradeTypes || [],
          legalEntity: legalEntity?.values || [],
          settlementCounterpartyTypes:
            settlementCounterpartyTypes?.values || [],
          executionCounterpartyTypes: executionCounterpartyTypes?.values || [],
        },
        fetchAccounts: getAccountsAutocomplete(),
        fetchSymbols,
        fetchUsers,
      }),
    [
      setFilter,
      removeFilter,
      types,
      tradeTypes,
      legalEntity?.values,
      settlementCounterpartyTypes?.values,
      executionCounterpartyTypes?.values,
      getAccountsAutocomplete,
      fetchSymbols,
      fetchUsers,
    ],
  );

  const exportTableParams = useMemo(
    () => ({
      type: 'server' as const,
      onFetch: async (params: TParams) => {
        const selectedColumns = prepareInitialSelectedColumns(
          columns,
          ['tradesTable-id-columns'],
          DISPLAYED_COLUMN_KEYS,
        );

        const preparedParams = prepareParamsWithAccountId({
          ...globalFilters,
          ...params,
        });

        if (
          hasAllRequiredFields<FetchTradesParams>(preparedParams, ['fromTo'])
        ) {
          const { data: tradesForExport, error } = await fetchTradesForExport({
            params: {
              ...preparedParams,
              fields: Object.keys(selectedColumns).filter((column) => column),
            },
          });

          if (error || !tradesForExport) {
            return [];
          }

          return tradesForExport;
        }

        return [];
      },
    }),
    [globalFilters, columns, fetchTradesForExport],
  );

  const { data: accountTypes } = useGetAccountTypesQuery();
  const accountTypesOptions = transformVariantsToSelectOptions(
    accountTypes?.values,
  );

  const additionalFilters = useMemo(
    () =>
      getAdditionalFilters({
        onFilter: setFilter,
        onRemove: removeFilter,
        defaultFilters: getDefaultFilters(),
        accountTypes: accountTypesOptions,
      }),
    [removeFilter, setFilter, accountTypesOptions],
  );

  const filterProps = useMemo(
    () => ({
      removeAllFilters: resetFilters,
      additionalFilters,
      filters,
      manualFilters: true,
      required: ['fromTo'],
    }),
    [additionalFilters, filters, resetFilters],
  );
  const serverPaginationProps = useMemo(
    () => ({
      pageSize: limit,
      setPage,
      setPageSize: setLimit,
      pageIndex: page,
      total,
      pageCount,
    }),
    [limit, page, pageCount, setLimit, setPage, total],
  );

  useSyncTableViewParams({ pageCount, setPage, tableId });

  const goToAddTradePage = useCallback(() => {
    navigate(TRADE_ADD_PATH, {
      state: { previousPath: window.location.href },
    });
  }, [navigate]);

  const goToBulkImport = useCallback(() => {
    navigate(TRADE_IMPORT_PATH, {
      state: { previousPath: window.location.href },
    });
  }, [navigate]);

  const additionalActions: ActionButtonProps[] = useMemo(() => {
    const actions: ActionButtonProps[] = [];

    if (currentUserHasAllBrandingPermissions) {
      actions.push({
        component: (
          <IconButton
            iconName="DownloadIcon"
            iconColor="action"
            iconSize={24}
            label="Bulk import"
            onClick={goToBulkImport}
          />
        ),
      });
    }

    if (withAddButton && currentUserHasAllBrandingPermissions) {
      actions.push({
        component: (
          <IconButton
            iconName="AddIcon"
            iconColor="action"
            iconSize={24}
            label="Add trade"
            onClick={goToAddTradePage}
          />
        ),
      });
    }

    return actions;
  }, [
    currentUserHasAllBrandingPermissions,
    goToAddTradePage,
    goToBulkImport,
    withAddButton,
  ]);

  useEffect(() => {
    if (data?.trades && !isEqual(previousDataTrades, data?.trades)) {
      logHandleTime();
    }
  }, [logHandleTime, data, previousDataTrades]);

  const bookmarkComponent = useMemo(() => {
    if (globalFilters) {
      return null;
    }

    return (
      <Bookmarks
        initialValues={selectedBookmark}
        onSave={(name) => handleSaveBookmark(name, filters)}
        onSaveAsNew={(name) => handleSaveAsNewBookmark(name, filters)}
        onShare={handleShareBookmark}
        onDelete={handleDeleteBookmark}
      />
    );
  }, [
    filters,
    globalFilters,
    handleSaveBookmark,
    handleSaveAsNewBookmark,
    handleShareBookmark,
    handleDeleteBookmark,
    selectedBookmark,
  ]);

  const displayedColumnKeys = useMemo(
    () =>
      selectedBookmark.columns.length
        ? selectedBookmark.columns
        : DISPLAYED_COLUMN_KEYS,
    [selectedBookmark.columns],
  );

  const successCallback = useCallback(() => {
    sendNotification('Trade has been updated', 'success');
  }, []);

  const errorCallback = useCallback(() => {
    sendNotification('Error updating trade', 'error');
  }, []);

  const onSaveRowHandler: OnSaveEditableRow<ITrade> = useCallback(
    async (_, newRow) => {
      const result = await updateTrade(
        pick(newRow, [
          'orderId',
          'orderPos',
          'clientComment',
          'internalComment',
        ]),
      );

      refetch();

      if (result) {
        successCallback();
      } else {
        errorCallback();
      }
    },
    [refetch, errorCallback, successCallback, updateTrade],
  );

  useEffect(() => {
    clearTableLocalStorageForActionColumn(tableId);
  }, [tableId]);

  const handleRollbackTrade = useCallback(async () => {
    if (!rollbackData) {
      return;
    }

    setShowConfirmModal(false);

    const res = await rollbackTrade({
      orderId: rollbackData.orderId,
      orderPosition: rollbackData.orderPosition,
    });

    if (!('error' in res)) {
      Notification.success({
        title: 'Trade was rolled back',
      });
    }

    setRollbackData(null);
  }, [rollbackData, rollbackTrade]);

  return (
    <>
      <Table
        additionalActions={additionalActions}
        columns={columns}
        data={data?.trades || EMPTY_ARRAY}
        defaultSortBy={DEFAULT_SORTING_TS}
        displayedColumnKeys={displayedColumnKeys}
        exportTableParams={exportTableParams}
        filteringProps={filterProps}
        filtersRightPanelComponent={bookmarkComponent}
        hasFilters
        hasPagination
        isFlexLayout
        isLoading={isLoading || isUpdateTradeLoading}
        isPinnedHeader
        manualSortBy
        noDataHeight={NO_DATA_HEIGHT}
        onSort={setSorting}
        {...(currentUserHasAllBrandingPermissions
          ? {
              rowActions: {
                show: true,
                onSave: onSaveRowHandler,
                additionalActions: [
                  {
                    label: (
                      <Tooltip title="Rollback">
                        <RollbackIcon />
                      </Tooltip>
                    ),
                    title: 'Rollback',
                    order: 0,
                    onClick: ({ orderId, orderPos }: ITrade) => {
                      setRollbackData({
                        orderId,
                        orderPosition: orderPos,
                      });
                      setShowConfirmModal(true);
                    },
                  },
                ],
                order: 1,
              },
            }
          : {})}
        saveColumnOrder
        saveViewParamsAfterLeave
        serverPaginationProps={serverPaginationProps}
        showScrollbar
        showTableInfo
        tableId={tableId}
        title="Trades"
      />
      <Modal
        title=""
        isOpened={showConfirmModal}
        onClose={() => {
          setShowConfirmModal(false);
          setRollbackData(null);
        }}
        confirmButton={{
          handleConfirm: handleRollbackTrade,
          confirmButtonName: 'Rollback',
        }}
        cancelButton={{
          cancelButtonName: 'Cancel',
        }}
        keepMounted={false}
      >
        Rollback trade?
      </Modal>
    </>
  );
};

export const TradesContainer: FC<ITradesContainerProps> = ({
  entity = 'trades-list',
  globalFilters,
  tableId = TABLE_ID,
  updateRefetch,
  withAddButton = true,
}) => {
  if (globalFilters) {
    const {
      selectedBookmark,
      handleSaveBookmark,
      handleSaveAsNewBookmark,
      handleShareBookmark,
      handleDeleteBookmark,
    } = getDefaultBookmarkResponse('Trades');

    return (
      <Trades
        entity={entity}
        globalFilters={globalFilters}
        tableId={tableId}
        updateRefetch={updateRefetch}
        withAddButton={withAddButton}
        selectedBookmark={selectedBookmark}
        handleSaveBookmark={handleSaveBookmark}
        handleSaveAsNewBookmark={handleSaveAsNewBookmark}
        handleShareBookmark={handleShareBookmark}
        handleDeleteBookmark={handleDeleteBookmark}
      />
    );
  }

  return (
    <WithBookmarks
      component={Trades}
      entity={entity}
      globalFilters={globalFilters}
      pageName="Trades"
      tableId={tableId}
      updateRefetch={updateRefetch}
      withAddButton={withAddButton}
    />
  );
};
