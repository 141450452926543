import { styled, TDefaultTheme } from 'react-ui-kit-exante';

export const StyledPanel = styled('div')(() => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: '30px',
}));

export const StyledColumn = styled('div')(
  ({ theme }: { theme: TDefaultTheme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    border: `1px solid ${theme?.color.line.primary}`,
    height: 'fit-content',

    'div[data-test-id="table__body--cell"] input': {
      color: theme.color.typo.primary,
    },
  }),
);

export const StyledCheckboxes = styled('div')(
  ({ theme }: { theme: TDefaultTheme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    border: `1px solid ${theme?.color.line.primary}`,
    height: 'fit-content',
    padding: '20px',
    minWidth: '300px',
  }),
);
