import { useLayoutEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import {
  BrowserRouter,
  Route,
  Routes,
  Router,
  Navigate,
} from 'react-router-dom';
import {
  NotificationProvider,
  ThemeProvider,
  useTheme,
  GlobalStyles,
} from 'react-ui-kit-exante';

import { useGetCurrentUserAccessRightsQuery } from '~/api';
import {
  AccountPage,
  AuditLogPage,
  ClientPage,
  CommissionRules,
  Commissions,
  GlobalSummaryByAccountsPage,
  GlobalSummaryByPositionPage,
  GlobalSummaryCurrencyRisk,
  GlobalSummaryPage,
  InterCommodityFormPage,
  InterCommodityPage,
  InterestRatesPage,
  LegalEntityPage,
  Limits,
  MirroringRulePage,
  NewUserPage,
  Overnights,
  SwaggerPage,
  SymbolPermissionsPage,
  TradeAddPage,
  TransactionAddPage,
  UnderlyingSettings,
  UserManagementPage,
  UserPage,
  TransferAddPage,
  ExpiredInstruments,
} from '~/pages';
import { RiskArrays } from '~/pages/RiskArrays';
import {
  ACCOUNTS_PATH,
  ACCOUNT_PATH,
  AUDIT_LOG_PATH,
  CLIENTS_PATH,
  CLIENT_ADD_PATH,
  CLIENT_PATH,
  COMMISSIONS_PATH,
  COMMISSION_RULES_PATH,
  GLOBAL_SUMMARY_BY_ACCOUNTS_PATH,
  GLOBAL_SUMMARY_BY_POSITION_SIDE_PATH,
  GLOBAL_SUMMARY_CURRENCY_RISK,
  GLOBAL_SUMMARY_PATH,
  INTER_COMMODITY_PATH,
  INTERESTS_PATH,
  LEGAL_ENTITIES_PATH,
  LEGAL_ENTITY_ADD_PATH,
  LEGAL_ENTITY_UPDATE_PATH,
  LEGAL_ENTITY_UPDATE_REDIRECT,
  LIMITS_PATH,
  MIRRORING_RULES_PATH,
  MIRRORING_RULE_ADD_PATH,
  MIRRORING_RULE_PATH,
  ACCOUNT_ADD_PATH,
  USER_ADD_PATH,
  OVERNIGHTS_PATH,
  RISK_ARRAYS_PATH,
  SWAGGERS_PATH,
  SYMBOL_PERMISSIONS_PATH,
  TRADES_PATH,
  TRADE_ADD_PATH,
  TRADE_IMPORT_PATH,
  TRANSACTIONS_PATH,
  TRANSACTION_ADD_PATH,
  TRANSACTION_IMPORT_PATH,
  USERS_INTERNAL_PATH,
  USERS_PATH,
  USER_INTERNAL_PATH,
  USER_PATH,
  INTER_COMMODITY_ADD_PATH,
  INTER_COMMODITY_UPDATE_PATH,
  INTER_COMMODITY_UPDATE_REDIRECT,
  UNDERLYING_SETTINGS,
  TRANSFER_ADD_PATH,
  EXPIRED_INSTRUMENTS,
} from '~/routes';
import { getBaseName } from '~/shared/utils/getBaseName';

// todo move all containers to pages folder if they are not there
import { AccountsContainer } from '../AccountsContainer';
import { ClientAddContainer } from '../ClientAddContainer';
import { ClientsContainer } from '../ClientsContainer';
import { LegalEntitiesContainer } from '../LegalEntitiesContainer';
import { MirroringRuleContainer } from '../MirroringRuleContainer';
import { NewAccountContainer } from '../NewAccountContainer';
import { NewMirroringRuleContainer } from '../NewMirroringRuleContainer';
import { SessionRegistrationContainer } from '../SessionRegistrationContainer';
import { TradeImportContainer } from '../TradeImportContainer';
import { TradesContainer } from '../TradesContainer';
import { TransactionImportContainer } from '../TransactionImportContainer';
import { TransactionsContainer } from '../TransactionsContainer';
import { UsersContainer } from '../UsersContainer';
import { VersionContainer } from '../VersionContainer';

import { ApplicationWrapper, ErrorFallback } from './components';
import { Header } from './components/Header';
import { useStoreRequests } from './useStoreRequests';

import '../../styles/Global.css';

const InnerComponent = () => {
  const { data: currentUserPermissions } = useGetCurrentUserAccessRightsQuery();

  useStoreRequests();

  if (!currentUserPermissions) {
    return null;
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Routes>
        <Route path={ACCOUNTS_PATH} element={<AccountsContainer />} />
        <Route path={ACCOUNT_ADD_PATH} element={<NewAccountContainer />} />
        <Route path={ACCOUNT_PATH} element={<AccountPage />} />

        <Route path={AUDIT_LOG_PATH} element={<AuditLogPage />} />

        <Route path={CLIENTS_PATH} element={<ClientsContainer />} />
        <Route path={CLIENT_PATH} element={<ClientPage />} />
        <Route path={CLIENT_ADD_PATH} element={<ClientAddContainer />} />

        <Route path={COMMISSIONS_PATH} element={<Commissions />} />
        <Route path={COMMISSION_RULES_PATH} element={<CommissionRules />} />

        <Route
          path={GLOBAL_SUMMARY_PATH}
          element={<GlobalSummaryPage isAggregatedView />}
        />
        <Route
          path={GLOBAL_SUMMARY_BY_ACCOUNTS_PATH}
          element={<GlobalSummaryByAccountsPage />}
        />
        <Route
          path={GLOBAL_SUMMARY_BY_POSITION_SIDE_PATH}
          element={
            <GlobalSummaryByPositionPage isAggregatedView isByPositionSide />
          }
        />
        <Route
          path={GLOBAL_SUMMARY_CURRENCY_RISK}
          element={<GlobalSummaryCurrencyRisk isCurrencyRisk />}
        />

        <Route path={INTERESTS_PATH} element={<InterestRatesPage />} />

        <Route path={INTER_COMMODITY_PATH} element={<InterCommodityPage />} />
        <Route
          path={INTER_COMMODITY_ADD_PATH}
          element={<InterCommodityFormPage />}
        />
        <Route
          path={INTER_COMMODITY_UPDATE_PATH}
          element={<InterCommodityFormPage />}
        />
        <Route
          path={INTER_COMMODITY_UPDATE_REDIRECT}
          element={<Navigate to={INTER_COMMODITY_PATH} replace />}
        />

        <Route path={LEGAL_ENTITY_ADD_PATH} element={<LegalEntityPage />} />
        <Route path={LEGAL_ENTITY_UPDATE_PATH} element={<LegalEntityPage />} />
        <Route
          path={LEGAL_ENTITY_UPDATE_REDIRECT}
          element={<Navigate to={LEGAL_ENTITIES_PATH} replace />}
        />
        <Route
          path={LEGAL_ENTITIES_PATH}
          element={<LegalEntitiesContainer />}
        />

        <Route path={USER_ADD_PATH} element={<NewUserPage />} />

        <Route
          path={MIRRORING_RULES_PATH}
          element={<MirroringRuleContainer />}
        />
        <Route
          path={MIRRORING_RULE_ADD_PATH}
          element={<NewMirroringRuleContainer />}
        />
        <Route path={MIRRORING_RULE_PATH} element={<MirroringRulePage />} />

        <Route path={OVERNIGHTS_PATH} element={<Overnights />} />

        <Route
          path={SYMBOL_PERMISSIONS_PATH}
          element={<SymbolPermissionsPage />}
        />

        <Route path={TRADES_PATH} element={<TradesContainer />} />
        <Route path={TRADE_ADD_PATH} element={<TradeAddPage />} />
        <Route path={TRADE_IMPORT_PATH} element={<TradeImportContainer />} />

        <Route path={TRANSACTIONS_PATH} element={<TransactionsContainer />} />
        <Route path={TRANSACTION_ADD_PATH} element={<TransactionAddPage />} />
        <Route
          path={TRANSACTION_IMPORT_PATH}
          element={<TransactionImportContainer />}
        />

        <Route path={USERS_INTERNAL_PATH} element={<UserManagementPage />} />
        <Route path={USERS_PATH} element={<UsersContainer />} />
        <Route path={USER_INTERNAL_PATH} element={<UserPage />} />
        <Route path={USER_PATH} element={<UserPage />} />

        <Route path={RISK_ARRAYS_PATH} element={<RiskArrays />} />

        <Route path={SWAGGERS_PATH} element={<SwaggerPage />} />

        <Route path={LIMITS_PATH} element={<Limits />} />

        <Route
          path={window.runUIhistoryContainer ? 'bo/*' : '*'}
          element={<Navigate to={ACCOUNTS_PATH} replace />}
        />
        <Route path={UNDERLYING_SETTINGS} element={<UnderlyingSettings />} />
        <Route path={TRANSFER_ADD_PATH} element={<TransferAddPage />} />
        <Route path={EXPIRED_INSTRUMENTS} element={<ExpiredInstruments />} />
      </Routes>
    </ErrorBoundary>
  );
};

export const ApplicationContainer = () => {
  const history = window.runUIhistoryContainer;
  const theme = useTheme();
  const [navigator, setNavigator] = useState({
    action: history?.action,
    location: history?.location,
  });

  useLayoutEffect(() => {
    if (history) {
      history.listen(() => {
        return setNavigator({
          action: history.action,
          location: history.location,
        });
      });
    }
  }, [history]);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <NotificationProvider />
      {window.runUIhistoryContainer ? (
        <Router
          location={navigator.location}
          navigationType={navigator.action}
          navigator={history}
        >
          <InnerComponent />
        </Router>
      ) : (
        <SessionRegistrationContainer>
          <BrowserRouter basename={getBaseName()}>
            <Header />
            <ApplicationWrapper>
              <InnerComponent />
              <VersionContainer />
            </ApplicationWrapper>
          </BrowserRouter>
        </SessionRegistrationContainer>
      )}
    </ThemeProvider>
  );
};
