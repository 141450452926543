import { useCallback, useEffect, useRef } from 'react';
import { UseFormSetValue, UseFormResetField } from 'react-hook-form';

import { symbolsService } from '~/resources';
import { ISymbolIdInfoResponse } from '~/types/symbols';

import { DEFAULT_VALUES } from '../constants';
import { TradeFormValues } from '../types';

export function useBrokerClientAccounts(
  getValues: (payload?: string | string[]) => typeof DEFAULT_VALUES,
  setValue: UseFormSetValue<TradeFormValues>,
  resetField: UseFormResetField<TradeFormValues>,
  counterPartyValue: string,
  settlementCounterpartyValue: string,
) {
  const brokerClientAccounts = useRef<
    ISymbolIdInfoResponse['brokerClientAccounts'] | null
  >(null);

  const updateBrokerIds = useCallback(() => {
    if (brokerClientAccounts.current) {
      const {
        nonSwitched: { counterparty },
      } = getValues();

      switch (counterparty) {
        case 'INTERNAL:XXX':
          setValue(
            'nonSwitched.brokerAccountId',
            brokerClientAccounts.current[0].account,
          );
          setValue(
            'nonSwitched.brokerClientId',
            brokerClientAccounts.current[0].clientId,
          );
          break;
        case 'INTERNAL:XXX2':
          setValue(
            'nonSwitched.brokerAccountId',
            brokerClientAccounts.current[1].account,
          );
          setValue(
            'nonSwitched.brokerClientId',
            brokerClientAccounts.current[1].clientId,
          );
          break;
        default:
          resetField('nonSwitched.brokerAccountId');
          resetField('nonSwitched.brokerClientId');
      }
    }
  }, [getValues, resetField, setValue]);

  const updateSettlementBrokerAccountIds = useCallback(() => {
    if (brokerClientAccounts.current) {
      const {
        nonSwitched: { settlementCounterparty },
      } = getValues();

      if (settlementCounterparty === 'INTERNAL') {
        setValue(
          'nonSwitched.settlementBrokerAccountId',
          brokerClientAccounts.current[0].account,
        );
        setValue(
          'nonSwitched.settlementBrokerClientId',
          brokerClientAccounts.current[0].clientId,
        );
      } else {
        resetField('nonSwitched.settlementBrokerAccountId');
        resetField('nonSwitched.settlementBrokerClientId');
      }
    }
  }, [getValues, resetField, setValue]);

  const changeBrokerClientAccountsHandle = useCallback(
    async (value: string) => {
      const symbolIdInfo = await symbolsService.getSymbolIdInfo({
        symbolId: value,
        fields: ['brokerClientAccounts'],
      });

      if (symbolIdInfo) {
        brokerClientAccounts.current = symbolIdInfo.brokerClientAccounts;

        updateBrokerIds();
        updateSettlementBrokerAccountIds();
      }
    },
    [updateBrokerIds, updateSettlementBrokerAccountIds],
  );

  useEffect(() => {
    if (counterPartyValue) {
      updateBrokerIds();
    }
  }, [updateBrokerIds, counterPartyValue]);

  useEffect(() => {
    if (settlementCounterpartyValue) {
      updateSettlementBrokerAccountIds();
    }
  }, [updateSettlementBrokerAccountIds, settlementCounterpartyValue]);

  return changeBrokerClientAccountsHandle;
}
