import { useEffect, useCallback, useState } from 'react';
import { AutocompleteAsync, Loader } from 'react-ui-kit-exante';

import { useLazySearchByAccountsQuery } from '~/api';
import { TInterestRatesMap } from '~/api/interestRates/types';
import { EMPTY_ARRAY } from '~/constants';
import { useAccountsAutocomplete } from '~/hooks';
import { ICurrency } from '~/types/currencies';
import { TGroupId } from '~/types/interestRates/interestRates';

import { useRatesByAccount, useRatesByGroup } from '../../hooks';
import { TSetRefetch } from '../../types';

import AccountSelectorStyles from './AccountSelector.module.css';

interface IAccountRatesProps {
  groupId: number | null;
  account: string;
  setAccount: (account: string) => void;
  currencies: ICurrency[];
  defaultRates: TInterestRatesMap;
  setRefetchCurrentTabQuery: TSetRefetch;
}

export const AccountSelector = ({
  groupId,
  account,
  setAccount,
  currencies,
  defaultRates,
  setRefetchCurrentTabQuery,
}: IAccountRatesProps) => {
  const [initialAccountLoading, setInitialAccountLoading] = useState(false);
  const getAccountsAutocompleteFn = useAccountsAutocomplete();
  const [getAccounts] = useLazySearchByAccountsQuery();

  const {
    ratesByGroup,
    loading: loadingRatesByGroup,
    refetch: refetchRatesByGroup,
  } = useRatesByGroup({
    group: groupId as TGroupId,
    defaultRates,
  });

  const { loading: loadingRatesByAccount, refetch: refetchRatesByAccount } =
    useRatesByAccount({
      accountId: account,
      defaultRates,
      ratesByGroup: groupId ? ratesByGroup : null,
      currencies,
    });

  useEffect(() => {
    setRefetchCurrentTabQuery({
      refetch: async () => {
        if (groupId) {
          await refetchRatesByGroup();
        }
        await refetchRatesByAccount();
      },
      isLoading: loadingRatesByAccount || loadingRatesByGroup,
    });
  }, [
    setRefetchCurrentTabQuery,
    refetchRatesByAccount,
    loadingRatesByAccount,
    loadingRatesByGroup,
    refetchRatesByGroup,
    groupId,
  ]);

  const getInitialAccount = useCallback(async () => {
    const response = await getAccounts({
      skip: 0,
      limit: 1,
      search: '',
      archived: false,
    });

    if ('error' in response || !response.data) {
      return {
        value: '',
        label: '',
      };
    }

    return response.data.options[0];
  }, [getAccounts]);

  const setInitialAccount = useCallback(async () => {
    setInitialAccountLoading(true);
    const { value: accountId } = await getInitialAccount();
    setAccount(accountId);
    setInitialAccountLoading(false);
  }, [getInitialAccount, setAccount]);

  useEffect(() => {
    if (!account) {
      setInitialAccount();
    }
  }, []);

  return (
    <div className={AccountSelectorStyles.Wrapper}>
      {initialAccountLoading ? (
        <Loader />
      ) : (
        <AutocompleteAsync
          fetchData={getAccountsAutocompleteFn()}
          onChange={(_, option) => {
            const { value = '' } = option || {};
            setAccount(value);
          }}
          options={EMPTY_ARRAY}
          placeholder="Account"
          value={account}
          sx={{
            minWidth: 280,
          }}
        />
      )}
    </div>
  );
};
