import { useContext } from 'react';

import { useGetAccountSummaryQuery } from '~/api';
import { DEFAULT_POOLING_INTERVAL_DATA } from '~/constants';
import { useCurrency } from '~/hooks';
import { formatDate } from '~/shared/utils';

import { accountPageContext } from '../../context';

export function useAccountSummary(accountId: string, archived = false) {
  const currency = useCurrency();
  const {
    accountSummary: {
      filters: { date, setDate, resetDate },
    },
  } = useContext(accountPageContext);

  const { data, isLoading, isFetching, refetch } = useGetAccountSummaryQuery(
    {
      id: accountId,
      date: date ? formatDate(date) : null,
      currency,
    },
    {
      skip: archived,
      pollingInterval: DEFAULT_POOLING_INTERVAL_DATA,
    },
  );

  return {
    currency,
    data,
    date,
    isLoading,
    setDate,
    resetDate,
    isFetching,
    refetch,
  };
}
