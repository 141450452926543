import { createApi } from '@reduxjs/toolkit/query/react';
import { get } from 'lodash';

import { baseQueryHandler } from '~/shared/utils';

import { commissionGroupsEndpoints } from './commissionsGroups.api.endpoints';

export const commissionGroupsApi = createApi({
  reducerPath: 'commissionGroupsApi',
  baseQuery: baseQueryHandler,
  endpoints: (builder) => ({
    getCommissionGroups: builder.query<
      { id: number; name: string }[],
      {
        ignoreForbiddenError?: boolean;
        onError?: (err: unknown) => unknown;
      } | void
    >({
      query: ({ ignoreForbiddenError, onError } = {}) => ({
        url: commissionGroupsEndpoints.base,
        ignoreForbiddenError,
        onError,
      }),
    }),
    addNewCommissionGroup: builder.mutation<
      {
        id: number;
        name: string;
      },
      {
        data: {
          name: string;
        };
        onError?: (err: unknown) => unknown;
      }
    >({
      query: (params) => ({
        url: commissionGroupsEndpoints.base,
        method: 'POST',
        data: params.data,
        onError: params.onError,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data: newGroup } = await queryFulfilled;

          dispatch(
            commissionGroupsApi.util.updateQueryData(
              'getCommissionGroups',
              { ignoreForbiddenError: true },
              (draft) => {
                draft.push(newGroup);
              },
            ),
          );
        } catch (e) {
          // todo
        }
      },
    }),
    removeCommissionGroup: builder.mutation<
      void,
      {
        data: {
          groupId: string;
        };
      }
    >({
      query: (params) => ({
        url: `${commissionGroupsEndpoints.base}/${params.data.groupId}`,
        method: 'DELETE',
      }),
      async onQueryStarted(
        { data: { groupId } },
        { dispatch, queryFulfilled },
      ) {
        try {
          await queryFulfilled;

          dispatch(
            commissionGroupsApi.util.updateQueryData(
              'getCommissionGroups',
              { ignoreForbiddenError: true },
              (draft) => {
                const filtered = draft.filter((t) => String(t.id) !== groupId);
                return filtered;
              },
            ),
          );
        } catch {
          // TODO
        }
      },
      transformErrorResponse: (e) => {
        const errorData = get(e, 'response.data.message', {
          message: '',
          description: { usedBy: [] },
        });
        const GROUP_IS_USED = 'Group is used';

        if (errorData.message === GROUP_IS_USED) {
          return `${GROUP_IS_USED} by following accounts ${errorData?.description?.usedBy?.join(
            ', ',
          )}`;
        }
        return e;
      },
    }),
  }),
});

export const {
  useGetCommissionGroupsQuery,
  useAddNewCommissionGroupMutation,
  useRemoveCommissionGroupMutation,
} = commissionGroupsApi;
