import { usePickUserPermissions } from '~/hooks';

export function useCanChangeTokens() {
  const userPermissions = usePickUserPermissions([
    'User passwords create/delete/change',
    'User permissions on services',
  ]);

  return (
    userPermissions['User passwords create/delete/change'].write &&
    userPermissions['User permissions on services'].write
  );
}
