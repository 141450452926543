import {
  useMemo,
  useCallback,
  useContext,
  Dispatch,
  SetStateAction,
} from 'react';

import { SaveButton } from '~/shared/components';

import { PasswordManagementContext } from '../../constants';
import { useCanChangeTokens } from '../useCanChangeTokens';
import { useSave } from '../useSave';

import { AddButton } from './components';

export function useActions(
  setDisplayAddTokenForm: Dispatch<SetStateAction<boolean>>,
  userId: number,
) {
  const userCanChangeTokens = useCanChangeTokens();

  const [state] = useContext(PasswordManagementContext);
  const { disableSaveButton } = state;

  const onAddButtonClickHandler = useCallback(() => {
    setDisplayAddTokenForm(true);
  }, [setDisplayAddTokenForm]);

  const saveData = useSave(userId);

  return useMemo(
    () =>
      userCanChangeTokens ? (
        <>
          <AddButton onClick={onAddButtonClickHandler} />
          <SaveButton disabled={disableSaveButton} onClick={saveData} />
        </>
      ) : undefined,
    [disableSaveButton, onAddButtonClickHandler, saveData, userCanChangeTokens],
  );
}
