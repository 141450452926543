import { createApi } from '@reduxjs/toolkit/query/react';
import { Notification } from 'react-ui-kit-exante';

import { baseQueryHandler, check403Error } from '~/shared/utils';

import { Statuses } from './constants';
import { endpoints } from './endpoints';
import {
  TRequestTaskIdParams,
  TRequestTaskStatusParams,
  TTaskIdResponse,
  TTaskStatusResponse,
} from './types';

export const globalSummaryTasksApi = createApi({
  reducerPath: 'globalSummaryTasksApi',
  baseQuery: baseQueryHandler,
  endpoints: (builder) => ({
    getTaskId: builder.query<TTaskIdResponse, TRequestTaskIdParams>({
      query: ({ date, ...params }) => ({
        url: `${endpoints.globalSummaryHistory}/${date}`,
        method: 'PUT',
        params,
      }),
    }),
    getTaskStatus: builder.query<TTaskStatusResponse, TRequestTaskStatusParams>(
      {
        query: ({ taskId }) => ({
          url: `${endpoints.tasks}/${taskId}`,
          ignoreForbiddenError: true,
        }),
        async onQueryStarted({ date }, { dispatch, queryFulfilled }) {
          try {
            const { data: response } = await queryFulfilled;
            const { status, error } = response;
            dispatch(
              globalSummaryTasksApi.util.updateQueryData(
                'getTaskId',
                { date },
                (draft) => {
                  const hasBeenCompleted = status === Statuses.Done || error;
                  if (hasBeenCompleted) {
                    draft.taskId = '';
                  }
                },
              ),
            );
          } catch {
            // TODO Log error
          }
        },
      },
    ),
  }),
});

export const { useGetTaskStatusQuery, useLazyGetTaskIdQuery } =
  globalSummaryTasksApi;
