import { isEqual } from 'lodash';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Bookmarks, Table, useTableData } from 'react-ui-kit-exante';

import { useLazyGetUsersQuery } from '~/api';
import { DEFAULT_PAGINATION_RESPONSE, NO_DATA_HEIGHT } from '~/constants';
import { useSyncTableViewParams, usePrevious, useLogHandleTime } from '~/hooks';
import { IBookmarkResponseProps } from '~/hooks/useBookmark/types';
import { USERS_INTERNAL_PATH } from '~/routes';
import { WithBookmarks } from '~/shared/components/WithBookmarks';
import { calculateCountOfPages, getTableId } from '~/shared/utils';
import { selectTypes } from '~/store/types';
import { IUser, IUsersState } from '~/types/users';

import {
  DISPLAYED_COLUMN_KEYS,
  getAdditionalFilters,
  getColumns,
  getDefaultFilters,
  predefinedFilters,
} from './filters';
import { DEFAULT_SORTING, getDefaultSorting } from './sorting';

export const UserManagement: FC<IBookmarkResponseProps> = ({
  selectedBookmark,
  handleSaveBookmark,
  handleSaveAsNewBookmark,
  handleShareBookmark,
  handleDeleteBookmark,
}) => {
  const { data: types } = useSelector(selectTypes);
  const navigate = useNavigate();
  const tableId = getTableId('UserManagement');
  const [fetchUsers] = useLazyGetUsersQuery();

  const { setStartHandleTime, logHandleTime } = useLogHandleTime(
    'users-management-list',
  );

  const getUsers = useCallback(
    async ({ params }: { params: Record<string, unknown> }) => {
      setStartHandleTime();

      const response = await fetchUsers({
        ...params,
        ...predefinedFilters,
      });

      if (response.error || !response.data) {
        return { users: [], pagination: DEFAULT_PAGINATION_RESPONSE };
      }

      return response.data;
    },
    [fetchUsers, setStartHandleTime],
  );

  const tableArgs = useMemo(
    () => ({
      data: { onFetch: getUsers },
      sorting: { getDefaultSorting },
      filters: {
        getDefaultFilters,
      },
      tableId,
      saveViewParamsAfterLeave: true,
    }),
    [getUsers, tableId],
  );

  const {
    data,
    limit,
    setLimit,
    setPage,
    page,
    isLoading,
    setFilter,
    removeFilter,
    resetFilters,
    setSorting,
    filters,
    params,
  } = useTableData<IUsersState>(tableArgs);

  const prevUsers = usePrevious(data?.users);

  const total = data?.pagination.total || 0;

  const pageCount = useMemo(
    () => calculateCountOfPages(total, limit),
    [limit, total],
  );

  const columns = useMemo(
    () =>
      getColumns({
        onFilter: setFilter,
        onRemove: removeFilter,
        types,
      }),
    [setFilter, removeFilter, types],
  );
  const additionalFilters = useMemo(
    () =>
      getAdditionalFilters({
        onFilter: setFilter,
        onRemove: removeFilter,
      }),
    [removeFilter, setFilter],
  );

  const filteringProps = useMemo(
    () => ({
      removeAllFilters: resetFilters,
      additionalFilters,
      filters,
      manualFilters: true,
    }),
    [additionalFilters, filters, resetFilters],
  );

  const handleRowClick = useCallback(
    ({ id }: IUser, index: number) =>
      navigate(`${USERS_INTERNAL_PATH}/${id}`, {
        state: {
          previousPath: window.location.href,
          requestParams: params,
          cursor: index,
        },
      }),
    [navigate, params],
  );

  useSyncTableViewParams({ pageCount, setPage, tableId });

  useEffect(() => {
    if (data?.users && !isEqual(prevUsers, data?.users)) {
      logHandleTime();
    }
  }, [data, prevUsers, logHandleTime]);

  const bookmarkComponent = useMemo(
    () => (
      <Bookmarks
        initialValues={selectedBookmark}
        onSave={(name) => handleSaveBookmark(name, filters)}
        onSaveAsNew={(name) => handleSaveAsNewBookmark(name, filters)}
        onShare={handleShareBookmark}
        onDelete={handleDeleteBookmark}
      />
    ),
    [
      filters,
      handleSaveBookmark,
      handleSaveAsNewBookmark,
      handleShareBookmark,
      handleDeleteBookmark,
      selectedBookmark,
    ],
  );

  const displayedColumnKeys = useMemo(
    () =>
      selectedBookmark.columns.length
        ? selectedBookmark.columns
        : DISPLAYED_COLUMN_KEYS,
    [selectedBookmark.columns],
  );

  return (
    <Table
      columns={columns}
      data={data?.users || []}
      defaultSortBy={DEFAULT_SORTING}
      displayedColumnKeys={displayedColumnKeys}
      filteringProps={filteringProps}
      filtersRightPanelComponent={bookmarkComponent}
      handleRowClick={handleRowClick}
      hasFilters
      hasPagination
      isFlexLayout
      isLoading={isLoading}
      isPinnedHeader
      manualSortBy
      noDataHeight={NO_DATA_HEIGHT}
      onSort={setSorting}
      saveColumnOrder
      saveViewParamsAfterLeave
      serverPaginationProps={{
        pageSize: limit,
        setPage,
        setPageSize: setLimit,
        pageIndex: page,
        total,
        pageCount,
      }}
      showScrollbar
      showTableInfo
      tableId={tableId}
      title="User management"
    />
  );
};

export const UserManagementPage = () => {
  const tableId = getTableId('UserManagement');

  return (
    <WithBookmarks
      component={UserManagement}
      pageName="User management"
      tableId={tableId}
    />
  );
};
