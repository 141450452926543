import { useCallback, useEffect, useRef } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { ToggleButtonGroup } from 'react-ui-kit-exante';

import { IOption } from '~/types/form';

interface IFormToggleButtonGroupContainerProps {
  alwaysSelected?: boolean;
  disabled?: boolean;
  exclusive?: boolean;
  fullWidth?: boolean;
  getValue?: (value: string) => void;
  label?: string;
  name: string;
  options: Array<IOption>;
}

export const FormToggleButtonGroupContainer = ({
  alwaysSelected = false,
  disabled = false,
  exclusive = false,
  fullWidth = false,
  getValue,
  label = '',
  name,
  options,
}: IFormToggleButtonGroupContainerProps) => {
  const formContext = useFormContext();
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    control: formContext.control,
    name,
  });
  const prevValue = useRef(value);

  const changeHandler = useCallback(
    (newValue: string) => {
      prevValue.current = value;
      onChange(newValue);

      if (getValue) {
        getValue(newValue);
      }
    },
    [getValue, value, onChange],
  );

  useEffect(() => {
    if (!value && alwaysSelected) {
      onChange(prevValue.current);
    }
  }, [alwaysSelected, onChange, value]);

  return (
    <ToggleButtonGroup
      disabled={disabled}
      error={!!error}
      exclusive={exclusive}
      fullWidth={fullWidth}
      label={label}
      message={error?.message}
      onChange={changeHandler}
      options={options}
      value={value}
    />
  );
};
