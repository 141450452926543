import { yupResolver } from '@hookform/resolvers/yup';
import { omitBy, isUndefined } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useData } from 'react-ui-kit-exante';

import { legalEntitiesService } from '~/resources';
import { LEGAL_ENTITY_UPDATE_PATH } from '~/routes';
import { flattenSelectedValues } from '~/shared/utils';
import { ILegalEntityFormData } from '~/types/legalEntities';

import { DEFAULT_VALUES } from '../constants';
import { FormMode } from '../types';
import { getValidationSchema } from '../validationSchema';

export function useLegalEntityForm(
  name: string | undefined,
  preCall: () => void,
) {
  const navigate = useNavigate();

  const formInstance = useForm<ILegalEntityFormData>({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(
      getValidationSchema(name ? FormMode.Edit : FormMode.Add),
    ),
  });

  const {
    formState: { isDirty, isSubmitting },
    getValues,
    reset,
  } = formInstance;

  const onSubmitHandler = useCallback(
    async (data: ILegalEntityFormData) => {
      if (name) {
        const result = await legalEntitiesService.updateLegalEntity(
          name,
          flattenSelectedValues(data),
        );

        if (result) {
          reset(omitBy(getValues(), isUndefined));
        }
      } else {
        const result = await legalEntitiesService.postLegalEntity(
          flattenSelectedValues<ILegalEntityFormData>(data),
        );

        if (result) {
          navigate(
            LEGAL_ENTITY_UPDATE_PATH.replace(':name', String(data.name)),
            {
              state: { previousPath: window.location.href },
            },
          );
        }
      }
    },
    [getValues, reset, name, navigate],
  );

  const submitHandle = formInstance.handleSubmit(onSubmitHandler);

  const getLegalEntityDetails = useCallback(async () => {
    if (name) {
      preCall();

      const legalEntityDetails =
        await legalEntitiesService.resolveLegalEntityDetail(name);

      if (legalEntityDetails) {
        reset(legalEntityDetails);
      }
    }
  }, [preCall, reset, name]);

  const queryOptions = useMemo(
    () => ({
      onFetch: getLegalEntityDetails,
    }),
    [getLegalEntityDetails],
  );

  const { fetchData, isLoading } = useData(queryOptions);

  useEffect(() => {
    if (name) {
      fetchData();
    }
  }, [name, fetchData]);

  return {
    formInstance,
    isDirty,
    isLoading,
    isSubmitting,
    submitHandle,
    fetchData,
  };
}
