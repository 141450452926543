import { styled, TDefaultTheme } from 'react-ui-kit-exante';

export const StyledContainer = styled('div')(() => ({
  rowGap: '20px',
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '50%',
}));

export const StyledHeader = styled('div')(
  ({ theme }: { theme: TDefaultTheme }) => ({
    backgroundColor: theme?.color.bg.primary,
    padding: '24px',
    marginBottom: '4px',

    button: {
      height: '24px',
      width: '24px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
);
