import { get, has } from 'lodash';
import {
  RefObject,
  Dispatch,
  SetStateAction,
  MouseEvent,
  useContext,
  useCallback,
  useState,
  useEffect,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { Table, TPropRowGetter, Notification } from 'react-ui-kit-exante';

import {
  useAddSymbolPermissionsGroupsMutation,
  useDeleteSymbolPermissionsGroupsMutation,
} from '~/api';
import { DEFAULT_SORT_TABLE_BY } from '~/constants';
import { IVirtualized } from '~/hooks';
import { LayersLayout } from '~/shared/components/LayersLayout';
import { WrapperLoader } from '~/shared/components/WrapperLoader';
import { getTableId } from '~/shared/utils';
import { FilterLayers, ISymbolTreeStructure } from '~/types/symbolPermissions';

import { ActionTypes, SymbolPermissionsContext } from '../../constants';
import { TSymbolPermissionsCellState, useFilters } from '../../hooks';
import { useColumns } from '../../hooks/useColumns';
import { FiltersContainer } from '../Filters';

import SymbolPermissionsStyles from './SymbolPermissions.module.css';

function isFiltersLayer(str: string): str is FilterLayers {
  const keys: string[] = Object.values(FilterLayers);
  return keys.includes(str);
}

interface ISymbolPermissionsProps {
  expanded: Record<string, unknown>;
  getRowProps: TPropRowGetter<ISymbolTreeStructure>;
  handleCellClick: ({
    column,
    row,
    tableState: { expanded },
    value,
    onChangeSource,
  }: TSymbolPermissionsCellState) => Promise<void>;
  handleSaveData: () => void;
  handleTableClick: (event: MouseEvent<HTMLDivElement>) => void;
  isLoading: boolean;
  isPermissionGroupChanged: boolean;
  onChangeHandler: (searchValue: string) => void;
  onRefresh: () => Promise<void>;
  reloadDataOnFilterChange: () => void;
  resetDataOnFilterChange: () => void;
  searchIsLoading: boolean;
  searchValue?: string;
  setBlockTableMessage: Dispatch<SetStateAction<string>>;
  tableData: ISymbolTreeStructure[];
  tableRef: RefObject<HTMLDivElement>;
  virtualized: IVirtualized;
  resetTable: () => void;
}

export const SymbolPermissions = ({
  expanded,
  searchValue,
  getRowProps,
  handleCellClick,
  handleSaveData,
  handleTableClick,
  isLoading,
  isPermissionGroupChanged,
  onChangeHandler,
  onRefresh,
  reloadDataOnFilterChange,
  resetDataOnFilterChange,
  searchIsLoading,
  setBlockTableMessage,
  tableData,
  tableRef,
  virtualized,
  resetTable,
}: ISymbolPermissionsProps) => {
  const [state, dispatch] = useContext(SymbolPermissionsContext);
  const [layer, setLayer] = useState(FilterLayers.Default);
  const [, setSearchParams] = useSearchParams();

  const {
    permissions: { changed: changedPermissions },
    filters: {
      select: { user, account },
    },
  } = state;

  const [addGroup] = useAddSymbolPermissionsGroupsMutation();
  const [deleteGroup] = useDeleteSymbolPermissionsGroupsMutation();

  const isSaveDisabled =
    !isPermissionGroupChanged && !Object.keys(changedPermissions).length;

  const columns = useColumns(handleCellClick);

  const LAYERS_OPTIONS = Object.values(FilterLayers);

  const {
    onChangeGroupHandler,
    onChangeLayerHandler,
    onChangeWithExpiredHandler,
  } = useFilters({
    resetDataOnFilterChange,
    setBlockTableMessage,
  });

  const handleChangeLayer = useCallback(
    (value: string) => {
      if (isFiltersLayer(value)) {
        setLayer(value);
        onChangeLayerHandler(value);

        setSearchParams((params) => {
          params.set('layer', value);

          return params;
        });
      }
    },
    [onChangeLayerHandler, setSearchParams],
  );

  const handleAddGroup = useCallback(
    async (groupName: string) => {
      const res = await addGroup({ name: groupName });

      if ('data' in res) {
        onChangeGroupHandler(res.data.id);

        Notification.success({
          title: 'Group was successfully added',
        });
      }
    },
    [addGroup, onChangeGroupHandler],
  );

  const handleDeleteGroup = useCallback(async () => {
    const selectedGroup = state.filters.select.group;
    if (selectedGroup) {
      const res = await deleteGroup({ id: selectedGroup });

      if (!has(res, 'error')) {
        Notification.success({
          title: 'Group was successfully deleted',
        });
      }

      dispatch({
        type: ActionTypes.FILTERS_GROUP_SET,
        payload: null,
      });
    }
  }, [deleteGroup, dispatch, state.filters.select.group]);

  const handleSettingShowExpired = useCallback(
    (checked: boolean) => {
      onChangeWithExpiredHandler(checked);
    },
    [onChangeWithExpiredHandler],
  );
  const [params] = useSearchParams();
  const initialLayer = params.get('layer');

  useEffect(() => {
    if (initialLayer && isFiltersLayer(initialLayer)) {
      handleChangeLayer(initialLayer);
    }
  }, []);

  return (
    <LayersLayout
      title="Symbol Permissions"
      tabs={LAYERS_OPTIONS}
      initialTab={
        initialLayer && isFiltersLayer(initialLayer) ? initialLayer : null
      }
      pageControls={
        layer !== FilterLayers.Default ? (
          <FiltersContainer
            layer={layer}
            reloadDataOnFilterChange={reloadDataOnFilterChange}
            resetDataOnFilterChange={resetDataOnFilterChange}
            resetTable={resetTable}
            setBlockTableMessage={setBlockTableMessage}
          />
        ) : null
      }
      searchParams={{
        onReset: resetDataOnFilterChange,
        onSearch: onChangeHandler,
        preventSearch: () => {
          if (layer === FilterLayers.Accounts && !account) {
            Notification.warning({ title: 'Please select account' });
            return true;
          }
          if (layer === FilterLayers.Users && !user) {
            Notification.warning({ title: 'Please select user' });
            return true;
          }
          return false;
        },
        initialSearchValue: searchValue,
      }}
      table={
        <WrapperLoader isLoading={isLoading} ref={tableRef}>
          <Table
            className={SymbolPermissionsStyles.Table}
            columns={columns}
            data={tableData}
            defaultSortBy={DEFAULT_SORT_TABLE_BY}
            expanded={expanded}
            getRowProps={getRowProps}
            handleCellClick={handleCellClick}
            isFlexLayout
            saveViewParamsAfterLeave
            tableId={getTableId('SymbolPermissions')}
            virtualized={virtualized}
          />
        </WrapperLoader>
      }
      onChangeLayer={handleChangeLayer}
      onDeleteGroup={handleDeleteGroup}
      onSave={handleSaveData}
      onAddGroup={handleAddGroup}
      onRefresh={onRefresh}
      loading={isLoading || searchIsLoading}
      showExpired={state.filters.withExpired}
      setShowExpired={handleSettingShowExpired}
      isSaveDisabled={isSaveDisabled}
      loaderParams={{
        onClickCapture: handleTableClick,
      }}
    />
  );
};
