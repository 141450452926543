import { mapKeys, omitBy } from 'lodash';

import { resolveAuditLogsQueryParams } from '~/resources/auditLog/constants';
import { TResolveAuditLogsParams } from '~/resources/auditLog/types';
import {
  createStringDateUTCWithoutTimezone,
  threeDaysAgo,
  today,
} from '~/shared/utils/dates';

import { IFetchParams } from './types';

const unsupportedFields = new Map([
  ['accountId', 'account'],
  ['clientId', 'account'],
]);

const renameUnsupportedFields = (fields: Record<string, unknown>) => {
  return mapKeys(fields, (_value, key) => {
    if (unsupportedFields.has(key)) {
      const supportedKey = unsupportedFields.get(key);

      if (supportedKey) {
        return supportedKey;
      }
    }
    return key;
  });
};

export const prepareParamsToService = (params: IFetchParams) => {
  const { page, limit, sorting, ...rest } = params;
  const offset = page * limit;

  const paramsObject: TResolveAuditLogsParams = {
    offset,
    limit,
    query: omitBy(
      renameUnsupportedFields(rest),
      (_value: unknown, key) => !resolveAuditLogsQueryParams.has(key),
    ),
  };

  if (Array.isArray(sorting) && Boolean(sorting.length)) {
    const [sortingParams] = sorting;
    const { desc, id } = sortingParams;

    paramsObject.order = desc ? 'desc' : 'asc';
    paramsObject.orderBy = id;
  }

  return paramsObject;
};

export function getDefaultFilters() {
  return {
    time: [
      createStringDateUTCWithoutTimezone(threeDaysAgo),
      createStringDateUTCWithoutTimezone(today),
    ],
  };
}
