import { IAutocompleteAsyncFetchDataResult } from 'react-ui-kit-exante';

import { EMPTY_ARRAY, FETCH_DATA_SIZE } from '~/constants';
import {
  calculateFetchData,
  IOptionsArguments,
  throwAbortError,
} from '~/shared/utils';
import { TSymbolIdInfoParams, TSymbolIdValueDateParams } from '~/types/symbols';
import { ITypesResponse } from '~/types/types';

import { SymbolsRepository } from './symbols.repository';

export class SymbolsService {
  public autoCompleteSearchSymbols = async (
    skip: number,
    search: string,
    options: IOptionsArguments,
  ): Promise<IAutocompleteAsyncFetchDataResult> => {
    try {
      const limit = FETCH_DATA_SIZE;

      const { data } = await SymbolsRepository.fetchSymbols(
        {
          limit,
          search,
          skip,
        },
        options,
      );

      const { pagination } = data;

      return {
        fetchData: calculateFetchData(
          skip,
          limit,
          pagination.total,
          this.autoCompleteSearchSymbols.bind(this, skip + limit),
        ),
        options: data.data.map(({ id }) => ({
          label: id,
          value: id,
        })),
      };
    } catch (error) {
      throwAbortError(error);
      return {
        fetchData: null,
        options: [],
      };
    }
  };

  public modern_autoCompleteSearchSymbols = async (
    skip: number,
    search: string,
    options: IOptionsArguments,
  ): Promise<IAutocompleteAsyncFetchDataResult> => {
    try {
      const limit = FETCH_DATA_SIZE;

      const { data } = await SymbolsRepository.modern_fetchSymbols(
        {
          limit,
          search,
          skip,
        },
        options,
      );

      const { pagination } = data;

      return {
        fetchData: calculateFetchData(
          skip,
          limit,
          pagination.total,
          this.autoCompleteSearchSymbols.bind(this, skip + limit),
        ),
        options: data.data.map((id) => ({
          label: id,
          value: id,
        })),
      };
    } catch (error) {
      throwAbortError(error);
      return {
        fetchData: null,
        options: [],
      };
    }
  };

  public getSymbolIdInfo = async (params: TSymbolIdInfoParams) => {
    try {
      const { data } = await SymbolsRepository.fetchSymbolIdInfo(params);

      return data;
    } catch (error) {
      throwAbortError(error);
      return null;
    }
  };

  public getSymbolValueDate = async (
    params: TSymbolIdValueDateParams,
  ): Promise<string | null> => {
    try {
      const {
        data: { valueDate },
      } = await SymbolsRepository.fetchSymbolValueDate(params);

      return valueDate;
    } catch (error) {
      throwAbortError(error);
      return null;
    }
  };

  public resolveSymbolTypes = async (): Promise<ITypesResponse> => {
    try {
      const { data } = await SymbolsRepository.fetchSymbolsTypes();

      return {
        values: data.values.map(function getSymbolType({ type }) {
          return type;
        }),
      };
    } catch (error) {
      return { values: EMPTY_ARRAY };
    }
  };
}
