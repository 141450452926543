import { FC, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Bookmarks,
  IconButton,
  Table,
  useTableData,
} from 'react-ui-kit-exante';

import {
  useGetAccountPurposeTypesQuery,
  useGetAccountTypesQuery,
} from '~/api/types/types.api';
import { EMPTY_ARRAY, NO_DATA_HEIGHT } from '~/constants';
import { IBookmarkResponseProps } from '~/hooks/useBookmark/types';
import { mirroringRulesService } from '~/resources';
import { getMirroringRulePageRoute, MIRRORING_RULE_ADD_PATH } from '~/routes';
import { WithBookmarks } from '~/shared/components/WithBookmarks';
import { getTableId, transformVariantsToSelectOptions } from '~/shared/utils';
import { TParams } from '~/types/api';
import { IMirroringRule, IMirroringRulesState } from '~/types/mirroringRules';

import MirroringRuleContainerStyles from './MirroringRuleContainer.module.css';
import { DISPLAYED_COLUMN_KEYS, getColumns } from './columns';
import { DEFAULT_SORTING } from './sorting';

export const MirroringRule: FC<IBookmarkResponseProps> = ({
  selectedBookmark,
  handleSaveBookmark,
  handleSaveAsNewBookmark,
  handleShareBookmark,
  handleDeleteBookmark,
}) => {
  const navigate = useNavigate();
  const { data: accountTypes } = useGetAccountTypesQuery();
  const accountTypesOptions = transformVariantsToSelectOptions(
    accountTypes?.values,
  );
  const { data: getAccountPurposeTypes } = useGetAccountPurposeTypesQuery();
  const accountPurposesOptions = transformVariantsToSelectOptions(
    getAccountPurposeTypes?.values,
  );
  const getMirroringRules = useCallback(
    ({ params }: { params: TParams }) =>
      mirroringRulesService.resolveMirroringRules(params),
    [],
  );
  const tableId = getTableId('MirroringRules');

  const tableArgs = useMemo(
    () => ({
      data: {
        onFetch: getMirroringRules,
      },
      tableId,
      saveViewParamsAfterLeave: true,
    }),
    [getMirroringRules, tableId],
  );

  const {
    data,
    isLoading,
    setFilter,
    removeFilter,
    resetFilters,
    filters,
    params,
  } = useTableData<IMirroringRulesState>(tableArgs);

  const columns = useMemo(
    () =>
      getColumns({
        onFilter: setFilter,
        onRemove: removeFilter,
        accountTypesOptions,
        accountPurposesOptions,
      }),
    [setFilter, removeFilter, accountTypesOptions, accountPurposesOptions],
  );

  const filteringProps = useMemo(
    () => ({
      removeAllFilters: resetFilters,
      filters,
      manualFilters: true,
    }),
    [resetFilters, filters],
  );

  const handleRowClick = useCallback(
    ({ id }: IMirroringRule, index: number) =>
      navigate(getMirroringRulePageRoute(id), {
        state: {
          previousPath: window.location.href,
          requestParams: params,
          cursor: index,
        },
      }),
    [navigate, params],
  );

  const handleClickNewMirroringRule = useCallback(() => {
    navigate(MIRRORING_RULE_ADD_PATH);
  }, [navigate]);

  const additionalActions = useMemo(
    () => [
      {
        key: 'addMirroringRule',
        component: (
          <IconButton
            iconName="AddIcon"
            iconColor="action"
            label="Add mirroring rule"
            iconSize={24}
            onClick={handleClickNewMirroringRule}
          />
        ),
      },
    ],
    [handleClickNewMirroringRule],
  );

  const bookmarkComponent = useMemo(
    () => (
      <Bookmarks
        initialValues={selectedBookmark}
        onSave={(name) => handleSaveBookmark(name, filters)}
        onSaveAsNew={(name) => handleSaveAsNewBookmark(name, filters)}
        onShare={handleShareBookmark}
        onDelete={handleDeleteBookmark}
      />
    ),
    [
      filters,
      handleSaveBookmark,
      handleSaveAsNewBookmark,
      handleShareBookmark,
      handleDeleteBookmark,
      selectedBookmark,
    ],
  );

  const displayedColumnKeys = useMemo(
    () =>
      selectedBookmark.columns.length
        ? selectedBookmark.columns
        : DISPLAYED_COLUMN_KEYS,
    [selectedBookmark.columns],
  );

  return (
    <Table
      additionalActions={additionalActions}
      className={MirroringRuleContainerStyles.Table}
      columns={columns}
      displayedColumnKeys={displayedColumnKeys}
      data={data?.mirroringRules || EMPTY_ARRAY}
      defaultSortBy={DEFAULT_SORTING}
      filteringProps={filteringProps}
      filtersRightPanelComponent={bookmarkComponent}
      handleRowClick={handleRowClick}
      hasFilters
      hasPagination
      isFlexLayout
      isLoading={isLoading}
      isPinnedHeader
      noDataHeight={NO_DATA_HEIGHT}
      saveColumnOrder
      showScrollbar
      showTableInfo
      tableId={tableId}
      title="Mirroring Rules"
    />
  );
};

export const MirroringRuleContainer = () => {
  const tableId = getTableId('MirroringRules');

  return (
    <WithBookmarks
      component={MirroringRule}
      pageName="Mirroring Rules"
      tableId={tableId}
    />
  );
};
