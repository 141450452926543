import * as Yup from 'yup';

import { YUP_SELECTED_VALUE } from '~/constants';
import { validationFreeSoloAutocompleteAsync } from '~/shared/utils/validation';
import { IOption } from '~/types/form';

export function getTradeAddFormValidationSchema(isSwitchTrade: boolean) {
  return Yup.object({
    nonSwitched: Yup.object({
      accountId: Yup.object()
        .test('accountId', 'Account is required', (value) =>
          Boolean(value?.value),
        )
        .nullable(),
      user: Yup.object()
        .test('user', 'Trader is required', (value) => Boolean(value?.value))
        .nullable(),
      tradeType: Yup.string(),
      symbolId: validationFreeSoloAutocompleteAsync('Symbol is required', {
        isRequired: true,
      }),
      price: Yup.number()
        .typeError('Price is required')
        .required('Price is required'),
      processAccruedInterest: Yup.boolean(),
      accruedInterest: Yup.number()
        .when('processAccruedInterest', {
          is: true,
          then: Yup.number().typeError('Should be a number'),
        })
        .when('processAccruedInterest', {
          is: false,
          then: Yup.number()
            .transform((currentValue, originalValue) => {
              return originalValue === '' ? null : currentValue;
            })
            .nullable(),
        }),
      quantity: Yup.number().typeError('Should be a number'),
      side: Yup.string().required('Side is required'),
      counterparty: Yup.string(),
      settlementCounterparty: Yup.string(),
      valueDate: Yup.string().required('Value date is required'),
      comment: Yup.string(),
      internalComment: Yup.string(),
      executionSchemeId: Yup.string(),
      symbolIdOverride: Yup.mixed()
        .when('executionSchemeId', {
          is: (value: IOption | string) => !value,
          then: Yup.object(YUP_SELECTED_VALUE).nullable(),
        })
        .when('executionSchemeId', {
          is: (value: IOption | string) => !!value,
          then: validationFreeSoloAutocompleteAsync('Symbol is required', {
            isRequired: true,
          }),
        }),
      takeCommission: Yup.boolean(),
    }),
    switched: Yup.object({
      accountId: Yup.mixed()
        .test('accountId', 'Account is required', (value) => {
          if (isSwitchTrade) {
            return Boolean(value && value.value);
          }
          return true;
        })
        .nullable(),
    }),
  });
}
