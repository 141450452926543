import { zipObject } from 'lodash';
import {
  Panel,
  TAutocompleteAsyncFetchData,
  Tooltip,
  Switch,
} from 'react-ui-kit-exante';

import {
  FormCheckboxContainer,
  FormDatePickerContainer,
  FormInputContainer,
  FormSelectAsyncContainer,
  FormSelectContainer,
  FormToggleButtonGroupContainer,
} from '~/containers/form';
import { WarningLite } from '~/images/icons';
import { FormItem } from '~/shared/components';
import { IOption } from '~/types/form';

import TradeAddPageStyles from '../../TradeAddPage.module.css';
import { WATCHED_NON_SWITCHED_FIELDS_FOR_COMMISSION } from '../../constants';
import { useCommission } from '../../hooks';
import { TakeCommissionCheckbox } from '../TakeCommissionCheckbox';

interface ITradeProps {
  disabledAccruedInterest: boolean;
  disabledSymbolIdOverride: boolean;
  fetchData: Record<string, TAutocompleteAsyncFetchData>;
  onChangeSwitchTrade(_: unknown, value: boolean): void;
  onChangeSymbolIdHandler(value: IOption): void;
  options: Record<string, Array<IOption>>;
  watchCommissionNonSwitchedFields: any;
}

export const TradeNonSwitch = ({
  disabledAccruedInterest,
  disabledSymbolIdOverride,
  fetchData,
  onChangeSwitchTrade,
  onChangeSymbolIdHandler,
  options,
  watchCommissionNonSwitchedFields,
}: ITradeProps) => {
  const {
    commission: commissionNonSwitched,
    isCommissionLoading: isCommissionLoadingNonSwitched,
  } = useCommission(
    zipObject(
      WATCHED_NON_SWITCHED_FIELDS_FOR_COMMISSION,
      watchCommissionNonSwitchedFields,
    ),
  );

  return (
    <Panel className={TradeAddPageStyles.Column}>
      <FormItem className={TradeAddPageStyles.FormItem}>
        <FormSelectAsyncContainer
          fetchData={fetchData.accountId}
          label="Account"
          name="nonSwitched.accountId"
        />
      </FormItem>

      <FormItem className={TradeAddPageStyles.FormItem}>
        <FormSelectAsyncContainer
          fetchData={fetchData.user}
          label="Trader"
          name="nonSwitched.user"
        />

        <FormSelectContainer
          label="Trade Type"
          name="nonSwitched.tradeType"
          options={options.tradeType}
        />
      </FormItem>

      <FormItem className={TradeAddPageStyles.FormItem}>
        <FormSelectAsyncContainer
          autoSelect
          fetchData={fetchData.symbolId}
          freeSolo
          label="Symbol"
          name="nonSwitched.symbolId"
          onChange={onChangeSymbolIdHandler}
        />

        <FormInputContainer label="Price" name="nonSwitched.price" />
      </FormItem>

      <FormItem className={TradeAddPageStyles.FormItem}>
        <FormInputContainer label="Quantity" name="nonSwitched.quantity" />

        <FormToggleButtonGroupContainer
          alwaysSelected
          exclusive
          fullWidth
          name="nonSwitched.side"
          options={options.side}
        />
      </FormItem>

      <FormItem className={TradeAddPageStyles.FormItem}>
        <FormCheckboxContainer
          label="Process accrued interest"
          name="nonSwitched.processAccruedInterest"
        />
      </FormItem>

      <FormInputContainer
        disabled={disabledAccruedInterest}
        label="Accrued interest"
        name="nonSwitched.accruedInterest"
        sx={{
          width: 262,
        }}
      />

      <FormItem className={TradeAddPageStyles.FormItem}>
        <FormSelectContainer
          label="Counterparty"
          name="nonSwitched.counterparty"
          options={options.counterparty}
        />

        <FormSelectContainer
          label="Settlement counterparty"
          name="nonSwitched.settlementCounterparty"
          options={options.settlementCounterparty}
        />
      </FormItem>

      <FormItem className={TradeAddPageStyles.FormItem}>
        <FormDatePickerContainer
          label="Value date"
          name="nonSwitched.valueDate"
        />
      </FormItem>

      <FormItem className={TradeAddPageStyles.FormItem}>
        <FormInputContainer
          label="Comment for client"
          name="nonSwitched.comment"
        />

        <FormInputContainer
          label="Internal comment"
          name="nonSwitched.internalComment"
        />
      </FormItem>

      <FormItem className={TradeAddPageStyles.FormItem}>
        <FormSelectContainer
          label="Execution scheme"
          name="nonSwitched.executionSchemeId"
          options={options.executionSchemeId}
        />
      </FormItem>

      <FormItem className={TradeAddPageStyles.FormItem}>
        <FormSelectAsyncContainer
          autoSelect
          disabled={disabledSymbolIdOverride}
          fetchData={fetchData.symbolId}
          freeSolo
          label="Symbol id override"
          name="nonSwitched.symbolIdOverride"
        />
      </FormItem>

      <FormItem className={TradeAddPageStyles.FormItem}>
        <FormInputContainer label="Exec broker" name="nonSwitched.execBroker" />
      </FormItem>

      <div className={TradeAddPageStyles.Commission}>
        <TakeCommissionCheckbox
          prefix="nonSwitched"
          commission={commissionNonSwitched}
          isLoading={isCommissionLoadingNonSwitched}
          watchFields={watchCommissionNonSwitchedFields}
          listOfFields={WATCHED_NON_SWITCHED_FIELDS_FOR_COMMISSION}
        />
      </div>

      <FormItem>
        <FormCheckboxContainer
          label="Use 4 eyes check"
          name="nonSwitched.use4EyesCheck"
        />

        <Tooltip
          placement="right"
          title="The transaction will be carried out only after manual verification, this will take time"
        >
          <span>
            <WarningLite />
          </span>
        </Tooltip>
      </FormItem>

      <Switch label="Switch Trade" onChange={onChangeSwitchTrade} />
    </Panel>
  );
};
