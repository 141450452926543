import { createApi } from '@reduxjs/toolkit/query/react';
import { mapValues, pick } from 'lodash';

import { baseQueryHandler } from '~/shared/utils';
import { IPaginationParameters, IPaginationResponse } from '~/types/api';
import { RowType } from '~/types/common';
import type {
  IChangedOvernights,
  IOvernightInstrumentResponse,
  IOvernightTree,
  TOvernightsQueryParams,
} from '~/types/overnights';
import { FilterLayers } from '~/types/overnights';

import {
  EMPTY_OVERNIGHTS_TREE,
  FIELDS_FOR_POST_DEFAULT_TREE,
} from './constants';
import {
  OVERNIGHTS_SETS,
  getFetchOvernightsInstruments,
  getOvernightsTreeEndpoint,
  getPostOvernightsEndpoint,
} from './endpoints';
import {
  convertOvernightsStructureFromFlatToTree,
  formatPercentage,
  IConvertOvernightsStructureFromFlatToTreeReturn,
  preparePath,
  preparePostDataForAccountsOrGroups,
} from './helpers';

interface IGetInstrumentsParams extends IPaginationParameters {
  path: string;
  queryParams: TOvernightsQueryParams;
}

interface IGetInstrumentsReturn {
  pagination: IPaginationResponse;
  data: IOvernightInstrumentResponse[];
  path: string;
}

export interface ISearchOvernightsInstrumentsParams
  extends IPaginationParameters {
  search: string;
  queryParams: TOvernightsQueryParams;
  instruments?: IOvernightTree[];
}

interface ISearchInstrumentsReturn {
  instruments: IOvernightTree[];
  count: number;
  errors: Error[];
}

interface ISaveGroupOvernightsParams {
  changedOvernights: IChangedOvernights;
  group: number;
}

interface ISaveAccountOvernightsParams {
  changedOvernights: IChangedOvernights;
  account: string;
}

export const overnightsApi = createApi({
  reducerPath: 'overnightsApi',
  baseQuery: baseQueryHandler,
  tagTypes: [
    'OvernightsTree',
    'OvernightsInstruments',
    'OvernightsSearchInstruments',
    'OvernightsGroup',
  ],
  endpoints: (builder) => ({
    getOvernightsTree: builder.query<
      IConvertOvernightsStructureFromFlatToTreeReturn,
      TOvernightsQueryParams
    >({
      queryFn: async (params, __, ___, fetchWithBaseQuery) => {
        if (!params) {
          return EMPTY_OVERNIGHTS_TREE;
        }

        const { layer, entity } = params;

        const { data, error } = await fetchWithBaseQuery({
          url: getOvernightsTreeEndpoint(layer, entity),
        });

        if (error || !data) {
          return EMPTY_OVERNIGHTS_TREE;
        }

        return {
          data: convertOvernightsStructureFromFlatToTree(data.data),
        };
      },
      providesTags: ['OvernightsTree'],
    }),
    getOvernightsInstruments: builder.query<
      IGetInstrumentsReturn,
      IGetInstrumentsParams
    >({
      queryFn: async (params, { dispatch }, __, fetchWithBaseQuery) => {
        const { path, limit, skip, queryParams } = params;

        if (!queryParams) {
          return {
            data: { pagination: { total: 0 }, data: [], path: '' },
          };
        }

        const { layer, entity } = queryParams;
        const newParams = { path, limit, skip };

        const { data, error } = await fetchWithBaseQuery({
          url: getFetchOvernightsInstruments(layer, entity),
          params: newParams,
        });

        if (error || !data) {
          return {
            data: { pagination: { total: 0 }, data: [], path: '' },
          };
        }

        if (data.pagination.total > skip + limit) {
          dispatch(
            overnightsApi.endpoints.getOvernightsInstruments.initiate({
              path,
              limit,
              skip: skip + limit,
              queryParams,
            }),
          );
        }

        return {
          data: {
            ...data,
            path,
          },
        };
      },
      providesTags: ['OvernightsInstruments'],
    }),
    searchInstruments: builder.query<
      ISearchInstrumentsReturn,
      ISearchOvernightsInstrumentsParams
    >({
      // We need custom error type here
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryFn: async (params, _, __, fetchWithBaseQuery) => {
        let { skip } = params;
        const { search, queryParams, limit } = params;

        if (!queryParams) {
          return { data: { instruments: [], errors: [] as Error[], count: 0 } };
        }

        let newInstruments: IOvernightTree[] = [];

        const { layer, entity } = queryParams;
        const newParams = { search, limit, skip };
        let total = limit;
        let promisesCount = 0;

        const promises = [
          fetchWithBaseQuery({
            url: getFetchOvernightsInstruments(layer, entity),
            params: newParams,
          }),
        ];

        const errors = [];

        while (promises.length > 0) {
          try {
            promisesCount += 1;
            // TODO Move queue to the utils
            // eslint-disable-next-line no-await-in-loop
            const front = await promises.shift();
            skip += limit;

            total = front?.data.pagination.total;

            newInstruments = newInstruments.concat(
              front?.data.data.map(
                ({
                  overnight,
                  ...instrument
                }: IOvernightInstrumentResponse) => ({
                  ...instrument,
                  ...overnight,
                  rowType: RowType.Instrument,
                }),
              ),
            );
          } catch (e) {
            if (e instanceof Error) {
              errors.push(e);
            }
          } finally {
            if (skip < total) {
              promises.push(
                fetchWithBaseQuery({
                  url: getFetchOvernightsInstruments(layer, entity),
                  params: {
                    search,
                    limit,
                    skip,
                  },
                }),
              );
            }
          }
        }

        if (promisesCount === errors.length) {
          return {
            error: {
              message: 'All requests were failed',
              errors,
              count: promisesCount,
            },
          };
        }

        return {
          data: { instruments: newInstruments, errors, count: promisesCount },
        };
      },
      providesTags: ['OvernightsSearchInstruments'],
    }),
    saveDefaultOvernights: builder.mutation({
      queryFn: async (
        changedOvernights: IChangedOvernights,
        _,
        __,
        fetchWithBaseQuery,
      ) => {
        const { nodes: changedNodes, instruments: changedInstruments } =
          changedOvernights;

        const { error } = await fetchWithBaseQuery({
          url: getPostOvernightsEndpoint(FilterLayers.Default),
          method: 'POST',
          data: Object.entries({
            ...changedNodes,
            ...changedInstruments,
          }).map((item) => {
            const [path, overnights] = item;
            const pathParams = preparePath(path, overnights.rowType);

            return {
              active: true,
              ...pathParams,
              ...mapValues(
                pick(overnights, FIELDS_FOR_POST_DEFAULT_TREE),
                (value, key) =>
                  key.startsWith('markup')
                    ? formatPercentage(Number(value))
                    : value,
              ),
            };
          }),
        });

        if (error) {
          return { error };
        }

        return { data: true };
      },
    }),
    saveGroupOvernights: builder.mutation<boolean, ISaveGroupOvernightsParams>({
      query: ({ changedOvernights, group }) => ({
        url: getPostOvernightsEndpoint(FilterLayers.Groups, group),
        method: 'POST',
        data: preparePostDataForAccountsOrGroups(changedOvernights),
      }),
    }),
    saveAccountOvernights: builder.mutation<
      boolean,
      ISaveAccountOvernightsParams
    >({
      queryFn: async (
        { changedOvernights, account },
        _,
        __,
        fetchWithBaseQuery,
      ) => {
        const { error } = await fetchWithBaseQuery({
          url: getPostOvernightsEndpoint(FilterLayers.Accounts, account),
          method: 'POST',
          data: preparePostDataForAccountsOrGroups(changedOvernights),
        });

        if (error) {
          return { error };
        }

        return { data: true };
      },
    }),
    getOvernightsGroup: builder.query<{ name: string; id: number }[], void>({
      query() {
        return {
          url: OVERNIGHTS_SETS,
          method: 'GET',
        };
      },
      providesTags: ['OvernightsGroup'],
    }),
    addOvernightsGroup: builder.mutation<
      { name: string; id: number },
      { name: string }
    >({
      query({ name }) {
        return {
          url: OVERNIGHTS_SETS,
          method: 'POST',
          data: {
            name,
          },
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data: newGroup } = await queryFulfilled;
          dispatch(
            overnightsApi.util.updateQueryData(
              'getOvernightsGroup',
              undefined,
              (draft) => {
                draft.push(newGroup);
              },
            ),
          );
        } catch (e) {
          // todo
        }
      },
    }),
    deleteOvernightsGroup: builder.mutation<unknown, { id: number }>({
      query({ id }) {
        return {
          url: `${OVERNIGHTS_SETS}/${id}`,
          method: 'DELETE',
        };
      },
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          overnightsApi.util.updateQueryData(
            'getOvernightsGroup',
            undefined,
            (draft) => {
              return draft.filter((t) => t.id !== id);
            },
          ),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    updateOvernightsGroup: builder.mutation<
      unknown,
      { id: number; name: string }
    >({
      query({ id, name }) {
        return {
          url: OVERNIGHTS_SETS,
          method: 'POST',
          data: {
            id,
            name,
          },
        };
      },
    }),
  }),
});

export const {
  useGetOvernightsTreeQuery,
  useLazyGetOvernightsTreeQuery,
  useLazyGetOvernightsInstrumentsQuery,
  useLazySearchInstrumentsQuery,
  useSaveDefaultOvernightsMutation,
  useSaveGroupOvernightsMutation,
  useSaveAccountOvernightsMutation,
  useUpdateOvernightsGroupMutation,
  useAddOvernightsGroupMutation,
  useGetOvernightsGroupQuery,
  useDeleteOvernightsGroupMutation,
} = overnightsApi;
