import { createApi } from '@reduxjs/toolkit/query/react';
import axios from 'axios';
import { cloneDeep, omit, omitBy, isUndefined, get, has } from 'lodash';

import { CONFLICT_CODE } from '~/constants';
import {
  baseQueryHandler,
  createServiceResponse,
  getArrayDifference,
  getKnownErrorMessage,
  isKnownError,
  paramsTransformer,
} from '~/shared/utils';
import {
  IAccount,
  IAccountGroupSettings,
  IAccountLimitSettings,
  IAccountStatistic,
  IAccountSummary,
  IAccountsState,
  ICoreAccount,
  IExtReportsSettings,
  IInternalSettings,
  IRegularPaymentsSettings,
  TAccountCashConversion,
  TAccountCommission,
  TAccountReferrals,
  TAccountRiskSettings,
  TAccountRoutingSettings,
  TMarginSettings,
  TRebateAccount,
  TRebateAccountsData,
} from '~/types/accounts';
import {
  IAccountMetrics,
  IMappedMarginStructure,
} from '~/types/accounts/metrics';
import { IServiceResponse, TParams } from '~/types/api';
import { IOption } from '~/types/form';

import { AccountAlreadyExistsError } from './accounts.errors';
import {
  ACCOUNTS_PARAMS_MAPPER,
  DEFAULT_ACCOUNT_METRICS_FIELDS,
} from './constants';
import {
  getAccountSummaryByCurrencyAPI,
  getAccountLocksEndpoint,
  getCrossRatesEndpoint,
  FETCH_ACCOUNTS,
  getAccountByIdEndpoint,
  getAccountPermissionSettingsEndpoint,
  getAccountLimitSettingsEndpoint,
  CREATE_ACCOUNT,
  getAccountRegularPaymentsSettingsEndpoint,
  getAccountExtReportsSettingsAPI,
  getAccountInternalSettingsAPI,
  getCashConversionSettingsAPI,
  getAccountCommissionSettingsEndpoint,
  getRoutingSettingsAPI,
  getRebateAccountsAPI,
  getDeleteRebateAccountsAPI,
  getMarginSettingsAPI,
  getRiskSettingsAPI,
  getAccountSummaryData,
  getReferralsAPI,
  getAccountMetricsByCurrencyDateAPI,
  getAccountMetricsAPI,
  FETCH_ACCOUNTS_STATISTIC,
  getAccountOvernightsEndpoint,
  getAccountInterestsRatesEndpoint,
  getCommissionsEndpoint,
  getLimitsEndpoint,
  getPermissionsEndpoint,
} from './endpoints';
import {
  accountSummaryMapper,
  accountsMapper,
  marginStructureMapper,
  prepareAccountForSend,
  prepareAccountResponseToView,
} from './helpers';
import {
  IAccountMetricsParameters,
  IAccountSummaryParameters,
  IAccountsStatisticParams,
  TCreateAccountPayload,
} from './types';

export const isConflictError = (error: unknown) => {
  return axios.isAxiosError(error) && error.response?.status === CONFLICT_CODE;
};

export const accountsApi = createApi({
  reducerPath: 'accountsApi',
  baseQuery: baseQueryHandler,
  tagTypes: ['Accounts'],
  endpoints: (builder) => ({
    getAccountSummary: builder.query<
      IAccountSummary,
      IAccountSummaryParameters
    >({
      queryFn: async (params, _, __, fetchWithBaseQuery) => {
        const { data: accountSummary, error: accountSummaryError } =
          await fetchWithBaseQuery({
            url: getAccountSummaryByCurrencyAPI(params),
            params: {
              showArchived: true,
            },
          });

        const { data: locks, error: accountLocksError } =
          await fetchWithBaseQuery({
            url: getAccountLocksEndpoint(params.id),
          });

        const { data: crossRates, error: crossRateError } =
          await fetchWithBaseQuery({
            url: getCrossRatesEndpoint(params.currency),
          });

        if (accountSummaryError || accountLocksError || crossRateError) {
          let resultError = ``;

          if (accountSummaryError) {
            resultError += `Account summary: ${accountSummaryError}\n`;
          }

          if (accountLocksError) {
            resultError += `Account locks: ${accountLocksError}\n`;
          }

          if (crossRateError) {
            resultError += `Cross rate: ${crossRateError}\n`;
          }

          return {
            error: resultError,
          };
        }

        return {
          data: accountSummaryMapper(accountSummary, locks, crossRates),
        };
      },
    }),
    getAccounts: builder.query<IAccountsState, TParams>({
      queryFn: async (params, _, __, fetchWithBaseQuery) => {
        const preparedParams = paramsTransformer({
          params,
          mapper: ACCOUNTS_PARAMS_MAPPER,
        });
        const {
          data: { data = [], pagination = {}, summary = {} },
          error,
        } = await fetchWithBaseQuery({
          url: FETCH_ACCOUNTS,
          params: preparedParams,
          ignoreForbiddenError: true,
        });

        if (error) {
          return {
            error,
          };
        }

        return {
          data: {
            accounts: data,
            pagination,
            summary,
          },
        };
      },
      providesTags: ['Accounts'],
    }),
    getCoreAccount: builder.query<ICoreAccount, { accountId: string }>({
      queryFn: async ({ accountId }, _, __, fetchWithBaseQuery) => {
        const { data, error } = await fetchWithBaseQuery({
          url: getAccountByIdEndpoint(accountId),
          ignoreForbiddenError: true,
        });

        if (error) {
          return {
            error,
          };
        }

        return {
          data: prepareAccountResponseToView(data),
        };
      },
      providesTags: (_result, _error, { accountId }) => {
        return [
          {
            type: 'Accounts',
            id: accountId,
          },
        ];
      },
    }),
    getGroupSettings: builder.query<
      IAccountGroupSettings,
      { accountId: string }
    >({
      queryFn: async ({ accountId }, _, __, fetchWithBaseQuery) => {
        const [permissionSettingsResponse, limitsResponse] = await Promise.all(
          [
            getAccountPermissionSettingsEndpoint(accountId),
            getAccountLimitSettingsEndpoint(accountId),
          ].map((url) =>
            fetchWithBaseQuery({ url, ignoreForbiddenError: true }),
          ),
        );

        if (permissionSettingsResponse.error || limitsResponse.error) {
          let resultError = '';

          if (permissionSettingsResponse.error) {
            resultError += `Permission settings: ${permissionSettingsResponse.error}\n`;
          }

          if (limitsResponse.error) {
            resultError += `Limits: ${limitsResponse.error}\n`;
          }

          return {
            error: resultError,
          };
        }

        return {
          data: {
            permissionsSetId:
              permissionSettingsResponse.data?.permissionsSetId || null,
            limitsSetId: limitsResponse.data?.limitsSetId || null,
          },
        };
      },
    }),
    updateGroupSettings: builder.mutation<
      IServiceResponse<IAccountLimitSettings>,
      { accountId: string; data: IAccountGroupSettings }
    >({
      queryFn: async ({ accountId, data = {} }, _, __, fetchWithBaseQuery) => {
        let updatedLimitsResponse = {};
        let updatedPermissionsResponse = {};

        if ('limitsSetId' in data) {
          updatedLimitsResponse = await fetchWithBaseQuery({
            url: getAccountLimitSettingsEndpoint(accountId),
            method: 'POST',
            data: {
              limitsSetId: data.limitsSetId,
            },
          });
        }

        if ('permissionsSetId' in data) {
          updatedPermissionsResponse = await fetchWithBaseQuery({
            url: getAccountPermissionSettingsEndpoint(accountId),
            method: 'POST',
            data: {
              permissionsSetId: data.permissionsSetId,
            },
          });
        }

        if (
          'error' in updatedPermissionsResponse ||
          'error' in updatedLimitsResponse
        ) {
          let resultError = '';

          if ('error' in updatedPermissionsResponse) {
            resultError += `Update permissions: ${get(
              updatedPermissionsResponse,
              'error',
            )}`;
          }

          if ('error' in updatedLimitsResponse) {
            resultError += `Update limits: ${get(
              updatedLimitsResponse,
              'error',
            )}`;
          }

          return { error: resultError };
        }

        const response = createServiceResponse({
          data,
          messages: { success: 'Group settings are successfully updated' },
        });

        // We are modifying response data somewhere in the react-form (form/createFormSubmitHandler)
        // cloneDeep is needed to prevent modifying of the rtk-cache
        // we have to return only data, not the whole cache object
        // TODO https://jira.exante.eu/browse/WBU-968
        return {
          data: {
            messages: response.messages,
            data: cloneDeep(data),
          },
        };
      },
    }),
    createAccount: builder.mutation<IAccount, TCreateAccountPayload>({
      query: (payload) => ({
        url: CREATE_ACCOUNT,
        method: 'POST',
        data: omit(payload, 'template'),
        params: { template: payload.template },
      }),
      transformErrorResponse: (e, _, args) => {
        if (isConflictError(e)) {
          const { id } = args;
          return {
            error: new AccountAlreadyExistsError(id),
          };
        }

        return {
          error: e,
        };
      },
    }),
    updateAccount: builder.mutation<
      IServiceResponse<ICoreAccount | null>,
      { accountId: string } & Partial<IAccount>
    >({
      query: (payload) => ({
        url: getAccountByIdEndpoint(payload.accountId),
        method: 'POST',
        data: prepareAccountForSend(payload),
      }),
      transformErrorResponse: (e, _, args) => {
        if (isConflictError(e)) {
          const { accountId } = args;
          return {
            error: new AccountAlreadyExistsError(accountId),
          };
        }

        return e;
      },
    }),
    getRegularPaymentsSettings: builder.query<
      IRegularPaymentsSettings,
      { accountId: string }
    >({
      query: ({ accountId }) => ({
        url: getAccountRegularPaymentsSettingsEndpoint(accountId),
      }),
    }),
    updateRegularPaymentsSettings: builder.mutation<
      IServiceResponse<IRegularPaymentsSettings | null>,
      {
        accountId: string;
        regularPaymentsSettings: Partial<IRegularPaymentsSettings>;
      }
    >({
      queryFn: async (
        { accountId, regularPaymentsSettings },
        _,
        __,
        fetchWithBaseQuery,
      ) => {
        const response = await fetchWithBaseQuery({
          url: getAccountRegularPaymentsSettingsEndpoint(accountId),
          method: 'POST',
          data: regularPaymentsSettings,
        });

        if ('error' in response) {
          return {
            error:
              response.error ||
              `Unexpected error while updating regular payments`,
          };
        }

        return {
          data: createServiceResponse({
            data: response.data,
            messages: {
              success: 'Regular payments settings are successfully updated.',
            },
          }),
        };
      },
    }),
    getExtReportsSettings: builder.query<
      IExtReportsSettings | null,
      { accountId: string }
    >({
      query: ({ accountId }) => ({
        url: getAccountExtReportsSettingsAPI(accountId),
      }),
    }),
    updateExtReportsSettings: builder.mutation<
      IServiceResponse<IExtReportsSettings | null>,
      {
        accountId: string;
        extReportsSettings: IExtReportsSettings;
      }
    >({
      queryFn: async (
        { accountId, extReportsSettings },
        _,
        __,
        fetchWithBaseQuery,
      ) => {
        const response = await fetchWithBaseQuery({
          url: getAccountExtReportsSettingsAPI(accountId),
          method: 'POST',
          data: extReportsSettings,
        });

        if ('error' in response) {
          return {
            error:
              response.error ||
              `Unexpected error while updating ext reports settings`,
          };
        }

        return {
          data: createServiceResponse({
            data: response.data,
            messages: {
              success: 'Ext reports settings are successfully updated.',
            },
          }),
        };
      },
    }),
    getInternalSettings: builder.query<
      IInternalSettings | null,
      { accountId: string }
    >({
      query: ({ accountId }) => ({
        url: getAccountInternalSettingsAPI(accountId),
      }),
    }),
    updateInternalSettings: builder.mutation<
      IServiceResponse<IInternalSettings | null>,
      { accountId: string; extReportsSettings: IInternalSettings }
    >({
      queryFn: async (
        { accountId, extReportsSettings },
        _,
        __,
        fetchWithBaseQuery,
      ) => {
        const response = await fetchWithBaseQuery({
          url: getAccountInternalSettingsAPI(accountId),
          data: extReportsSettings,
          method: 'POST',
        });

        if ('error' in response) {
          return {
            error:
              response.error ||
              `Unexpected error while updating internal settings`,
          };
        }

        return {
          data: createServiceResponse({
            data: response.data,
            messages: {
              success: 'Internal settings are successfully updated.',
            },
          }),
        };
      },
    }),
    getCashConversionSettings: builder.query<
      TAccountCashConversion | null,
      { accountId: string }
    >({
      query: ({ accountId }) => ({
        url: getCashConversionSettingsAPI(accountId),
      }),
    }),
    updateCashConversionSettings: builder.mutation<
      IServiceResponse<TAccountCashConversion | null>,
      { accountId: string; cashConversionSettings: TAccountCashConversion }
    >({
      queryFn: async (
        { accountId, cashConversionSettings },
        _,
        __,
        fetchWitBaseQuery,
      ) => {
        if (cashConversionSettings.convertCashInto === 'null') {
          cashConversionSettings.convertCashInto = null;
        }

        const response = await fetchWitBaseQuery({
          url: getCashConversionSettingsAPI(accountId),
          method: 'POST',
          data: cashConversionSettings,
        });

        if ('error' in response) {
          return {
            error:
              response.error ||
              `Unexpected error while updating cash conversion settings`,
          };
        }
        return {
          data: createServiceResponse({
            data: response.data,
            messages: {
              success: 'Cash conversion settings are successfully updated.',
            },
          }),
        };
      },
    }),
    getCommissionSettings: builder.query<
      TAccountCommission | null,
      { accountId: string }
    >({
      query: ({ accountId }) => ({
        url: getAccountCommissionSettingsEndpoint(accountId),
        ignoreForbiddenError: true,
      }),
    }),
    updateCommissionSettings: builder.mutation<
      IServiceResponse<TAccountCommission | null>,
      {
        accountId: string;
        commissionSettings: TAccountCommission;
      }
    >({
      queryFn: async (
        { accountId, commissionSettings },
        _,
        __,
        fetchWitBaseQuery,
      ) => {
        const response = await fetchWitBaseQuery({
          url: getAccountCommissionSettingsEndpoint(accountId),
          method: 'POST',
          data: commissionSettings,
        });

        if ('error' in response) {
          return {
            error:
              response.error ||
              `Unexpected error while updating commission settings`,
          };
        }
        return {
          data: createServiceResponse({
            data: response.data,
            messages: {
              success: 'Commission settings are successfully updated.',
            },
          }),
        };
      },
    }),
    getRoutingSettings: builder.query<
      TAccountRoutingSettings | null,
      { accountId: string }
    >({
      query: ({ accountId }) => ({
        url: getRoutingSettingsAPI(accountId),
        ignoreForbiddenError: true,
      }),
    }),
    updateRoutingSettings: builder.mutation<
      IServiceResponse<TAccountRoutingSettings | null>,
      { accountId: string; routingSettings: TAccountRoutingSettings }
    >({
      queryFn: async (
        { accountId, routingSettings },
        _,
        __,
        fetchWithBaseQuery,
      ) => {
        const response = await fetchWithBaseQuery({
          url: getRoutingSettingsAPI(accountId),
          method: 'POST',
          data: routingSettings,
        });

        if ('error' in response) {
          return {
            error:
              response.error ||
              `Unexpected error while updating routing settings`,
          };
        }

        return {
          data: createServiceResponse({
            data: response.data,
            messages: { success: 'Routing settings are successfully updated.' },
          }),
        };
      },
    }),
    getRebates: builder.query<TRebateAccountsData, { accountId: string }>({
      query: ({ accountId }) => ({
        url: getRebateAccountsAPI(accountId),
      }),
    }),
    updateRebates: builder.mutation<
      IServiceResponse<TRebateAccount[]>,
      {
        accountId: string;
        previousRebates: TRebateAccount[];
        rebates: TRebateAccount[];
      }
    >({
      queryFn: async (
        { accountId, previousRebates, rebates },
        _,
        __,
        fetchWithBaseQuery,
      ) => {
        const messages: { success: string[]; error: string[] } = {
          success: [],
          error: [],
        };

        const { deletedElements, editedElements, createdElements } =
          getArrayDifference(previousRebates, rebates, 'id');

        const requestsToDelete = deletedElements.flatMap((rebate) =>
          rebate.id
            ? fetchWithBaseQuery({
                url: getDeleteRebateAccountsAPI(accountId, rebate.id),
                method: 'DELETE',
              })
            : [],
        );

        const deleteResponses = await Promise.allSettled(requestsToDelete);

        let error;

        deleteResponses.forEach((result, index) => {
          const currentRebateId = deletedElements[index].id;

          if (result.status === 'fulfilled') {
            messages.success.push(
              `Rebate with id ${currentRebateId} is successfully deleted.`,
            );
          }

          if (result.status === 'rejected') {
            if (isKnownError(get(result, 'error'))) {
              messages.error.push(
                `${currentRebateId}: ${getKnownErrorMessage(
                  get(result, 'error'),
                )}`,
              );
            } else {
              messages.error.push(
                `Rebate with id ${currentRebateId} deleting failed ${result.reason.messages}`,
              );
            }
          }
        });

        if (error) {
          return {
            error,
          };
        }

        const createdAndEditedElements = editedElements.concat(createdElements);
        const createAndEditResponse = await fetchWithBaseQuery({
          url: getRebateAccountsAPI(accountId),
          method: 'POST',
          data: createdAndEditedElements,
        });

        if (createAndEditResponse.error) {
          messages.error.push(`Error while updating rebates`);

          return {
            data: {
              data: [],
              messages,
            },
          };
        }

        createdAndEditedElements.forEach((item) => {
          const currentRebateId = item.id;

          messages.success.push(
            `Rebate with id ${currentRebateId} is successfully updated.`,
          );
        });

        return {
          data: {
            data: rebates,
            messages,
          },
        };
      },
    }),

    getMarginSettings: builder.query<
      TMarginSettings | null,
      { accountId: string }
    >({
      query: ({ accountId }) => ({
        url: getMarginSettingsAPI(accountId),
      }),
    }),
    updateMarginSettings: builder.mutation<
      IServiceResponse<TMarginSettings | null>,
      { accountId: string; marginSettings: TMarginSettings }
    >({
      queryFn: async (
        { accountId, marginSettings },
        _,
        __,
        fetchWithBaseQuery,
      ) => {
        const response = await fetchWithBaseQuery({
          url: getMarginSettingsAPI(accountId),
          method: 'POST',
          data: marginSettings,
        });

        if ('error' in response) {
          return {
            error:
              response.error ||
              `Unexpected error while updating margin settings`,
          };
        }

        return {
          data: createServiceResponse({
            data: response.data,
            messages: { success: 'Margin settings are successfully updated.' },
          }),
        };
      },
    }),
    getRiskSettings: builder.query<
      TAccountRiskSettings | null,
      { accountId: string }
    >({
      query: ({ accountId }) => ({
        url: getRiskSettingsAPI(accountId),
      }),
    }),
    updateRiskSettings: builder.mutation<
      IServiceResponse<TAccountRiskSettings | null>,
      { accountId: string; riskSettings: TAccountRiskSettings }
    >({
      queryFn: async (
        { accountId, riskSettings },
        _,
        __,
        fetchWithBaseQuery,
      ) => {
        const response = await fetchWithBaseQuery({
          url: getRiskSettingsAPI(accountId),
          method: 'POST',
          data: riskSettings,
        });

        if ('error' in response) {
          return {
            error:
              response.error || `Unexpected error while updating risk settings`,
          };
        }

        return {
          data: createServiceResponse({
            data: response.data,
            messages: { success: 'Risk settings are successfully updated.' },
          }),
        };
      },
    }),
    searchByAccounts: builder.query<
      {
        total: number;
        canceled?: boolean;
        nextSkip: number | null;
        options: Array<IOption>;
      },
      {
        skip: number;
        search: string;
        limit?: number;
        archived?: boolean;
      }
    >({
      queryFn: async (
        { limit = 100, skip, ...restParams },
        _,
        __,
        fetchWithBaseQuery,
      ) => {
        const preparedParams = paramsTransformer({
          params: omitBy({ limit, skip, ...restParams }, isUndefined),
          mapper: ACCOUNTS_PARAMS_MAPPER,
        });

        const response = await fetchWithBaseQuery({
          url: FETCH_ACCOUNTS,
          params: preparedParams,
          requestOptions: {},
        });

        if ('error' in response) {
          return {
            error:
              response.error || `Unexpected error while searching accounts`,
          };
        }

        const accounts = accountsMapper(response.data.data);
        const { pagination } = response.data;

        return {
          data: {
            total: pagination.total,
            nextSkip: skip + limit < pagination.total ? skip + limit : null,
            options: accounts.map(({ id }) => ({
              label: id,
              value: id,
            })),
          },
        };
      },
    }),
    getAccountData: builder.query({
      query: (params) => ({
        url: getAccountSummaryData(params),
      }),
    }),
    getAccountReferrals: builder.query<
      TAccountReferrals | null,
      { accountId: string }
    >({
      query: ({ accountId }) => ({
        url: getReferralsAPI(accountId),
        ignoreForbiddenError: true,
      }),
    }),
    getAccountMetrics: builder.query<
      IAccountMetrics,
      IAccountMetricsParameters
    >({
      query: ({
        accountId,
        currency,
        date,
        fields = DEFAULT_ACCOUNT_METRICS_FIELDS,
      }) => {
        return {
          url: date
            ? getAccountMetricsByCurrencyDateAPI(accountId, currency, date)
            : getAccountMetricsAPI(accountId),
          params: { fields, cached: true },
          ignoreForbiddenError: true,
        };
      },
    }),
    getMarginStructure: builder.query<
      IMappedMarginStructure,
      IAccountMetricsParameters
    >({
      query: (params) => ({
        url: params.date
          ? getAccountMetricsByCurrencyDateAPI(
              params.accountId,
              params.currency,
              params.date || '',
            )
          : getAccountMetricsAPI(params.accountId),
        params: { ...params, fields: ['marginStructure'] },
        ignoreForbiddenError: true,
      }),
      transformResponse: (response) => {
        return marginStructureMapper(response?.marginStructure || []);
      },
    }),
    getAccountStatistic: builder.query<
      IAccountStatistic,
      IAccountsStatisticParams
    >({
      query: (params) => ({
        url: FETCH_ACCOUNTS_STATISTIC,
        params,
        ignoreForbiddenError: true,
      }),
    }),

    getAccountOverrides: builder.query({
      queryFn: async ({ accountId }, _, __, fetchWitBaseQuery) => {
        const urlGetters = [
          getAccountOvernightsEndpoint,
          getAccountInterestsRatesEndpoint,
          getCommissionsEndpoint,
          getLimitsEndpoint,
          getPermissionsEndpoint,
        ];
        const toRequest = (urlGetter: (typeof urlGetters)[number]) =>
          fetchWitBaseQuery({
            url: urlGetter(accountId),
            ignoreForbiddenError: true,
          });

        const [
          overnightsResponse,
          interestsResponse,
          commissionsResponse,
          limitsResponse,
          permissionsResponse,
        ] = await Promise.all(urlGetters.map(toRequest));

        if (
          [
            overnightsResponse,
            interestsResponse,
            commissionsResponse,
            limitsResponse,
            permissionsResponse,
          ].some((res) => res.error)
        ) {
          const errorMessages: string[] = [];

          (
            [
              ["Get account's overnights", overnightsResponse],
              ["Get account's interests", interestsResponse],
              ["Get account's commissions", commissionsResponse],
              ["Get account's limits", limitsResponse],
              ["Get account's permissions", permissionsResponse],
            ] as const
          ).forEach(([title, res]) => {
            errorMessages.push(`${title}: ${res.error}`);
          });

          return {
            error: errorMessages.join('\n'),
          };
        }

        return {
          data: {
            overnights: overnightsResponse.data
              ? Boolean(overnightsResponse.data.length)
              : false,
            interests: interestsResponse.data
              ? Boolean(interestsResponse.data.length)
              : false,
            commissions: commissionsResponse.data
              ? Boolean(commissionsResponse.data.length)
              : false,
            limits: limitsResponse.data
              ? Boolean(limitsResponse.data.length)
              : false,
            permissions: permissionsResponse.data
              ? Boolean(permissionsResponse.data.length)
              : false,
          },
        };
      },
    }),
  }),
});

export const {
  useCreateAccountMutation,
  useGetAccountOverridesQuery,
  useGetAccountStatisticQuery,
  useGetAccountSummaryQuery,
  useGetAccountsQuery,
  useGetCashConversionSettingsQuery,
  useGetCommissionSettingsQuery,
  useGetCoreAccountQuery,
  useGetExtReportsSettingsQuery,
  useGetGroupSettingsQuery,
  useGetInternalSettingsQuery,
  useGetMarginSettingsQuery,
  useGetMarginStructureQuery,
  useGetRebatesQuery,
  useGetRegularPaymentsSettingsQuery,
  useGetRiskSettingsQuery,
  useGetRoutingSettingsQuery,
  useLazyGetAccountDataQuery,
  useLazyGetAccountReferralsQuery,
  useLazyGetAccountsQuery,
  useLazyGetCommissionSettingsQuery,
  useLazyGetGroupSettingsQuery,
  useLazyGetRegularPaymentsSettingsQuery,
  useLazySearchByAccountsQuery,
  useUpdateAccountMutation,
  useUpdateCashConversionSettingsMutation,
  useUpdateCommissionSettingsMutation,
  useUpdateExtReportsSettingsMutation,
  useUpdateGroupSettingsMutation,
  useUpdateInternalSettingsMutation,
  useUpdateMarginSettingsMutation,
  useUpdateRebatesMutation,
  useUpdateRegularPaymentsSettingsMutation,
  useUpdateRiskSettingsMutation,
  useUpdateRoutingSettingsMutation,
} = accountsApi;
