import { styled, TDefaultTheme } from 'react-ui-kit-exante';

export const StyledWrapper = styled('div')(
  ({ theme }: { theme: TDefaultTheme }) => ({
    '.TableFooter div': {
      background: theme?.color.bg.primary,
      color: theme.color.typo.primary,
    },
  }),
);
