import { createApi } from '@reduxjs/toolkit/query/react';
import { AxiosError } from 'axios';
import { get } from 'lodash';

import { baseQueryHandler } from '~/shared/utils';

import { expiredInstrumentsEndpoints } from './expiredInstruments.endpoints';
import {
  ExpiredInstrumentsFields,
  GetAllExpiredInstrumentsResponse,
  GetAllowedSymbolsParams,
  GetAllowedSymbolsResponse,
  UpdateExpiredSymbolsResponse,
} from './expiredInstruments.types';

export const expiredInstrumentActions = {
  moveInCash: 'Move in cash',
  convertInto: 'Convert into',
  doNothing: 'Do nothing',
} as const;

const isAxiosError = (err: unknown): err is AxiosError => {
  return Boolean(get(err, 'isAxiosError'));
};

const expiredInstrumentsFields: ExpiredInstrumentsFields[] = [
  'symbolId',
  'optionGroupId',
];

export const expiredInstrumentsApi = createApi({
  reducerPath: 'expiredInstrumentsApi',
  baseQuery: baseQueryHandler,
  tagTypes: ['ExpiredInstruments'],
  endpoints: (builder) => ({
    getAllExpiredInstruments: builder.query<
      GetAllExpiredInstrumentsResponse,
      void
    >({
      query: () => {
        const queryParams = { fields: expiredInstrumentsFields };

        return {
          url: expiredInstrumentsEndpoints.getAllInstruments,
          params: {
            ...queryParams,
            expired: true,
          },
        };
      },
      providesTags: ['ExpiredInstruments'],
    }),
    getAllowedSymbols: builder.query<
      GetAllowedSymbolsResponse,
      {
        params: GetAllowedSymbolsParams;
      }
    >({
      query: ({ params }) => ({
        url: expiredInstrumentsEndpoints.getAllowedSymbols,
        params,
      }),
    }),
    updateExpiredSymbols: builder.mutation<UpdateExpiredSymbolsResponse, void>({
      queryFn: async (_, api, __, baseQuery) => {
        const state = api.getState();
        const expiredInstrumentsState = get(state, 'expiredInstruments', {
          requestsToUpdate: [],
        });

        const response = await baseQuery({
          url: expiredInstrumentsEndpoints.updateInstrument,
          data: Object.entries(
            expiredInstrumentsState.requestsToUpdate,
          ).flatMap(([key, value = { type: null }]) => {
            if (value.type === 'symbol') {
              return { symbolId: key, ...value };
            }
            if (value.type === 'optionGroup') {
              return { optionGroupId: key, ...value };
            }
            return [];
          }),
          method: 'POST',
        });

        if ('error' in response) {
          return {
            error: response.error || `Unexpected error`,
          };
        }

        return {
          data: response.data,
        };
      },
      invalidatesTags: ['ExpiredInstruments'],
    }),
  }),
});

export const {
  useLazyGetAllExpiredInstrumentsQuery,
  useGetAllExpiredInstrumentsQuery,
  useGetAllowedSymbolsQuery,
  useLazyGetAllowedSymbolsQuery,
  useUpdateExpiredSymbolsMutation,
} = expiredInstrumentsApi;
