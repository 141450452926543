import { transformVariantsToSelectOptions } from '~/shared/utils';
import { IOption } from '~/types/form';
import { TApplicationState } from '~/types/store';

import { USER_TYPE_MAP } from './constants';

export function selectTypes(state: TApplicationState) {
  return state.types;
}

export function selectSexOptions(state: TApplicationState) {
  const filterOptions = selectTypes(state);

  return transformVariantsToSelectOptions(filterOptions.data.sex.values, {
    capitalized: true,
  });
}

export const selectUserType = (state: TApplicationState) => {
  const types = selectTypes(state);

  return types.data.userType.values;
};

export const selectUserTypeOptions = (state: TApplicationState): IOption[] => {
  const userTypes = selectUserType(state);

  return userTypes.map((item) => ({ value: item, label: USER_TYPE_MAP[item] }));
};
