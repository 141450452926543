import { CSSProperties, ComponentType, ReactNode } from 'react';
import { styled } from 'react-ui-kit-exante';

export const StyledContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.color.bg.primary,
}));

export const StyledTabsWrapper = styled('div')(
  ({ lastTabStyles = {} }: { lastTabStyles?: CSSProperties }) => ({
    position: 'relative',
    '& [role="tab"]:last-of-type': lastTabStyles,
  }),
) as ComponentType<{ lastTabStyles?: CSSProperties; children: ReactNode }>;

export const StyledContentContainer = styled('main')(() => ({
  padding: 24,
  paddingTop: 0,
}));

export const StyledHeader = styled('header')(({ theme }) => ({
  display: 'flex',
  background: theme?.color.bg.primary,
  padding: `16px 24px`,
  justifyContent: 'space-between',
  alignItems: 'center',
  color: theme?.color.typo.primary,
  margin: 0,
  font: `32px ${theme?.font.header}`,
}));

export const StyledControlsContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '24px 0',
  gap: 24,
}));

export const StyledTableControls = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: `12px 0`,
}));

export const StyledPageControlsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 24,
  justifyContent: 'space-between',
  borderBottom: `1px solid ${theme.color.input.border}`,
}));

export const StyledCheckboxContainer = styled('div')(() => ({
  marginRight: 'auto',
  marginLeft: 20,
  whiteSpace: 'nowrap',
}));
