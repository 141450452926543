import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';
import { omit } from 'lodash';
import { useCallback, useMemo } from 'react';
import {
  FormProvider,
  useController,
  useForm,
  useFormContext,
} from 'react-hook-form';
import {
  IconButton,
  Notification,
  OnSaveEditableRow,
  Panel,
  Table,
} from 'react-ui-kit-exante';

import { useGetRebatesQuery } from '~/api';
import { EMPTY_ARRAY, NO_DATA_HEIGHT } from '~/constants';
import { useAccountsAutocomplete, usePickUserPermissions } from '~/hooks';
import { DeleteIcon } from '~/images/icons';
import { FormItem } from '~/shared/components';
import { getTableId } from '~/shared/utils';
import { TRebateAccount, TRebateAccountsData } from '~/types/accounts';

import { FormInputContainer, FormSelectAsyncContainer } from '../form';

import RebatesContainerStyles from './RebatesContainer.module.css';
import { columns } from './columns';
import {
  DEFAULT_REBATE,
  REBATES_ALREADY_SELECTED_ERROR,
  REBATES_CURRENT_ACCOUNT_ERROR,
  REBATES_ERROR,
} from './consts';
import { checkIsRebatesValid } from './helpers';
import { RebateAddForm, RebatesContainerProps } from './types';
import { ADD_REBATE_VALIDATION_SCHEMA } from './validationSchema';

import '~/styles/Global.css';

export const RebatesContainer = ({ accountId }: RebatesContainerProps) => {
  const { control } = useFormContext();
  const {
    field: { onChange, value },
    fieldState: { isDirty },
  } = useController({
    control,
    name: 'rebates',
  });
  const getAccountsAutocompleteFn = useAccountsAutocomplete();

  const userPermissions = usePickUserPermissions([
    'Account info',
    'Account info: rebate settings for account',
  ]);

  const methods = useForm<RebateAddForm>({
    defaultValues: DEFAULT_REBATE,
    resolver: yupResolver(ADD_REBATE_VALIDATION_SCHEMA),
  });
  const { handleSubmit, getValues, reset } = methods;

  const { error: rebatesError } = useGetRebatesQuery({
    accountId,
  });

  const tableId = getTableId('Rebates');

  const onAddAccount = () => {
    const values = getValues();

    const newRebate = {
      percent: values.percent,
      id: values.id?.value,
    };
    const updatedRebates = [...value, newRebate];

    const isAccountSelected = (value as TRebateAccountsData).find(
      (account) => account.id === newRebate.id,
    );
    if (isAccountSelected) {
      Notification.error({
        title: REBATES_ALREADY_SELECTED_ERROR,
      });
    } else if (newRebate.id === accountId) {
      Notification.error({
        title: REBATES_CURRENT_ACCOUNT_ERROR,
      });
    } else if (!checkIsRebatesValid(updatedRebates)) {
      Notification.error({
        title: REBATES_ERROR,
      });
    } else {
      onChange(updatedRebates);
      reset();
    }
  };

  const onUpdate: OnSaveEditableRow<TRebateAccount> = useCallback(
    async (previousData: TRebateAccount, updatedData: TRebateAccount) => {
      const tableData: TRebateAccountsData = [...value];
      const updatedIndex = tableData.findIndex(
        (val) => val.id === updatedData.id,
      );
      tableData[updatedIndex] = {
        ...omit({ ...tableData[updatedIndex], ...updatedData }, ['actions']),
      }; // todo not pass action column in ui-kit
      if (!checkIsRebatesValid(tableData)) {
        await Notification.error({
          title: REBATES_ERROR,
        });
        return;
      }
      await onChange(tableData);
    },
    [onChange, value],
  );

  const deleteClickHandler = useCallback(
    (rowValue: TRebateAccount) => {
      const updatedData = (value as TRebateAccountsData).filter(
        ({ id }) => id !== rowValue.id,
      );
      onChange(updatedData);
    },
    [onChange, value],
  );

  const rowActions = useMemo(
    () => ({
      show: true,
      onSave: onUpdate,
      additionalActions: [
        {
          label: <DeleteIcon />,
          onClick: deleteClickHandler,
          title: 'Remove',
        },
      ],
    }),
    [deleteClickHandler, onUpdate],
  );

  const getRowProps = useCallback(() => {
    if (isDirty) {
      return { style: { color: '#EC9F0B' } };
    }

    return {};
  }, [isDirty]);

  if (!userPermissions['Account info: rebate settings for account'].read) {
    return null;
  }

  return (
    <Panel
      className={cn({
        Disabled:
          rebatesError ||
          !userPermissions['Account info'].write ||
          !userPermissions['Account info: rebate settings for account'].write,
      })}
      disableBodyPaddings
      title="Rebates"
    >
      <FormProvider {...methods}>
        <FormItem withMargin withPadding>
          <FormSelectAsyncContainer
            label="Select account"
            name="id"
            fetchData={getAccountsAutocompleteFn()}
            disableWarning
          />
          <FormInputContainer
            label="Rebate"
            name="percent"
            type="number"
            disableWarning
          />
          <IconButton
            iconColor="promo"
            iconName="AddIcon"
            onClick={handleSubmit(onAddAccount)}
            className={RebatesContainerStyles.AddButton}
          />
        </FormItem>
      </FormProvider>

      <Table
        columns={columns}
        data={value || EMPTY_ARRAY}
        isLoading={!value}
        isFlexLayout
        tableId={tableId}
        disableSortBy
        noDataHeight={NO_DATA_HEIGHT}
        rowActions={rowActions}
        getRowProps={getRowProps}
        saveViewParamsAfterLeave
      />
    </Panel>
  );
};
