import { styled, TDefaultTheme } from 'react-ui-kit-exante';

export const StyledWrapper = styled('div')(
  ({
    withBackground,
    theme,
  }: {
    withBackground: boolean;
    theme?: TDefaultTheme;
  }) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    ...(withBackground && {
      background: theme?.color.bg.primary,
    }),
  }),
);
