import { useEffect } from 'react';

import { useLogHandleTime } from '~/hooks';
import { IUserTabComponentProps } from '~/pages/UserPage/TabManagement/types';
import { useUserPageContextManager } from '~/pages/UserPage/hooks';
import { StaticCenteredLoader } from '~/shared/components';

import { AccessRightsFormContainer } from './AccessRightsFormContainer';
import { useAccessRightsData } from './useAccessRightsData';

export const UserAccessRightsContainer = ({
  userId,
  userName,
}: IUserTabComponentProps) => {
  const { setStartHandleTime, logHandleTime } = useLogHandleTime(
    'user-access-right-entry-screen',
  );
  setStartHandleTime();

  const { setRefreshFn } = useUserPageContextManager();

  const {
    queries: {
      accessRightsQuery,
      userQuery,
      accountPermissionsQuery,
      userServicesQuery,
    },
    data: {
      services,
      legalEntities,
      accountTypes,
      brandingPermission,
      userServices,
    },
    isDataLoading,
  } = useAccessRightsData(userId, userName);

  useEffect(() => {
    if (userQuery.data && accessRightsQuery.data && !isDataLoading) {
      logHandleTime();
    }
  }, [userQuery, accessRightsQuery, isDataLoading, logHandleTime]);

  useEffect(() => {
    const queries = [accessRightsQuery, userQuery, userServicesQuery];
    if (!setRefreshFn) {
      return;
    }
    setRefreshFn('accessRights', {
      refetch: () => Promise.all(queries.map(({ refetch }) => refetch())),
      isLoading: queries.some(({ isFetching }) => isFetching),
    });
  }, [setRefreshFn, accessRightsQuery, userQuery, userServicesQuery]);

  if (isDataLoading) {
    return <StaticCenteredLoader withBackground height="420px" />;
  }

  if (!accessRightsQuery.data || !userQuery.data) {
    return <div>No data</div>;
  }

  return (
    <AccessRightsFormContainer
      accessRights={accessRightsQuery.data}
      accountPermissionsQueryRefetch={accountPermissionsQuery.refetch}
      userServices={userServices}
      userServicesQuery={userServicesQuery}
      brandingPermission={brandingPermission}
      currentAccountTypes={accountTypes}
      legalEntities={legalEntities}
      services={services}
      user={userQuery.data}
    />
  );
};
