import { SESSION_ID_RUN_UI_AUDITLOG_KEY } from '~/constants';
import {
  apiRequest,
  getAccessTokenFromCookies,
  getSessionIdFromCookie,
} from '~/shared/utils';
import { getDefaultHeaders } from '~/shared/utils/apiRequest/helpers';

import { FETCH_AUDIT_LOGS } from './endpoints';
import { IFetchAuditLogResponse, TFetchAuditLogsParams } from './types';

export class AuditLogRepository {
  public static fetchAuditLogs(params: TFetchAuditLogsParams) {
    return apiRequest<IFetchAuditLogResponse>({
      url: FETCH_AUDIT_LOGS,
      params,
      ...{
        headers: getDefaultHeaders(
          window.runUIhistoryContainer
            ? getSessionIdFromCookie(SESSION_ID_RUN_UI_AUDITLOG_KEY)
            : process.env.REACT_APP_AUDITLOG_TOKEN,
          getAccessTokenFromCookies(),
        ),
      },
    });
  }
}
