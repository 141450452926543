export const getRowPropsHelperTrades = (
  errors: Error[],
  hasErrors: boolean,
  index: number,
  uploadSuccessfully: boolean,
) => {
  return uploadSuccessfully || hasErrors
    ? {
        style: {
          background: errors[index] ? 'rgb(159, 64, 64)' : 'rgb(64, 159, 107)',
          color: !errors.length ? undefined : 'white',
        },
      }
    : {};
};
