export const availableRequestParams = new Set([
  'fields',
  'limit',
  'offset',
  'query',
  'orderBy',
  'order',
]);

export const resolveAuditLogsQueryParams = new Set([
  'account',
  'change',
  'description',
  'symbol',
  'time',
  'type',
  'user',
  'who',
]);
