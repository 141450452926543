/**
 * Replaces variable values in a route with a specified constant value.
 *
 * @param {string} route - The original route containing variable values.
 * @param {string} replacement - The constant value to replace the variables with.
 * @returns {string} - The new route with replaced variable values.
 *
 * @example
 * const originalRoute = "/bo/accounts/:accountId/test";
 * const constantValue = "123";
 * const updatedRoute = replaceRouteVariable(originalRoute, constantValue);
 * console.log(updatedRoute); // Outputs: "/bo/accounts/123/test"
 */
export function replaceRouteVariable(
  route: string,
  replacement: string | number,
): string {
  const regex = /\/([^/:]+\/?):([^/]+|$)/g;

  return route.replace(regex, `/$1${replacement}`);
}
