import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loader } from 'react-ui-kit-exante';

import {
  useGetCoreUserQuery,
  useGetCurrentUserAccessRightsQuery,
  usePostTradesMutation,
} from '~/api';
import { useAttachCSV, useBack } from '~/hooks';
import { TRADES_PATH } from '~/routes';
import { sendNotification } from '~/shared/utils';
import {
  getInputFileText,
  getRowPropsHelperTrades,
} from '~/shared/utils/bulkImport';
import { getUploadActionsTrades } from '~/shared/utils/bulkImport/getUploadActionsTrades';
import {
  TTradeImportColumn,
  ITradeCSV,
  ITradePostRequest,
} from '~/types/trades';

import { TradeImport } from './TradeImport';
import { COLUMNS_CONFIG } from './constants';

const notifications = {
  success: 'All trades were uploaded successfully',
  fail: 'Some trades were not uploaded',
};

export const TradeImportContainer = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [allTradesUploadSuccessfully, setAllTradesUploadSuccessfully] =
    useState(false);
  const [errors, setErrors] = useState<Error[]>([]);
  const [postTrade] = usePostTradesMutation();
  const { tableData, columns, handleInputFile } = useAttachCSV<
    TTradeImportColumn,
    ITradeCSV
  >(COLUMNS_CONFIG);
  const hasErrors = useMemo(() => errors.some(Boolean), [errors]);
  const { data: { userId } = {} } = useGetCurrentUserAccessRightsQuery();
  const {
    data: currentUser,
    isLoading: isLoadingCurrentUser,
    isFetching: isFetchingCurrentUser,
  } = useGetCoreUserQuery({ id: Number(userId) });
  const navigate = useNavigate();

  useEffect(() => {
    const currentUserHasAllBrandingPermissions =
      currentUser?.info.brandingPermission === 'ALL';

    if (!currentUserHasAllBrandingPermissions) {
      navigate('/', {
        state: { previousPath: window.location.href },
      });
    }
  }, [currentUser?.info.brandingPermission, navigate]);

  const resetErrors = () => {
    setErrors([]);
  };

  const handleUpload = useCallback(async () => {
    setIsLoading(true);

    const onError = (index: number, error: unknown) => {
      if (error instanceof Error) {
        errors[index] = error;
      }
    };
    const handleImportTrades = async (trades: ITradePostRequest[]) => {
      const importErrors: Error[] = [];
      await Promise.all(
        trades.map((trade, index) =>
          postTrade({
            params: trade,
            onError: onError.bind(null, index),
          }),
        ),
      );
      const success = errors.length === 0;
      if (allTradesUploadSuccessfully) {
        sendNotification(notifications.success, 'success');
      } else {
        sendNotification(notifications.fail, 'error');
      }
      return { errors: importErrors, allTradesUploadSuccessfully: success };
    };

    const data = await handleImportTrades(tableData);

    setErrors(data.errors);
    setAllTradesUploadSuccessfully(data.allTradesUploadSuccessfully);

    setIsLoading(false);
  }, [allTradesUploadSuccessfully, errors, postTrade, tableData]);

  const wrappedHandleInputFile = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setAllTradesUploadSuccessfully(false);
    resetErrors();
    handleInputFile(event);
  };

  const getRowProps = useCallback(
    ({ index }: { index: number }) =>
      getRowPropsHelperTrades(
        errors,
        hasErrors,
        index,
        allTradesUploadSuccessfully,
      ),
    [allTradesUploadSuccessfully, errors, hasErrors],
  );

  const displayTable = Boolean(columns.length);
  const inputFileText = useMemo(
    () =>
      getInputFileText(displayTable, allTradesUploadSuccessfully, hasErrors),
    [allTradesUploadSuccessfully, displayTable, hasErrors],
  );

  const additionalActions = useMemo(
    () =>
      getUploadActionsTrades(
        handleUpload,
        hasErrors,
        allTradesUploadSuccessfully,
        'Upload trades',
      ),
    [handleUpload, hasErrors, allTradesUploadSuccessfully],
  );

  const handleCloseImport = useBack({ defaultPath: TRADES_PATH });

  if (isLoadingCurrentUser || isFetchingCurrentUser) {
    return <Loader />;
  }

  return (
    <TradeImport
      additionalActions={additionalActions}
      onInputFile={wrappedHandleInputFile}
      columns={columns}
      displayTable={displayTable}
      getRowProps={getRowProps}
      inputFileText={inputFileText}
      isLoading={isLoading}
      tableData={tableData}
      onClose={handleCloseImport}
    />
  );
};
