import { styled, TDefaultTheme } from 'react-ui-kit-exante';

export const StyledTokenForm = styled('div')(
  ({ theme }: { theme: TDefaultTheme }) => ({
    display: 'grid',
    gap: '30px',
    background: theme?.color.bg.primary,
    padding: '20px calc(100% - 320px - 20px) 20px 20px',
  }),
);
