import { yupResolver } from '@hookform/resolvers/yup';
import { capitalize } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { CenteredLoader } from 'react-ui-kit-exante';

import {
  useGetCoreUserQuery,
  useGetCurrentUserAccessRightsQuery,
  usePostTransactionsMutation,
} from '~/api';
import { useGetOperationTypesQuery } from '~/api/types/types.api';
import { useAccountsAutocomplete, useBack } from '~/hooks';
import { symbolsService } from '~/resources';
import { TRANSACTIONS_PATH } from '~/routes';
import { flattenSelectedValues, sendNotification } from '~/shared/utils';
import { selectCurrenciesOptionsByWeight } from '~/store/currencies';
import { ITransactionForm } from '~/types/transactions';

import { useAccountTabs } from '../AccountPage/TabManagement/hooks';

import { Transaction } from './components/Transaction';
import { DEFAULT_VALUES, TRANSACTION_TYPE_OPTIONS } from './constants';
import {
  useDisabled,
  useExternalCounterpartyOptions,
  useOperationTypesAllowedForAutoConversion,
} from './hooks';
import { TRANSACTION_ADD_FORM_VALIDATION_SCHEMA } from './validationSchema';

export const TransactionAddPage = () => {
  const location = useLocation();
  const useFormMethods = useForm<ITransactionForm>({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(TRANSACTION_ADD_FORM_VALIDATION_SCHEMA),
  });
  const currenciesOptions = useSelector(selectCurrenciesOptionsByWeight);
  const [postTransaction] = usePostTransactionsMutation();
  const { data: operationTypes } = useGetOperationTypesQuery();
  const operationTypeOptions = (operationTypes?.values || []).map(
    (operationType) => ({
      value: operationType,
      label: operationType.split('/').map(capitalize).join('/'),
    }),
  );

  const accountId = location?.state?.accountId;
  const { data: { userId } = {} } = useGetCurrentUserAccessRightsQuery();
  const {
    data: currentUser,
    isLoading: isLoadingCurrentUser,
    isFetching: isFetchingCurrentUser,
  } = useGetCoreUserQuery({ id: Number(userId) });
  const navigate = useNavigate();

  useEffect(() => {
    const currentUserHasAllBrandingPermissions =
      currentUser?.info.brandingPermission === 'ALL';

    if (!currentUserHasAllBrandingPermissions) {
      navigate('/', {
        state: { previousPath: window.location.href },
      });
    }
  }, [currentUser?.info.brandingPermission, navigate]);

  const { externalCounterpartyOptions, isExternalCounterpartyLoading } =
    useExternalCounterpartyOptions();
  const { isOperationTypesAllowedForAutoConversionLoading } =
    useOperationTypesAllowedForAutoConversion();

  const { activityTabIndex } = useAccountTabs();

  const successCallback = useCallback(() => {
    const data = useFormMethods.getValues();
    const use4EyesCheck = data.extraData['4EyesCheck'];

    sendNotification(
      `Transaction has been submitted${use4EyesCheck ? ' for approval' : ''}`,
      'success',
    );
  }, [useFormMethods]);

  const redirectToTransactions = useBack({
    defaultPath: TRANSACTIONS_PATH,
  });

  const redirectToTransactionsPage = useCallback(() => {
    redirectToTransactions();

    localStorage.setItem(
      'currentTab',
      accountId ? String(activityTabIndex) : '0',
    );
  }, [accountId, activityTabIndex, redirectToTransactions]);

  const onSubmitHandler = useCallback(
    async (data: ITransactionForm) => {
      const result = await postTransaction({
        params: flattenSelectedValues(data),
        onSuccess: successCallback,
      });

      if ('data' in result && result.data) {
        redirectToTransactionsPage();
      }
    },
    [postTransaction, redirectToTransactionsPage, successCallback],
  );

  const submitHandle = useFormMethods.handleSubmit(onSubmitHandler);
  const getAccountsAutocompleteFn = useAccountsAutocomplete();

  const fetchData = useMemo(
    () => ({
      accountId: getAccountsAutocompleteFn(),
      asset: symbolsService.modern_autoCompleteSearchSymbols.bind(null, 0),
      symbolId: symbolsService.autoCompleteSearchSymbols.bind(null, 0),
    }),
    [getAccountsAutocompleteFn],
  );

  const options = useMemo(
    () => ({
      currencies: currenciesOptions,
      externalCounterparty: externalCounterpartyOptions,
      operationType: operationTypeOptions,
      transactionType: TRANSACTION_TYPE_OPTIONS,
    }),
    [currenciesOptions, externalCounterpartyOptions, operationTypeOptions],
  );

  const disabledFields = useDisabled(useFormMethods.watch);

  useEffect(() => {
    const { setValue } = useFormMethods;

    if (accountId) {
      setValue('accountId', {
        label: accountId,
        value: accountId,
      });
    }
  }, [accountId]);

  if (
    isExternalCounterpartyLoading ||
    isOperationTypesAllowedForAutoConversionLoading ||
    isLoadingCurrentUser ||
    isFetchingCurrentUser
  ) {
    return <CenteredLoader />;
  }

  return (
    <FormProvider {...useFormMethods}>
      <Transaction
        disabledFields={disabledFields}
        fetchData={fetchData}
        onCloseHandler={redirectToTransactionsPage}
        onSubmitHandler={submitHandle}
        options={options}
      />
    </FormProvider>
  );
};
