export const RemoveIcon = () => {
  return (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 8H6.66667H28"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6667 8.00002V5.33335C10.6667 4.62611 10.9477 3.94783 11.4478 3.44774C11.9479 2.94764 12.6262 2.66669 13.3334 2.66669H18.6667C19.374 2.66669 20.0523 2.94764 20.5524 3.44774C21.0525 3.94783 21.3334 4.62611 21.3334 5.33335V8.00002M25.3334 8.00002V26.6667C25.3334 27.3739 25.0525 28.0522 24.5524 28.5523C24.0523 29.0524 23.374 29.3334 22.6667 29.3334H9.33341C8.62617 29.3334 7.94789 29.0524 7.4478 28.5523C6.9477 28.0522 6.66675 27.3739 6.66675 26.6667V8.00002H25.3334Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3333 14.6667V22.6667"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.6667 14.6667V22.6667"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
