import { createApi } from '@reduxjs/toolkit/query/react';

import { START_END_DATE_FIELDS } from '~/constants';
import { baseQueryHandler, paramsTransformer } from '~/shared/utils';
import { prepareDateRangeStringForAPI } from '~/shared/utils/dates';
import { TMapper } from '~/types/api';

export const FETCH_NOTIFICATIONS = '/api/notifications/';

export const NOTIFICATIONS_PARAMS_MAPPER: TMapper = {
  date: {
    parser: (values: [string, string]) =>
      prepareDateRangeStringForAPI(START_END_DATE_FIELDS, values),
  },
};

export const notificationsApi = createApi({
  reducerPath: 'notificationsApi',
  baseQuery: baseQueryHandler,
  endpoints: (builder) => ({
    getNotifications: builder.query({
      query: (params) => {
        const preparedParams = paramsTransformer({
          params,
          mapper: NOTIFICATIONS_PARAMS_MAPPER,
        });
        return {
          url: FETCH_NOTIFICATIONS,
          params: preparedParams,
        };
      },
    }),
  }),
});

export const { useLazyGetNotificationsQuery } = notificationsApi;
