import cn from 'classnames';
import { FC, useCallback, useMemo, useRef } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { IconButton, Modal, Panel, useModal } from 'react-ui-kit-exante';

import { CONFIRM_DELETE_MESSAGE } from '~/constants';
import { usePickUserPermissions, useUsersAutocomplete } from '~/hooks';

import { FormSelectAsyncContainer } from '../../form';

import SubUsersStyles from './SubUsers.module.css';

interface ISubUsersProps {
  hasUserInfoWriteAccess: boolean;
  userName: string;
}

export const SubUsers: FC<ISubUsersProps> = ({
  hasUserInfoWriteAccess,
  userName,
}) => {
  const getUsersAutocompleteFn = useUsersAutocomplete();
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'subUsers',
  });

  const confirmModal = useModal();
  const confirmIndex = useRef<number | null>(null);

  const userPermissions = usePickUserPermissions([
    'User bindings',
    'User permissions on services',
  ]);

  const fetchUsers = useMemo(
    () => getUsersAutocompleteFn(),
    [getUsersAutocompleteFn],
  );

  const handleAddRow = useCallback(
    () => append({ subUser: null, user: userName }),
    [append, userName],
  );
  const handleDeleteClick = (index: number) => () => {
    confirmIndex.current = index;
    confirmModal.onOpen();
  };

  const handleRemoveRow = () => {
    if (confirmIndex.current === null) {
      return;
    }

    remove(confirmIndex.current);
    confirmModal.onClose();
  };

  if (
    !userPermissions['User bindings'].read ||
    !userPermissions['User permissions on services'].read
  ) {
    return null;
  }

  return (
    <Panel
      className={cn(SubUsersStyles.Container, {
        Disabled:
          !hasUserInfoWriteAccess ||
          !userPermissions['User bindings'].write ||
          !userPermissions['User permissions on services'].write,
      })}
      title="Sub users"
    >
      {fields.map((field, index) => (
        <div key={field.id} className={SubUsersStyles.Item}>
          <FormSelectAsyncContainer
            label="User name"
            name={`subUsers.${index}.subUser`}
            fetchData={fetchUsers}
          />

          <div className={SubUsersStyles.DeleteWrapper}>
            <IconButton
              iconName="DeleteIcon"
              iconColor="radical"
              iconSize={24}
              onClick={handleDeleteClick(index)}
            />
          </div>
        </div>
      ))}

      <div className={SubUsersStyles.AddWrapper}>
        <IconButton
          iconName="AddIcon"
          iconColor="action"
          onClick={handleAddRow}
        />
      </div>

      <Modal
        isOpened={confirmModal.isOpened}
        onClose={confirmModal.onClose}
        title="Are you sure?"
        confirmButton={{
          handleConfirm: handleRemoveRow,
        }}
        keepMounted={false}
      >
        <div>{CONFIRM_DELETE_MESSAGE}</div>
      </Modal>
    </Panel>
  );
};
