import { styled, TDefaultTheme } from 'react-ui-kit-exante';

export const StyledContainer = styled('div')(() => ({
  position: 'relative',
}));

export const StyledInput = styled('input')(
  ({ isWarning, theme }: { isWarning: boolean; theme?: TDefaultTheme }) => ({
    borderRadius: '4px',
    backgroundColor: isWarning ? '#fff' : 'transparent',
    border: '1px solid transparent',
    outline: 'none',
    paddingRight: '22px',
    appearance: 'textfield',
    borderColor: isWarning ? '1px solid #ec9f0b' : '1px solid transparent',
    color: theme?.color.typo.primary,

    '&:focus': {
      backgroundColor: theme?.color.input.bg.hover,
    },
    '&:active': {
      backgroundColor: theme?.color.input.bg.hover,
    },
  }),
);

export const StyledReset = styled('button')(
  ({ isWarning }: { isWarning: boolean }) => ({
    width: '12px',
    height: '12px',
    display: 'inline-block',
    position: 'absolute',
    right: '10px',
    top: 'calc(50% - 6px)',
    zIndex: '1',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    border: 'none',
    visibility: 'hidden',

    '> svg': {
      width: '12px',
      height: '12px',
    },
    ...(isWarning && {
      visibility: 'visible',
    }),
  }),
);

export const StyledDisabled = styled('span')(() => ({
  padding: '0 2px',
}));
