import { IconButton, Panel, Table } from 'react-ui-kit-exante';
import { ActionButtonProps } from 'react-ui-kit-exante/build/Components/Table/ActionButton/ActionButton.types';

import { DEFAULT_SORT_TABLE_BY } from '~/constants';
import { FormInputFileContainer } from '~/containers/form';
import { CloseButton } from '~/shared/components';
import { downloadFromUrl, getTableId } from '~/shared/utils';
import {
  TRADE_TEMPLATE,
  TRADE_TEMPLATE_FILENAME,
} from '~/shared/utils/files/paths';
import {
  ITradeCSV,
  TTradeImportColumn,
  TTradeImportGetRowProps,
} from '~/types/trades';

import TradeImportStyles from './TradeImport.module.css';

interface ITradeImport {
  additionalActions: ActionButtonProps[] | undefined;
  onInputFile(event: React.ChangeEvent<HTMLInputElement>): void;
  columns: TTradeImportColumn[];
  displayTable: boolean;
  getRowProps: TTradeImportGetRowProps;
  inputFileText: string;
  isLoading: boolean;
  tableData: ITradeCSV[];
  onClose(): void;
}

export const TradeImport = ({
  additionalActions,
  onInputFile,
  columns,
  displayTable,
  getRowProps,
  inputFileText,
  isLoading,
  tableData,
  onClose,
}: ITradeImport) => {
  const onDownload = () => {
    downloadFromUrl({
      url: TRADE_TEMPLATE,
      filename: TRADE_TEMPLATE_FILENAME,
    });
  };

  return (
    <section className={TradeImportStyles.TradeImport}>
      <Panel
        action={
          <div className={TradeImportStyles.ActionsWrapper}>
            <IconButton
              iconColor="action"
              iconName="DownloadIcon"
              iconSize={36}
              label="Download import template"
              onClick={onDownload}
            />

            <FormInputFileContainer accept=".csv" onChange={onInputFile}>
              {inputFileText}
            </FormInputFileContainer>

            <CloseButton onClick={onClose} />
          </div>
        }
        disableBodyPaddings
        title="Bulk import (trades)"
      />

      {displayTable && (
        <Table
          additionalActions={additionalActions}
          className={TradeImportStyles.TradeImportTable}
          columns={columns}
          data={tableData}
          defaultSortBy={DEFAULT_SORT_TABLE_BY}
          getRowProps={getRowProps}
          hasPagination
          isLoading={isLoading}
          showScrollbar
          tableId={getTableId('TradeImport')}
        />
      )}
    </section>
  );
};
