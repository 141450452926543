import { createElement } from 'react';
import { FilterValue, IColumn } from 'react-ui-kit-exante';

import { IAuditLog } from '~/resources/auditLog';
import { TooltipWrapper } from '~/shared/components/TooltipWrapper';
import { getPredefinedDateRanges } from '~/shared/utils';

export const DISPLAYED_COLUMN_KEYS: (keyof IAuditLog)[] = [
  'account',
  'change',
  'description',
  'ip',
  'symbol',
  'time',
  'type',
  'user',
  'who',
];

interface IGetColumns {
  onFilter(col: string, value: FilterValue): void;
  onRemove(col: string): void;
}

export function getColumns({
  onFilter,
  onRemove,
}: IGetColumns): IColumn<IAuditLog>[] {
  return [
    {
      Header: 'Time',
      accessor: 'time',
      type: 'dateTimeRange',
      onFilter,
      onToday: onFilter,
      onYesterday: onFilter,
      onLastWeek: onFilter,
      onRemove,
      formatting: 'dateTimeUTC',
      predefinedDateRanges: getPredefinedDateRanges(onFilter),
    },
    {
      Header: 'Change',
      accessor: 'change',
      width: 300,
      onFilter,
      onRemove,
      tooltip: {
        type: 'custom',
        options: {
          Title(value: unknown) {
            return createElement(TooltipWrapper, { value });
          },
        },
      },
    },
    {
      Header: 'Type',
      accessor: 'type',
      onFilter,
      onRemove,
      tooltip: 'custom',
    },
    {
      Header: 'Account',
      accessor: 'account',
      onFilter,
      onRemove,
    },
    {
      Header: 'User',
      accessor: 'user',
      onFilter,
      onRemove,
    },
    {
      Header: 'Who',
      accessor: 'who',
      onFilter,
      onRemove,
    },
    {
      Header: 'IP',
      accessor: 'ip',
      disableFilters: true,
    },
    {
      Header: 'Symbol',
      accessor: 'symbol',
      onFilter,
      onRemove,
    },
    {
      Header: 'Description',
      accessor: 'description',
      onFilter,
      onRemove,
      disableSortBy: true,
      tooltip: 'custom',
    },
  ];
}
