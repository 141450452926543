import { isEqual, isFunction, pick } from 'lodash';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Bookmarks,
  ExportTableParams,
  Table,
  useTableData,
  Notification,
  Modal,
  OnSaveEditableRow,
  IOnFetchArguments,
} from 'react-ui-kit-exante';

import {
  useGetCoreUserQuery,
  useGetCurrentUserAccessRightsQuery,
  useLazyGetTransactionsForExportQuery,
  useLazyGetTransactionsQuery,
  useRollbackTransactionMutation,
  useUpdateTransactionMutation,
} from '~/api';
import {
  useGetAccountTypesQuery,
  useGetCategoriesTypesQuery,
  useGetLegalEntityTypesQuery,
  useGetOperationTypesQuery,
} from '~/api/types/types.api';
import { NO_DATA_HEIGHT } from '~/constants';
import { prepareInitialSelectedColumns } from '~/containers/PositionsContainer/hooks/useSelectedColumns/helpers';
import {
  useAccountsAutocomplete,
  useLogHandleTime,
  usePrevious,
  useSyncTableViewParams,
} from '~/hooks';
import { getDefaultBookmarkResponse } from '~/hooks/useBookmark/helpers';
import { IBookmarkResponseProps } from '~/hooks/useBookmark/types';
import { RollbackIcon } from '~/images/icons/Rollback';
import { symbolsService } from '~/resources';
import { WithBookmarks } from '~/shared/components/WithBookmarks';
import {
  calculateCountOfPages,
  clearTableLocalStorageForActionColumn,
  getIsFieldExistsAndEmpty,
  prepareParamsWithAccountId,
  sendNotification,
  transformVariantsToSelectOptions,
} from '~/shared/utils';
import { selectTypes } from '~/store/types';
import { IRefreshActiveTabQuery } from '~/types/refetch';
import { ITransaction, ITransactionsState } from '~/types/transactions';

import { TABLE_ID } from './constants';
import {
  DISPLAYED_COLUMN_KEYS,
  getAdditionalFilters,
  getColumns,
  getDefaultFilters,
} from './filters';
import { useAdditionalActions } from './hooks';
import { DEFAULT_SORTING_TS, getDefaultSorting } from './sorting';
import { TGlobalFilters } from './types';

interface ITransactionsContainerProps {
  entity?: string;
  globalFilters?: TGlobalFilters;
  tableId?: string;
  updateRefetch?: (state: IRefreshActiveTabQuery) => void;
  withAddButton?: boolean;
}

export const Transactions: FC<
  ITransactionsContainerProps & IBookmarkResponseProps
> = ({
  entity = 'transactions-list',
  globalFilters,
  handleDeleteBookmark,
  handleSaveAsNewBookmark,
  handleSaveBookmark,
  handleShareBookmark,
  selectedBookmark,
  tableId = TABLE_ID,
  updateRefetch,
  withAddButton = true,
}) => {
  const [rollbackTransaction] = useRollbackTransactionMutation();

  const { data: types } = useSelector(selectTypes);
  const { setStartHandleTime, logHandleTime } = useLogHandleTime(entity);
  const [fetchTransactions] = useLazyGetTransactionsQuery();
  const [updateTransaction, { isLoading: isUpdateTransactionLoading }] =
    useUpdateTransactionMutation();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [rollbackId, setRollbackId] = useState<null | number>(null);
  const { data: legalEntityTypes } = useGetLegalEntityTypesQuery();
  const { data: categoryTypes } = useGetCategoriesTypesQuery();
  const { data: operationTypes } = useGetOperationTypesQuery();
  const { data: { userId } = {} } = useGetCurrentUserAccessRightsQuery();
  const { data: currentUser } = useGetCoreUserQuery({ id: Number(userId) });
  const currentUserHasAllBrandingPermissions =
    currentUser?.info.brandingPermission === 'ALL';

  const additionalActions = useAdditionalActions(withAddButton);

  const getTransactions = useCallback(
    async ({ params }: IOnFetchArguments) => {
      const isEmptyAccountsList =
        globalFilters && getIsFieldExistsAndEmpty(globalFilters, 'accountId');
      const defaultState = { transactions: [], pagination: { total: 0 } };

      if (isEmptyAccountsList) {
        const emptyResponse = { transactions: [], pagination: { total: 0 } };
        return emptyResponse;
      }
      const preparedParams = prepareParamsWithAccountId(
        {
          ...globalFilters,
          ...params,
        },
        'accountId',
      );

      setStartHandleTime();

      const { data, error } = await fetchTransactions(preparedParams);

      if (error || !data) {
        return defaultState;
      }

      return data;
    },
    [fetchTransactions, globalFilters, setStartHandleTime],
  );

  const tableArgs = useMemo(
    () => ({
      data: { onFetch: getTransactions },
      filters: { getDefaultFilters, required: ['fromTo'] },
      sorting: { getDefaultSorting },
      tableId,
      saveViewParamsAfterLeave: true,
    }),
    [getTransactions, tableId],
  );

  const {
    data,
    limit,
    setLimit,
    setPage,
    page,
    isLoading,
    setFilter,
    removeFilter,
    resetFilters,
    setSorting,
    filters,
    fetchData: refetch,
  } = useTableData<ITransactionsState>(tableArgs);
  const [fetchTransactionsForExport] = useLazyGetTransactionsForExportQuery();
  const getAccountsAutocompleteFn = useAccountsAutocomplete();

  useEffect(() => {
    if (isFunction(updateRefetch)) {
      updateRefetch({
        refetch,
        isLoading,
      });
    }
  }, [updateRefetch, refetch, isLoading]);

  const total = data?.pagination.total || 0;
  const pageCount = useMemo(
    () => calculateCountOfPages(total, limit),
    [limit, total],
  );

  const fetchSymbols = useMemo(
    () => symbolsService.modern_autoCompleteSearchSymbols.bind(null, 0),
    [],
  );

  const columns = useMemo(
    () =>
      getColumns({
        onFilter: setFilter,
        onRemove: removeFilter,
        types: {
          ...types,
          legalEntity: legalEntityTypes?.values || [],
          categories: categoryTypes?.values || [],
          operations: operationTypes?.values || [],
        },
        fetchAccounts: getAccountsAutocompleteFn(),
        fetchSymbols,
      }),
    [
      setFilter,
      removeFilter,
      types,
      legalEntityTypes?.values,
      categoryTypes?.values,
      operationTypes?.values,
      getAccountsAutocompleteFn,
      fetchSymbols,
    ],
  );
  const previousDataTransactions = usePrevious(data?.transactions);

  const exportTableParams = useMemo(
    () => ({
      type: 'server',
      onFetch: async (params) => {
        const selectedColumns = prepareInitialSelectedColumns(
          columns,
          ['transactionsTable-id-columns'],
          DISPLAYED_COLUMN_KEYS,
        );

        const preparedParams = prepareParamsWithAccountId(
          {
            ...globalFilters,
            ...params,
          },
          'accountId',
        );

        const { data: transactionsForExport } =
          await fetchTransactionsForExport({
            ...preparedParams,
            fields: Object.keys(selectedColumns).filter((column) => column),
          });

        return transactionsForExport;
      },
    }),
    [columns, fetchTransactionsForExport, globalFilters],
  ) as ExportTableParams<ITransaction> | undefined;

  const { data: accountTypes } = useGetAccountTypesQuery();
  const accountTypesOptions = transformVariantsToSelectOptions(
    accountTypes?.values,
  );

  const additionalFilters = useMemo(
    () =>
      getAdditionalFilters({
        onFilter: setFilter,
        onRemove: removeFilter,
        defaultFilters: getDefaultFilters(),
        accountTypes: accountTypesOptions,
      }),
    [removeFilter, setFilter, accountTypesOptions],
  );
  const filteringProps = useMemo(
    () => ({
      removeAllFilters: resetFilters,
      additionalFilters,
      filters,
      manualFilters: true,
      required: ['fromTo'],
    }),
    [additionalFilters, filters, resetFilters],
  );
  const serverPaginationProps = useMemo(
    () => ({
      pageSize: limit,
      setPage,
      setPageSize: setLimit,
      pageIndex: page,
      total,
      pageCount,
    }),
    [limit, page, pageCount, setLimit, setPage, total],
  );

  useSyncTableViewParams({ pageCount, setPage, tableId });

  useEffect(() => {
    if (
      data?.transactions &&
      !isEqual(previousDataTransactions, data?.transactions)
    ) {
      logHandleTime();
    }
  }, [logHandleTime, data, previousDataTransactions]);

  const bookmarkComponent = useMemo(() => {
    if (globalFilters) {
      return null;
    }

    return (
      <Bookmarks
        initialValues={selectedBookmark}
        onSave={(name) => handleSaveBookmark(name, filters)}
        onSaveAsNew={(name) => handleSaveAsNewBookmark(name, filters)}
        onShare={handleShareBookmark}
        onDelete={handleDeleteBookmark}
      />
    );
  }, [
    filters,
    globalFilters,
    handleSaveBookmark,
    handleSaveAsNewBookmark,
    handleShareBookmark,
    handleDeleteBookmark,
    selectedBookmark,
  ]);

  const displayedColumnKeys = useMemo(
    () =>
      selectedBookmark.columns.length
        ? selectedBookmark.columns
        : DISPLAYED_COLUMN_KEYS,
    [selectedBookmark.columns],
  );

  const successCallback = useCallback(() => {
    sendNotification('Trade has been updated', 'success');
  }, []);

  const errorCallback = useCallback(() => {
    sendNotification('Error updating trade', 'error');
  }, []);

  const onSaveRowHandler: OnSaveEditableRow<ITransaction> = useCallback(
    async (_, newRow) => {
      const result = await updateTransaction(
        pick(newRow, ['uuid', 'comment', 'internalComment']),
      );

      refetch();

      if (result) {
        successCallback();
      } else {
        errorCallback();
      }
    },
    [refetch, updateTransaction, successCallback, errorCallback],
  );

  useEffect(() => {
    clearTableLocalStorageForActionColumn(tableId);
  }, [tableId]);

  const handleRollbackTransaction = useCallback(async () => {
    if (rollbackId === null) {
      return;
    }

    setShowConfirmModal(false);

    const res = await rollbackTransaction(rollbackId);

    if ('error' in res) {
      Notification.error({
        title: `Error while rolling back: ${res.error}`,
      });
    } else {
      Notification.success({
        title: 'Transaction was rolled back',
      });
    }

    setRollbackId(null);
  }, [rollbackId, rollbackTransaction]);

  return (
    <>
      <Table
        additionalActions={additionalActions}
        columns={columns}
        data={data?.transactions || []}
        defaultSortBy={DEFAULT_SORTING_TS}
        displayedColumnKeys={displayedColumnKeys}
        exportTableParams={exportTableParams}
        filteringProps={filteringProps}
        filtersRightPanelComponent={bookmarkComponent}
        hasFilters
        hasPagination
        isFlexLayout
        isLoading={isLoading || isUpdateTransactionLoading}
        isPinnedHeader
        manualSortBy
        noDataHeight={NO_DATA_HEIGHT}
        onSort={setSorting}
        {...(currentUserHasAllBrandingPermissions
          ? {
              rowActions: {
                show: true,
                onSave: onSaveRowHandler,
                additionalActions: [
                  {
                    label: <RollbackIcon />,
                    title: 'Rollback',
                    order: 0,
                    onClick: ({ id }: ITransaction) => {
                      if (id) {
                        setRollbackId(id);
                        setShowConfirmModal(true);
                      }
                    },
                    show: (_, values: ITransaction) => {
                      return !(values.operationType === 'TRADE');
                    },
                  },
                ],
                order: 1,
              },
            }
          : {})}
        saveColumnOrder
        saveViewParamsAfterLeave
        serverPaginationProps={serverPaginationProps}
        showScrollbar
        showTableInfo
        tableId={tableId}
        title="Transactions"
      />
      <Modal
        title=""
        isOpened={showConfirmModal}
        onClose={() => {
          setShowConfirmModal(false);
          setRollbackId(null);
        }}
        confirmButton={{
          handleConfirm: handleRollbackTransaction,
          confirmButtonName: 'Rollback',
        }}
        cancelButton={{
          cancelButtonName: 'Cancel',
        }}
        keepMounted={false}
      >
        Rollback transaction?
      </Modal>
    </>
  );
};

export const TransactionsContainer: FC<ITransactionsContainerProps> = ({
  entity = 'transactions-list',
  globalFilters,
  tableId = TABLE_ID,
  updateRefetch,
  withAddButton = true,
}) => {
  if (globalFilters) {
    const {
      selectedBookmark,
      handleSaveBookmark,
      handleSaveAsNewBookmark,
      handleShareBookmark,
      handleDeleteBookmark,
    } = getDefaultBookmarkResponse('Transactions');

    return (
      <Transactions
        entity={entity}
        globalFilters={globalFilters}
        tableId={tableId}
        updateRefetch={updateRefetch}
        withAddButton={withAddButton}
        selectedBookmark={selectedBookmark}
        handleSaveBookmark={handleSaveBookmark}
        handleSaveAsNewBookmark={handleSaveAsNewBookmark}
        handleShareBookmark={handleShareBookmark}
        handleDeleteBookmark={handleDeleteBookmark}
      />
    );
  }

  return (
    <WithBookmarks
      component={Transactions}
      entity={entity}
      globalFilters={globalFilters}
      pageName="Transactions"
      tableId={tableId}
      updateRefetch={updateRefetch}
      withAddButton={withAddButton}
    />
  );
};
