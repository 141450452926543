import { get } from 'lodash';
import { ICellValue, IColumn } from 'react-ui-kit-exante';

import { PATH_DELIMITER } from '~/constants/common';

import { CheckBoxCell, NameCell } from './components';
import { IntegerCell } from './components/IntegerCell';
import { toExpandedTreePath } from './helpers';
import { ITreeNode } from './types';

const riskArrayColumnStyles = {
  padding: 8,
};

const renderRiskArrayCell = ({
  column,
  row: {
    original: { ...rest },
  },
  value,
}: ICellValue<ITreeNode>) => {
  if (rest.type !== 'leaf') {
    return <span>{value}</span>;
  }
  return (
    <IntegerCell
      name={column.id}
      path={rest.index}
      id={rest.id}
      fullPath={`${toExpandedTreePath(rest.index)}.${column.id}`}
    />
  );
};

export const getColumns = ({
  iconsMap,
}: {
  iconsMap: Record<string, string>;
}): IColumn<ITreeNode>[] => [
  {
    Header: 'Name',
    id: 'name',
    accessor: 'name',
    disableSortBy: true,
    width: 500,
    Cell: ({ row }: ICellValue<ITreeNode>) => {
      const name = row.original.name || '';
      const hasStrikes = Boolean(get(row, 'original.strikes.length'));
      const isLeaf = row.original.type === 'leaf' && row.original.path;

      return (
        <NameCell
          name={name}
          icon={
            isLeaf || hasStrikes
              ? iconsMap[row.original.path.split(PATH_DELIMITER)[0]]
              : row.original.icon
          }
        />
      );
    },
  },
  {
    Header: 'Underlying',
    id: 'underlying',
    accessor: 'underlying',
    width: 125,
    disableSortBy: true,
    cellStyle: {
      padding: 8,
    },
  },
  {
    Header: 'Initial margin',
    id: 'initialMargin',
    accessor: 'initialMargin',
    width: 125,
    disableSortBy: true,
    cellStyle: {
      padding: 8,
    },
    Cell: ({ column, row }: ICellValue<ITreeNode>) => {
      if (row.original.type === 'leaf') {
        return row.values[column.id];
      }
      return '–';
    },
  },
  {
    Header: 'Enabled',
    id: 'usePortfolioMargin',
    accessor: 'usePortfolioMargin',
    width: 90,
    type: 'checkbox',
    disableSortBy: true,
    cellStyle: {
      padding: 8,
    },
    Cell: ({ column, row: { original } }: ICellValue<ITreeNode>) => {
      return (
        <CheckBoxCell
          id={original.id}
          name={column.id}
          path={original.index}
          type={original.type}
          subRows={original.subRows}
        />
      );
    },
  },
  {
    Header: 'is Auto',
    id: 'isAutoCalculated',
    accessor: 'isAutoCalculated',
    width: 90,
    type: 'checkbox',
    disableSortBy: true,
    cellStyle: {
      padding: 8,
    },
    Cell: ({ column, row: { original } }: ICellValue<ITreeNode>) => {
      return (
        <CheckBoxCell
          id={original.id}
          name={column.id}
          path={original.index}
          type={original.type}
          subRows={original.subRows}
          root={original.path}
        />
      );
    },
  },
  {
    Header: 'FOV+1',
    id: 'risk-array-0',
    accessor: 'risk-array-0',
    width: 80,
    type: 'number',
    disableSortBy: true,
    Cell: renderRiskArrayCell,
    cellStyle: riskArrayColumnStyles,
  },
  {
    Header: 'FOV-1',
    id: 'risk-array-1',
    accessor: 'risk-array-1',
    width: 80,
    type: 'number',
    disableSortBy: true,
    Cell: renderRiskArrayCell,
    cellStyle: riskArrayColumnStyles,
  },
  {
    Header: 'F+1/3V+1',
    id: 'risk-array-2',
    accessor: 'risk-array-2',
    width: 80,
    type: 'number',
    disableSortBy: true,
    Cell: renderRiskArrayCell,
    cellStyle: riskArrayColumnStyles,
  },
  {
    Header: 'F+1/3V-1',
    id: 'risk-array-3',
    accessor: 'risk-array-3',
    width: 80,
    type: 'number',
    disableSortBy: true,
    Cell: renderRiskArrayCell,
    cellStyle: riskArrayColumnStyles,
  },
  {
    Header: 'F-1/3V+1',
    id: 'risk-array-4',
    accessor: 'risk-array-4',
    width: 80,
    type: 'number',
    disableSortBy: true,
    Cell: renderRiskArrayCell,
    cellStyle: riskArrayColumnStyles,
  },
  {
    Header: 'F-1/3V-1',
    id: 'risk-array-5',
    accessor: 'risk-array-5',
    width: 80,
    type: 'number',
    disableSortBy: true,
    Cell: renderRiskArrayCell,
    cellStyle: riskArrayColumnStyles,
  },
  {
    Header: 'F+2/3V+1',
    id: 'risk-array-6',
    accessor: 'risk-array-6',
    width: 80,
    type: 'number',
    disableSortBy: true,
    Cell: renderRiskArrayCell,
    cellStyle: riskArrayColumnStyles,
  },
  {
    Header: 'F+2/3V-1',
    id: 'risk-array-7',
    accessor: 'risk-array-7',
    width: 80,
    type: 'number',
    disableSortBy: true,
    Cell: renderRiskArrayCell,
    cellStyle: riskArrayColumnStyles,
  },
  {
    Header: 'F-2/3V+1',
    id: 'risk-array-8',
    accessor: 'risk-array-8',
    width: 80,
    type: 'number',
    disableSortBy: true,
    Cell: renderRiskArrayCell,
    cellStyle: riskArrayColumnStyles,
  },
  {
    Header: 'F-2/3V-1',
    id: 'risk-array-9',
    accessor: 'risk-array-9',
    width: 80,
    type: 'number',
    disableSortBy: true,
    Cell: renderRiskArrayCell,
    cellStyle: riskArrayColumnStyles,
  },
  {
    Header: 'F+3/3V+1',
    id: 'risk-array-10',
    accessor: 'risk-array-10',
    width: 80,
    type: 'number',
    disableSortBy: true,
    Cell: renderRiskArrayCell,
    cellStyle: riskArrayColumnStyles,
  },
  {
    Header: 'F+3/3V-1',
    id: 'risk-array-11',
    accessor: 'risk-array-11',
    width: 80,
    type: 'number',
    disableSortBy: true,
    Cell: renderRiskArrayCell,
    cellStyle: riskArrayColumnStyles,
  },
  {
    Header: 'F-3/3V+1',
    id: 'risk-array-12',
    accessor: 'risk-array-12',
    width: 80,
    type: 'number',
    disableSortBy: true,
    Cell: renderRiskArrayCell,
    cellStyle: riskArrayColumnStyles,
  },
  {
    Header: 'F-3/3V-1',
    id: 'risk-array-13',
    accessor: 'risk-array-13',
    width: 80,
    type: 'number',
    disableSortBy: true,
    Cell: renderRiskArrayCell,
    cellStyle: riskArrayColumnStyles,
  },
  {
    Header: 'F+EX',
    id: 'risk-array-14',
    accessor: 'risk-array-14',
    width: 80,
    type: 'number',
    disableSortBy: true,
    Cell: renderRiskArrayCell,
    cellStyle: riskArrayColumnStyles,
  },
  {
    Header: 'F-EX',
    id: 'risk-array-15',
    accessor: 'risk-array-15',
    width: 80,
    type: 'number',
    disableSortBy: true,
    Cell: renderRiskArrayCell,
    cellStyle: riskArrayColumnStyles,
  },
  {
    Header: 'Δ',
    id: 'delta',
    accessor: 'delta',
    width: 80,
    type: 'number',
    disableSortBy: true,
    Cell: renderRiskArrayCell,
    cellStyle: riskArrayColumnStyles,
  },
];
