import Big from 'big.js';
import { ChangeEvent, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { IColumn, IRowExpand, Notification } from 'react-ui-kit-exante';

import { updateInstrumentValue, updateNodeValue } from '~/store/commissions';
import { FilterLayers, ICommissionTree } from '~/types/commissions';
import { RowType } from '~/types/common';

import { PERCENT_MARKER } from '../../../../constants';

import { StyledInput, StyledButton } from './TextCell.styled';

interface IValueCell {
  column: IColumn<ICommissionTree>;
  row: IRowExpand<ICommissionTree>;
  value: null | string | number;
}

export const TextCell = ({ column, value, row }: IValueCell) => {
  const MULTIPLIER = column.Header.endsWith(PERCENT_MARKER) ? 100 : 1;
  const { layer } = column.getProps();
  const dispatch = useDispatch();

  const [isEdit, setIsEdit] = useState(false);
  const [inputValue, setInputValue] = useState<null | number | string>(() => {
    if (value) {
      return new Big(value).times(new Big(MULTIPLIER)).toString();
    }
    return value;
  });

  const isNodeCell = row.original.rowType === RowType.Node;

  const onClickHandler = useCallback(() => {
    if (layer === FilterLayers.Default && row.original.inherited) {
      Notification.warning({
        title: `You can not change inherited ${
          row.original.rowType === 'node' ? 'node' : 'instrument'
        }`,
      });

      return;
    }

    if (column.id.startsWith('exchange.') && layer !== FilterLayers.Default) {
      Notification.warning({
        title: 'You can not change exchange on non-default layer',
      });

      return;
    }

    if (layer !== FilterLayers.Default && !row.original.override) {
      Notification.warning({
        title: `You can not change non-overridable commission`,
      });

      return;
    }

    setIsEdit(true);
  }, [layer, row, column]);

  const onChangeHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setInputValue(e.target.value || null);
    },
    [setInputValue],
  );

  const onBlurHandler = useCallback(async () => {
    const actionForUpdateValue = isNodeCell
      ? updateNodeValue
      : updateInstrumentValue;

    dispatch(
      actionForUpdateValue({
        path: row.original.path,
        value: inputValue === null ? null : Big(inputValue).div(MULTIPLIER),
        column: column.id,
      }),
    );

    setIsEdit(false);
  }, [MULTIPLIER, isNodeCell, column, dispatch, inputValue, row, setIsEdit]);

  if (isEdit) {
    return (
      <StyledInput
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        type="number"
        value={inputValue || ''}
        onChange={onChangeHandler}
        onBlur={onBlurHandler}
      />
    );
  }

  return (
    <StyledButton onClick={onClickHandler}>
      {!value ? '—' : new Big(value).times(new Big(MULTIPLIER)).toString()}
    </StyledButton>
  );
};
