import { createApi } from '@reduxjs/toolkit/query/react';
import { Notification } from 'react-ui-kit-exante';

import { PROXY_CORE_PATH } from '~/constants';
import { baseQueryHandler } from '~/shared/utils';

interface TransferBodyProps {
  to: string;
  from: string;
  asset?: string;
  amount: number;
  comment: string;
  username: string;
  currency?: string;
  internalComment: string;
  isTransferValidationOff: boolean;
}

export const transferApi = createApi({
  reducerPath: 'transferApi',
  baseQuery: baseQueryHandler,
  endpoints: (builder) => ({
    addTransfer: builder.mutation({
      query: (data) => {
        const {
          to,
          from,
          type,
          asset,
          amount,
          comment,
          currency,
          username,
          internalComment,
        } = data;
        const body: TransferBodyProps = {
          amount,
          comment,
          to: to?.value,
          internalComment,
          from: from?.value,
          username: username?.value,
          isTransferValidationOff: true,
        };

        if (type === 'currency') {
          body.currency = currency;
        } else {
          body.asset = asset;
        }

        return {
          url: `${PROXY_CORE_PATH}v2.1/transfer`,
          method: 'POST',
          data: body,
        };
      },
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Notification.success({
            title: 'Success',
          });
        } catch (e) {
          console.error(e);
        }
      },
    }),
  }),
});

export const { useAddTransferMutation } = transferApi;
