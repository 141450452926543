import { ChangeEvent, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { AutocompleteAsync, Select } from 'react-ui-kit-exante';

import {
  useGetOvernightsGroupQuery,
  useLazyGetRegularPaymentsSettingsQuery,
  useLazySearchByAccountsQuery,
} from '~/api';
import { EMPTY_ARRAY } from '~/constants';
import { useAccountsAutocomplete } from '~/hooks';
import { FiltersWrapper } from '~/shared/components';
import {
  dictSetRegularPaymentSettings,
  filtersSetAccount,
  filtersSetGroup,
  filtersSetRelatedGroup,
  selectDownloadedPaths,
  selectFiltersAccount,
  selectFiltersGroup,
  selectFiltersLayer,
} from '~/store/overnights';
import { getQueryParams } from '~/store/overnights/helpers';
import { FilterLayers } from '~/types/overnights';

import { useReloadInstruments } from '../../hooks';

interface IFiltersProps {
  fetchOvernightsTree: () => void;
  disabled?: boolean;
}

export const Filters = ({ fetchOvernightsTree, disabled }: IFiltersProps) => {
  const filtersGroup = useSelector(selectFiltersGroup);
  const filtersAccount = useSelector(selectFiltersAccount);
  const downloadedPaths = useSelector(selectDownloadedPaths);
  const {
    data: overnightSets,
    isLoading: loadingGroups,
    isFetching: fetchingGroups,
  } = useGetOvernightsGroupQuery();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const reloadInstruments = useReloadInstruments();
  const overnightSetsOptions =
    overnightSets?.map(({ name: label, id: value }) => ({ label, value })) ||
    EMPTY_ARRAY;
  const filtersLayer = useSelector(selectFiltersLayer);
  const initAccount = useRef(false);
  const [getRegularPaymentsSettings] = useLazyGetRegularPaymentsSettingsQuery();
  const getAccountsAutocompleteFn = useAccountsAutocomplete();
  const [searchByAccounts] = useLazySearchByAccountsQuery();

  const overnightsSetsOptionsWithDefault = [
    { value: 'default', label: 'Default' },
    ...overnightSetsOptions,
  ];

  const getAccountsOvernightsGroup = useCallback(
    async (account: string) => {
      const regularPaymentSettings = await getRegularPaymentsSettings({
        accountId: account,
      });
      if (!('error' in regularPaymentSettings)) {
        dispatch(
          dictSetRegularPaymentSettings(regularPaymentSettings.data || {}),
        );
        dispatch(
          filtersSetRelatedGroup(regularPaymentSettings.data?.overnightsSetId),
        );
      }
    },
    [dispatch, getRegularPaymentsSettings],
  );

  const onChangeAccountHandler = useCallback(
    (value: string) => {
      dispatch(filtersSetAccount(value));

      fetchOvernightsTree();
    },
    [dispatch, fetchOvernightsTree],
  );

  const setDefaultGroup = useCallback(() => {
    if (!filtersGroup && overnightSets && overnightSets.length > 0) {
      const [defaultGroup] = overnightSets;

      if (filtersLayer === FilterLayers.Groups) {
        dispatch(
          filtersSetGroup({
            value: defaultGroup.id,
            shouldResetDownloadedPaths: false,
          }),
        );
      } else if (filtersLayer === FilterLayers.Accounts) {
        dispatch(
          filtersSetRelatedGroup({
            value: defaultGroup.id,
            shouldResetDownloadedPaths: false,
          }),
        );
      }
    }
  }, [dispatch, filtersGroup, filtersLayer, overnightSets]);

  const onChangeGroupHandler = useCallback(
    async ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      dispatch(
        filtersSetGroup({
          value,
          shouldResetDownloadedPaths: filtersLayer === FilterLayers.Groups,
        }),
      );

      if (filtersLayer === FilterLayers.Groups) {
        fetchOvernightsTree();
      } else {
        const groupQueryParams = getQueryParams({
          layer: FilterLayers.Groups,
          group: Number(value),
          account: null,
          relatedGroup: null,
        });

        await reloadInstruments(downloadedPaths, groupQueryParams);
      }
    },
    [
      dispatch,
      downloadedPaths,
      fetchOvernightsTree,
      filtersLayer,
      reloadInstruments,
    ],
  );

  // TODO Should be removed when autoSelect will be added in the UI-kit
  const setDefaultAccount = useCallback(async () => {
    const res = await searchByAccounts({
      search: '',
      skip: 0,
      limit: 1,
      archived: false,
    });

    if ('error' in res || !res.data) {
      return;
    }

    const [defaultAccount] = res.data.options;
    if (defaultAccount) {
      onChangeAccountHandler(defaultAccount.value);
      setDefaultGroup();
      initAccount.current = true;
    }
  }, [onChangeAccountHandler, searchByAccounts, setDefaultGroup]);

  useEffect(() => {
    const initialAccount = searchParams.get('account');

    if (!filtersAccount && filtersLayer === FilterLayers.Accounts) {
      if (initialAccount) {
        onChangeAccountHandler(initialAccount);

        setDefaultGroup();
      } else if (!initAccount.current) {
        setDefaultAccount();
      }
    }
  }, [filtersLayer, filtersAccount]);

  useEffect(() => {
    if (filtersLayer === FilterLayers.Accounts && filtersAccount) {
      getAccountsOvernightsGroup(filtersAccount);
    }
  }, [filtersAccount, filtersLayer, getAccountsOvernightsGroup]);

  useEffect(() => {
    if (filtersLayer === FilterLayers.Groups) {
      setDefaultGroup();
    }
  }, [filtersAccount, filtersLayer, setDefaultGroup]);

  return (
    <FiltersWrapper>
      {filtersLayer === FilterLayers.Accounts && (
        <AutocompleteAsync
          fetchData={getAccountsAutocompleteFn()}
          onChange={(_, option) => {
            const { value = '' } = option || {};
            onChangeAccountHandler(value);
            setSearchParams((params) => {
              if (!value) {
                params.delete('account');
              } else {
                params.set('account', value);
              }

              return params;
            });
          }}
          options={EMPTY_ARRAY}
          placeholder="Account"
          value={filtersAccount}
          disabled={disabled}
        />
      )}

      {(filtersLayer === FilterLayers.Accounts ||
        filtersLayer === FilterLayers.Groups) && (
        <Select
          disabled={loadingGroups || fetchingGroups || disabled}
          fullWidth
          label="Group"
          onChange={onChangeGroupHandler}
          options={
            filtersLayer === FilterLayers.Groups
              ? overnightSetsOptions
              : overnightsSetsOptionsWithDefault
          }
          placeholder="Group"
          value={filtersGroup ? `${filtersGroup}` : 'default'}
        />
      )}
    </FiltersWrapper>
  );
};
