import { apiRequest, IOptionsArguments } from '~/shared/utils';
import { IDataWithPagination, TParams } from '~/types/api';
import { ISymbolsListItem } from '~/types/symbolPermissions';
import {
  ISymbolIdInfoResponse,
  TSymbolIdValueDateParams,
  TSymbolIdInfoParams,
  TSymbolTypesResponse,
} from '~/types/symbols';

import {
  FETCH_SYMBOLID_INFO_ENDPOINT,
  FETCH_SYMBOLS,
  FETCH_SYMBOLS_DEPRECATED,
  FETCH_SYMBOL_TYPES,
  FETCH_SYMBOL_VALUE_DATE_ENDPOINT,
} from './endpoints';

export class SymbolsRepository {
  public static fetchSymbols(params: TParams, options?: IOptionsArguments) {
    return apiRequest<IDataWithPagination<ISymbolsListItem[]>>(
      {
        params,
        url: FETCH_SYMBOLS_DEPRECATED,
      },
      options,
    );
  }

  public static modern_fetchSymbols(
    params: TParams,
    options?: IOptionsArguments,
  ) {
    return apiRequest<IDataWithPagination<string[]>>(
      {
        params,
        url: FETCH_SYMBOLS,
      },
      options,
    );
  }

  public static fetchSymbolIdInfo(
    params: TSymbolIdInfoParams,
    options?: IOptionsArguments,
  ) {
    return apiRequest<ISymbolIdInfoResponse>(
      {
        url: FETCH_SYMBOLID_INFO_ENDPOINT,
        params,
      },
      options,
    );
  }

  public static fetchSymbolValueDate(
    params: TSymbolIdValueDateParams,
    options?: IOptionsArguments,
  ) {
    return apiRequest<Record<'valueDate', string>>(
      {
        url: FETCH_SYMBOL_VALUE_DATE_ENDPOINT,
        params,
      },
      options,
    );
  }

  public static fetchSymbolsTypes() {
    return apiRequest<TSymbolTypesResponse>({
      url: FETCH_SYMBOL_TYPES,
    });
  }
}
