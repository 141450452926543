import { styled, TDefaultTheme } from 'react-ui-kit-exante';

export const StyledWrapper = styled('div')(() => ({
  width: '100%',

  ':first-child .Button': {
    borderTop: 'none',
  },
}));

export const StyledButton = styled('button')(
  ({ theme }: { theme: TDefaultTheme }) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    border: 'none',
    backgroundColor: theme?.color.bg.primary,
    borderTop: `1px solid ${theme?.color?.dropdown?.border}`,
    cursor: 'pointer',
    padding: '16px 24px',
    color: theme?.color.typo.primary,
  }),
);

export const StyledArrow = styled('div')(
  ({ isRight }: { isRight: boolean }) => ({
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '24px',
    height: '24px',
    color: '#b8bbb7',
    marginRight: '16px',

    svg: {
      transform: isRight ? 'rotate(90deg)' : 'initial', // Применяет поворот, если isRight равен true
    },
  }),
);

export const StyledHiddenChildren = styled('div')(
  ({ isHidden }: { isHidden: boolean }) => ({
    display: isHidden ? 'none' : 'block',
  }),
);
