import { styled, TDefaultTheme } from 'react-ui-kit-exante';

export const StyledHeader = styled('div')(
  ({ theme }: { theme: TDefaultTheme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: theme?.color.bg.primary,
    borderBottom: `1px solid ${theme?.color.line.primary}`,
    padding: '24px',
    color: theme?.color.typo.primary,
  }),
);

export const StyledTitle = styled('h2')(() => ({
  fontFamily: 'FoxSansPro, sans-serif',
  fontSize: '24px',
  lineHeight: '24px',
  fontWeight: 400,
  margin: 0,
}));

export const StyledActions = styled('div')(() => ({
  display: 'flex',
  columnGap: '16px',
}));

export const StyledActionContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: '70px',
  columnGap: '16px',
}));
