import { ReactNode } from 'react';

import UploadMarkStyles from './UploadMark.module.css';

interface IUploadMarkProps {
  children: ReactNode;
}

/**
 * This component need for table additionalActions because children inside action is button
 * And we can't pass className to style this button.
 * And I can't pass to children ui-kit Button because there is console error(semantic)
 */
export const UploadMark = ({ children }: IUploadMarkProps) => {
  return <mark className={UploadMarkStyles.Button}>{children}</mark>;
};
