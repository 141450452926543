import { cloneDeep, get, keys, pick } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { IRowExpand, Notification } from 'react-ui-kit-exante';

import {
  TRiskArrayFullNode,
  riskArraysApi,
  useLazyGetRiskArrayNodeQuery,
  useLazyGetRiskArraysTreeQuery,
  TRiskArrayTreeResponse,
} from '~/api';
import { EMPTY_ARRAY, EMPTY_OBJECT, MIN_SEARCH_LENGTH } from '~/constants';

import { DEFAULT_LIMIT, rowTypes } from '../constants';
import {
  createIconsMap,
  prepareQueriesToUpdate,
  toExpandedTreePath,
  transformRiskArrayToTree,
  transformSearchResultToTree,
} from '../helpers';
import { ITreeNode } from '../types';

import { IAddRequestToUpdate, IRemoveRequestToUpdate } from './types';
import { usePagination } from './usePagination';
import { useUpdateTree } from './useUpdateTree';

let originalCache: ITreeNode[] | null = null;
let cache: ITreeNode[] | null = null;
const requestsToUpdate = new Map();
let init = false;

const uploadedNodes = new Set();
let expanded: Record<string, boolean> = {};

let lastSearchQuery = '';

export const useRiskArrayTree = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const { update } = useUpdateTree();
  const [showExpired, setShowExpired] = useState(false);

  const [fetchTree, getRiskArraysTreeResult] = useLazyGetRiskArraysTreeQuery();
  const { data, isLoading, isFetching } = getRiskArraysTreeResult;
  const [tree, setTree] = useState<ITreeNode[]>(EMPTY_ARRAY);

  const [saveLoading, setSaveLoading] = useState(false);

  const searchEnabled = Boolean(search) && search.length >= MIN_SEARCH_LENGTH;

  const [trigger, getRiskArrayNodeResult] = useLazyGetRiskArrayNodeQuery();

  const iconsMap = useMemo(() => {
    return createIconsMap(data);
  }, [data]);

  const {
    data: node,
    isLoading: isNodeLoading,
    isFetching: isNodeFetching,
  } = getRiskArrayNodeResult;

  const {
    skip,
    limit,
    setLimit,
    loadNext,
    increaseSkip,
    resetSkip,
    showPagination,
    readSearchResults,
    addToSearchResults,
    clearSearchResults,
  } = usePagination(searchEnabled, node?.pagination.total || 0);

  const handleSearchTreeResult = useCallback((treeNodes: ITreeNode[]) => {
    cache = treeNodes;

    originalCache = cloneDeep(cache);

    if (cache) {
      originalCache = cloneDeep(cache);
      setTree(cloneDeep(cache));
    } else {
      originalCache = EMPTY_ARRAY;
    }
    setSaveLoading(false);
  }, []);

  const setInitialSearchTree = useCallback(
    (key: string, res: typeof node) => {
      setSaveLoading(true);

      addToSearchResults(key, res?.data || []);

      handleSearchTreeResult(
        transformSearchResultToTree(
          readSearchResults(key) as TRiskArrayFullNode[],
          iconsMap,
        ),
      );
    },
    [addToSearchResults, handleSearchTreeResult, iconsMap, readSearchResults],
  );

  const clearState = useCallback(() => {
    originalCache = null;
    cache = null;
    requestsToUpdate.clear();
    init = false;
    uploadedNodes.clear();
    expanded = {};
  }, []);

  const setInitialTree = useCallback(
    (response: TRiskArrayTreeResponse | undefined) => {
      cache = response ? transformRiskArrayToTree(response.data) : null;

      if (cache) {
        originalCache = cloneDeep(cache);
        setTree(cloneDeep(originalCache));
      } else {
        originalCache = EMPTY_ARRAY;
      }
    },
    [],
  );

  const fetchAndInitTree = useCallback(
    async (params: Record<string, unknown> = {}) => {
      const { data: treeResponse, error } = await fetchTree({
        ...params,
        showExpired,
      });
      if (!error && treeResponse) {
        init = true;
        setInitialTree(treeResponse);
      }
    },
    [fetchTree, setInitialTree, showExpired],
  );

  const fetchAndInitSearchTree = useCallback(async () => {
    if (lastSearchQuery && lastSearchQuery !== search) {
      resetSkip();
      clearSearchResults(lastSearchQuery);
    }
    setTree([]);

    const res = await trigger({
      showExpired,
      search,
      limit: DEFAULT_LIMIT,
      skip: 0,
    });

    setInitialSearchTree(search, res.data);
    increaseSkip();
    lastSearchQuery = search;
  }, [
    clearSearchResults,
    increaseSkip,
    resetSkip,
    search,
    setInitialSearchTree,
    showExpired,
    trigger,
  ]);

  useEffect(() => clearState, [clearState]);

  const refetch = useCallback(
    async (
      refetchFullTree: boolean,
      requestParams: { showExpired: boolean },
    ) => {
      if (searchEnabled) {
        trigger({
          showExpired,
          search,
          limit,
          skip: 0,
        });
        return;
      }
      const refetchQueries: {
        name: string;
        path: string;
        index: string;
      }[] = [];

      keys(expanded)
        .filter((key) => expanded[key])
        .forEach((k) => {
          const currentNode = get(originalCache, toExpandedTreePath(k));
          if (!currentNode) {
            return;
          }

          const isNode = currentNode.type === rowTypes.node;
          const hasSubRows = currentNode.subRows?.length > 0;
          const shouldRefetchNode = isNode && hasSubRows;

          if (shouldRefetchNode) {
            refetchQueries.push(pick(currentNode, ['name', 'path', 'index']));
          }
        });

      if (refetchFullTree) {
        await fetchAndInitTree(requestParams);
      }

      refetchQueries.forEach((params) => {
        if (uploadedNodes.has(params.path)) {
          dispatch(
            riskArraysApi.endpoints.getRiskArrayNode.initiate(
              {
                ...params,
                ...requestParams,
                skip: 0,
                limit,
              },
              { subscribe: false, forceRefetch: true },
            ),
          );
        }
      });
    },
    [
      dispatch,
      fetchAndInitTree,
      limit,
      search,
      searchEnabled,
      showExpired,
      trigger,
    ],
  );

  const getIconsMap = useCallback(() => iconsMap, [iconsMap]);

  const insertNewNodes = useCallback(
    (
      response:
        | {
            data: TRiskArrayFullNode[];
            requestParams: {
              path?: string;
              index?: string;
              search?: string;
              showExpired?: boolean;
            };
          }
        | undefined,
    ) => {
      const newNodeHasBeenUploaded = Boolean(response?.data);
      const isCacheInitialized = cache && cache.length;

      if (isCacheInitialized && newNodeHasBeenUploaded) {
        const uploadedNodeIndex = response?.requestParams.index;
        const uploadedNodesExists = Boolean(response?.data?.length);
        const parentNode = get(cache, toExpandedTreePath(uploadedNodeIndex));
        const parentNodeHasNoSubRows = Boolean(!parentNode.subRows?.length);

        const toSubRowObject = (
          { displayName, id, isNode, ...rest }: TRiskArrayFullNode,
          i: number,
        ) => {
          const hasStrikes =
            Array.isArray(rest.strikes) && rest.strikes.length > 0;
          const strikes = hasStrikes
            ? rest.strikes?.map((item, strikeIndex) => ({
                ...item,
                index: `${uploadedNodeIndex}.${i}.${strikeIndex}`,
              }))
            : [];

          return {
            ...rest,
            name: hasStrikes ? displayName : `${displayName} (${id})`,
            index: `${uploadedNodeIndex}.${i}`,
            type: isNode || hasStrikes ? rowTypes.node : rowTypes.leaf,
            id,
            ...(hasStrikes ? { subRows: strikes } : EMPTY_OBJECT),
          };
        };

        if (parentNodeHasNoSubRows) {
          parentNode.subRows = uploadedNodesExists
            ? response?.data?.map(toSubRowObject)
            : EMPTY_ARRAY;
        } else {
          parentNode.subRows = response?.data?.map(toSubRowObject);
        }
      }
      if (cache) {
        originalCache = cloneDeep(cache);
        setTree(cloneDeep(cache));
      } else {
        originalCache = EMPTY_ARRAY;
        setTree(EMPTY_ARRAY);
      }
    },
    [],
  );

  useEffect(() => {
    if (!searchEnabled && !init) {
      fetchAndInitTree();
    }
  }, [fetchAndInitTree, searchEnabled]);

  useEffect(() => {
    if (searchEnabled) {
      fetchAndInitSearchTree();
    }
  }, [fetchAndInitSearchTree, searchEnabled]);

  const expandRow = useCallback(
    async (row: IRowExpand<ITreeNode>) => {
      expanded = {
        ...expanded,
        [row.id]: !expanded[row.id],
      };

      const isLeaf = row.original.type === rowTypes.leaf;

      if (isLeaf) {
        return;
      }

      const { original } = row;
      const {
        index: rowIndex,
        path: rowPath,
        type: rowType,
        subRows: rowSubRows,
      } = original;

      row.toggleRowExpanded();

      const cachedData = get(cache, toExpandedTreePath(rowIndex));
      const shouldUpdateCache =
        !cachedData ||
        (cachedData.type === rowTypes.node && cachedData.subRows.length === 0);

      if (shouldUpdateCache) {
        const nodeHasSubRows = Boolean(rowSubRows && rowSubRows.length);

        if (rowType === rowTypes.node && !nodeHasSubRows) {
          const response = await trigger({
            path: rowPath,
            index: rowIndex,
            showExpired,
            search,
          });
          uploadedNodes.add(rowPath);
          insertNewNodes(response.data);
        }
      }
    },
    [insertNewNodes, search, showExpired, trigger],
  );

  const getTree = useCallback(() => {
    return tree;
  }, [tree]);

  const getIsDirty = () => {
    const hasRequestsToUpdate = requestsToUpdate.size > 0;
    if (hasRequestsToUpdate) {
      const requests = [...requestsToUpdate.values()];
      const hasFieldsToUpdate = requests.some(
        (requestsMap) => requestsMap.size > 0,
      );

      return hasFieldsToUpdate;
    }
    return false;
  };

  const saveTree = useCallback(async () => {
    if (getIsDirty()) {
      setSaveLoading(true);
      const requests = prepareQueriesToUpdate({
        requestsToUpdate,
        cache,
        originalCache,
        setTree,
        tree,
      });
      const result = await Promise.all(requests.map(update));

      const responses = result.map((response) => {
        if ('error' in response) {
          return false;
        }

        return true;
      });

      const someRequestsFailed = responses.some((fulfilled) => !fulfilled);

      const resetToDefaultValues = () => {
        if (originalCache) {
          cache = cloneDeep(originalCache);
          setTree(cloneDeep(cache));
        }
      };

      const updateToNewValues = () => {
        if (cache) {
          originalCache = cloneDeep(cache);
          setTree(cloneDeep(cache));
        }
      };

      if (someRequestsFailed) {
        resetToDefaultValues();
      } else {
        updateToNewValues();
      }

      requestsToUpdate.clear();
      setSaveLoading(false);
    }
  }, [tree, update]);

  const getOriginalCache = () => originalCache;

  const addRequestToUpdate: IAddRequestToUpdate = ({
    name,
    path,
    id,
    value,
  }) => {
    const fullPath = `${toExpandedTreePath(path)}.${name}`;
    const requestToUpdateObject = {
      fullPath,
      id,
      value,
      path,
      fieldName: name,
    };

    if (requestsToUpdate.has(id)) {
      const requests = requestsToUpdate.get(id);

      requests.set(name, requestToUpdateObject);
    } else {
      const queriesToUpdateFields = new Map();

      queriesToUpdateFields.set(name, requestToUpdateObject);
      requestsToUpdate.set(id, queriesToUpdateFields);
    }
  };

  const removeRequestToUpdate: IRemoveRequestToUpdate = ({ id, name }) => {
    if (requestsToUpdate.has(id)) {
      const fields = requestsToUpdate.get(id);

      fields.delete(name);

      const hasNoFieldsToUpdate = requestsToUpdate.get(id).size === 0;
      if (hasNoFieldsToUpdate) {
        requestsToUpdate.delete(id);
      }
    }
  };

  const handleSetShowExpired = useCallback(async () => {
    setShowExpired((prev) => {
      const newValue = !prev;
      refetch(true, { showExpired: newValue });
      return newValue;
    });
  }, [refetch]);

  const getExpandedRows = useCallback(() => {
    return expanded;
  }, []);

  const getValue = useCallback(
    ({ name, path }: { name: string; path: string }) => {
      const obj = get(tree, toExpandedTreePath(path));
      return obj ? obj[name] : null;
    },
    [tree],
  );

  const isDirtyField = useCallback(
    (name: string, path: string) => {
      const value = getValue({ name, path });
      const fullPath = `${toExpandedTreePath(path)}.${name}`;
      const res = get(originalCache, fullPath) !== value;

      return res;
    },
    [getValue],
  );

  const handleSetSearch = useCallback(
    async (value: string) => {
      setSearch(value);
      if (!value) {
        expanded = {};
        const { data: treeResponse, error } = await fetchTree({ showExpired });
        if (!error && treeResponse) {
          setInitialTree(treeResponse);
        }
      }
    },
    [fetchTree, setInitialTree, showExpired],
  );

  const handleLoadNext = useCallback(() => {
    loadNext(trigger, { showExpired, search }, (response) => {
      setInitialSearchTree(search, response.data);
    });
  }, [loadNext, search, setInitialSearchTree, showExpired, trigger]);

  return {
    getTree,
    setTree,
    expandRow,
    getIconsMap,
    saveTree,
    isLoading:
      saveLoading || isLoading || isFetching || isNodeFetching || isNodeLoading,
    isChunkFetching: isNodeFetching,
    getOriginalCache,
    addRequestToUpdate,
    removeRequestToUpdate,
    setSearch: handleSetSearch,
    search,
    showExpired,
    refetch,
    setShowExpired: handleSetShowExpired,
    getExpandedRows,
    isDirtyField,
    getIsDirty,
    loadNext: handleLoadNext,
    pagination: {
      total: node?.pagination.total || 0,
      skip,
      limit,
      setLimit,
      showPagination,
    },
  };
};
