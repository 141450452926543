import { IFetchDataResult } from 'react-ui-kit-exante/build/Components/Inputs/AutocompleteAsync/types';

import { IOptionsArguments } from '~/shared/utils';

export type TRecordWithUndefined<T> = {
  [P in keyof T]: T[P] | undefined;
};

export type ActionMap<M extends { [index: string]: unknown }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export type AutoCompleteSearch = (
  search: string,
  options: IOptionsArguments,
) => Promise<IFetchDataResult>;

export enum RowType {
  Node = 'node',
  Instrument = 'instrument',
}
