import { ITypesResponse, ITypesWithDefaultResponse } from '~/types/types';

export const DEFAULT_ENTITY: ITypesResponse = { values: [] };
export const DEFAULT_ENTITY_WITH_DEFAULT_VALUE: ITypesWithDefaultResponse = {
  ...DEFAULT_ENTITY,
  default: '',
};
export const DEFAULT_SEX_ENTITY: ITypesResponse = {
  values: ['male', 'female', 'other'],
};
export const DEFAULT_USER_TYPE: ITypesResponse = {
  values: ['trader', 'internal', 'attorney'],
};
export const USER_TYPE_MAP: Record<string, string> = {
  trader: 'Authorised Trader',
  attorney: 'Power of Attorney',
  internal: 'Employee',
};
export const DEFAULT_CORPORATE_CATEGORY: ITypesResponse = {
  values: [
    'Not related to financial markets',
    'Elective professional',
    'Large undertaking',
    'National / Governmental company',
    'Company is required to be authorized',
    'Institutional investor',
  ],
};

export const DEFAULT_ACCOUNT_PURPOSES = {
  values: [],
  default: '',
};
