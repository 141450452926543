import { FETCH_PROXY_AUTH_DB } from '~/constants';
import { apiRequest } from '~/shared/utils';
import { IServicesParams } from '~/types/services';

import { FETCH_SERVICES } from './constants';
import { getUpdateServicePermissionAPI } from './endpoints';
import { IServicesResponse, UserService, UserServicesResponse } from './types';

export class ServicesRepository {
  public static async fetchServices() {
    return apiRequest<IServicesResponse>({ url: FETCH_SERVICES });
  }

  public static async fetchUserServices(userId: number) {
    const response = await apiRequest<UserServicesResponse>({
      url: `${FETCH_PROXY_AUTH_DB}1.0/auth/user_services`,
      params: {
        userid: userId,
      },
    });
    return response;
  }

  public static addService(services: string[], userId: string) {
    return apiRequest<UserService>({
      url: `${FETCH_PROXY_AUTH_DB}1.0/auth/user_services`,
      method: 'POST',
      data: services.map((serviceName) => ({
        service: serviceName,
        userid: userId,
      })),
    });
  }

  public static deleteService(serviceBindingId: number) {
    return apiRequest<void>({
      url: `${FETCH_PROXY_AUTH_DB}1.0/auth/user_services/${serviceBindingId}`,
      method: 'DELETE',
    });
  }

  public static addServicePermission(
    userName: string,
    serviceName: string,
    params: IServicesParams,
  ) {
    return apiRequest({
      url: getUpdateServicePermissionAPI(userName, serviceName),
      method: 'POST',
      data: params,
    });
  }

  public static deleteServicePermission(userName: string, serviceName: string) {
    return apiRequest({
      url: getUpdateServicePermissionAPI(userName, serviceName),
      method: 'DELETE',
    });
  }
}
