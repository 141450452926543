import { availableRequestParams } from '~/resources/auditLog/constants';

export class AuditLogsParamsError extends Error {
  constructor(invalidParam: string | string[]) {
    super();
    this.message = `Invalid params. Supported params: [${[
      ...availableRequestParams,
    ]}]. Received: [${invalidParam}]`;
  }
}
