import { useMemo } from 'react';

import { AccountPermissionsContainer } from '~/containers/AccountPermissionsContainer';
import { UserAccessRightsContainer } from '~/containers/UserAccessRightsContainer';
import { UserActivityContainer } from '~/containers/UserActivityContainer';
import { UserGeneralSettingsContainer } from '~/containers/UserGeneralSettingsContainer';
import { usePickUserPermissions } from '~/hooks';
import { UserPasswordManagementPage } from '~/pages/UserPasswordManagementPage';
import { ITab } from '~/types/tabs';

import { TTabsIds } from '../context/types';

import { IUserTabComponentProps } from './types';

export function useUserTabs() {
  const userPermissions = usePickUserPermissions([
    'User BO permissions',
    'User account status',
    'User info',
    'User passwords create/delete/change',
    'User permissions on services',
  ]);

  return useMemo(() => {
    const tabsArray: ITab<TTabsIds, IUserTabComponentProps>[] = [];

    if (userPermissions['User info'].read) {
      tabsArray.push({
        id: 'general',
        title: 'Info',
        Component: UserGeneralSettingsContainer,
      });
    }

    if (
      userPermissions['User permissions on services'].read &&
      userPermissions['User BO permissions'].read
    ) {
      tabsArray.push({
        id: 'accessRights',
        title: 'Access rights',
        Component: UserAccessRightsContainer,
      });
    }

    if (
      userPermissions['User passwords create/delete/change'].write &&
      userPermissions['User permissions on services'].read
    ) {
      tabsArray.push({
        id: 'passwordManagement',
        title: 'Password management',
        Component: UserPasswordManagementPage,
      });
    }

    if (userPermissions['User account status'].read) {
      tabsArray.push({
        id: 'accountPermissions',
        title: 'Account permissions',
        Component: AccountPermissionsContainer,
      });
    }

    tabsArray.push({
      id: 'activity',
      title: 'Activity',
      Component: UserActivityContainer,
    });

    return tabsArray;
  }, [userPermissions]);
}
