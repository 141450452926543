import qs from 'qs';

import { AUTHORIZATION_HEADER_KEY, SESSION_ID_HEADER_KEY } from '~/constants';

import { removeEmptyStrings } from '../removeEmptyStrings';

import { TAccessToken } from './types';

export function getDefaultHeaders(
  sessionId = '',
  accessToken = '',
  isTransferValidationOff = false,
) {
  // temporary "crutch", we are waiting for a fix on the backend
  // so that validation can be turned off in the request body
  let baseHeaders: { 'Content-Type': string; 'x-use-validation'?: string } = {
    'Content-Type': 'application/json',
  };

  if (isTransferValidationOff) {
    baseHeaders = { ...baseHeaders, 'x-use-validation': 'false' };
  }

  if (sessionId) {
    return { ...baseHeaders, [SESSION_ID_HEADER_KEY]: sessionId };
  }
  if (accessToken) {
    try {
      const { access_token: token }: TAccessToken = JSON.parse(accessToken);
      return {
        ...baseHeaders,
        [AUTHORIZATION_HEADER_KEY]: `Bearer ${token}`,
      };
    } catch (error) {
      console.error(`Access token error: ${error}`);
    }
  }

  return baseHeaders;
}

export function paramsSerializer(params: Record<string, unknown>) {
  const paramsWithoutEmptyStrings = removeEmptyStrings(params);

  return qs.stringify(paramsWithoutEmptyStrings, {
    arrayFormat: 'comma',
    skipNulls: true,
  });
}
