import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryHandler } from '~/shared/utils';
import { ICommissionRule } from '~/types/commisionRules';

const COMMISSION_RULES_ENDPOINT = '/api/symbols/commissions/rules';

export const commissionRulesApi = createApi({
  reducerPath: 'commissionRulesApi',
  baseQuery: baseQueryHandler,
  tagTypes: ['CommissionRules'],
  endpoints: (builder) => ({
    getCommissionRules: builder.query<ICommissionRule[], void>({
      query: () => ({
        url: COMMISSION_RULES_ENDPOINT,
      }),
      providesTags: ['CommissionRules'],
    }),
    addCommissionRule: builder.mutation({
      query: (data) => ({
        url: COMMISSION_RULES_ENDPOINT,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['CommissionRules'],
    }),
    editCommissionRule: builder.mutation({
      query: ({ name, data }) => ({
        url: `${COMMISSION_RULES_ENDPOINT}/${name}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: ['CommissionRules'],
    }),
    deleteCommissionRule: builder.mutation({
      query: (name) => ({
        url: `${COMMISSION_RULES_ENDPOINT}/${name}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['CommissionRules'],
    }),
  }),
});

export const {
  useAddCommissionRuleMutation,
  useDeleteCommissionRuleMutation,
  useGetCommissionRulesQuery,
  useEditCommissionRuleMutation,
} = commissionRulesApi;
