import { ICellValue, IColumn } from 'react-ui-kit-exante';

import { EMPTY_ARRAY, typeIconNames } from '~/constants';
import { PositiveNegative } from '~/shared/components/PositiveNegative';
import { TotalFooter } from '~/shared/components/TotalFooter';
import {
  defaultLocaleFormatter,
  formatCurrency,
  formatPercentage,
} from '~/shared/utils';
import { formatCellToCurrency } from '~/shared/utils/formatters/formatCellToCurrency';
import { calculateColumnValuesSum } from '~/shared/utils/table';
import { IPosition } from '~/types/accounts';

import { DescriptionFooter } from './components/DescriptionFooter';
import { InstrumentCell } from './components/InstrumentCell';
import { CASH_GROUP } from './constants';

export const DEFAULT_DISPLAYED_COLUMN_KEYS = [
  'symbolId',
  'description',
  'quantity',
  'averagePrice',
  'price',
  'currency',
  'pnl',
  'convertedPnl',
  'value',
  'convertedValue',
  'blockedQtty',
];

export function getColumns(currency: string): IColumn<IPosition>[] {
  return [
    {
      Header: 'Instrument',
      accessor: 'symbolId',
      Cell: ({
        row: {
          values,
          original: { type, expirationTime },
        },
      }: ICellValue<IPosition>) => (
        <InstrumentCell
          iconName={typeIconNames[type]}
          asset={values.symbolId}
          expirationDate={expirationTime}
        />
      ),
      Footer: TotalFooter,
    },
    {
      Header: 'Description',
      accessor: 'description',
      Cell: ({
        row: {
          values,
          original: { type, expirationTime },
        },
      }: ICellValue<IPosition>) =>
        type === CASH_GROUP ? (
          <InstrumentCell
            iconName={typeIconNames.CASH}
            asset={values.currency}
            expirationDate={expirationTime}
          />
        ) : (
          values.description
        ),
      Footer: DescriptionFooter,
    },
    {
      Header: 'Qtty',
      accessor: 'quantity',
      Cell: formatCellToCurrency<IPosition>('quantity'),
      formatting: 'number',
    },
    { Header: 'Avg. Price', accessor: 'averagePrice', formatting: 'number' },
    { Header: 'Price', accessor: 'price', formatting: 'number' },
    { Header: 'CCY', accessor: 'currency' },
    {
      Header: 'P&L',
      accessor: 'pnl',
      Cell: ({ row: { values, original } }: ICellValue<IPosition>) => (
        <PositiveNegative
          value={values.pnl}
          expirationDate={original.expirationDate}
        />
      ),
      formatting: 'number',
    },
    {
      Header: `P&L, ${currency}`,
      accessor: 'convertedPnl',
      Cell: ({ row: { values, original } }: ICellValue<IPosition>) => (
        <PositiveNegative
          value={values.convertedPnl}
          expirationDate={original.expirationDate}
        />
      ),
      Footer: calculateColumnValuesSum<IPosition>('convertedPnl', {
        colored: true,
      }),
      formatting: 'number',
    },
    {
      Header: 'P&L, %',
      accessor: 'pnlPercentage',
      Cell: ({ row: { values, original } }: ICellValue<IPosition>) => (
        <PositiveNegative
          value={values.pnlPercentage}
          valueFormatter={formatPercentage}
          expirationDate={original.expirationDate}
        />
      ),
    },
    {
      Header: 'Value',
      accessor: 'value',
      Cell: formatCellToCurrency<IPosition>('value'),
      formatting: 'number',
    },
    {
      Header: `Value, ${currency}`,
      accessor: 'convertedValue',
      Cell: ({ row: { values } }: ICellValue<IPosition>) =>
        formatCurrency(defaultLocaleFormatter, values.convertedValue),
      Footer: calculateColumnValuesSum<IPosition>('convertedValue'),
      formatting: 'number',
    },
    {
      Header: 'Daily P&L',
      accessor: 'dailyPnl',
      Cell: ({ row: { values, original } }: ICellValue<IPosition>) => (
        <PositiveNegative
          value={values.dailyPnl}
          expirationDate={original.expirationDate}
        />
      ),
      formatting: 'number',
    },
    {
      Header: `Daily P&L, ${currency}`,
      accessor: 'convertedDailyPnl',
      Cell: ({ row: { values, original } }: ICellValue<IPosition>) => (
        <PositiveNegative
          value={values.convertedDailyPnl}
          expirationDate={original.expirationDate}
        />
      ),
      Footer: calculateColumnValuesSum<IPosition>('convertedDailyPnl'),
      formatting: 'number',
    },
    {
      Header: 'Daily trades P&L',
      accessor: 'dailyTradesPnl',
      formatting: 'number',
    },
    { Header: 'Price unit', accessor: 'priceUnit' },
    {
      Header: 'Expiration date',
      accessor: 'expirationDate',
      formatting: 'date',
    },
    { Header: 'Id', accessor: 'id' },
    { Header: 'Contract multiplier', accessor: 'contractMultiplier' },
    {
      Header: 'Share percent',
      accessor: 'sharePercent',
      Cell: ({ row: { values } }: ICellValue<IPosition>) =>
        formatPercentage(values.sharePercent),
      formatting: 'number',
    },
    { Header: 'Expiration time', accessor: 'expirationTime' },
    { Header: 'Realized P&L', accessor: 'realizedPnl', formatting: 'number' },
    {
      Header: `Daily trades P&L, ${currency}`,
      accessor: 'convertedDailyTradesPnl',
      formatting: 'number',
    },
    {
      Header: 'Daily pnl %',
      accessor: 'dailyPnlPercentage',
      Cell: ({ row: { values } }: ICellValue<IPosition>) =>
        formatPercentage(values.dailyPnlPercentage),
    },
    { Header: 'Sedol', accessor: 'sedol' },
    { Header: 'Exchange', accessor: 'exchange' },
    { Header: 'Short name', accessor: 'shortName' },
    { Header: 'Ticker', accessor: 'ticker' },
    { Header: 'Isin', accessor: 'isin' },
    { Header: 'Figi', accessor: 'figi' },
    { Header: 'Cusip', accessor: 'cusip' },
    { Header: 'Accrued interest', accessor: 'accruedInterest' },
    {
      Header: `Accrued interest, ${currency}`,
      accessor: 'convertedAccruedInterest',
    },
    {
      Header: 'Quote timestamp',
      accessor: 'quoteTimestamp',
      formatting: 'dateTime',
    },
  ];
}

export function getExistingColumns(
  positions: IPosition[],
  columns: IColumn<IPosition>[],
  requiredColumnKeys?: string[],
): IColumn<IPosition>[] {
  const position = positions[0];

  if (!position) {
    return EMPTY_ARRAY;
  }

  const allFields = Object.keys(position).reduce<Record<string, boolean>>(
    (acc, curr) => ({ ...acc, [curr]: true }),
    {},
  );

  return columns.reduce((acc, curr) => {
    if (requiredColumnKeys && requiredColumnKeys.includes(curr.accessor)) {
      return [...acc, { ...curr, required: true }];
    }

    if (allFields[curr.accessor]) {
      return [...acc, curr];
    }

    return acc;
  }, []);
}
