import { PROXY_CORE_PATH } from '~/constants';
import { formatDate } from '~/shared/utils';
import { TMapper } from '~/types/api';

export const FETCH_CARDS = `${PROXY_CORE_PATH}v2.0/intercash_cards`;

export const CARDS_BODY_MAPPER: TMapper = {
  issueId: {
    parser: (value?: string | null) => ({
      issueId: value === null ? undefined : value,
    }),
  },
  expiryDate: {
    parser: (date: string) => ({
      expiryDate: date ? formatDate(new Date(date)) : null,
    }),
  },
};
