import { UploadMark } from '../../components/UploadMark';

export function getUploadActionsTrades(
  handleUploadTrades: () => void,
  hasErrorResponse: boolean,
  allRowsUploadSuccessfully: boolean,
  uploadText: string,
) {
  return allRowsUploadSuccessfully || hasErrorResponse
    ? undefined
    : [
        {
          key: 'upload',
          children: <UploadMark>{uploadText}</UploadMark>,
          onClick: handleUploadTrades,
        },
      ];
}
