import { createApi } from '@reduxjs/toolkit/query/react';
import { Notification } from 'react-ui-kit-exante';

import { baseQueryHandler } from '~/shared/utils';
import { IIntermonthSpreadMargin } from '~/types/dictionary';

import { endpoints } from './constants';

type GetAllUnderlyingParams = Partial<{
  skip: number;
  limit: number;
  underlying: string;
  order: 'asc' | 'desc';
  orderBy: string;
}>;
type GetAllUnderlyingResponse = {
  data: IIntermonthSpreadMargin[];
  pagination: { total: number };
};

export const underlyingApi = createApi({
  reducerPath: 'underlyingApi',
  baseQuery: baseQueryHandler,
  tagTypes: ['Underlying'],
  endpoints: (builder) => ({
    getUnderlying: builder.query<IIntermonthSpreadMargin[], void>({
      query: () => ({
        url: endpoints.base,
      }),
    }),
    getAllUnderlying: builder.query<
      GetAllUnderlyingResponse,
      GetAllUnderlyingParams
    >({
      query: (params) => ({
        url: endpoints.allUnderlying,
        params,
      }),
      providesTags: ['Underlying'],
    }),
    addUnderlying: builder.mutation({
      query: (data) => ({
        url: endpoints.base,
        method: 'POST',
        data,
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Notification.success({
            title: 'Successfully edited',
          });
        } catch (e) {
          console.error(e);
        }
      },
    }),
  }),
});

export const {
  useLazyGetAllUnderlyingQuery,
  useGetAllUnderlyingQuery,
  useGetUnderlyingQuery,
  useAddUnderlyingMutation,
  useLazyGetUnderlyingQuery,
} = underlyingApi;
