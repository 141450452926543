import { ICommissionRules } from '~/types/commissions';

interface IRules {
  internal: Record<string, string>;
  rebate: Record<string, string>;
  exchange: Record<string, string>;
}

export function generateRulesData(data: ICommissionRules) {
  const rules: IRules = {
    internal: {},
    rebate: {},
    exchange: {},
  };

  Object.entries(data).forEach(([key, value]) => {
    rules[value.type][key] = value.rate;
  });

  return rules;
}
